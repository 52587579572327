import React, { useEffect } from 'react'
import "./css/ContractToHire.scss"
import { Link } from 'react-router-dom'
import { contractToHireForTalent_1, contractToHireForTalent_2,contractToHireForTalent_3 } from '../../assets'
const ContractToHireForTalent = () => {

    useEffect(() => {
        window.scroll(0,0);
    },[])
    return (
        <div className="contract-container">
            {/* Hero Section */}
            <div className="contract-hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Interested in full-time opportunities? Let’s make it happen.</h1>
                        <p>If you're interested in full-time work, you can now explore contract-to-hire opportunities on Workcroft.</p>
                        <Link to="/matchedHourlyProject/all">
                        <button className="primary-btn">Find-contract-to-hire opportunities</button>
                        </Link>
                    </div>
                    <p className='opportunities'>Are you hiring for full time?</p>
                    <p className="signup-text">
                        <Link to="/contract-to-hire" className="signup-link">See how contract-to-hire works for clients talent?</Link>
                    </p>
                </div>
                <div className="hero-image">
                    <img src={contractToHireForTalent_1} alt="Person working on laptop" />
                </div>
            </div>

            <section className="how-it-works">
                <div className="how-it-works__content">
                    <div className="how-it-works__image">
                        <img src={contractToHireForTalent_2} alt="Team meeting in modern office" />
                    </div>
                    <div className="how-it-works__steps">
                        <h2>From contract to hire: Here's how contract-to-hire works on Workcroft.</h2>
                        <div className="steps">
                            <div className="step">
                                <span className="step__number">1</span>
                                <div>
                                    <h3>Find your fit</h3>
                                    <p>Look for jobs with contract-to-hire potential, which begin with a short-term contract and could transition into a full-time position.</p>
                                </div>
                            </div>
                            <div className="step">
                                <span className="step__number">2</span>
                                <div>

                                    <h3>Let your fit find you</h3>
                                    <p>Emphasize your goals and preferences for contract-to-hire opportunities in your profile.</p>
                                </div>
                            </div>
                            <div className="step">
                                <span className="step__number">3</span>
                                <div>

                                    <h3>Work with clients</h3>
                                    <p>This is a chance for both you and the client to experience a working relationship and assess compatibility.</p>
                                </div>
                            </div>
                            <div className="step">
                                <span className="step__number">4</span>
                                <div>

                                    <h3>Decide whether to go full time</h3>
                                    <p>If both parties agree to transition to a full-time arrangement, the client will request to convert your contract on Workcroft—simply accept the request to finalize the change.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="reasons">
                <div className="reasons__content">
                    <div className="reasons__text">
                        <h2>Why contract-to-hire?</h2>

                        <div className="reasons__list">
                            <div className="reason">
                                <h3>Maximize your potential on your terms </h3>
                                <p>
                                Develop relationships with clients that can evolve with you, from freelance and fractional work to contract-to-hire and full-time positions.
                                </p>
                            </div>

                            <div className="reason">
                                <h3>Get to work quickly</h3>
                                <p>
                                Start in as little as a day with a short-term project that could lead to a full-time opportunity.
                                </p>
                            </div>

                            <div className="reason">
                                <h3>Find your perfect fit</h3>
                                <p>
                                Before committing to a full-time role, test the waters by working on a short-term project with the business.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="reasons__image">
                        <img
                            src={contractToHireForTalent_3}
                            alt="Team meeting in modern office space"
                        />
                    </div>
                </div>
            </section>


        </div>
    )
}

export default ContractToHireForTalent