// src/components/JobCategorySelect.js
import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./css/projectcategory.css";
import axios from "axios";

const ProjectCategorySelector = ({setSelectedOption,setSpecializationData,resetSpecialization,selectedData,}) => {
  const [options, setOptions] = useState([]);

  const getcategoryData = async () => {
    try {
      const response = await axios.get(`/api/data/project-category`);
      //console.log(response);
      const options = response?.data?.map((cat, indx) => ({
        value: cat,
        label: cat,
      }));
      //console.log(options, "categories");
      setOptions(options);
    } catch (error) {
      console.error("Error fetching job categories:", error);
    }
  };

  useEffect(() => {
    getcategoryData();
  }, []);

  const getdata = async (value) => {
    try {
      const response = await axios.get(`/api/data/specialization`, {
        params: { profileCategory: value },
      });
      //console.log(response);
      const options = response?.data?.map((sp, indx) => ({
        value: sp,
        label: sp,
      }));
      //console.log(options, "specialization");
      setSpecializationData(options);
    } catch (error) {
      console.error("Error fetching job categories:", error);
    }
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    getdata(option?.value);
    resetSpecialization("");
  };
  return (
    <div className="job-select-container">
      <Select
        options={options}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Select Category.."
      />
    </div>
  );
};

export default ProjectCategorySelector;
