import React, { useState } from "react";

const SeeMoreLess = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedText = isExpanded
    ? text
    : text.substring(0, maxLength) + (text.length > maxLength ? "..." : "");

  return (
    <div>
      <p>{displayedText}</p>
      {text.length > maxLength && (
        <p
          style={{
            color: "#2092c7",
            marginTop: "-10px",
            cursor: "pointer",
          }}
          onClick={toggleIsExpanded}
        >
          {isExpanded ? "Less" : "More"}
        </p>
      )}
    </div>
  );
};

export default SeeMoreLess;
