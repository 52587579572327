import React, { useEffect, useState } from "react";
import "./css/HireWorldWide.scss";
import { Link } from "react-router-dom";
import { dollarCoin, rocket, search, solarsShield, downArrow } from "../../assets";
const HireWorldWide = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    const steps = [
        {
            number: `${solarsShield}`,
            title: "Over 1M reviews",
            description: "Build connections with top-rated professionals",
        },
        {
            number: `${rocket}`,
            title: "Protected payments",
            description: "Effortless billing so you can concentrate on meaningful work",
        },
        {
            number: `${dollarCoin}`,
            title: "Hire who you need",
            description: "Discover professionals who can start immediately and tackle any task",
        },
    ];
    const [openSection, setOpenSection] = useState("");

    const toggleSection = (section) => {
        setOpenSection(openSection === section ? "" : section);
    };
    const [activeIndices, setActiveIndices] = useState(new Set());

    const allCategories = [
        { icon: "Development & IT", title: "Development & IT", services: ["Web Development", "Software Development", "iOS App Development", "Android App Development", "Game Development", "Web Development", "Software Development", "iOS App Development", "Android App Development", "Game Development", "Web Development", "Software Development", "iOS App Development", "Android App Development", "Game Development"] },
        { icon: "Design & Creative", title: "Design & Creative", services: ["Mobile App Design", "Web Development"] },
        { icon: "Sales & Marketing", title: "Sales & Marketing", services: ["Marketing", "Sales"] },
        { icon: "Writing & Translation", title: "Writing & Translation", services: ["Content Writing", "Translation"] },
        { icon: "Admin & Customer Support", title: "Admin & Customer Support", services: ["Customer Support", "Administrative Tasks"] },
        { icon: "Finance & Accounting", title: "Finance & Accounting", services: ["Accounting", "Financial Reporting"] },
    ];

    const toggleAccordion = (index) => {
        console.log(index);
        
        const newActiveIndices = new Set(activeIndices);
        if (newActiveIndices.has(index)) {
            newActiveIndices.delete(index);
        } else {
            newActiveIndices.add(index);
        }
        setActiveIndices(newActiveIndices);
    };


    return (
        <div className="hire-worldwide-container">
            {/* Hero Section */}
            <div className="hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Employ professionals with the skills you require</h1>
                        <p>A simple search connects you with independent professionals and teams ready to complete your job.</p>
                    </div>

                    <div className="inputBox">
                        <p>Try “Web Design”</p>

                        <div className="searchBtn">
                            <img src={search} alt="" className="searchIcon" />
                        </div>
                    </div>
                    <p className="signup-text">
                        Looking for work?
                        <Link to="" className="signup-link">
                            Apply as an independent pro
                        </Link>
                    </p>
                </div>
                <div className="hero-image">
                    {/* <img src={directContract_1} alt="Person working on laptop" /> */}
                </div>
            </div>

            <section className="how-it-works">
                <h2>Safe and secure hiring, no matter the scale of the work</h2>
                <div className="steps-container">
                    {steps.map((step, index) => (
                        <div key={index} className="step-item">
                            <div className="step-number">
                                <img src={step.number} alt="" />
                            </div>
                            <div className="content">
                                <h3>{step.title}</h3>
                                <p>{step.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className="how-it-works">
                <h2>Choose a category to see popular skills for hire</h2>
                <div className="buttons">
                    <button className="soloo"><Link to="">Solo professionals</Link></button>
                    <button className="team"><Link to="">Teams</Link></button>
                </div>

                <p className="otherContent">Bringing on a professional is ideal for expanding your team or embarking on a project.</p>

            </section>

            <div className="howoitwork_accordion">
                {allCategories.map((category, index) => (
                    <div
                        key={index}
                        className={`howoitwork_accordion-item ${activeIndices.has(index) ? 'active' : ''}`}
                    >
                        <button
                            className="howoitwork_accordion-button"
                            onClick={() => toggleAccordion(index)}
                        >
                            <span className="howoitwork_arrow-icon">
                                <img src={downArrow} alt="" />
                            </span>
                            <span className="howoitwork_accordion-title">{category.title}</span>
                        </button>

                        <div className="howoitwork_accordion-content">
                            <div className="howoitwork_services-grid">
                                {category.services.map((service, serviceIndex) => (
                                    <div key={serviceIndex} className="howoitwork_service-item">
                                        {service}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            
        </div >
    );
};

export default HireWorldWide;
