import React from "react";
import Select from "react-select";
import "./css/projectcategory.css";

const FreelancerDropDown = ({
  setSelectedOption,
  optionData,
  selectedData,
}) => {
  const handleChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="job-select-container">
      <Select
        options={optionData}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Freelancers Needed"
      />
    </div>
  );
};
const DurationDropDown = ({
  setSelectedOption,
  optionData,
  selectedData,
}) => {
  const handleChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="job-select-container">
      <Select
        options={optionData}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Duration"

      />
    </div>
  );
};
const DurationTypeDropDown = ({
  setSelectedOption,
  optionData,
  selectedData,
}) => {
  const handleChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="job-select-container">
      <Select
        options={optionData}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Month/Week"

      />
    </div>
  );
};
const MinimumBudgetForHourlyProject = ({
  setSelectedOption,
  optionData,
  selectedData,
}) => {
  const handleChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="job-select-container">
      <Select
        options={optionData}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Min"

      />
    </div>
  );
};
const MaximumBudgetForHourlyProject = ({
  setSelectedOption,
  optionData,
  selectedData,
}) => {
  const handleChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="job-select-container">
      <Select
        options={optionData}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Max"

      />
    </div>
  );
};
const MinimumBudgetForFixedCodeProject = ({
  setSelectedOption,
  optionData,
  selectedData,
}) => {
  const handleChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="job-select-container">
      <Select
        options={optionData}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Min"

      />
    </div>
  );
};
const MaximumBudgetForFixedCodeProject = ({
  setSelectedOption,
  optionData,
  selectedData,
}) => {
  const handleChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="job-select-container">
      <Select
        options={optionData}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Max"

      />
    </div>
  );
};


export  {FreelancerDropDown,DurationDropDown,DurationTypeDropDown,MinimumBudgetForFixedCodeProject,MaximumBudgetForFixedCodeProject,MinimumBudgetForHourlyProject,MaximumBudgetForHourlyProject};
