import React from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";

const AddSkillsSelector = ({
  setSkillVissible,
  skill,
  setSkill,
  skillVissible,
}) => {
  const handleOnSelect = (selectValue) => {
    if (selectValue?.value === "Other") {
      setSkill({ ...skill, name: "Other" });
      setSkillVissible(true);
    } else {
      setSkillVissible(false);
      setSkill({ ...skill, name: selectValue.value });
    }
  };
  // Function to load options from API
  const loadOptions = async (inputValue) => {
    if (!inputValue?.trim()) {
      return [];
    }

    try {
      const response = await axios.get(`/api/data/skills`, {
        params: { keyword: inputValue },
      });

      if (Array.isArray(response.data)) {
        //setResultData(response.data);
        return response.data.map((skill) => ({
          label: skill, // Adjust based on your API's response structure
          value: skill, // Adjust based on your API's response structure
        }));
      } else {
        console.error("Unexpected response format:", response.data);
      }

      // Map the API response to the format expected by react-select
    } catch (error) {
      console.error("Error fetching skills:", error);
      return [];
    }
  };

  // Render the AsyncSelect component
  return (
    <AsyncSelect
      name="name"
      cacheOptions
      defaultOptions
      value={{
        label: skill.name,
        value: skill.name,
      }}
      loadOptions={loadOptions}
      onChange={handleOnSelect}
      placeholder="Select a skill..."
    />
  );
};

export default AddSkillsSelector;
