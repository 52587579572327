import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "../css/dialog.css";
import { BiMessage, BiRightArrowCircle } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import toast from "react-hot-toast";
import axios from "axios";

function SimpleDialogNew(props) {
  const { candidates, appliedJobId, employerId,mainId, open, onClose } = props;
  console.log(candidates, appliedJobId,employerId,mainId,"props");
  const [message, setMessage] = React.useState("");
  const [resume1,setResume1] = React.useState(null);
  const textareaRef = React.useRef(null);
  const handleClose = () => {
    setMessage("");
    onClose();
  };
  React.useEffect(() => {
    if (open && mainId) {
      const fetchResume = async () => {
        try {
          const response = await axios.get(`/api/employer/getNameFromResumeApplied/${mainId}`);
          setResume1(response.data.resume);
        } catch (err) {
          toast.error("Unable to fetch resume data");
        }
      };

      fetchResume();
    }
  }, [open ,mainId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message && message.length > 3500) {
      textareaRef.current.focus();
      return;
    }
    else{

      try {
        const response = await axios.post(
          "/api/employer/messagecandidatenewnew",
        //   { sender: employerId, receiver: candidates.map((i) => [i._id, i.user]), appliedJobId, message },
          { sender: employerId, receiver: [[mainId,candidates]], appliedJobId, message },
          {
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
          }
        );
        const data = await response.data;
        toast.success(data.message);
        setMessage("");
      } catch (err) {
        toast.error("Unable to send message");
      } finally {
        handleClose();
      }
    }
  };

  return (
    <Dialog className="dialog" onClose={handleClose} open={open}>
      <div className="dialog-container">
        <div className="title">
          <BiMessage /> Workcroft
          <span style={{ cursor: "pointer" }} onClick={handleClose}>
            <RxCross1 />
          </span>
        </div>

        <div className="candidate-list">
          <span>Message to:</span>
          {/* {candidates.map((candidate) => (
            <span>
              {candidate?.resume?.PersonalDetails?.firstName} {candidate?.resume?.PersonalDetails?.lastName}
            </span>
          ))} */}
          
            <span>
              {resume1?.PersonalDetails?.firstName} {resume1?.PersonalDetails?.lastName}
            </span>
          
        </div>

        <form onSubmit={handleSubmit} className="w-100">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write a message..."
            className="w-100"
            name=""
            rows="10"
            style={{ borderColor: message && message?.length > 3500 ? 'red' : '', paddingLeft: '20px' }}
            ref={textareaRef}
          ></textarea>
          {message && message?.length > 3500 ?
            <p className='text-end' style={{ fontSize: "13px", color: "red" }}>Please limit your input to 3500 characters or less</p>
            : ""
          }
          {/* <span>Max file size: 10MB. File type allowed: zip, pdf, doc, docx, jpeg, jpg, png</span> */}
          <button type="submit"> Send Message </button>
        </form>
      </div>
    </Dialog>
  );
}

export default SimpleDialogNew;
