import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../Student_New/UserApplications.scss";
import "../Student_New/Jobs.scss";
import PropagateLoader from "react-spinners/PropagateLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowForward, IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { MONTH_DETAILS } from "../data";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Grid } from "@mui/material";
import "./AdminDashboard.css";
import { LuClipboardEdit } from "react-icons/lu";
import { Spinner } from "react-bootstrap";
import { MdOutlineEdit, MdOutlineVerifiedUser } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { loginUserForAdminEmployee } from "../../actions/userActions";
import { RiDeleteBinLine } from "react-icons/ri";
import Popup from "reactjs-popup";
import PaginationWithLastButton from "../Student_New/PaginationWithLastButton";

function AdminEmployers() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [employerData, setEmployerData] = useState("");
    const [sortByJobs, setSortByJobs] = useState(null);
    const [sortByActiveJobs, setSortByActiveJobs] = useState(null);
    const initialForm = {
        _id: "",
        name: "",
        phone: "",
        city: "",
        organisationName: "",
    };
    const initialBalance = {
        _id: "",
        connectCredit: "",
        connectSpent: "",
    };
    const [employee, setEmployee] = useState(initialForm);
    const [balanceConnect, setBalanceConnect] = useState(initialBalance);
    const [totalItem, setTotalItem] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loginType, setLoginType] = useState("Employer");
    const [sortField, setSortField] = useState(null); // State for sorting field
    const [sortOrder, setSortOrder] = useState(null);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const borderRemoveRef = useRef(null);
    const [totalpages, settotalpages] = useState(1);

    const modalRef = useRef(null);
    const handleLogin = async (userId) => {
        if (loginType === "Employer") {
            // Assuming loginUser is an action creator
            await dispatch(loginUserForAdminEmployee(userId, loginType));
            localStorage.setItem("userType", loginType);
            navigate("/employerApplication/Internship");
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token")) {
            if (user?.role === "User") {
                navigate("/");
            } else if (user?.role === "Employer") {
                navigate("/employerApplication/Internship");
            }
        }
    }, [isAuthenticated, user, navigate]);
    const GetEmployerData = async (searchInput = "") => {
        setLoading(true);
        console.log(currentPage, "currentPage");
        try {
            const { data } = await axios.post("/api/user/getEmployerDataForAdmin", {
                // searchInput: search,
                searchInput,
                page: currentPage,
                pageSize: 15, // Display 15 data per page
                sortBy: sortField,
                sortOrder: sortOrder,
            });
            setLoading(false);
            setEmployerData(data.data);
            setTotalPages(data.totalPages);
            setTotalItem(data.totalItems);
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data, {
                style: {
                    padding: "18px",
                },
            });
        }
    };
    useEffect(() => {
        GetEmployerData();
    }, [sortField, sortOrder]);
    // const handleSortnew = (field) => {
    //   if (sortField === field) {
    //     // If the same field is clicked again, toggle the sorting order
    //     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    //   } else {
    //     // If a different field is clicked, set the new field and default sorting order to ascending
    //     setSortField(field);
    //     setSortOrder("asc");
    //   }
    // };
    const handleSortnew = (field, direction) => {
        setSortField(field);
        setSortOrder(direction);
    };
    console.log(sortField, sortOrder);

    const handleSort = (type) => {
        if (type === "jobs") {
            const sortedData = [...employerData].sort((a, b) => {
                return a.jobs.length - b.jobs.length;
            });
            const sortedEmployerData = sortByJobs === "asc" ? sortedData : sortedData.reverse();

            setSortByJobs(sortByJobs === "asc" ? "desc" : "asc");
            setEmployerData(sortedEmployerData);
        } else if (type === "activeJobs") {
            const sortedData = [...employerData].sort((a, b) => {
                return countActiveJobs(a.jobs) - countActiveJobs(b.jobs);
            });
            const sortedEmployerData = sortByActiveJobs === "asc" ? sortedData : sortedData.reverse();

            setSortByActiveJobs(sortByActiveJobs === "asc" ? "desc" : "asc");
            setEmployerData(sortedEmployerData);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    useEffect(() => {
        GetEmployerData();
        window.scrollTo(0, 0);
    }, [currentPage]);

    // }
    const countActiveJobs = (jobs) => {
        let activeJobsCount = 0;
        if (jobs && jobs.length) {
            // Ensure jobs is defined and not empty
            jobs.forEach((job) => {
                if (job.status === "Approved") {
                    activeJobsCount++;
                }
            });
        }
        return activeJobsCount;
    };
    const countTotalHired = (jobs) => {
        if (!jobs || !Array.isArray(jobs)) {
            return 0;
        }

        return jobs.reduce((totalHired, job) => {
            if (job.userApplied && Array.isArray(job.userApplied)) {
                const hiredForThisJob = job.userApplied.reduce((jobHired, user) => {
                    if (user.appliedJobs && Array.isArray(user.appliedJobs)) {
                        const hiredApplications = user.appliedJobs.filter((application) => application.status === "Hired");
                        return jobHired + hiredApplications.length;
                    }
                    return jobHired;
                }, 0);

                return totalHired + hiredForThisJob;
            }
            return totalHired;
        }, 0);
    };

    const countTotalApplied = (jobs) => {
        if (!jobs || !Array.isArray(jobs)) {
            return 0;
        }

        return jobs.reduce((totalApplied, job) => {
            if (job.userApplied && Array.isArray(job.userApplied)) {
                const appliedForThisJob = job.userApplied.reduce((jobApplied, user) => {
                    if (user.appliedJobs && Array.isArray(user.appliedJobs)) {
                        return jobApplied + user.appliedJobs.length;
                    }
                    return jobApplied;
                }, 0);

                return totalApplied + appliedForThisJob;
            }
            return totalApplied;
        }, 0);
    };

    const countJoblength = (jobs) => {
        let joblength = 0;
        if (jobs && jobs.length) {
            jobs.forEach((job) => {
                if (job.opportunityType === "Hourly") {
                    joblength++;
                }
            });
        }
        return joblength;
    };

    const countInternshiplength = (jobs) => {
        let internshiplength = 0;
        if (jobs && jobs.length) {
            jobs.forEach((job) => {
                if (job.opportunityType === "Fixed Cost") {
                    internshiplength++;
                }
            });
        }
        return internshiplength;
    };

    // ACTIVE DEACTIVE OPTION:

    const [id, setId] = useState("");
    const [userStatus, setUserStatus] = useState("");

    const idSelector = (_id) => {
        setId(_id);
    };

    const ActiveUser = async () => {
        window.scrollTo(0, 0);
        setLoading(true);
        try {
            const { data } = await axios.post("/api/user/activedeactive", {
                id: id,
                status: "active",
            });
            // GetEmployerData();
            const userIndex = employerData.findIndex((user) => user._id === id);
            // If the user is found, update the active field directly
            if (userIndex !== -1) {
                employerData[userIndex].active = true;
            }
            toast.success("User is active now");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(error.response.data, {
                style: {
                    padding: "18px",
                },
            });
        }
    };

    const DeActiveUser = async () => {
        window.scrollTo(0, 0);
        setLoading(true);
        try {
            const { data } = await axios.post("/api/user/activedeactive", {
                id: id,
                status: "de-active",
            });
            // GetEmployerData();
            const userIndex = employerData.findIndex((user) => user._id === id);
            // If the user is found, update the active field directly
            if (userIndex !== -1) {
                employerData[userIndex].active = false;
            }
            toast.success("User is not active now");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(error.response.data, {
                style: {
                    padding: "18px",
                },
            });
        }
    };

    // SEARCH OPTION:

    const [search, setSearch] = useState("");
    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };

    //  const handleSearch = () =>{
    //   GetEmployerData();
    //    setSearch("");
    //  }
    const handleSearch = () => {
        if (search.trim() !== "") {
            // Only call GetJobData with search input if it's not empty
            GetEmployerData(search.trim());
            setSearch("");
        } else {
            setCurrentPage(1);
            if (currentPage !== 1) {
                // Check if current page is not already 1
                setCurrentPage(1); // Set current page to 1
            }
            GetEmployerData(); // If search input is empty, fetch all data
            // setCurrentPage(1, () => {
            //   GetEmployerData("");
            // });
            setSearch("");
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    // FOR AUTHENTICATION
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (!localStorage.getItem("token") || !localStorage.getItem("userType") || localStorage.getItem("userType") !== "Admin") {
            navigate("/admin_login");
        }
    });
    //  END
    useEffect(() => {
        borderRemoveRef.current.scrollTop = 0;
    }, [employee._id]);

    const fetchData = async (_id) => {
        borderRemoveRef.current.scrollTop = 0;
        const response = await fetch(`/api/admin/getUserToUpdatedata/${_id}`);
        if (!response.ok) {
            throw new Error("Failed to fetch user data");
        }
        const data = await response.json();

        console.log(data, "user data");
        setEmployee({
            _id: data.user._id,
            name: data.user.name || "",
            phone: data.user.phone || "",
            city: data.user.city || "",
            organisationName: data.user.organisationName || "",
            email: data.user.email,
        });
    };

    const handleUserChange = (e) => {
        setEmployee({
            ...employee,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!employee.name) {
            setLoading(false);
            toast.error("Name is required!!");
        } else if (!employee.phone) {
            setLoading(false);
            toast.error("Phone is required!!");
        } else if (!employee.city) {
            setLoading(false);
            toast.error("City is required!!");
        } else if (!employee.organisationName) {
            setLoading(false);
            toast.error("Company Name is required!!");
        } else {
            console.log(employee.email, "email");
            try {
                const { data } = await axios.post("/api/admin/udateemployeedatafromadminsite", {
                    name: employee.name,
                    phone: employee.phone,
                    city: employee.city,
                    organisationName: employee.organisationName,
                    email: employee.email,
                });
                setLoading(false);
                toast.success("Employee Updated Successfully!", {
                    style: {
                        // padding: "18px",
                    },
                });
                document.querySelector(".btn-close").click();
                GetEmployerData();
                setEmployee({
                    name: "",
                    phone: "",
                    city: "",
                    organisationName: "",
                });
            } catch (error) {
                setLoading(false);
                toast.error(error.response.data, {
                    style: {
                        padding: "18px",
                    },
                });
            }
        }
    };
    const [showModal, setShowModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handleDeleteConfirmation = (id) => {
        setIdToDelete(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        await handleDeleteCandidate(idToDelete);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };
    const handleDeleteCandidate = async (id) => {
        setLoading(true);
        try {
            await axios.post(`/api/admin/deleteEmployerFromAdminside/${id}`);

            const empIndex = employerData.findIndex((job) => job._id === id);

            if (empIndex !== -1) {
                const updatedEmpData = [...employerData];
                updatedEmpData.splice(empIndex, 1);
                setEmployerData(updatedEmpData);
            }
            toast.success("User Deleted Successfully");
            setLoading(false);
            await axios.post("/api/admin/logDeleteAttempt", { userId: id });
        } catch (error) {
            console.error("Error deleting job:", error);
        }
    };
    console.log(employerData, "employerData");
    return (
        <>
            <div className="my-applications">
                {loading ? (
                    <>
                        <div className="my-auto" style={{ textAlign: "center" }}>
                            <p
                                className="loaderLoader"
                                style={{
                                    color: "#4B92C8",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    marginLeft: "16px",
                                    marginBottom: "5px",
                                }}
                            >
                                Loading..
                            </p>
                            <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "-4px" }} />
                        </div>
                    </>
                ) : (
                    <>
                        <p className="borderremove" style={{ color: "#494949", fontSize: "20px", fontWeight: "500", backgroundColor: "white", borderRadius: "10px", paddingLeft: "10%", paddingRight: "10%", paddingTop: "1%", paddingBottom: "1%" }}>
                            Employer Details
                        </p>
                        <div className="AdminSearchBox">
                            <input className="AdminSearchInput" type="text" placeholder="Search by name, email, phone" onChange={handleSearchInput} value={search} onKeyPress={handleKeyPress} />
                            <button className="AdminSearchButton" onClick={handleSearch}>
                                Search
                            </button>
                        </div>
                        <div className="table-box table-responsive border borderremove px-0">
                            <table className="applications table" style={{ marginLeft: "-2px" }}>
                                <thead>
                                    <tr>
                                        <th >
                                            <div style={{width:"170px",textAlign:"start",paddingLeft:"2rem"}}>
                                                Employer Name
                                            </div>
                                            </th>
                                        <th style={{textAlign:"start",padding:"none"}}>
                                            <div style={{width:"170px",textAlign:"start"}}>
                                                Comapny Name

                                            </div>
                                        </th>
                                        <th >
                                            <div style={{width:"170px",textAlign:"start"}}>
                                                Email

                                            </div>
                                            </th>
                                        <th className="tableHead">Phone</th>
                                        <th className="tableHead">User Since</th>
                                        <th className="tableHead">Profile Status</th>
                                        <th className="tableHead">Edit Profile</th>
                                        <th className="tableHead">#Delete Employer</th>

                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }} className="tableheadingstyle25">
                                            <div className="tableHead">
                                                #Hourly Project Posted
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "internshipPosted" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSortnew("internshipPosted", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "internshipPosted" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSortnew("internshipPosted", "desc")} />
                                                </div>
                                            </div>
                                        </th>
                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }} className="tableheadingstyle25">
                                            <div className="tableHead">
                                                #Fixed Project Posted
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "jobPosted" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSortnew("jobPosted", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "jobPosted" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSortnew("jobPosted", "desc")} />
                                                </div>
                                            </div>
                                        </th>
                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }} className="tableheadingstyle25">
                                            <div className="tableHead">
                                                #Active Hourly/Fixed Posts
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "activeJobPosted" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSortnew("activeJobPosted", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "activeJobPosted" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSortnew("activeJobPosted", "desc")} />
                                                </div>
                                            </div>
                                        </th>
                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }} className="tableheadingstyle25">
                                            <div className="tableHeadMoreWidth">
                                                #Hourly/Fixed Projects Freelancers Hired
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "totalFreelancersHired" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSortnew("totalFreelancersHired", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "totalFreelancersHired" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSortnew("totalFreelancersHired", "desc")} />
                                                </div>
                                            </div>
                                        </th>
                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }} className="tableheadingstyle25">
                                            <div className="tableHead">
                                                #Freelancer Applied, Alltime
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "totalJobsApplied" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSortnew("totalJobsApplied", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "totalJobsApplied" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSortnew("totalJobsApplied", "desc")} />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading2 ? (
                                        <>
                                            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center" style={{ paddingLeft: "5%" }}>
                                                <div className=" mx-auto" style={{ marginBottom: "20px", marginTop: "20px", justifyContent: "center", justifyItem: "center", textAlign: "center" }}>
                                                    <ScaleLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" />
                                                </div>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            {employerData.length != 0 ? (
                                                <>
                                                    {employerData.map((data, index) => (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", cursor: "pointer", textDecoration: "underline", color: "#4B92C8" }} onClick={() => handleLogin(data._id)} className="tableheadingstyle1">
                                                                {data.name}
                                                            </td>
                                                            <td style={{ fontSize: "14px" }} className="tableheadingstyle6">
                                                                {data.organisationName}
                                                            </td>
                                                            {/* <td style={{fontSize: "14px"}}> {data.email.split('@')[0]+" "}<span>@</span>{data.email.split('@')[1]}</td> */}
                                                            <td style={{ fontSize: "14px" }} className="tableheadingstyle23">
                                                                {data.email.split("@")[0]}
                                                                <span>@</span>
                                                                {data.email.split("@")[1]}
                                                            </td>
                                                            <td style={{ fontSize: "14px" }} className="tableheadingstyle21">
                                                                {data.phone ? data.phone : "Not Available"}
                                                            </td>
                                                            <td style={{ fontSize: "14px", minWidth: "140px" }} className="tableheadingstyle22">
                                                                {data.dataAdded?.slice(8, 10) + " " + MONTH_DETAILS[parseInt(data.dataAdded?.slice(5, 7), 10)] + "' " + data.dataAdded?.slice(2, 4)}
                                                            </td>
                                                            <td style={{ textAlign: "center" }} className="tableheadingstyle24">
                                                                <div class="dropdown3 ml-4" style={{ borderRadius: "5px", border: "solid 1px #4B92C8", marginBottom: "-10px" }}>
                                                                    <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" onClick={() => idSelector(data._id)} style={{ fontSize: "15px", width: "100%" }}>
                                                                        {data.active == false ? "De-active" : "Active"}
                                                                    </button>
                                                                    <ul class="dropdown-menu dropdown-menu-light px-2 custom-dropdown-menu1" aria-labelledby="dropdownMenuButton2">
                                                                        {data.active == false ? (
                                                                            <li>
                                                                                <a class="dropdown-item border text-center py-2" style={{ backgroundColor: "#4B92C8", color: "white", borderRadius: "5px", cursor: "pointer", fontSize: "15px" }} onClick={ActiveUser}>
                                                                                    Active
                                                                                </a>
                                                                            </li>
                                                                        ) : (
                                                                            <li>
                                                                                <a class="dropdown-item border text-center py-2" style={{ backgroundColor: "#4B92C8", color: "white", borderRadius: "5px", cursor: "pointer", fontSize: "15px" }} onClick={DeActiveUser}>
                                                                                    De-active
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </td>

                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle25">
                                                                <LuClipboardEdit className="docIcon" style={{ fontSize: "21px", color: "#4B92C8" }} onClick={() => fetchData(data._id)} data-bs-toggle="modal" data-bs-target="#exampleModal" />
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle deletehovering">
                                                                <RiDeleteBinLine className="docIcon" style={{ fontSize: "23px", color: "#646464" }} onClick={() => handleDeleteConfirmation(data._id)} />
                                                            </td>
                                                            {/* <td style={{fontSize: "14px", textAlign: "center"}} className="tableheadingstyle25">{data.jobs.length}</td> */}
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle25">
                                                                {sortField === "internshipPosted" ? countInternshiplength(data.fullJobs) : countInternshiplength(data.jobs)}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle25">
                                                                {sortField === "jobPosted" ? countJoblength(data.fullJobs) : countJoblength(data.jobs)}
                                                            </td>

                                                            {/* <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle25">
                  {sortField === "internshipPosted" ? data.internshipCount : data.jobs.filter(job => job.opportunityType === "Internship").length}
                </td>
                <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle25">
                  {sortField === "jobPosted" ? data.jobCount : data.jobs.filter(job => job.opportunityType === "Job").length}
                </td> */}

                                                            {/* <td style={{fontSize: "14px", textAlign: "center"}} className="tableheadingstyle25">{countActiveJobs(data.jobs)}</td> */}
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle25">
                                                                {sortField === "activeJobPosted" ? countActiveJobs(data.fullJobs) : countActiveJobs(data.jobs)}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle25">
                                                                {sortField === "totalFreelancersHired" ? countTotalHired(data.fullJobs) : countTotalHired(data.jobs)}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle25">
                                                                {sortField === "totalJobsApplied" ? countTotalApplied(data.fullJobs) : countTotalApplied(data.jobs)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    <p style={{ padding: "15px  20px", minWidth: "200px", paddingBottom: "10", color: "red" }}>No data found!</p>
                                                </>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="pagination" style={{ width: "100%", maxWidth: "1200px" }}>
                            <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{ marginBottom: "1%", paddingRight: "5%" }}>
                                {/* {totalPages > 1 ? (
                                    <></>
                                ) : (
                                    <>
                                        <div></div>
                                    </>
                                )} */}
                            </Grid>
                            <div style={{ textAlign: "center", maxWidth: "1200px", marginInline: "auto" }}>
                                <PaginationWithLastButton totalPages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
                            </div>
                            {/* {totalPages > 1 ? (
                                <>
                                </>
                            ) : (
                                <></>
                            )} */}
                        </div>
                    </>
                )}
            </div>
            {/* EDIT PROFILE MODEL */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content borderremove123" ref={borderRemoveRef} style={{ overflowY: "auto", borderRadius: "2px", height: "auto" }}>
                        <div class="modal-body">
                            <div style={{ textAlign: "end", paddingRight: "30px" }}>
                                <button style={{ position: "fixed" }} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            {/*EDIT APPLICATION CODE START HERE*/}
                            <div className="container">
                                <p className="text-center mx-auto" style={{ margin: "40px 0px", fontSize: "20px", fontWeight: "500", color: "#5F5F5F" }}>
                                    Edit Profile
                                </p>
                                <div className="section mx-auto" style={{ width: "90%" }}>
                                    <form className="w-100 d-flex flex-column">
                                        <div className="sub-section123">
                                            <div className="details w-100">
                                                <div className="title">Full Name</div>
                                                <input style={{ backgroundColor: "white", width: "100%", border: "1px solid #ddd" }} type="text" value={employee && employee.name} id="name" name="name" onChange={handleUserChange} onMouseOver="this.style.border='1px solid #00a5ec'" onMouseOut="this.style.border='1px solid #ddd'" />
                                            </div>

                                            <div className="details w-100">
                                                <div className="title">Email</div>
                                                <input style={{ width: "100%", border: "1px solid #ddd" }} type="email" placeholder="Email" value={employee && employee.email} name="email" onChange={handleUserChange} readOnly onMouseOver="this.style.border='1px solid #00a5ec'" onMouseOut="this.style.border='1px solid #ddd'" />
                                            </div>

                                            <div className="details w-100">
                                                <div className="title">Organization Name</div>
                                                <input style={{ backgroundColor: "white", width: "100%", border: "1px solid #ddd" }} type="text" placeholder="Company Name" value={employee && employee.organisationName} name="organisationName" id="organisationName" onChange={handleUserChange} onMouseOver="this.style.border='1px solid #00a5ec'" onMouseOut="this.style.border='1px solid #ddd'" />
                                            </div>

                                            <div className="details w-100">
                                                <div className="title">City</div>
                                                <input style={{ backgroundColor: "white", width: "100%", border: "1px solid #ddd" }} type="text" placeholder="City" value={employee && employee.city} name="city" id="city" onChange={handleUserChange} onMouseOver="this.style.border='1px solid #00a5ec'" onMouseOut="this.style.border='1px solid #ddd'" />
                                            </div>

                                            <div className="details w-100">
                                                <div className="title">Mobile Number</div>
                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                    <input style={{ border: "1px solid #ddd" }} type="text" placeholder="" className="w-25" value="+91" readOnly />
                                                    <input minLength={10} maxLength={10} style={{ backgroundColor: "white", border: "1px solid #ddd" }} type="text" className="w-50" value={employee && employee.phone} name="phone" id="phone" onChange={handleUserChange} onMouseOver="this.style.border='1px solid #00a5ec'" onMouseOut="this.style.border='1px solid #ddd'" />
                                                    <span className="w-25 d-flex align-items-center gap-1" style={{ color: "#2fb212" }}>
                                                        <MdOutlineVerifiedUser /> Verified
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" className="submit-btn123" onClick={handleUpdate}>
                                            {loading ? <Spinner /> : <span>Done</span>}
                                        </button>
                                    </form>
                                </div>
                            </div>
                            {/*EDIT APPLICATION CODE END HERE*/}
                        </div>
                    </div>
                </div>
            </div>

            <Popup open={showModal} modal closeOnDocumentClick={false}>
                <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                    <div className="popup-containernew">
                        <div className="success-message mx-3" style={{ marginBottom: "10px" }}>
                            Are you sure you want to delete? This action cannot be undone.
                        </div>
                        <div style={{ display: "flex", alignItems: "center", alignSelf: "center", gap: "10px", marginBottom: "15px" }}>
                            <button className="employerbuttonbacktodash" onClick={handleCancelDelete}>
                                Cancel
                            </button>
                            <button className="employerbuttontobuyinformation" style={{ backgroundColor: "#ED5E68" }} onClick={handleConfirmDelete}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>

            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default AdminEmployers;
