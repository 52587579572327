import React, { useEffect } from "react";
import "./css/ContractToHire.scss";
import { Link } from "react-router-dom";
import { contractToHire_1, contractToHire_2, contractToHire_3, contractToHire_4 } from "../../assets";
const ContractToHire = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div className="contract-container">
            {/* Hero Section */}
            <div className="contract-hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Transition from contract work to full-time hire with trusted talent.</h1>
                        <p>Discover highly skilled talent on Workcroft who are open to contract-to-hire arrangements. Begin with a short-term contract today and trial a working relationship—you can hire them full-time when the time is right.</p>
                        <Link to="/freelancer-details">
                            <button className="primary-btn">Post a contract-to-hire job</button>
                        </Link>
                    </div>
                    <p className="opportunities">Are you looking for work opportunities?</p>
                    <p className="signup-text">
                        <Link to="/contract-to-hire-talent" className="signup-link">
                            See how contract-to-hire works for talent.
                        </Link>
                    </p>
                </div>
                <div className="hero-image">
                    <img src={contractToHire_1} alt="Person working on laptop" />
                </div>
            </div>

            <section className="how-it-works">
                <div className="how-it-works__content">
                    <div className="how-it-works__image">
                        <img src={contractToHire_2} alt="Team meeting in modern office" />
                    </div>
                    <div className="how-it-works__steps">
                        <h2>Here's how contract-to-hire operates on Workcroft.</h2>
                        <p>A contract-to-hire opportunity begins with a short-term contract and can lead to a full-time position.</p>
                        <div className="steps">
                            <div className="step">
                                <span className="step__number">1</span>
                                <div>
                                    <h3>Create a contract-to-hire job posting</h3>
                                    <p>Post your job to inform talent that it's a short-term contract with potential for full-time employment.</p>
                                </div>
                            </div>
                            <div className="step">
                                <span className="step__number">2</span>
                                <div>
                                    <h3>Trial collaboration with talent</h3>
                                    <p>Select one or multiple professionals and begin working on a short-term contract to evaluate their skills and suitability for your business.</p>
                                </div>
                            </div>
                            <div className="step">
                                <span className="step__number">3</span>
                                <div>
                                    <h3>Decide whether to transition to full-time</h3>
                                    <p>When you're ready, hire the trusted talent that best fits your full-time needs. If you both decide to transition to full-time employment, simply pay a one-time fee to convert your Workcroft contract, and we'll assist both parties through the process.</p>
                                </div>
                            </div>
                            <div className="step">
                                <span className="step__number">4</span>
                                <div>
                                    <h3>Keep up the good work</h3>
                                    <p>
                                        Once finished, you can transition your working relationship to your systems. Need assistance with compliant payment for talent?{" "}
                                        <span>
                                            <Link to="/any-hire">Workcroft Any Hire</Link>
                                        </span>{" "}
                                        has you covered.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="reasons">
                <div className="reasons__content">
                    <div className="reasons__text">
                        <h2>Why consider contract-to-hire? Let us outline the reasons.</h2>

                        <div className="reasons__list">
                            <div className="reason">
                                <h3>Hire with confidence</h3>
                                <p>Establish trust, mitigate risk, and make informed hiring decisions by starting with short-term contracts for talent. If their skills align perfectly and they are open to transitioning to full-time work, you can choose to hire them full-time at any time.</p>
                            </div>

                            <div className="reason">
                                <h3>Embrace flexible hiring</h3>
                                <p>Workcroft's marketplace is designed to meet the varied hiring needs of your business. Whether you need talent for quick projects, hourly contracts, or contract-to-hire leading to full-time positions, you can find it all in one place.</p>
                            </div>

                            <div className="reason">
                                <h3>Streamline your hiring</h3>
                                <p>Tap into Workcroft's global pool of professionals and utilize our self-service features to swiftly find, vet, and hire the skills you require.</p>
                            </div>
                        </div>
                    </div>

                    <div className="reasons__image">
                        <img src={contractToHire_3} alt="Team meeting in modern office space" />
                    </div>
                </div>
            </section>

            <section className="discover">
                <div className="discover__content">
                    <div className="discover__icon">
                        <img src={contractToHire_4} alt="" className="search-icon" />
                    </div>

                    <div className="discover__text">
                        <h2>Discover talent interested in contract-to-hire opportunities</h2>
                        <Link to="/freelancer-details">
                        <button className="button button--success">Post a contract-to-hire job</button>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContractToHire;
