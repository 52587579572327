import React, { useState } from "react";
import PreviewModel from "./PreviewModel";
import CloseButton from "../Student_New/CloseButton";
import PropagateLoader from "react-spinners/PropagateLoader";

const ViewPortfolioprojects = ({ isopen, displayproject, onClose }) => {
  const [hoveredProject, setHoveredProject] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(false);
  if (!isopen) return null;
  const overlayStyle = {
    position: "fixed",
    top: "3rem",
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalStyle = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "80vw",
    height: "85vh",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "relative",
    overflow: "scroll"
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
  };
  const handleProjectClick = async (project) => {
    //console.log(project, "jfkdk");
    setLoading(true);
    setSelectedProject(null);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setSelectedProject(project);
    setLoading(false);
  };
  return (
    <>
      <div
        className="modal fade"
        id="previewmodalresume"
        tabIndex="-1"
        aria-labelledby="previewModalresumeLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog custom-modal-dialog unique-modal-dialog modal-dialog-centered width-settingofmodal">
          <div
            className="modal-content px-3 py-3"
            style={{
              borderRadius: "15px",
              // // marginTop: "10px",
              height: "95vh",
            }}
          >
            <div className="preview-header">
              <h2 style={{ fontSize: "28px" }}>
                {selectedProject?.projectTitle}
              </h2>

              <CloseButton className="btnclosepreview" />
            </div>
            <div
              className="modal-body"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              {loading ? (
                <div
                  className=""
                  style={{ textAlign: "center", paddingBottom: "220px" }}
                >
                  <p
                    style={{
                      color: "#4B92C8",
                      fontWeight: "500",
                      fontSize: "20px",
                      marginLeft: "43px",
                      marginBottom: "5px",
                      marginTop: "220px",
                    }}
                  >
                    Loading..
                  </p>
                  <PropagateLoader
                    color={"#4B92C8"}
                    loading={true}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    style={{ marginLeft: "27px" }}
                  />
                </div>
              ) : (
                selectedProject && (
                  <div className={`fade-in ${!loading ? "show" : ""}`}>
                    <div className="mb-3 flex-container-portfolio">
                      <div
                        className="leftsectionpreviewport"
                        style={{
                          flex: 1,
                          marginRight: "20px",
                          position: "sticky",
                          top: "0",
                          alignSelf: "flex-start",
                        }}
                      >
                        {/* <div
                        style={{ marginTop: "15px", marginBottom: "40px" }}
                      >

                      modfhdjhfujdyfydyfu
                        <h2 style={{ fontSize: "28px" }}>
                          {selectedProject.projectTitle}
                        </h2>
                      </div> */}
                        {selectedProject.yourRole !== "" && (
                          <div style={{ marginBottom: "25px" }}>
                            <p style={{ fontSize: "16px" }}>
                              <b>My role:</b> {selectedProject.yourRole}
                            </p>
                          </div>
                        )}
                        {selectedProject.projectDescription !== "" && (
                          <div style={{ marginBottom: "25px" }}>
                            <p style={{ fontSize: "16px" }}>
                              <b>Project Description:</b>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    selectedProject.projectDescription.replace(
                                      /\n/g,
                                      "<br />"
                                    ),
                                }}
                              />
                              {/* {selectedProject.projectDescription} */}
                            </p>
                          </div>
                        )}
                        <div style={{ marginBottom: "25px" }}>
                          <h4>Skills and deliverables</h4>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {selectedProject.skills.map((skill) => (
                              <span
                                key={skill.value}
                                className="post1234forresume mr-4 mt-3"
                              >
                                {skill.label}
                              </span>
                            ))}
                          </div>
                        </div>
                        <hr
                          //className="dropdown-divider1 mb-4 mt-3"
                          style={{
                            height: "0.1px",
                            color: "rgb(185, 185, 185)",
                          }}
                        />
                      </div>
                      <div
                        className="mb-3 rightsectionpreviewport"
                        style={{
                          flex: 2,
                          minHeight: "240px",
                          flexDirection: "column",
                        }}
                      >
                        <PreviewModel portfolioDetails={selectedProject} />

                        <hr
                          className="dropdown-divider1 mb-4 mt-3"
                          style={{
                            height: "0.05px",
                            color: "rgb(185, 185, 185)",
                          }}
                        />
                        <div className="mb-3" style={{ marginTop: "35px" }}>
                          {/* <p style={{ fontSize: "18px", marginBottom: '15px' }}>More by <span style={{ color: "#2092c7" }}>{resumeData.PersonalDetails.firstName} {resumeData.PersonalDetails.lastName}</span></p> */}
                          <p style={{ fontSize: "18px", marginBottom: "15px" }}>
                            More
                          </p>
                          <div className="row">
                            {displayproject
                               ?.filter(
                                (project) => project._id !== selectedProject._id
                              ) // Exclude selected project
                              .slice(0, 3) // Get next 3 projects
                              .map((project) => (
                                <div
                                  className="col-md-4"
                                  key={project._id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleProjectClick(project)}
                                >
                                  <div className="card">
                                    <img
                                      className="card-img-top"
                                      src={project.selectedThumbnail.url}
                                      alt={`Project ${project._id}`}
                                    />
                                    <div className="card-body">
                                      <p
                                        className="card-text"
                                        style={{ color: "#2092c7" }}
                                      >
                                        {project.projectTitle}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={overlayStyle}>
        <div style={modalStyle}>
          <button style={closeButtonStyle} onClick={onClose}>
            &times;
          </button>
          <h2>Portfolio Project</h2>
          <div>
            <div id="published" className="tab-pane active">
              <div className="row">
                {displayproject?.map((project) => (
                  <div
                    className="col-md-6"
                    key={project?.id}
                    style={{
                      cursor: "pointer",
                      positon: "relative",
                    }}
                    onMouseEnter={() => setHoveredProject(project?._id)}
                    onMouseLeave={() => setHoveredProject(null)}
                  >
                    <div className="card portcard">
                      {/* <img className="card-img-top"  src={project.selectedThumbnail.url} alt={`Project ${project._id}`} /> */}
                      <img
                        className={`card-img-top project-image ${
                          hoveredProject === project?._id ? "dim-image" : ""
                        }`}
                        src={project?.selectedThumbnail?.url}
                        alt={`Project ${project._id}`}
                      />
                      <div className="card-body">
                        <p
                          className="card-text project-title"
                          style={{ color: "#2092c7" }}
                          data-bs-toggle="modal"
                          data-bs-target="#previewmodalresume"
                          onClick={() => {
                            setSelectedProject(project);
                            console.log(project, "ppp");
                            // let prev = generateDataInSequence(
                            //   project?.mediaSequence,
                            //   project
                            // );
                            // setPortfolioDetailsData(
                            //   project?.mediaSequence
                            // );
                            // console.log(prev, "preview data");
                          }}
                        >
                          {project?.projectTitle}
                        </p>
                      </div>
                      {console.log("sdfs", project)}

                      {/* {hoveredProject === project._id && (
                                          <RiDeleteBinLine
                                            className="delete-project-draft"
                                            style={{ position: 'absolute', top: '50px', right: '80px' }}
                                            onClick={() => {
                                              setSelectedProject(project);
                                              setOpenPopupDelete(true);
                                            }}
                                          />
                                        )} */}
                    </div>
                  </div>
                ))}
              </div>
              {/* custom paginATION */}
              {/* <div>
                      {totalPages !== 0 && (
                        <Pagination
                          totalPages={totalPages}
                          currentPage={activePage}
                          onPageChange={setActivePage}
                        />
                      )}
                    </div> */}
              {/* <nav aria-label="Page navigation">
                            <ul className="pagination pagination-navigation">
                              <li
                                className={`page-item ${
                                  activePage === 1 ? "disabled" : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((prev) =>
                                      Math.max(prev - 1, 1)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === 1 ? "gray" : "#2092c7",
                                    pointerEvents:
                                      activePage === 1 ? "none" : "auto",
                                    cursor:
                                      activePage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <IoIosArrowBack />
                                </div>
                              </li>
                              <li
                                className={`page-item ${
                                  activePage === 1 ? "disabled" : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((prev) =>
                                      Math.max(prev - 1, 1)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === 1 ? "gray" : "#2092c7",
                                    pointerEvents:
                                      activePage === 1 ? "none" : "auto",
                                    cursor:
                                      activePage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Previous
                                </div>
                              </li>
                              
                              {[...Array(totalPages)].map((_, page) => (
                                <li
                                  key={page}
                                  className="page-item"
                                  onClick={() => setActivePage(page + 1)}
                                >
                                  <div
                                    className="page-link"
                                    style={{
                                      backgroundColor:
                                        activePage === page + 1
                                          ? "#2092c7"
                                          : "transparent",
                                      color:
                                        activePage === page + 1
                                          ? "white"
                                          : "#2092c7",
                                      borderRadius: "50%",
                                      height: "25px",
                                      width: "25px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {page + 1}
                                  </div>
                                </li>
                              ))}
                              <li
                                className={`page-item ${
                                  activePage === totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((next) =>
                                      Math.min(next + 1, totalPages)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === totalPages
                                        ? "gray"
                                        : "#2092c7",
                                    pointerEvents:
                                      activePage === totalPages
                                        ? "none"
                                        : "auto",
                                    cursor:
                                      activePage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Next
                                </div>
                              </li>
                              <li
                                className={`page-item ${
                                  activePage === totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((next) =>
                                      Math.min(next + 1, totalPages)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === totalPages
                                        ? "gray"
                                        : "#2092c7",
                                    pointerEvents:
                                      activePage === totalPages
                                        ? "none"
                                        : "auto",
                                    cursor:
                                      activePage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <IoIosArrowForward />
                                </div>
                              </li>
                            </ul>
                          </nav> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPortfolioprojects;
