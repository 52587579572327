import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/userActions";
import Logo from "../../assets/images/logo1.png";
import "./css/Header.scss";
import photo from "../../assets/images/icons/profile.png";

function Header(props) {
  const { isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <div
      className="app-navbar "
      style={{ height: "80px", backgroundColor: "red" }}
    >
      <div className="logo-box">
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
      {props.expanded && (
        <div className="links-box" id="extended-header">
          <Link to="/searchJobs" className="links">
            Internship
          </Link>
          <Link to="/searchJobs" className="links">
            Jobs
          </Link>

          <div className="user-links">
            {isAuthenticated ? (
              <>
                <Link
                  to="/chat"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-message-square"
                  >
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                  </svg>
                </Link>
                <div className="my-profile">
                  <div className="profile">
                    <span className="user-photo">
                      <img src={photo} />
                    </span>
                    <span
                      className="arrow-icon"
                      style={{ background: "black" }}
                    ></span>
                    <div className="profile-links">
                      <div className="links">
                        <a href="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-search"
                          >
                            <circle cx={11} cy={11} r={8} />
                            <line x1={21} y1={21} x2="16.65" y2="16.65" />
                          </svg>
                          Search Jobs
                        </a>
                        <Link to="/myProfile">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-user"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            <circle cx={12} cy={7} r={4} />
                          </svg>
                          Profile
                        </Link>
                        <a href="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-file-text"
                          >
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                            <polyline points="14 2 14 8 20 8" />
                            <line x1={16} y1={13} x2={8} y2={13} />
                            <line x1={16} y1={17} x2={8} y2={17} />
                            <polyline points="10 9 9 9 8 9" />
                          </svg>
                          My Applications
                        </a>
                        <a href="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-message-square"
                          >
                            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                          </svg>
                          Help Center
                        </a>
                        <button
                          onClick={() => {
                            dispatch(logoutUser());
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-log-out"
                          >
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                            <polyline points="16 17 21 12 16 7" />
                            <line x1={21} y1={12} x2={9} y2={12} />
                          </svg>
                          Sign out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Link to="/login" className="btn login-outline">
                  Login
                </Link>
                <button
                  className="btn registration-btn"
                  style={{
                    display: "flex",
                    gap: "5px",
                    padding: ".8rem 1.6rem",
                    alignItems: "center",
                  }}
                >
                  Register
                  <span className="arrow-icon"></span>
                  <div className="dropdown-box">
                    <div className="dropdown">
                      <Link to="/studentSignup">As Student</Link>
                      <Link to="/employeeSignup">As Employer</Link>
                    </div>
                  </div>
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
