import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Spinner from "../Layout/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../../actions/userActions";
import { envelope,lock, eyeClosed,eyeOpen } from "../../assets";

function AdminLogin() {
    const navigate = useNavigate();
    const { isAuthenticated, loading, user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loginType, setLoginType] = useState("Admin");
    const [admins, setAdmins] = useState({
      email: "",
      password: "",
    });
    const [hideShowPassword,sethideShowPassword] = useState(false)


    const handleUserChange = (e) => {
      setAdmins({
        ...admins,
        [e.target.name]: e.target.value,
      });
    };


  
    const login = async (event) => {
      event.preventDefault();
        await dispatch(loginAdmin(admins, loginType));
        localStorage.setItem('userType', 'Admin'); 
        setAdmins({ ...admins, password: "" });
    };

    useEffect(() => {
    if (localStorage.getItem('token')) {
      if (user?.role === 'Admin') {
        navigate('/admin');
      }
    }
  }, [isAuthenticated, user, navigate]);


  return (
    <>
    <div className="login-component">
        <form className="form-box">
          <div className="form">
            <div className="text-center">       
            <p style={{ fontSize: '24px', fontWeight: "500" }}       
            className= ""
            >Admin</p> 
            </div>
            <p className="text-center" style={{fontSize: "16px", color: "gray", marginTop: "-10px"}}>Login as a super user</p>
            {loginType === "Admin" && (
              <>
                <TextField
                  type="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={admins.email}
                  onChange={handleUserChange}
                  name="email"
                  id="email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={envelope} className="icons"></img>
                      </InputAdornment>
                    ),
                    style: { outline: "none" },
                  }}
                />
                <TextField
                  type={hideShowPassword ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={admins.password}
                  onChange={handleUserChange}
                  name="password"
                  id="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                       {hideShowPassword ? (<img src={eyeOpen} className="icons" style={{cursor:"pointer"}} alt="password" onClick={()=> sethideShowPassword(!hideShowPassword)}/>) : (<img src={eyeClosed} className="icons" alt="password" style={{cursor:"pointer"}} onClick={()=> sethideShowPassword(!hideShowPassword)}/>) }
                      </InputAdornment>
                    ),
                  }}
                />
               {/* <div style={{ float: "right" }}>
                  <a href="/recoveryemail">
                    <small style={{ fontSize: "12px", color: "black" }}>
                      Forgot Password?
                    </small>
                  </a>
                </div>*/}
                <button
                  onClick={login}
                  className="btn mb-5"
                >
                  {loading ? <Spinner /> : <span>Log In</span>}
                </button>
               {/* <p className="text-center pt-4" style={{ fontSize: "13px" }}>
                  Don't have an Account ?{" "}
                  <a href="/AdminSignup" style={{ fontSize: "13px" }}>
                    Sign up
                  </a>
                </p>*/}
              </> 
               )}
          </div>
        </form>
      </div>
      <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>
  );
}

export default AdminLogin;
