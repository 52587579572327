import React from 'react'
import './css/Messages.scss'

function Messages() {
    return (
        <div className="message-student message-employer">
            <div className="chat-box">
                <div className="recent-chats">
                    <h3 className="recent-chats-title" style={{ background: "transparent" }}>Messages form all Intrenships and Jobs</h3>
                    <div className="select-box">
                        <select name="jobTitle">
                            <option value="All Internships and Jobs">All Internships and Jobs</option>
                            <option value="PHP Development">PHP Development</option>
                            <option value="Node JS Development">Node JS Development</option>
                            <option value="MERN Development">MERN Development</option>
                            <option value="Automation Testing">Automation Testing</option>
                        </select>
                        <select name="jobTitle">
                            <option value="All Internships and Jobs">All Internships and Jobs</option>
                        </select>
                    </div>
                    <div className="tabs">
                        <button className="btn btn-primary">All</button>
                        <button className="btn btn-primary">Unread</button>
                    </div>
                    <div className="chats">
                        <a href='/' className='chat'>
                            <strong className="title">BlackCoffer</strong>
                            <span className='message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, maxime magnam?</span>
                            <span className='date'>1-Apr-2023</span>
                        </a>
                        <a href='/' className='chat'>
                            <strong className="title">BlackCoffer</strong>
                            <span className='message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, maxime magnam?</span>
                            <span className='date'>1-Apr-2023</span>
                        </a>
                        <a href='/' className='chat'>
                            <strong className="title">BlackCoffer</strong>
                            <span className='message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, maxime magnam?</span>
                            <span className='date'>1-Apr-2023</span>
                        </a>
                        <a href='/' className='chat'>
                            <strong className="title">BlackCoffer</strong>
                            <span className='message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, maxime magnam?</span>
                            <span className='date'>1-Apr-2023</span>
                        </a>
                        <a href='/' className='chat'>
                            <strong className="title">BlackCoffer</strong>
                            <span className='message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, maxime magnam?</span>
                            <span className='date'>1-Apr-2023</span>
                        </a>
                        <a href='/' className='chat'>
                            <strong className="title">BlackCoffer</strong>
                            <span className='message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, maxime magnam?</span>
                            <span className='date'>1-Apr-2023</span>
                        </a>
                        <a href='/' className='chat'>
                            <strong className="title">BlackCoffer</strong>
                            <span className='message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, maxime magnam?</span>
                            <span className='date'>1-Apr-2023</span>
                        </a>
                        <a href='/' className='chat'>
                            <strong className="title">BlackCoffer</strong>
                            <span className='message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, maxime magnam?</span>
                            <span className='date'>1-Apr-2023</span>
                        </a>
                    </div>
                </div>
                <div className="chatroom">
                    <div className="sender-area">
                        <div className="profile">
                            <strong>Avichal Kaushik |</strong>
                            <span>Full Stack Developer</span>
                        </div>
                        <div className="options">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                        </div>
                    </div>
                    <div className='message-section'>
                        <div className="conversations">
                            <div className="date-time">12-Jan-2023</div>
                            <div className="conversation left">
                                <p>Hi Anushka,
                                    We came across your profile for Operations internship at Solution Graph and quite liked it. You may view the internship details and if you are interested in the opportunity, please reply to this message and we would let you know regarding next steps.
                                    Thanks, Ashok Chandan Solution Graph</p>
                            </div>
                            <div className="conversation right">
                                <p>Hi, thank you for your invitation. I am interested in this internship. Please let me know regarding next steps.

                                    Thanks, Anushka Gaikwad</p>
                            </div>
                            <div className="date-time">8-Feb-2023</div>
                            <div className="conversation left">
                                <p>Hi Anushka,
                                    We came across your profile for Operations internship at Solution Graph and quite liked it. You may view the internship details and if you are interested in the opportunity, please reply to this message and we would let you know regarding next steps.
                                    Thanks, Ashok Chandan Solution Graph</p>
                            </div>
                            <div className="conversation right">
                                <p>Hi, thank you for your invitation. I am interested in this internship. Please let me know regarding next steps.

                                    Thanks, Anushka Gaikwad</p>
                            </div>
                            <div className="date-time">1-Apr-2023</div>
                            <div className="conversation left">
                                <p>Hi Anushka,
                                    We came across your profile for Operations internship at Solution Graph and quite liked it. You may view the internship details and if you are interested in the opportunity, please reply to this message and we would let you know regarding next steps.
                                    Thanks, Ashok Chandan Solution Graph</p>
                            </div>
                            <div className="conversation right">
                                <p>Hi, thank you for your invitation. I am interested in this internship. Please let me know regarding next steps.

                                    Thanks, Anushka Gaikwad</p>
                            </div>
                            <div className="conversation left">
                                <p>Hi Anushka,
                                    We came across your profile for Operations internship at Solution Graph and quite liked it. You may view the internship details and if you are interested in the opportunity, please reply to this message and we would let you know regarding next steps.
                                    Thanks, Ashok Chandan Solution Graph</p>
                            </div>
                            <div className="conversation right">
                                <p>Hi, thank you for your invitation. I am interested in this internship. Please let me know regarding next steps.

                                    Thanks, Anushka Gaikwad</p>
                            </div>
                            <div className="related-messages">
                                <div>
                                    <span className="hire chip">Hire</span>
                                    <span className="ni chip">Not Intrested</span>
                                </div>
                                <div>
                                    <span className="si chip">Schedule Interview</span>
                                    <span className="sa chip">Send Assignment</span>
                                </div>
                            </div>
                        </div>
                        <div className="send-message-box">
                            <input type="text" placeholder='Write Message....' />
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
                            <button className="btn btn-primary">Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Messages