//For coverletter pagee 

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ProtectedRoute(props) {
  const { Component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    let userType = localStorage.getItem('userType');

    if (!userType) {
      navigate('/login');
    }else if (userType === 'Employer') {
        navigate('/Notfound');
      }
     
  }, [navigate]);

  return <>{localStorage.getItem('userType')=== 'User' ? <Component /> : null}</>;

}

export default ProtectedRoute;