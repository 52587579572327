import React, { useState, useEffect, useRef } from 'react';
import './css/TruncateText.css';

const TruncateText = ({ text, maxWords }) => {
  const [truncatedText, setTruncatedText] = useState(text);
  const textRef = useRef();

  useEffect(() => {
    const originalWords = text.split(" ");
    if (originalWords.length <= maxWords) {
      setTruncatedText(text); // If the text is within the word limit, show as it is.
    } else {
      setTruncatedText(originalWords.slice(0, maxWords).join(" ") + "...");
    }
  }, [text, maxWords]);

  return (
    <div className="truncate-text" ref={textRef}>
      <h3 style={{fontSize: "19px", textAlign: "start" }} className="title pt-3">
        {truncatedText}
      </h3>
    </div>
  );
};

export default TruncateText;
