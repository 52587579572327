import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './AdminDashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import list from "../../assets/images/list.png";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from "react-redux";


function AdminDashboard() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [jobData, setJobData] = useState("")
  const [count, setCount] = useState("")
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(10);
  const [approvalStatus, setApprovalStatus] = useState({});
  const [usercount, setUserData] = useState([]);
  const [Employercount, setEmployerData] = useState([]);
  const [Approvedjob, setApprovedjob] = useState([]);
  const [Notapprovedjob, setNotapprovedjob] = useState([]);
  const[TotalAppliedJobs,setTotalAppliedJobs] = useState([]);





  const toggleStatus = async (id) => {
    try {
      const response = await axios.post("/api/admin/updateJobStatus", {
        jobId: id,
        status: !approvalStatus[id] ? "Approved" : "Not Approved",
      }, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });

      if (response.data.success) {
        setApprovalStatus((prevStatus) => ({
          ...prevStatus,
          [id]: !prevStatus[id],

        }));

      } else {
        console.error("Failed to update job status:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating job status:", error);
    }
  };
  useEffect(() => {
    fetchData(page);
  }, [page]);

  const fetchData = async (page) => {

    try {
      const { data } = await axios.post("/api/admin/getAllJobsAdmin", { page }, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const jobD = data.jobs.reverse()
      // console.log('jobD', jobD);
      const initialApprovalStatus = {};

      jobD.forEach((job) => {
        initialApprovalStatus[job._id] = job.status === "Approved";
      })
      setCount(data.count)
      setJobData(jobD)
      setApprovalStatus(initialApprovalStatus);

    } catch (error) {


      console.log(error.response.data);

    }
  }

 
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get("/api/admin/getAllUserData");
        console.log("API Response:", response.data);
        const usersRole = response.data.users.filter(user => user.role === 'User').length;
        const EmployersRole = response.data.users.filter(user => user.role === 'Employer').length;

        // console.log("usersWithSpecificRole", usersRole);
        // console.log("EmployersWithSpecificRole", EmployersRole);


        setUserData(usersRole);  
        setEmployerData(EmployersRole);  

      
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUser();
  }, []); 


  useEffect(() => {
    const fetchJobstatus = async () => {
      try {
        const response = await axios.get("/api/admin/getJobstatus");
        console.log("API Response:", response.data);
        const approved = response.data.jobs.filter(job => job.status === 'Approved').length;
        const notapproved = response.data.jobs.filter(job => job.status === 'Not Approved').length;

        // console.log("approved", approved);
        // console.log("notapproved", notapproved);


        setApprovedjob(approved);  
        setNotapprovedjob(notapproved);  

      
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchJobstatus();
  }, []); 


  useEffect(() => {
    const fetchappliedjobs = async () => {
      try {
        const response = await axios.get("/api/admin/getAppliedJob");
        console.log("appliedjobs", response.data);

        const totalAppliedJobsCount = response.data.appliedjobs.length;
        console.log("Total Applied Jobs:", totalAppliedJobsCount);
        setTotalAppliedJobs(totalAppliedJobsCount);
 
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchappliedjobs();
  }, []); 

 






  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  function handleMenuClick(index) {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  }


  // Generate the page numbers based on the total number of pages
  const generatePageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(count / 10); i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${page !== i ? "" : "disabled"}`}>
          <a className="page-link" href="#" onClick={() => setPage(i)}>
            {i}
          </a>
        </li>
      );
    }


    return pageNumbers;
  };


  const generatePageOneToTen = (start, end) => {

    if (end > (count / 10)) {
      end = (count / 10);
    }

    const pageNumbers = [];

    for (let i = start; i <= Math.ceil(end); i++) {
      pageNumbers.push(
        <li key={i} style={{ backgroundColor: "#F8F8F5" }}>
          <a className="page-link" href="#" onClick={() => fetchData(i)}>
            {i}
          </a>
        </li>
      );
    }


    return pageNumbers;

  };


  const forw = () => {

    if (end + 10 > count / 10) {
      setStart((count / 10) - 9)
      setEnd(count / 10);
    }
    else {
      setStart(start + 10);
      setEnd(end + 10);

    }

  }


  const backw = () => {

    if ((start - 10) < 1) {
      setStart(1);

    }
    else {
      setStart(start - 10);
      setEnd(end - 10);

    }


  }

  useEffect(() => {
    if(!localStorage.getItem('token')  || !localStorage.getItem('userType') || localStorage.getItem('userType') !== "Admin"){
      navigate("/admin_login");
    }
  });

  return (




    <div className="my-applications" style={{ background: 'white' }}>
     
       {/* <div class="container" style={{marginRight:'115px'}}>
          <div class="row">
            <div style={{ width: '250px' }}  >
              <div class="card bg-c-blue order-card">

                <div class="card-block">

                  <h2 class="m-b-2" >  <i class="fa fa-briefcase"></i> Total Jobs:<span>{count}</span>
                  </h2>
                  <h2 class="m-b-0" style={{ marginTop: '15px', fontSize: '20px' }}>Approved Jobs:<span style={{ marginLeft: '5px' }}>{Approvedjob}</span></h2>
                </div>
              </div>
            </div>
            <div style={{ width: '250px' }}>
              <div class="card bg-c-pink order-card">


                <div class="card-block">

                  <h2 class="m-b-2" >  <i class="fa fa-file-text"></i>  Total Jobs:<span>{count}</span>
                  </h2>
                  <h2 class="m-b-0" style={{ marginTop: '15px' }}>Rejected : {Notapprovedjob}</h2>
                </div>
              </div>
            </div>


            <div style={{ width: '250px' }}>
              <div class="card bg-lavender order-card">


                <div class="card-block">

                  <h3 class="m-b-2" style={{marginLeft:'20px',maxHeight:"20px"}} >  <i class="fa fa-plus"></i> Applied Jobs
                  </h3>
                  <h1 class="m-b-0" style={{ marginTop: '15px', fontSize: '20px' }}><h1 style={{ marginLeft: '80px' }}>{TotalAppliedJobs}</h1></h1>
                </div>
              </div>
            </div>


            <div style={{ width: '250px' }}>
              <div class="card bg-c-green order-card">


                <div class="card-block">

                  <h3 class="m-b-2" style={{marginLeft:'20px',maxHeight:"20px"}} >  <i class="fa fa-graduation-cap"></i>  Total User's
                  </h3>
                  <h1 class="m-b-0" style={{ marginTop: '15px', fontSize: '20px' }}><h1 style={{ marginLeft: '80px'}}>  {usercount}</h1></h1>
                </div>
              </div>
            </div>

            <div style={{ width: '250px' ,marginLeft:"1000px",marginTop:'-149px' }}>        
                  <div class="card bg-c-yellow order-card">


                <div class="card-block">

                  <h3 class="m-b-2" style={{marginLeft:'20px',maxHeight:"20px"}}>  <i class="fa fa-user-circle-o"></i>  Total Employer's
                  </h3>
                  <h1 class="m-b-0" style={{ marginTop: '15px', fontSize: '20px' }}><h1 style={{ marginLeft: '80px' }}><span >  {Employercount}</span></h1></h1>
                </div>
              </div>
            </div>



          </div>
        </div>
}

{/* 
        <h2 className="title" style={{ marginLeft: "530px" }}>Application </h2>

        <div className="search">

        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          label="Search"
        />
      </div> */}
 
  <h3 className="title" style={{ textAlign: "center", fontSize: "21px", color: "#444444" }}>
   Posted Jobs/Internships
  </h3>

  {/*<div className="search" style={{marginLeft:'280px',width:'250px'}}>
    <TextField
      id="outlined-basic"
      variant="outlined"
      fullWidth
      label="Search"
      // InputProps={{
      //   startAdornment: (
      //     <InputAdornment position="start">
      //       <SearchIcon />
      //     </InputAdornment>
      //   ),
      //   style: { fontSize: '16px' } 

      // }}
    />
    </div>*/}


        <div className="table-box table-responsive mx-auto">

          <table className="applications table"
            border="0" cellSpacing="0" cellPadding="0">
            <thead style={{ background: '#EEEEEE' }}>

              <tr>
                <th>PROFILE</th>
                <th >ORGANIZATION NAME</th>
                <th>STATUS</th>
                <th>REVIEW APPLICATION</th>
                <th>ACTIONS</th>


              </tr>
            </thead>
            <tbody>


              {jobData && jobData.map((job) => {
                // console.log("New Code - Profile Data:", job);

                return (
                  <tr key={job._id}>

                    <td>{job.profile}</td>
                    <td>{job.organisationName}</td>
                    <td>
                      {/* {job.status} */}
                      {approvalStatus[job._id] ? "Approved" : "Not Approved"}
                    </td>
                    <td><img src={list} alt="Account" onClick={() => navigate('/preview', { state: job })} style={{ cursor: 'pointer' }} />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <button className="btn btn-primary" onClick={() => toggleStatus(job._id)} style={{ marginTop: '15px' }}>
                        {approvalStatus[job._id] ? "Approved" : "Not Approved"}
                      </button>  
                      </td>         
                      </tr>
                );
              })}

            </tbody>
          </table>
        </div>




        <div className="pagination" style={{marginTop: "-30px"}}>
          <nav
            aria-label="Page navigation example"

          >

            <ul className="pagination justify-content-center" style={{ marginLeft: "19px", backgroundColor: "white" }}>
              <div className="pagination d-flex justify-content-start align-items-center flex-wrap" >
                <li className={`page-item ${start <= 1 ? "disabled" : ""}`} >
                  <a className="page-link" tabIndex="-1" onClick={() => backw()}>
                    &laquo;
                  </a>
                </li>


                {generatePageOneToTen(start, end)}



                <li className={`page-item ${end + 10 >= (count / 10) ? "disabled" : ""}`}>
                  <a className="page-link" onClick={() => forw()}>
                    &raquo;
                  </a>
                </li>
              </div>

            </ul>

          </nav>
        </div>


      </div>
  

  );
}

export default AdminDashboard;
