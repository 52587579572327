// BusinessBenefits.jsx
import React from 'react';
import '../css/LandingPageNew.scss';
import { GlobalMarketPlace, handshake, solarsShield, solarStar } from '../../../assets';
const BusinessBenefits = () => {
  const features = [
    {
      icon: `${solarStar}`,
      title: "Proof of high quality",
      description: "Review any professional's work samples, client feedback, and identity verification."
    },
    {
      icon: `${handshake}`,
      title: "Recruit with confidence",
      description: "Interview suitable candidates for your job, negotiate rates and only pay for approved work."
    },
    {
      icon: `${solarsShield}`,
      title: "Secure and reliable",
      description: "Concentrate on your work, knowing your data and privacy are protected. Our 24/7 support is always available if you need it."
    }
  ];

  return (
    <div className="benefits-container">
      <div className="features-section">
        <h1 className="title">
          Why businesses<br />
          turn to Workcroft
        </h1>
        
        <div className="features-list">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <span className="feature-icon"><img src={feature.icon} alt="" /></span>
              <div className="feature-content">
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="rating-section">
        <div className="image-container">
          <img src={GlobalMarketPlace} alt="Person using laptop" />
        </div>
        <div className="rating-content">
          <h2 className="rating-title">
            We're the global marketplace for work.
          </h2>
          <div className="rating-stats">
            <div className="rating-value">
              <span className="star">★</span> 4.9/5
            </div>
            <p className="rating-description">
              Clients provide ratings for professionals on Workcroft.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessBenefits;