import React from "react";
import "./css/AboutUs.scss";
const Story = () => {
    return (
        <div className="story-wrapper" style={{ backgroundColor: "white" }}>
            <h4 className="subHeading">Post a Project</h4>
            <p className="storyContent">Post a project to tell us about your project. We’ll quickly match you with awesome freelancers and skillsets.</p>
            <br />
            <br />
            <h4 className="subHeading">Interview</h4>
            <p className="storyContent">Browse proposals, profiles of freelancers & their reviews. Compare, interview & finalise the candidate.</p>
            <br />
            <br />
            <h4 className="subHeading">Hire</h4>
            <p className="storyContent">Assign projects to the finalized freelancer on Blackcoffer Freelance and pay them after the successful delivery of milestones, tasks, and projects.</p>
            <br />
            <br />
        </div>
    );
};

export default Story;
