import React, { useMemo } from 'react';

const ApplyButton = ({ data, user, profiledetail, connectCredit, setShowModal }) => {
    // Move URL generation logic to useMemo to prevent recalculation on every render
    const generateUrl = useMemo(() => {
        const { phone, city, resume } = user;
        
        // Check if profile is incomplete
        if (!phone || !city || !resume || !profiledetail || 
            profiledetail.Education.length === 0 || 
            (profiledetail.Job.length === 0 && profiledetail.Internship.length === 0) || 
            profiledetail.Skills.length === 0 || 
            profiledetail.Portfolio.length === 0 || 
            profiledetail.DocDetails.length === 0) {
            return '/profile';
        }
        
        // Check if resume is missing
        if ((phone || city) && !resume) {
            return '/profile';
        }

        // Generate job ID
        const jobId = `${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
        
        // Check credits and return appropriate URL
        if ((data.opportunityType === "Job" && connectCredit < 8) ||
            (data.opportunityType === "Hourly" && connectCredit < 4)) {
            return null; // Will trigger modal instead
        }
        
        return `/coverletterpage/${jobId}`;
    }, [data, user, profiledetail, connectCredit]);

    const handleClick = (e) => {
        e.preventDefault();
        
        // If URL is null, show modal
        if (!generateUrl) {
            setShowModal(true);
            return;
        }
        
        // Open in new tab
        window.open(generateUrl, "_blank");
    };

    return (
        <a
            href={generateUrl} // Use # as fallback if generateUrl is null
            rel="noreferrer"
            target="_blank"
            className="btn btnapply"
            style={{
                width: "100px",
                marginLeft: "20px",
            }}
            onClick={handleClick}
        >
            Apply Now
        </a>
    );
};

export default ApplyButton;