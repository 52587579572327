import React from 'react';

const Notfound = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
       <img src={require('../assets/images/404error.png')} alt="404 Error" style={{ width: '200px', height: 'auto' }} />

      <h1>404 Not Found</h1>
      <p>The page you're looking for does not exist.</p>
    </div>
  );
}

export default Notfound;
