import React, { forwardRef } from "react";
import { MdClose } from "react-icons/md";
import "./portfolioStyles.css";

const CloseButton = forwardRef(({ onClick, className }, ref) => {
  return (
    <div style={{ textAlign: "end" }}>
      <button
        onClick={onClick}
        type="button"
        data-bs-dismiss="modal"
        ref={ref}
        className={`conatiner ${className}`}
      >
        <MdClose className="close" />
      </button>
    </div>
  );
});

export default CloseButton;
