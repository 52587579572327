export const generateDataInSequence = (sequenceArray, data, articals) => {
  let sqArray = [...sequenceArray];
  console.log(sqArray, "sqarray");
  let newData = sqArray?.map((tm, index) =>
    tm === "image"
      ? {
          index: index + 1,
          type: tm,
          images: data?.photo,
        }
      : tm === "video"
      ? {
          index: index + 1,
          type: tm,
          videos: data?.videoLink,
        }
      : tm === "text-content"
      ? {
          index: index + 1,
          type: tm,
          textContents: data?.textContent,
        }
      : {
          index: index + 1,
          type: tm,
          webLinks: articals,
        }
  );
  return newData;
};

export function addEllipsis(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "....";
  }
  return str;
}

export function timeAgo(createdAt) {
  const now = new Date();
  const createdDate = new Date(createdAt);
  const diffInMilliseconds = now - createdDate;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  // console.log(diffInHours, "diffrent hours");

  if (diffInMinutes < 60) {
    return "Just now";
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  } else {
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  }
}

export function getDayValue(postedString) {
  // Regular expression to match the number in the string
  const match = postedString.match(/(\d+)\s+day/);

  // If a match is found, return the number as an integer
  if (match) {
    return parseInt(match[1], 10);
  }

  // Return null or a default value if the string doesn't match the expected format
  return null;
}

export function filterSimilerProjects(array1, array2, id) {
  const lowercasedArray2 = array2?.map((skill) => skill.toLowerCase());
  return array1.filter(
    (item) =>
      item.skill.some((skill) =>
        lowercasedArray2.includes(skill.toLowerCase())
      ) && item?._id !== id
  );
}

export function getRangeApplicants(value) {
  if (value === 0) {
    return "0";
  } else if (value >= 1 && value <= 5) {
    return "1 to 5";
  } else if (value >= 5 && value <= 10) {
    return "5 to 10";
  } else if (value > 10 && value <= 20) {
    return "10 to 20";
  } else if (value > 20 && value <= 50) {
    return "20 to 50";
  } else if (value > 50) {
    return "50+";
  }
}

export const findLargestBid = (bids) => {
  // If the array is empty, return null or a suitable default value
  if (!bids || bids.length === 0) {
    return 0;
  }
  // Use reduce to find the object with the largest bidAmount
  const findValue = bids.reduce((maxBid, currentBid) => {
    // Compare the bid amounts (convert them to numbers)
    return Number(currentBid.bidAmount) > Number(maxBid.bidAmount)
      ? currentBid
      : maxBid;
  });
  return findValue?.bidAmount;
};

export const findSmallestBid = (bids) => {
  // If the array is empty, return null or a suitable default value
  if (!bids || bids.length === 0) {
    return 0;
  }

  // Use reduce to find the object with the smallest bidAmount

  let findValue = bids.reduce((minBid, currentBid) => {
    // Compare the bid amounts (convert them to numbers)
    return Number(currentBid.bidAmount) < Number(minBid.bidAmount)
      ? currentBid
      : minBid;
  });
  return findValue?.bidAmount;
};
export function extractIdFromUrl(url) {
  const regex = /_(\w+)$/;
  const match = url.match(regex);
  if (match) {
    return match[1];
  } else {
    return null; // or handle the case when no match is found
  }
}

export const calculateDaysDifference = (startDate) => {
  const start = new Date(startDate);
  const end = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = end - start;

  // Convert the difference to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Set the state2 with the calculated difference
  return differenceInDays;
};
const getDaySuffix2 = (day) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDate2 = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear().toString().substr(-2);
  return `${day}${getDaySuffix2(day)} ${month}'${year}`;
};
const getDaySuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
export const formatDate = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = date?.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().substr(-2);
  return `${day}${getDaySuffix(day)} ${month}'${year}`;
};

export const calculateAverage = (data) => {
  const totalCount = data.reduce(
    (sum, item) => sum + Number(item.bidAmount),
    0
  );
  const average = totalCount / data.length;
  console.log(totalCount);

  return Math.floor(average);
};

export function determineColor(createdAt) {
  const timeAgoString = timeAgo(createdAt);

  if (timeAgoString.includes("hour") || timeAgoString.includes("Just now")) {
    return "green";
  }

  const days = getDayValue(timeAgoString);

  if (days !== null) {
    if (days < 6) {
      return "green";
    } else if (days < 11) {
      return "#0D8CB8";
    } else {
      return "#535353";
    }
  }

  // Default color if no conditions are met
  return "green";
}

export function determineBgColor(createdAt) {
  const timeAgoString = timeAgo(createdAt);

  if (timeAgoString.includes("hour") || timeAgoString.includes("Just now")) {
    return "#E1FFE1";
  }

  const days = getDayValue(timeAgoString);

  if (days !== null) {
    if (days < 6) {
      return "#E1FFE1";
    } else if (days < 11) {
      return "#E2F8FF";
    } else {
      return "#f5f5f5";
    }
  }

  // Default color if no conditions are met
  return "#E1FFE1";
}
