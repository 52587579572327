import React from "react";
import "./pagination.css"; // Ensure you have this CSS file for styling
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {

  const getPaginationItems = () => {
    let pages = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages <= 4) {
      // If total pages are less than or equal to 4, show all pages
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < 4) {
      // Show the first 4 pages
      startPage = 1;
      endPage = 4;
    } else if (currentPage >= totalPages - 2) {
      // Show the last 4 pages
      startPage = totalPages - 3;
      endPage = totalPages;
    } else {
      // Show the current page in the center with dots at start and end
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // Adding the range of page numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <a
          key={i}
          className={`page-item ${i === currentPage ? "active" : "not-active"}`}
          onClick={() => onPageChange(i)}
          style={{ color: i === currentPage ? "white" : "#0e709e" }}
        >
          {i}
        </a>
      );
    }

    // Adding dots and the first/last page number if necessary
    if (totalPages > 4 && currentPage >= 4 && currentPage < totalPages - 2) {
      pages.unshift(
        <span key="start-dots" className="dots">
          ...
        </span>
      );
      pages.push(
        <span key="end-dots" className="dots">
          ...
        </span>
      );
    }

    return pages;
  };

  return (
    <div className="pagination">
      <a
        className="button-prev"
        onClick={() => {
          if (currentPage === 1) {
            return;
          }
          onPageChange(currentPage - 1);
        }}
        style={{
          cursor: currentPage === 1 ? "not-allowed" : "pointer",
          color: currentPage === 1 ? "#a8a7a7" : "#0e709e",
        }}
      >
        <MdKeyboardArrowLeft style={{ fontSize: "20px" }} />
        <p style={{ marginTop: "10px", marginLeft: "5px" }}> Previous</p>
      </a>
      {getPaginationItems()}
      <a
        className="button-next"
        onClick={() => {
          if (currentPage === totalPages) {
            return;
          }
          onPageChange(currentPage + 1);
        }}
        style={{
          cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          color: currentPage === totalPages ? "#a8a7a7" : "#0e709e",
        }}
      >
        <p style={{ marginTop: "10px", marginRight: "5px" }}> Next</p>
        <MdKeyboardArrowRight style={{ fontSize: "20px" }} />
      </a>
    </div>
  );
};

export default Pagination;
