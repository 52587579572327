import React, { useEffect } from "react";
import "./css/Enterprise.scss";
import { Link } from "react-router-dom";
import { enterprise_1, enterprise_2, enterprise_3,brokenCheck,network,userStar,solar_flag,brokenCheckBlack,networkBlack,userStarBlack,solar_flagBlack } from "../../assets";
const Enterprise = () => {

    useEffect(() => {
        window.scroll(0,0)
    },[])
    return (
        <div className="enterprise-container">
            <div className="enterprise-hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Bring the top 1% of Workcroft talent to your business, tailored to your needs</h1>
                        <p>Bridge skill gaps in your team with specialized talent or build your dream team of experts using our fully customizable Enterprise platform.</p>
                        <Link to="/contactus">
                            <button style={{marginTop:"2rem"}} className="primary-btn">Contact Us</button>
                        </Link>
                    </div>
                </div>
                <div className="hero-image">
                    <img src={enterprise_1} alt="Person working on laptop" />
                </div>
            </div>

            <section className="how-it-works">
                <div className="how-it-works__content">
                    <div className="how-it-works__image">
                        <img src={enterprise_2} alt="Team meeting in modern office" />
                    </div>
                    <div className="how-it-works__steps">
                        <h2>How it works</h2>
                        <div className="steps">
                            <div className="step">
                                <span className="step__number">1</span>
                                <p>Tell us about your project and specify the skills you're seeking</p>
                            </div>
                            <div className="step">
                                <span className="step__number">2</span>
                                <p>Easily explore qualified talent with proven track records</p>
                            </div>
                            <div className="step">
                                <span className="step__number">3</span>
                                <p>Securely and efficiently hire using our customizable tools</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <h2>Whether it's small projects or complex initiatives, we're here to assist</h2>
                <div className="features__grid">
                    <div className="feature">
                        <img src={brokenCheck} className="feature__icon" alt="" />
                        <h3>Bring projects to successful completion</h3>
                        <p>Accomplish tasks and short-term projects with professionals who can fulfill your business requirements.</p>
                    </div>
                    <div className="feature">
                        <img src={network} className="feature__icon" alt="" />
                        <h3>Adjust and grow your workforce</h3>
                        <p>Expand your team to receive support for projects with multiple deliverables at any time.</p>
                    </div>
                    <div className="feature">
                        <img src={userStar} className="feature__icon" alt="" />
                        <h3>Add more talent to your bench</h3>
                        <p>Incorporate new roles into your team to achieve core company objectives.</p>
                    </div>
                    <div className="feature">
                        <img src={solar_flag} className="feature__icon" alt="" />
                        <h3>Delegate a little or a lot to our team</h3>
                        <p>Discover a range of managed solutions to aid in delivering complex projects.</p>
                    </div>
                </div>
            </section>

            <section className="solutions">
                <h2>Cutting-Edge Solutions to Elevate Your Talent Strategy</h2>
                <div className="solutions__content">
                    <div className="solutions__info">
                        <div className="solutions__card">
                            <h3>Manage talent from start to finish</h3>
                            <p>Handle everything in a single platform, from shortlisting top candidates to project management and collaboration tools.</p>

                            <h3>Collaborate with trusted talent</h3>
                            <p>Bring in familiar talent and oversee their engagement through a unified platform.</p>

                            <h3>Ensure compliance and workplace safety</h3>
                            <p>Utilize our international classification services to safeguard your business against legal risks.</p>
                        </div>
                    </div>
                    
                </div>
            </section>

            <section className="collaborate ">
                <h2>Collaborate with a dedicated Workcroft team member at every stage</h2>
                <p>Enterprise clients receive dedicated support teams at every stage of their journey.</p>
                <div className="features__grid">
                    <div className="feature">
                        <img src={brokenCheckBlack} className="feature__icon" alt="" />
                        <h3>Account Manager</h3>
                        <p>Maintains your account and collaborates with your team to optimize your Workcroft investment.</p>
                    </div>
                    <div className="feature">
                        <img src={networkBlack} className="feature__icon" alt="" />
                        <h3>Solutions Architect</h3>
                        <p>Develops custom talent solutions aligned with your organization's unique strategic goals.</p>
                    </div>
                    <div className="feature">
                        <img src={userStarBlack} className="feature__icon" alt="" />
                        <h3>Program Success Coordinators</h3>
                        <p>Keeps your team informed about using Workcroft and updates regarding your account.</p>
                    </div>
                    <div className="feature">
                        <img src={solar_flagBlack} className="feature__icon" alt="" />
                        <h3>Workcroft Professional</h3>
                        <p>Assists your team throughout the hiring process, from talent screening to project initiation.</p>
                    </div>
                </div>
                <Link to="/contactus">
                            <button className="primary-btn">Contact Us</button>
                        </Link>
            </section>

            
        </div>
    );
};

export default Enterprise;
