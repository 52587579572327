// src/components/Header.js
import React from "react";
import "../css/LandingPageNew.scss";
import { HeroSectionImage } from "../../../assets";
import { Link } from "react-router-dom";
const GetStarted = () => {
  return (
    <section className="hero-section">
        <div className="content-wrapper">
          <div className="hero-content">
            <h1>Connecting Top Talent with Leading Companies</h1>
            <p className="subtitle">
              Efficiently bridging the gap between skilled professionals and businesses.
            </p>
            <a href="/signup" className="get-started-btn">Get started</a>
          </div>
          
          <div className="hero-illustration">
            <img src={HeroSectionImage} alt="Hero section Image" />
          </div>
        </div>
      </section>

  );
};

export default GetStarted;
