import React from 'react'

export default function JobStipends({ job, setJobValueByName, setJob }) {
    const setPerk = (event) => {
        if (event.target.checked) {
            setJob({
                ...job,
                perks: job.perks.concat(event.target.value)
            })
        }
        else {
            setJob({
                ...job,
                perks: job.perks.filter(elem => elem !== event.target.value)
            })
        }
    }

    return (
        <>
            <div className="stipend-box">
                <div className="my-radio">
                    <label htmlFor="internshipProfile" className='label'>Internship Profile</label>
                    <div className="input">
                        <input type="radio" name="salaryProfile" checked={job.salaryProfile === "fixed"} onChange={setJobValueByName} value="fixed" id='fixed' />
                        <label htmlFor="fixed">Fixed</label>
                    </div>
                    <div className="input">
                        <input type="radio" name="salaryProfile" checked={job.salaryProfile === "negotiable"} onChange={setJobValueByName} value="negotiable" id='negotiable' />
                        <label htmlFor="negotiable">Negotiable</label>
                    </div>
                    <div className="input">
                        <input type="radio" name="salaryProfile" checked={job.salaryProfile === "unpaid"} onChange={setJobValueByName} value="unpaid" id='unpaid' />
                        <label htmlFor="unpaid">Unpaid</label>
                    </div>
                </div>
                <div className="my-radio">
                    <label htmlFor="internshipProfile" className='label'>Perks</label>
                    <div className="input">
                        <input type="checkbox" name='perks' checked={job.perks.includes("certificate")} onChange={setPerk} value="certificate" id='certificate' />
                        <label htmlFor="certificate">Certificate</label>
                    </div>
                    <div className="input">
                        <input type="checkbox" name='perks' checked={job.perks.includes("flexibleHours")} onChange={setPerk} value="flexibleHours" id='flexibleHours' />
                        <label htmlFor="flexibleHours">Flexible Work Hours</label>
                    </div>
                    <div className="input">
                        <input type="checkbox" name='perks' checked={job.perks.includes("letterOfRecommendation")} onChange={setPerk} value="letterOfRecommendation" id='letterOfRecommendation' />
                        <label htmlFor="letterOfRecommendation">Letter of Recommendation</label>
                    </div>
                    <div className="input">
                        <input type="checkbox" name='perks' checked={job.perks.includes("FiveDaysAWeek")} onChange={setPerk} value="FiveDaysAWeek" id='FiveDaysAWeek' />
                        <label htmlFor="FiveDaysAWeek">Five days a week</label>
                    </div>
                </div>
            </div>
        </>
    )
}
