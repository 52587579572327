import React, { useState } from "react";
import "./css/Faqs.scss";
import { downArrow } from "../../assets";

const Faqs = () => {
    const [openSection, setOpenSection] = useState("");

    const toggleSection = (section) => {
        setOpenSection(openSection === section ? "" : section);
    };

    const FAQData = {
        "About Workcroft": [
            {
                question: "What projects can be done on Workcroft?",
                answer: `Anything you can do on a computer can be done through Workcroft.
    
    Freelancers in our marketplace can tackle a wide range of projects — big or small, one-off or repeat, individual or team-based. Whether you need a content writer for an SEO-friendly blog post, or your own 24/7 software development team to build a mobile app or create a web portal, you'll find talent on Workcroft ready to support your business.`,
            },
            {
                question: "What's the difference between finding clients online, versus locally?",
                answer: `Technology is making online work similar to local work, with added speed, cost, and quality advantages.
    
    To begin with, online work can happen wherever there's a reliable Internet connection — an office, home, café, or rooftop. This also means you can choose who you work with, among a larger pool of people from around the globe.
    
    There are some things that don't change when working freelance jobs with clients you find online. Once the project begins, you'll want to communicate openly and clearly, and work to create a successful project for all.`,
            },
            {
                question: "How does Workcroft make money?",
                answer: "Workcroft charges freelancers a service fee ranging from 20% to 5%, depending on the total amount they’ve billed with a client.",
            },
        ],
        "About Hiring": [
            {
                question: "What should I do after I register?",
                answer: `Define your project. Outline your goals, deliverables, the skills you're looking for, and your desired deadline.`,
            },
            {
                question: "How do I make my project a success?",
                answer: `Start by writing a clear and concise job post. Also, when evaluating candidates, focus on freelancers with proven work on similar projects (we'll highlight a short list of freelancers likely to succeed). Once you’ve selected your freelancer, communicate clearly about your needs.`,
            },
            {
                question: "What if I’m not happy with the results?",
                answer: `Most projects on Workcroft get completed on time and as expected, but if needed, we’ll step in to offer help.
    
    If there’s a disagreement between you and your freelancer, we’ll provide dispute assistance and, if needed, connect you with arbitration.`,
            },
            {
                question: "How can I ensure that my IP is safe?",
                answer: `The Service Contract terms in our User Agreement state that you own any intellectual property that you pay for, and our Workcroft Terms of Service contain default Non-Disclosure Agreement (NDA) which many clients consider sufficient for this purpose.
    
    You can also add additional terms to your job, and have your freelancer sign an additional NDA if needed.`,
            },
            {
                question: "How do I know my freelancer is accurately billing for my project?",
                answer: `You can evaluate your freelancer's activity through hourly, daily, or weekly reports.`,
            },
            {
                question: "Who owns the legal rights to work product developed by a freelancer engaged through Workcroft?",
                answer: `Once a freelancer receives payment for work completed on a project, our Terms of Service specify that ownership of that work transfers to the client.
    
    If desired, freelancers and clients may also agree on different or additional terms regarding work product ownership.`,
            },
            {
                question: "How do I pay my freelancer and what does it cost?",
                answer: `You and your freelancer agree upon the price according to your project scope and budget. Choose to pay using MasterCard, Visa, American Express, or PayPal. Any payments are subject to a standard processing fee.`,
            },
            {
                question: "How often will I be charged?",
                answer: `Hourly projects use our automated billing system. Fixed-price projects are divided into agreed-upon milestones.
    
    You have two options for working with your freelancer: hourly or fixed-price.`,
            },
            {
                question: "Can I get an official invoice?",
                answer: `Yes, all payments to freelancers have invoices that can be downloaded or printed.`,
            },
            {
                question: "Do I have to file tax forms for my freelancer?",
                answer: `You should not be responsible for`,
            },
            {
                question: "What do I look for in a freelancer?",
                answer: `Most clients look for freelancers who have 1. The skills to get your job done and 2. A track record of satisfying clients.
    
    You can answer these questions by reviewing a candidate’s Workcroft profile, which each freelancer has. It can include work experience and samples, client feedback, skills test scores, and much more. You may also want to look for freelancers in our Top Rated and Rising Talent programs. Then, have a quick interview with your top candidates to answer questions related to your project.`,
            },
            {
                question: "How do I hire on Workcroft?",
                answer: `After you sign up, find a freelancer and click the “Hire” button to get started.`,
            },
            {
                question: "Does Workcroft screen freelancers?",
                answer: `We have several measures in place to ensure Workcroft is a fair and reliable marketplace.
    
    We use multiple means to verify that freelancers are who they say they are. Information is also displayed that gives you a sense of each person’s skill level. In part, this includes:
    
    - Authenticating email addresses
    - Displaying each freelancer’s Job Success score and feedback on past projects
    - Providing results of online skills tests
    
    Ultimately, it’s your responsibility to screen prospective freelancers to make sure their skills and experience meet the needs of your project. Custom screening questions can be included in your job post, and Workcroft Messages provides chat and video conference tools for interviewing finalists.`,
            },
            {
                question: "Can Workcroft help find freelancers for me?",
                answer: `Yes.`,
            },
        ],
        "About Freelancing": [
            {
                question: "How do I get started freelancing through Workcroft?",
                answer: `All you need to get started is a computer, Internet connection, and amazing skills.
                         Your next step is creating an outstanding freelancer profile to showcase your business.
                         Think of it as an introduction, resume, and marketing brochure rolled into one, highlighting your:
                         - Professional skills, experience, and portfolio
                         - Education and accomplishments
                         - Online skills test results
                         The best profiles are complete, well-written, error-free, and feature a professional, friendly-looking picture.
                         Once you have a strong profile, start looking for jobs. Search, submit proposals, interview, repeat.
                         More activity means more job prospects.`,
            },
            {
                question: "Who can find freelance opportunities online?",
                answer: `Anyone with professional skills and a reliable Internet connection can use Workcroft to find opportunities 
                         and build a thriving business. Thousands of jobs are posted daily, offering a variety of work opportunities for 
                         those with the right skills.`,
            },
            {
                question: "What’s the best way to work with a client online?",
                answer: `In many ways, working with a client online is just like working with any other client. To be successful, you should 
                         choose work you're qualified for, communicate well with your clients and project team, and take the time to build 
                         a great working relationship. Here are a few tips from our marketplace’s most successful freelancers:
                         - Choose your projects carefully.
                         - Start the job strong.
                         - Communicate clearly.
                         - Ensure client satisfaction.`,
            },
            {
                question: "How can I find the right freelance projects?",
                answer: `Workcroft’s matching and search capabilities help you pinpoint interesting projects. Determine the type of jobs 
                         you want to search for based on factors such as:
                         - Project size (small project or longer-term contract)
                         - Availability (hours per week)
                         - Payment preference (fixed price or by the hour)
                         Search Workcroft for jobs that fit your skills and preferences. Read descriptions carefully to determine the 
                         ones you are most qualified for and if they meet your criteria. Customize your proposal to address the needs 
                         outlined in the description.`,
            },
            {
                question: "What kind of clients can I find on Workcroft?",
                answer: `Clients using the Workcroft platform range from Fortune 500 companies to one-person startups. Workcroft clients 
                         can be top tech companies building a huge product or small businesses needing a brochure design. You'll have 
                         the opportunity to work with companies you like on projects you love.`,
            },
            {
                question: "Why should I freelance through Workcroft?",
                answer: `Workcroft gives you the freedom to work anytime, from anywhere, and grow your freelance business with ongoing 
                         opportunities. Workcroft offers:
                         - Unparalleled opportunity with 100+ clients and 1,000+ projects posted each month
                         - Reputation building through a client feedback system, enhancing your business and earnings potential`,
            },
            {
                question: "How much can I earn?",
                answer: `Freelancers set and negotiate their own rates. Your earning power is often tied to experience and demand for your 
                         skills. Freelancers on Workcroft, on average, increase their hourly rate almost 60% in the first year and close to 
                         190% over 3 years. With global clientele, your earning potential is unlimited by local market rates.`,
            },
            {
                question: "How much does Workcroft cost?",
                answer: `It's free to join Workcroft. We charge 4 to 8 connects for submitting proposals from freelancers.`,
            },
        ],
    };

    return (
        <div className="faqs">
            {Object.entries(FAQData).map(([section, questions]) => (
                <div key={section} className="faqs__section">
                    <h2 className="faqs__section-title">{section}</h2>

                    {questions.map((faq, index) => (
                        <div key={index} className={`faqs__item ${openSection === `${section}-${index}` ? "faqs__item--open" : ""}`}>
                            <button className="faqs__question" onClick={() => toggleSection(`${section}-${index}`)}>
                                {faq.question}
                                <span className="faqs__icon">
                                    <img src={downArrow} alt="" />
                                </span>
                            </button>
                            <div className="faqs__answer">
                                <div className="faqs__answer-content">{faq.answer}</div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Faqs;
