import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CreateJobs from './CreateJobs'
import Messages from './Messages'
import OrganizationDetails from './OrganizationDetails'
import PersonalDetails from './PersonalDetails'
import ViewApplications from './ViewApplications'

function Employer() {
    return (
        <Routes>
            <Route exact path='/applications' element={<ViewApplications />} />
            <Route exact path='/organizationDetails' element={<OrganizationDetails />} />
            <Route exact path='/personalDetails' element={<PersonalDetails />} />
            <Route exact path='/postJob' element={<CreateJobs />} />
            <Route exact path='/messages' element={<Messages />} />
        </Routes>
    )
}

export default Employer