import React, { useState, useEffect } from 'react'
import "./css/ViewApplications.scss"

function ViewApplications() {
    const [applicationType, setApplicationType] = useState("Job");
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0)
      },[]);

    return (
        <div className="view-applications my-applications">
            <div className="filters">
                <span onClick={() => { setApplicationType("Internship") }} className={`${applicationType === "Internship" ? "active" : ""}`}>Internships</span>
                <span onClick={() => { setApplicationType("Job") }} className={`${applicationType === "Job" ? "active" : ""}`}>Jobs</span>
            </div>

            <div className="table-box">
                <table className="applications">
                    <thead>
                        <tr>
                            <th>Profile</th>
                            <th>Status</th>
                            <th>Action</th>
                            <th>Share and Other</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Full Stack Development</td>
                            <td>Closed</td>
                            <td>
                                <strong className='viewed status'>View Application</strong>
                            </td>
                            <td>
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text view"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line x1={16} y1={13} x2={8} y2={13} /><line x1={16} y1={17} x2={8} y2={17} /><polyline points="10 9 9 9 8 9" /></svg>
                            </td>
                        </tr>
                        <tr>
                            <td>Full Stack Development</td>
                            <td>Closed</td>
                            <td>
                                <strong className='viewed status'>View Application</strong>
                            </td>
                            <td>
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text view"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line x1={16} y1={13} x2={8} y2={13} /><line x1={16} y1={17} x2={8} y2={17} /><polyline points="10 9 9 9 8 9" /></svg>
                            </td>
                        </tr>
                        <tr>
                            <td>Full Stack Development</td>
                            <td>Closed</td>
                            <td>
                                <strong className='viewed status'>View Application</strong>
                            </td>
                            <td>
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text view"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line x1={16} y1={13} x2={8} y2={13} /><line x1={16} y1={17} x2={8} y2={17} /><polyline points="10 9 9 9 8 9" /></svg>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ViewApplications