import React from "react";
import "./css/Plan.scss";

const Plan = () => {
  return (
    <div className="container-fluid plansection">
      <div className="container py-5">
        <h3 className="title text-center ">
          Select a plan and hire your ideal candidate
        </h3>
        <div className="row my-5">
          <div className="col-lg-4 col-md-4 col-sm-12 mx-auto py-2">
            <div className="plan1 text-center mx-4">
              <h3 className="type">Startup</h3>
              <h3 className="rate ">$14</h3>
              <p className="para">per user, per month</p>
              <button className="btn price my-5">Start My 15-day Trial</button>
              <div className="bottmborder"></div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mx-auto py-2">
            <div className="Business text-center mx-4">
              <h3 className="type">Business</h3>
              <h3 className="rate ">$29</h3>
              <p className="para">per user, per month</p>
              <button className="btn price my-5">Start My 15-day Trial</button>
              <div className="bottmborder"></div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mx-auto py-2">
            <div className="plan1 text-center mx-4">
              <h3 className="type">Agency</h3>
              <h3 className="rate ">$139</h3>
              <p className="para">per user, per month</p>
              <button className="btn price my-5">Start My 15-day Trial</button>
              <div className="bottmborder"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
