import React from 'react'

function PostJobForm({ job, setJobValueByName, setJob }) {
    const addSkill = (event) => {
        if (event.key === "Enter") {
            if (!event.target.value) return;

            setJob({
                ...job, skillsRequired: job.skillsRequired.concat(event.target.value)
            })
            event.target.value = "";
        }
    }

    const removeSkill = (skill) => {
        setJob({
            ...job, skillsRequired: job.skillsRequired.filter(elem => elem !== skill)
        })
    }

    const handlePartTimeAllowedInput = (event) => {
        if (event.target.id === "yes") {
            setJob({
                ...job,
                isPartTimeAllowed: true
            })
        }
        if (event.target.id === "no") {
            setJob({
                ...job,
                isPartTimeAllowed: false
            })
        }
    }

    return (
        <>
            <div className="radio-box">
                <div className="check-container">
                    <label htmlFor="opportunityType" className='strong'>Opportunity Type</label>
                    <div className='checks'>
                        <input type="radio" name="opportunityType" onChange={setJobValueByName} id='internship' value="interntship" />
                        <label htmlFor="internship">Internships</label>
                    </div>
                    <div className="checks">
                        <input type="radio" name="opportunityType" onChange={setJobValueByName} id='fresherJob' value="Fresher Jobs" />
                        <label htmlFor="fresherJob">Fresher Jobs</label>
                    </div>
                </div>
                <div className="check-container">
                    <label htmlFor="startDate" className='strong'>Internship Start Date</label>
                    <div className='checks'>
                        <input type="radio" name="startDate" onChange={setJobValueByName} id='immidiately' value="Immediately" />
                        <label htmlFor="immidiately">Immediately</label>
                    </div>
                    <div className="checks">
                        <input type="radio" name="startDate" onChange={setJobValueByName} id='later' value="Later" />
                        <label htmlFor="later">Later</label>
                    </div>
                </div>
                <div className="check-container">
                    <label htmlFor="startDate" className='strong'>Internship Type</label>
                    <div className='checks'>
                        <input type="radio" name="jobType" onChange={setJobValueByName} id='inOffice' value="Office" />
                        <label htmlFor="inOffice">In Office</label>
                    </div>
                    <div className="checks">
                        <input type="radio" name="jobType" onChange={setJobValueByName} id='remote' value="Remote" />
                        <label htmlFor="remote">Remote</label>
                    </div>
                </div>
            </div>

            <div className="inputs">
                <strong className="label">Internships Details</strong>

                <div className="input-box">
                    <label htmlFor="internshipProfile">Internship Profile</label>
                    <input type="text" onChange={setJobValueByName} value={job.internshipProfile} name='internshipProfile'  />
                </div>
                <div className="input-box">
                    <label htmlFor="skillsRequired">Skills Required</label>
                    <input type="text" name='skillsRequired' onKeyUp={addSkill} />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap", width: "100%", gap: "1rem" }}>
                    {job.skillsRequired && job.skillsRequired.map((elem) => {
                        return <div key={elem} className="chip rounded">
                            {elem}
                            <svg onClick={() => { removeSkill(elem) }} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} width={14} height={14} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg>
                        </div>
                    })}
                </div>
                <div className="input-box">
                    <label htmlFor="cities">City/Cities</label>
                    <input type="text" onChange={setJobValueByName} value={job.cities} name='cities' />
                </div>
                <div className="input-box">
                    <label htmlFor="partTimeAllowed">Is part time allowed?</label>
                    <div className="radios" style={{ display: "flex", justifyContent: "flex-start" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: ".5rem", maxWidth: "80px" }}>
                            <input type="radio" onChange={handlePartTimeAllowedInput} checked={job.isPartTimeAllowed} name="partTimeAllowed" id="yes" />
                            <label htmlFor='yes'>Yes</label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: ".5rem", maxWidth: "80px" }}>
                            <input type="radio" onChange={handlePartTimeAllowedInput} checked={!job.isPartTimeAllowed} name="partTimeAllowed" id="no" />
                            <label htmlFor='no'>No</label>
                        </div>
                    </div>
                </div>
                <div className="input-box">
                    <label htmlFor="openings">No. of Openings</label>
                    <input type="number" onChange={setJobValueByName} min="1" value={job.noOfOpenings} name='noOfOpenings' />
                </div>
                <div className="input-box" style={{ alignItems: "flex-start" }}>
                    <label htmlFor="duration">Duration</label>
                    <div className="box">
                        <div className="duration-box" style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}>
                            <div className="year">
                                <select name="years" id="years">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                                <label htmlFor="years">Years</label>
                            </div>
                            <div className="year" style={{ gridTemplateColumns: "60px  55px" }}>
                                <select name="month" id="month">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                                <label htmlFor="years">Months</label>
                            </div>
                            <div className="year">
                                <select name="day" id="day">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                </select>
                                <label htmlFor="years">Days</label>
                            </div>
                        </div>
                        <div style={{ textAlign: "center", margin: "1rem", fontWeight: "500", fontSize: "1.4rem" }}>OR</div>
                        <input type="text" placeholder='YY-MM-DD' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PostJobForm