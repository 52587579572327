import React, { useState, useEffect } from 'react'
import Spinner from '../Layout/Spinner'
// import Logo from '../../assets/images/logo.png'
import Logo from '../../assets/images/Workcroft.png'
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function RecoveryEmail() {


  const navigate = useNavigate();
   const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({ email: "" });



    const handleUserChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
      };




    
      const signup = async (event) => {
        event.preventDefault();
        setLoading(true);
      


        if(user.email==""){

            toast.error("Enter your email")
            setLoading(false);
        }

        else{
          try {
            const { data } = await axios.post("/api/user/otpfp", user);
            setLoading(false);
           navigate('/otpverification', { state: user })
          
          } catch (error) {
            setLoading(false);
            toast.error(error.response.data, {
              style: {
                padding: '18px',  
              }
            });
          
          }
    
        }
    
     
      
      };




   
{/*
    const signup = async () => {
        setLoading(true);
        if(user.otp==""){
            setLoading(false);
            toast.error("Please enter the OTP!!")
        }
        else if(user.otp == await otp()){
          try {
            const { data } = await axios.post("/api/user/signup", state);
            console.log(data);
            setLoading(false);
            toast.success("Account Created Successfully!", {
              style: {
                padding: '18px',  
              }
            });  
            const timer = setTimeout(() => {
                navigate('/login'); 
              }, 2000);
            
        timer();
      
          } catch (error) {
            console.log(error.response.data);
            setLoading(false);
            toast.success(error.response.data, {
              style: {
                padding: '18px',  
              }
            });
          
          }
    
          
        }
        else{
            setLoading(false);
            toast.error("Wrong OTP entered!!")
        }
   
      
      };

    */}
   

    

    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          const myButton = document.querySelector('.sendOTP');
          if (myButton) {
            myButton.click();
          } else {
            console.error('Button not found');
          }
        }
      };
      // Attach the event listener to the document
      document.addEventListener('keydown', handleKeyPress);
      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, []);

   
    return (<>

       
        
        <div className="login-component" >
            <div className="form-box">
                <div className="form">
                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: "3rem" }}>
                        <img src={Logo} alt="logo" style={{ width: "100px", height: "auto" }} />
                    </div>
                    <p className='text-center' style={{fontSize: "25px",  fontWeight: "bold"}}>Forgot Password?</p>
                    <p className='text-center'> Enter your Registered Email<br></br></p>
                
                    <div className="input-box">
                       
                        <input type="text" name="email" id="email"  onChange={handleUserChange} value={user.email} />
                    </div>
                    {  /* <div className="input-box">
                        <label htmlFor="password">Password</label>
                        <Link to='/forgot' className='forgot'>Forgot Password?</Link>
                        <input type="password" name="password" value={user.password} onChange={handleUserChange} id="password" />
                    </div> */}
                  
                    <button onClick={signup} className="btn btn-primary sendOTP" style={{ width: "100%", marginTop: "2rem" }}>
                        {loading ? <Spinner /> : <span>Send OTP</span>}
                    </button>
                </div>
            </div>
        </div>
        <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>)
}

export default RecoveryEmail