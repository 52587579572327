import React,{useEffect} from "react";
import { GoDotFill } from "react-icons/go";



function Privicy() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
      }, []);
    return (<>
        <div className="terms-of-use" style={{ backgroundColor: "white" }}>
            <div style={{ maxWidth: "1200px", marginLeft: "auto", marginRight: "auto", paddingTop: "50px", paddingBottom: "50px"}} className="about-us-mobile-responsive">

                <h1 style={{ fontSize: "32px", fontWeight: "bold" }}>Privacy Policy</h1>
                <p style={{ fontSize: "14px" }}>Effective May 26, 2024</p>
                <br />

                <p style={{ fontSize: '14px', lineHeight: "25px" }}>Workcroft (“<b>Workcroft</b>”) provides this Privacy Policy to let you know our policies and procedures regarding the collection, use and disclosure of information through Workcroft.com (the “<b>Site</b>”), 
                and any other websites, features, applications, widgets or online services that are owned or controlled by Workcroft and that post a link to this Privacy Policy (together with the Site, the “<b>Service</b>”), 
                as well as any information Workcroft collects offline in connection with the Service. It also describes the choices available to you regarding the use of, your access to, and how to update and correct your personal information.
                <b>Note that we combine the information we collect from you from the Site, through the Service generally, or offline.</b></p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact us.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>Please note that certain features or services referenced in this Privacy Policy may not be offered on the Service at all times. Please also review our <b>Terms of Service</b>, which governs your use of the Service.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>We’ve provided short summaries in this Privacy Policy to help you understand what information we collect, how we use it, and what choices or rights you may have. While these summaries help explain some of the concepts in a simple and clear way, we encourage you to read the entire Privacy Policy to understand our data practices.</p>

                <h3 style={{ marginTop: "40px", marginBottom: "30px", fontSize: "20px" }}>1. INFORMATION COLLECTION</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>Users of the Service may be <b>Clients</b>, <b>Freelancers</b>, or <b>Agencies</b> (as each is defined in the user agreement).</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Information You Provide to Us</strong></p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>When you use the Service, you may provide us with information about you. This may include your name and contact information, financial information to make or receive payment for services obtained through the Workcroft platform, or information to help us fill out tax forms. When you use the Service, we may also collect information related to your use of the Service and aggregate this with information about other users. This helps us improve our Services for you. You may also provide us with information about your contacts or friends if, for example, you’d like to add those contacts to a message room. Agencies may also provide us with information about Freelancers associated with the Agency.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><strong>Personal Information:</strong> 
                In the course of using the Service (whether as a Client or Freelancer), 
                we may require or otherwise collect information that identifies you as a specific individual and can be used to contact or identify you
                (“<b>Personal Information</b>”). Examples of Personal Information include your name, email address, company address, billing address, and phone number.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><strong>Payment Information:</strong> 
                If you use the Service to make or receive payments, we will also collect certain payment information, such as credit card, PayPal or other financial account information, and billing address.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><strong>Identity Verification:</strong> 
                We may collect Personal Information, such as your date of birth or taxpayer identification number, to validate your identity or as may be required by law, such as to complete tax filings. We may request documents to verify this information, such as a copy of your government-issued identification or photo or a billing statement.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><strong>General Audience Service:</strong> 
                The Service is general audience and intended for users 18 and older. We do not knowingly collect Personal Information from anyone younger than age 18. If we become aware that a child younger than 18 has provided us with Personal Information, we will use commercially reasonable efforts to delete such information from our files. If you are the parent or legal guardian of a child younger than age 18 and believe that Workcroft has collected Personal Information from your child, please contact us at 
                 <a href="hello@workcroft.com"> hello@workcroft.com</a></p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><strong>Non-Identifying Information/Usernames:</strong> 
                We also may collect other information, such as zip codes, demographic data, information regarding your use of the Service, and general project-related data 
                (“<b>Non-Identifying Information</b>”). We may aggregate information collected from Workcroft registered and non-registered users (“<b>Workcroft Users</b>”). 
                We consider usernames to be Non-Identifying Information. Usernames are made public through the Service and are viewable by other Workcroft Users.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                In some cases, we may render Personal Information (generally, email address) into a form of Non-Identifying Information referred to in this Privacy Policy as 
                “<b>Hashed Information.</b>”  This is typically accomplished using a mathematical process (commonly known as a hash function) to convert information into a code. The code does not identify you directly, but it may be used to connect your activity and interests.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><strong>Combination of Personal and Non-Identifying Information:</strong> 
                Certain Non-Identifying Information would be considered a part of your Personal Information if it were combined with other identifiers in a way that enables you to be identified (for example, combining information with your name). But the same pieces of information are considered Non-Identifying Information when they are taken alone or combined only with other non-identifying information (for example, your viewing preferences). We may combine your Personal Information with Non-Identifying Information, but Workcroft will treat the combined information as Personal Information.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><strong>Collection of the Third Party Personal Information:</strong> 
                We collect the following personal information from you about your contacts or friends: First name, last name, and email address when you provide it to us for the purpose of adding your contacts to a message room.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>You do not have a statutory obligation to provide us with any information, but you may have a contractual obligation to do so, and if we do not receive certain information from you, then we will not be able to provide our Service to you. If you have any questions regarding whether provision of information is mandatory and the consequences for withholding such information, please contact us using the contact information below.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Information Received from Third Parties</strong> <br /><br />
                Third parties may also give us information about you. If we combine that information with information about you collected through the Service, we will still treat that combined information as set forth in this Privacy Policy. 
                <br /><br /> We also may receive information about you from third parties. For example, we may supplement the information we collect with outside records or third parties may provide information in connection with a co-marketing agreement or at your request (such as if you choose to sign in with a third-party service). If we combine the information we receive from others with information we collect through the Service, we will treat the combined information as described in this Privacy Policy.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Information Collected Automatically</strong><br /><br />
                Like other online companies, we receive technical information when you use our Services. We use these technologies to analyze how people use our Services, to improve how our Site functions, to save your log-in information for future sessions, and to serve you with advertisements that may interest you.
                 <br /><br /> We and our third party service providers, including analytics and third party content providers, may automatically collect certain information from you whenever you access or interact with the Service. This information may include, among other information, the browser and operating system you are using, the URL or advertisement that referred you to the Service, the search terms you entered into a search engine that led you to the Service, areas within the Service that you visited, and other information commonly shared when browsers communicate with websites. We may combine this automatically collected log information with other information we collect about you. We do this to improve services we offer you, to improve marketing, analytics, and site functionality.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}>The information we collect also includes the Internet Protocol 
                (“<b>IP</b>”) address or other unique device identifier (“<b>Device Identifier</b>”) 
                for any device (computer, mobile phone, tablet, etc.) used to access the Service. A Device Identifier is a number that is automatically assigned or connected to the device you use to access the Service, and our servers identify your device by its Device Identifier. Some mobile service providers may also provide us or our third party service providers with information regarding the physical location of the device used to access the Service.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><b><span style={{color:"#1F57C3"}}>Workcroft and its partners use cookies or similar technologies to analyze trends, administer the website, track users’ movement around the website, and to gather demographic information about our user base as a whole. The technology used to collect information automatically from Workcroft Users may include the following:</span></b></p>


                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Cookies:</strong> 
                Like many websites, we and our marketing partners, affiliates, analytics, and service providers use “cookies” to collect information. A cookie is a small data file that we transfer to your computer’s hard disk for record-keeping purposes. We use both persistent cookies that remain on your computer or similar device (such as to save your registration ID and login password for future logins to the Service and to track your compliance with the Workcroft Terms of Service) and session ID cookies, which expire at the end of your browser session (for example, to enable certain features of the Service, to better understand how Workcroft Users interact with the Service and to monitor aggregate usage by Workcroft Users and web traffic routing on the Service). You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functionality of the Service.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Web Beacons:</strong> 
                We and our marketing partners, affiliates, analytics, and service providers may also employ software technology known as “web beacons” and/or “tracking tags” to help us keep track of what content on our Service is effective and to serve relevant advertising to you. Web beacons are small graphics with a unique identifier that may be invisible to you, and which are used to track the online activity of Internet users. Web beacons are embedded in the web pages you review or email messages you receive. Web beacons or similar technologies may be used for a number of purposes, including, without limitation, to count visitors to our Service, to monitor how Workcroft Users navigate the Service, to count how many emails that were sent were actually opened, or to count how many particular articles or links were actually viewed.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Embedded Scripts:</strong>We and our marketing partners, affiliates, analytics, and service providers may also employ software technology known as an Embedded Script. An Embedded Script is programming code that is designed to collect information about your interactions with the Service, such as the links you click on. The code is temporarily downloaded onto your computer or other device and is deactivated or deleted when you disconnect from the Service.</p>

                <p style={{ fontSize: '14px', lineHeight: "30px" }}><br />In addition, we and our marketing partners, affiliates, analytics, and service providers may use a variety of other technologies (such as tags) that collect similar information for security and fraud detection purposes and we may use third parties to perform these services on our behalf.</p>

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><br /><strong>HOW WE RESPOND TO DO NOT TRACK SIGNALS</strong> <br /><br />
                Workcroft does not respond to Do-Not-Track signals.<br /><br /> 
                Please note that your browser setting may allow you to automatically transmit a “Do Not Track” signal to websites and online service you visit. There is no consensus among industry participants as to what “Do Not Track” means in this context. Like many websites and online services, Workcroft does not alter its practices when it receives a “Do Not Track” signal from a visitor’s browser. To find out more about “Do Not Track,”
                please visit <a href="http://www.allaboutdnt.com" style={{color:'#108A00'}}>http://www.allaboutdnt.com</a>.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>User Profiles</strong></p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}> 
                Freelancers may create a profile, with certain or all information publicly available. Clients and Agencies may also create organization profiles.<br /><br /> 
                You may have the opportunity to create a profile, which consists of information about you, and may include Personal Information, photographs, examples of your work, information on work previously performed via the Service and outside the Service, skills, tests taken, test scores, hourly pay rates, feedback/rating information and other information, including your username
                (“<b>Profile</b>”). The information in your Profile may be visible to all Workcroft Users and the general public subject to the privacy choices you make within your Workcroft Profile. You may edit certain information in your Profile via your account and may choose to limit who is able to view certain content you post to your Profile. Clients and Agencies of associated individual users or companies may also have the opportunity to create an organization Profile. If, in any case, you believe that an unauthorized profile has been created about you, you can request for it to be removed by contacting us at <a href="hello@workcroft.com">hello@workcroft.com</a>.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Messaging</strong><br /><br /> 
                Freelancers, Agencies, and Clients may communicate with each other through the Service. For example, Freelancers, Agencies, and Clients may wish to discuss Client needs and Freelancer work proposals. If you communicate with an Agency or Client, that Agency or Client will also be a “data controller” with respect to such communications.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Community Forums</strong>  <br /> <br />
                We may offer public forums or blogs. If you participate, your comments and questions will be publicly displayed. 
                <br /> <br /> 
                The Service may provide you the opportunity to participate and post content publicly in forums, on blogs, through interactive features Workcroft and through other communication functionality 
                (“<b>Community Forums</b>”). 
                You may choose, through such features or otherwise, to submit or post questions, comments, or other content 
                (collectively, “<b>User Forum Content</b>”). Please note that certain information, such as your name and Profile may be publicly displayed on the Service along with your User Forum Content. Please note that your use of Community Forums is subject to the Workcroft Forum Rules and our Terms of Service.
                <br /><br />
                <b> <span style={{color:"#1F57C3"}}>Note that anything you post to a Community Forum is public — others will have access to your User Forum Content and may use it or share it with third parties. If you choose to voluntarily disclose Personal Information in your User Forum Content or use Community Forums to link to your Profile, that information will be considered public information and the protections of this Privacy Policy will not apply.</span></b>
                <br />  <br />To request removal of your personal information from our blog or community forum, contact us at <a href="hello@workcroft.com">hello@workcroft.com</a>. 
                In some cases, we may not be able to remove your Personal Information, in which case we will let you know if we are unable to do so and why.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Testimonials</strong><br /><br />
                We display personal testimonials of satisfied customers on our Service, in addition to other endorsements. With your consent we may post your testimonial along with your name. If you wish to update or delete your testimonial, you can contact us at <a href="hello@workcroft.com">hello@workcroft.com</a>.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Work Listings Through the Service</strong> <br /><br />
                If you choose to post a work listing via the Service as a Client, the contents of such listing will be viewable publicly, unless you designate the listing as only viewable through the Service or as private using the settings available on the applicable website. Work listings include information such as budget, location, history of work listing(s) by the Client, the names of other Freelancers performing work for the Client, Client feedback and rating information and timing of project performance.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Feedback</strong><br /><br />
                We collect feedback from Workcroft Users about their experience with other Workcroft Users of our Service. Please note that any feedback you provide via the Service or feedback provided about you is publicly viewable via the Service. On very rare occasions, we may remove feedback pursuant to the relevant provisions of our Terms of Service, including the Terms of Use. </p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Email to Friends and Referral Program</strong> <br /><br />
                Workcroft lets you send project postings to friends via email. Workcroft also offers the ability to send friends emails about providing or purchasing services through the Service. If you choose to use either feature, your friend’s email address will be used to send the requested posting and your email address will be used to copy you on the message to your friend or to send the message on your behalf. Workcroft stores this information for the sole purpose of sending this one-time email and tracking the success of our referral program. 
                  <br/><br/> Your friend may contact us at <a href="hello@workcroft.com">hello@workcroft.com</a> to request that we remove this information from our database.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><strong>Social Networking Services</strong> <br /><br />
                You may register for an account directly with our Site, or through a social networking service. If you register with a social networking service (or later link your account to one), we will collect certain information about you from that social networking service, and what we collect depends on your privacy settings with that social networking service. The Service may also allow you to “like” or share content with social networking services.
                <br /><br />
                You may register to join the Service directly via the Service or by logging into your account with a third party social networking service 
                (“<b>SNS</b>”) 
                via our Service (e.g., Facebook, Github and other third party services that let you sign in using your existing credentials with those services). If you choose to register via an SNS, or to later link your account with the Service to your account with an SNS, we will use the Personal Information you have provided to the SNS (such as your name, email address, gender and other information you make publicly available via the SNS) to create your account. Note that the information we collect from and through an SNS may depend on the privacy settings you have set with the SNS and the permissions you grant to us in connection with linking your account with the Service to your account with an SNS. Other than what we may share with the SNS as described below, the Personal Information an SNS has about you is obtained by the SNS independent of our Service, and Workcroft is not responsible for it.
                <br /><br />
                The Workcroft Service also may permit additional interactions between it and a third party website, service, or other content provider, such as enabling you to “like” or share content to a third party SNS. If you choose to “like” or share content, or to otherwise share information from or via our Service with a third party site or service, that information may be publicly displayed, and the third party may have access to information about you and your use of our Service (and we may have access to information about you from that third party). These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the feature to function properly. Your interactions with third parties through an SNS or similar features are governed by the respective privacy policies of those third parties.
                 <br /><br />
                 You represent that you are entitled to use your SNS account for the purposes described herein without breach by you of any of the terms and conditions that govern the SNS, and without obligating Workcroft to pay any fees or making Workcroft subject to any usage limitations imposed by such SNS. You can disable the link between your Workcroft account and your SNS account at any time though the “Settings” section of our Service. Please note that your relationship with any SNS is governed solely by your agreement(s) with such SNS. If your SNS account terminates, then functions enabled through the link between your Workcroft account and your SNS account will terminate as well.</p>
                {/* Add more content for Social Networking Services */}

                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>2. USE OF INFORMATION</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                We use information collected through the Service to provide and improve the Service, process your requests, prevent fraud, provide you with information and advertising that may interest you, comply with the law, and as otherwise permitted with your consent.</p>
                <br />
                <h3 style={{ fontSize: "19px" }}>WE USE INFORMATION WE COLLECT:</h3>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                To provide and improve the Service, complete your transactions, and address your inquiries, process your registration, verify the information you provide is valid, and for compliance and internal business purposes.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                To contact you with administrative communications and Workcroft newsletters, marketing or promotional materials (on behalf of Workcroft or third parties) and other information that may be of interest to you. If you decide at any time that you no longer wish to receive such communications from us, please follow the instructions in the Your Choices and Rights section, below.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                To tailor content we display to you and offers we may present to you, both on the Service and elsewhere online.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                To administer and develop our business relationship with you and, if applicable, the corporation or other legal entity you represent.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                To assess your proposal to perform a freelance project for Workcroft and prepare related governmental and internal statistics reports.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                To enforce and comply with the law, including to conduct an investigation, to protect the property and rights of Workcroft or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, illegal, fraudulent, unethical or legally actionable activity. We may also use Device Identifiers to identify Workcroft Users.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                For the purposes disclosed at the time you provide your information, with your consent, and as further described in this Privacy Policy.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>We use your Personal Information for the purposes described above:</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                To Honor Our Contractual Commitments to You. Much of our processing of Personal Information is to meet our contractual obligations to our investors, or to take steps at User’s request in anticipation of entering into a contract with them.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                For Our Legitimate Interests. In many cases, we handle Personal Information on the grounds that it furthers our legitimate interests in commercial activities, such as the following, in ways that are not overridden by the interests or fundamental rights and freedoms of the affected individuals:</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" ,marginLeft:'25px'}}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />Providing our Site and Service.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" ,marginLeft:'25px'}}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />Analyzing and improving our business.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" ,marginLeft:'25px'}}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />Communications, including marketing and responding to your inquiries about our services.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" ,marginLeft:'25px'}}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />Addressing information security needs and protecting our Users, Hirekingdom Jobs, and others.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" ,marginLeft:'25px'}}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />Managing legal issues.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                To Comply with Legal Obligations. We need to use and disclose Personal Information in certain ways to comply with our legal obligations.</p>


                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>3. DATA RETENTION</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                Unless you request that we delete certain information, we retain the information we collect for at least 5 years. Your information may persist in copies made for backup and business continuity purposes for additional time. If you choose to provide us with Personal Information, we encourage you to routinely update the data to ensure that we have accurate and up-to-date information about you.</p>
                {/* Add more content for Data Retention */}

                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>4. INFORMATION SHARING AND DISCLOSURE</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                We may share information about you to provide the Services, for legal and investigative purposes, in connection with sweepstakes and promotions, or if we are part of a merger or acquisition. We may also share non-identifying information with third parties. You have choices as to whether we share your personal information with third parties for their own marketing purposes.
                <br /><br />We may share aggregated Non-Identifying Information and we may otherwise disclose Non-Identifying Information (including, without limitation, Hashed Information) to third parties. We do not share your Personal Information with third parties for those third parties’ marketing purposes unless we first provide you with the opportunity to opt-in to or opt-out of such sharing. We may also share the information we have collected about you, including Personal Information, as disclosed at the time you provide your information, with your consent, as otherwise described in this Privacy Policy, or in the following circumstances:</p>
                {/* Add more content for Information Sharing and Disclosure */}

                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                <b>Information about Freelancers Shared with Clients, Agencies, and Workcroft Payroll Vendors:</b> 
                We share information regarding Freelancers who have entered into a contract with a Client or who have elected to participate in Workcroft Payroll, including information in Work Diaries and work history, with Clients, Agencies and Workcroft Payroll vendors. Note that if a Freelancer is suspended from the Workcroft Service, we may share that information with Clients for whom that Freelancer has worked or submitted proposals for work. We may also share information with Agencies to whom Freelancers are associated for a particular work project. If you choose to submit a proposal for work as a Freelancer via the Service, we will share information relevant to your application with the applicable Client(s), including, but not limited to, the information contained in your Freelancer Profile.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                <b>Information about Clients and Agencies Shared with Freelancers:</b> 
                If you have entered into a service contract or agreed to use Workcroft Payroll with another user, we may provide him/her with your name, company address, billing address, or tax ID or VAT number in order to complete the transaction or to facilitate the resolution of a claim or dispute. The user receiving your information is not allowed to use it for purposes unrelated to the transaction, such as to contact you for marketing purposes, unless you have expressly consented to it.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><b>Service Providers:</b>
                We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services (e.g., without limitation, maintenance services, database management, web analytics and online advertising, payment processing, fraud detection and improvement of Workcroft features) or to assist us in analyzing how our Service is used. These third parties may have access to your Personal Information in order to perform these tasks on our behalf.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} />
                <b>What Happens If You Agree to Receive Information from Third Parties or Request that We Share Your Information:</b>
                You may be presented with an opportunity to receive information and/or marketing offers from one or more third parties. If you agree at that time to have your Personal Information shared, your Personal Information will be disclosed to that third party (or parties) and will be subject to the privacy policy and practices of that third party. We are not responsible for the privacy policies and practices of third parties, and, if you later decide that you no longer want to receive communications from a third party, you will need to contact that third party directly. You also may request, sometimes through your use of an SNS or similar interactive feature or third party application, that we share information about you with a third party and we will typically do so under those circumstances.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><b>Legal and Investigative Purposes:</b>
                Workcroft will share information with government agencies as required by law in response to lawful requests by public authorities, including to meet national security or law enforcement requirements and, including without limitation, in connection with reporting earnings. We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. We will disclose information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (including but not limited to subpoenas), or, at the request of governmental authorities or other third parties conducting an investigation where we determine in our sole discretion the disclosure is necessary to (a) protect the property and rights of Workcroftr a third party, (b) protect the safety of the public or any person, or (c) prevent or stop activity we may consider to be, or pose a risk of being, illegal, fraudulent, unethical or legally actionable activity. We may also use Device Identifiers to identify Workcroft Users, and may do so in cooperation with third parties at our discretion.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><b>Internal and Business Transfers:</b>
                Workcroft may share information, including Personal Information, with its parent company Workcroft (OPC) Pvt Ltd., and any current or future subsidiaries or affiliates, primarily for business and operational purposes. We may sell, transfer, or otherwise share some or all of our assets, including your Personal Information, in connection with a merger, acquisition, reorganization or sale of assets (including, in each case, as part of the due-diligence process with any potential acquiring entity) or in the event of bankruptcy.</p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}><GoDotFill className='mr-3' style={{ fontSize: "10px" }} /><b>Sweepstakes, Contests, and Promotions:</b> 
                We may offer sweepstakes, contests, and other promotions (any, a “Promotion”) that may require registration. By participating in a Promotion, you are agreeing to the official rules that govern that Promotion, which may contain specific requirements of you, including, except where prohibited by law, allowing the sponsor(s) of the Promotion to use your name, voice, likeness, or other indicia of persona in advertising or marketing associated with the Promotion. If you choose to enter a Promotion, your Personal Information may be disclosed to third parties or the public in connection with the administration of such Promotion, including, without limitation, in connection with winner selection, prize fulfillment, and as required by law or permitted by the Promotion’s official rules, such as on a winner’s list.</p>

                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>5. THIRD PARTY ANALYTICS PROVIDERS, AD SERVERS AND SIMILAR THIRD PARTIES</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                We may work with advertising agencies and vendors who use technology to help us understand how people use our Site. These vendors may use technologies to serve you advertisements that may interest you. You can choose to opt out of receiving interest-based advertising.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                Workcroft works with (or may in the future work with) network advertisers, ad agencies, analytics service providers and other vendors to provide us with information regarding traffic on the Service, including pages viewed and the actions taken when visiting the Service; to serve our advertisements on other web sites, within mobile apps and elsewhere online; and to provide us with information regarding the use of the Service and the effectiveness of our advertisements. Our service providers may collect certain information about your visits to and activity on the Service as well as other websites or services, they may set and access their own tracking technologies on your device (including cookies and web beacons), and may use that information to show you targeted advertisements. Some of these parties may collect Personal Information when you visit the Service or other online websites and services. We may also share certain Non-Identifying Information with these parties, including Hashed Information, in connection with the services they provide to us. If you choose to opt out, please note you will continue to receive generic ads.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>While we may use a variety of service providers to perform advertising services, some of these companies are members of the Network Advertising Initiative 
                (“<b>NAI</b>”) or the Digital Advertising Alliance 
                (“<b>DAA</b>”) 
                Self-Regulatory Program for Online Behavioral Advertising. You may want to visit <a href="http://www.networkadvertising.org/managing/opt_out.asp" style={{color:'#108A00'}}>http://www.networkadvertising.org/managing/opt_out.asp</a>, 
                which provides information regarding targeted advertising and the “opt-out” procedures of NAI members. You may also want to visit or <a href="http://www.aboutads.info/choices/" style={{color:'#108A00'}}>http://www.aboutads.info/choices/</a>, 
                which provides information regarding targeted advertising and offers an “opt-out” by participating companies in the DAA Self-Regulatory Program.</p>

                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>6. YOUR CHOICES AND RIGHTS</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>You have certain choices regarding how we may communicate with you.
                Registered Workcroft Users may update their choices regarding the types of communications you receive from us through your online account. You also may opt-out of receiving marketing emails from us by following the opt-out instructions provided in those emails. Please note that we reserve the right to send you certain communications relating to your account or use of the Service (for example, administrative and service announcements) via email and other means and these transactional account messages may be unaffected if you opt-out from receiving marketing communications. You may opt-out of receiving text messages by replying “STOP” to any text message received. Registered Workcroft Users who access the Service by using an Workcroft mobile application may, with permission, receive push notifications. Similarly, registered Workcroft Users who access the Service by using certain desktop browsers may, with permission, receive push notifications. Notification preferences can be modified in the settings menu for the mobile application or the applicable browser.
                </p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                All Users may request access to or correction of any Personal Information we have about them or delete their account and/or request deletion of all Personal Information we have about them. In certain jurisdictions, Users may have certain rights with regard to their Personal Information. We will honor User requests to the extent we can reasonably do so and as required by law, but some information will remain on the Services, such as information you posted publicly.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                Upon request Workcroft will provide you with information about whether we hold any of your personal information. You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information. You may access, correct, or request deletion of your personal information by making updates to that information or by contacting Workcroft through your online account. If you request to access all personal information you’ve submitted, we will respond to your request to access within 30 days. If you completely delete all such information, then your account may become deactivated. If your account is deactivated or you ask to close your account, you will no longer be able to use the Service. If you would like us to delete your account in our system, you can do so through the Workcroft Service (once you logged in, visit user dashboard, and then click on the close my account link).</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>Depending on where you are located, you may have certain rights with regard to your Personal Information (including, in certain cases, under the Privacy Shield, described below). If you are located in the European Economic Area
                 (“<b>EEA</b>”), 
                 in addition to the rights described above, you may contact us at the contact information provided below to (1) request a restriction on the processing of your Personal Information, (2) object to the processing of your Personal Information, or (3) exercise other rights with respect to your Personal Information. Workcroft uses automated means to calculate the Job Success Score of Freelancers. If you believe that our services have miscalculated your Job Success Score, please email us at 
                  <a href=" hello@workcroft.com"> hello@workcroft.com</a> for assistance. We may be able to assist you by conducting a manual review of your Job Success Score, such as by assessing whether the information you’ve provided us is accurate. While we strongly encourage you to first raise any questions or concerns about your Personal Information directly with us, you have a right to lodge a complaint with the relevant supervisory authority.
                </p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>We will use commercially reasonable efforts to honor your requests for deletion; however, certain information will actively persist on the Service even if you close your account, including information in your Work Diaries and messages you posted to the Service. In addition, your Personal Information may remain in our archives and information you update or delete, or information within a closed account, may persist internally for our administrative purposes, to the extent permitted by law. It is not always possible to completely remove or delete information from our databases. In addition, we typically will not remove information you posted publicly through or on the Service. Bear in mind that neither you nor Workcroft can delete all copies of information that has been previously shared with others on the Service.
                </p>

                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>7. SECURITY</h3>
                <p style={{ fontSize: '16px', lineHeight: "25px" }}>We take a number of steps to protect your data, but no security is guaranteed.</p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>Workcroft takes commercially reasonable steps to help protect and secure the information it collects and stores about Workcroft Users. All access to the Site is encrypted using industry-standard transport layer security technology 
                (“<b>TLS</b>”).
                When you enter sensitive information (such as tax identification number), we encrypt the transmission of that information using secure socket layer technology 
                 (“<b>SSL</b>”). 
                 We also use HTTP strict transport security to add an additional layer of protection for our Workcroft Users. But remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. Thus, while we strive to protect your Personal Information, Workcroft cannot ensure and does not warrant the security of any information you transmit to us.</p>

                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}><h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>8. INTERNATIONAL TRANSFER OF PERSONAL INFORMATION</h3></h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>Because we are an Indian company, we process and store your information in India and our service providers may process and store it elsewhere.
                <br /><br />Workcroft is an Indian company. If you are located outside India and choose to provide information to us, Workcroft transfers Personal Information to the Inia for processing, and our service providers may process Personal Information in India and elsewhere. These countries may not have the same data protection laws as the country in which you initially provided the information. When we transfer your information to India, we will protect it as described in this Privacy Policy.
                    <br /><br />When we transfer Personal Information from territories in the EEA or with similar laws to our affiliates or service providers in India and elsewhere outside the EEA, we rely on approved data transfer mechanisms, including standard contractual clauses approved by the European Commission and the Privacy Shield, as described below. You may request a copy of the standard contractual clauses relevant to your Personal Information, if any, using the contact information below.
                </p>

                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>9. LINKS TO OTHER SITES</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>Our Service contains links to other websites. If you choose to click on a third party link, you will be directed to that third party’s website. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party, nor is it an endorsement of their privacy or information security policies or practices. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data or solicit Personal Information from you. We encourage you to read the privacy policies or statements of the other websites you visit.</p>

                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>10. PUBLIC PROFILE</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>The profile you create on our Site will be publicly accessible unless otherwise indicated. You may change the privacy settings of your profile through your account portal.</p>

                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>11. PHISHING</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>Phishing websites imitate legitimate websites in order to obtain personal or financial information. Identity theft and the practice currently known as “phishing” are of great concern to Workcroft. For more information about phishing, visit the website of the Federal Trade Commission at 
                <a href="http://www.consumer.ftc.gov/articles/0003-phishing" style={{color:'#108A00'}}> http://www.consumer.ftc.gov/articles/0003-phishing</a>. 
                In addition, if you believe you have received an email or had a similar interaction with a third party pretending to be Workcroft, please report it at <a href="hello@workcroft.com">hello@workcroft.com</a>.
                </p>

                {/* <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>11. PHISHING</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>We may change this Privacy Policy...</p> */}
                {/* Add more content for Changes to This Policy */}

                {/* Section 13: Contacting Us */}
                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>12. CHANGES TO THIS POLICY</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>We may change this Privacy Policy. If we make material changes, we will provide notice.<br/><br/>
                Workcroft may update this Privacy Policy at any time and any changes will be effective upon posting. In the event that there are material changes to the way we treat your Personal Information, we will display a notice through the Services prior to the change becoming effective. We may also notify you by email, in our discretion. However, we will use your Personal Information in a manner consistent with the Privacy Policy in effect at the time you submitted the information, unless you consent to the new or revised policy.
                </p>
                {/* Add more content for Contacting Us */}
                <h3 style={{ marginTop: "50px", marginBottom: "30px", fontSize: "20px" }}>13. CONTACTING US</h3>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>If you have any questions about this Privacy Policy, please contact us at <a href="hello@workcroft.com">hello@workcroft.com</a> or by mail addressed to Workcroft, 4/2, E-Extn, Shyam Vihar Phase 1, Najafgarh, Delhi - 110043, India. </p>
                {/* Add more content for Contacting Us */}
            </div>
        </div>

    </>)
}

export default Privicy;