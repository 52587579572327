import React, {useEffect} from "react";
import "./css/Preview.scss";
import home from "../../assets/images/home.png";
import circle from "../../assets/images/circle.png";
import money from "../../assets/images/money.png";
import calendar from "../../assets/images/calendar.png";
import game from "../../assets/images/game.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EmployerPreview = () => {


  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);

  const navigate = useNavigate();
  const { state } = useLocation();

const seprator=(str)=>{
  const arr = str.split(',')
   return arr;
}

  return (
    <div className="reviewpage container-fluid px-5 py-5">
      <h1 className="text-center pt-5 pb-3 mb-4">
        {state.profile} 
        {state.jobType ==='Remote' ?
        (<> work from home</>):(<></>)
          } {(state.opportunityType).toLowerCase()} at {state.organisationName} 
          {state.jobType ==='Remote' ?
         (<></>):(<> {state.city}</>)
          }
      </h1>
      <div className="r1 container">
        <div className="sectionn1 mb-3">
          <h3 style={{fontSize: "25px"}} className="title pt-3">
          {state.profile}
           
            <span className="icon mx-4">
              <a href="#">
                <i class="fa fa-external-link" aria-hidden="true"></i>
              </a>
            </span>
           
          </h3>
          <h4 style={{fontSize: "15px", color: "gray"}} className="companyname  pt-4">{state.organisationName}</h4>
          <div className="content mb-5" style={{marginTop: "20px"}}>
            <span>
            {state.jobType ==='Remote' ?
           (<>
           <img src={home} alt="Engineering" style={{ width: "18px", marginTop: "-5px" }} />
           <span style={{fontSize: "17px"}} className="wfm mx-2">Work From Home</span> 
           </>):(<>
            <i class="fa fa-building-o" aria-hidden="true" style={{color: "gray"}}></i>
              <span style={{fontSize: "17px"}} className="wfm mx-2">{state.city}</span>
           </>)
        }   
            </span>
            <div className="row py-4 ">
              <div className="col-lg-2 col-md-2 col-sm-3">
                <p>
                  <span>
                    <img src={circle} alt="play" style={{ width: "18px" }} />
                  </span>
                  <span className="mx-3 heeading">START DATE</span>
                </p>
                <h3 className="head">{(state.startDate).slice(8,10) +"-"+ (state.startDate).slice(5,7) +"-"+ (state.startDate).slice(0,4)} </h3>
              </div>
              {/* <div className="col-lg-2 col-md-2 col-sm-3">
                <p>
                  <span>
                    <img src={calendar} alt="play" style={{ width: "18px" }} />
                  </span>
                  <span className="mx-3 heeading">DURATION</span>
                </p>
                <h3 className="head">{state.duration} Months</h3>
              </div> */}

              
                      {state.opportunityType !== 'Job' && (
                    <div className="col-lg-2 col-md-2 col-sm-3" style={{marginLeft:'-30px'}}>
                      <p>
                        <span>
                          <img src={calendar} alt="play" style={{ width: "18px" }} />
                        </span>
                        <span className="mx-3 heeading">DURATION</span>
                      </p>
                    <h3 className="head">{state.duration} Months</h3>
                    </div>
                  )}



              <div className="col-lg-2 col-md-2 col-sm-3">
                <p>
                  <span>
                    <img src={money} alt="play" style={{ width: "18px",marginLeft:'-30px'}} />
                  </span>
                  <span className="mx-3 heeading">STIPEND/SALARY</span>
                </p>
                {state.salary==0? (<>
                  <h3 className="head">Unpaid</h3>
                </>):(<>
                <h3 className="head">₹{state.salary}/month</h3>
                </>)}
              </div>
              <div className="col-lg-2 col-md-2 col-sm-3">
                <p>
                  <span>
                    <img src={game} alt="play" style={{ width: "18px" }} />
                  </span>
                  <span className="mx-3 heeading">Apply by</span>
                </p>
                <h3 className="head">{(state.startDate).slice(8,10) +"-"+ (state.startDate).slice(5,7) +"-"+ (state.startDate).slice(0,4)}</h3>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-3"></div>
            </div>
            <div className="row py-2 px-1">
              <span>
                <span>
                  <button className="btn post mr-4">{state.isPartTimeAllowed ==='yes' || state.isPartTimeAllowed === 'Yes' || state.isPartTimeAllowed === 'YES'?
                            (<>Part-Time</>):(<>Full-Time</>)
                             }</button>
                </span>
                <span>
                  <button className="btn post ">{state.opportunityType}</button>
                </span>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="sectionn1 mb-3" style={{ marginTop: "30px" }}>
          <h3 className="title pt-2">
            About {state.organisationName}
            <span className="icon mx-4">
              <a href="#">
                <span className="mx-2" style={{ color: "#0077E4" }}>
                  Website
                </span>
                <i class="fa fa-external-link" aria-hidden="true"></i>
              </a>
            </span>
           
          </h3>

          <hr className="mt-4"></hr>
          <div className="content my-3">
            {/*
            <span className="wfm mt-4  pt-3">Job description</span>
            <h4 className="companynamee mt-3 mb-4">
              Great offer for Freshers and experience<br></br>Us process
              <br></br>Salary - best in industry
              <br></br>24*7 rotational shifts
              <br></br>2 days off (rotational)
              <br></br>One side cab one side shuttle service
              <br></br>For more details contact 9871610764
            </h4>
            <span className="wfm mt-5  pt-4">
              About the work from home job/internship
            </span>
            <h4 className="companynamee mt-3 mb-4">
              Selected intern's day-to-day responsibilities include:<br></br>
              l. Work on user flow of website<br></br>2. Create Al for website
              <br></br>3. Create prototype for testing
            </h4>
            */}
        <span className="wfm mt-4  pt-3">Organization Description</span>
        <h4 className="companynamee mt-3 mb-4" style={{width: "500px"}}>{state.organisationDescription}</h4>
           {/* <span className="wfm mt-4  pt-3">Job description</span>
            <h4 className="companynamee mt-3 mb-4">
              Great offer for Freshers and experience<br></br>Us process
              <br></br>Salary - best in industry
              <br></br>24*7 rotational shifts
              <br></br>2 days off (rotational)
              <br></br>One side cab one side shuttle service
              <br></br>For more details contact 9871610764
            </h4> */}


                 <span className="wfm mt-4  pt-3">Job description</span>

             <h4 className="companynamee mt-3 mb-4" style={{ width: "500px" }}>{state.jobDescription}</h4>
            
            <div className="row py-2 ">
              <span>
                <span className="wfm mt-5 px-4 pt-4 ">Skill(s) required</span>
                <br></br>
                <span>
               { seprator(state.skill).map((data) => {
                      return <>
                  <button className="btn post mt-4 mx-3 px-4">
                      {data}
                     </button>
                     </>
                    })
                     }
                </span>
               
              </span>
            </div>



            <div className="row py-2">

<span className="wfm mt-5 px-4 pt-2" style={{ marginLeft: "-4px" }}>Cities</span>

<span className=" mt-2 "style={{ marginLeft: "5px" }}>{state.city.join(" , ")}</span>


</div>




            <div className="row py-2 mt-4 ">
              <span>
                <h3 className="wfm mt-1 px-4 pt-1 mb-3">
                  Earn certification in these skills
                </h3>
                <span className="mx-4 ">
                { seprator(state.skill).map((data) => {
                      return <>
                  <a className="mr-4" href="#" style={{ color: "#4B92C8", fontSize: "13px", textDecoration: 'underline' }}>
                    Learn {data}
                  </a>
                  </>
                    })
                     }
                </span>
                
              </span>
            </div>
            <h3 className="wfm mt-5 mb-3">Who can apply</h3>
            <h4 className="companynamee mt-3 mb-4">
              Only those candidates can apply who:
              <br></br>1. are available for the  {state.jobType ==='Remote' ?
        (<>work from home</>):(<></>)} {(state.opportunityType).toLowerCase()}  {state.jobType ==='Remote' ?
        (<></>):(<>in {state.city}</>)
         }
              <br></br>2. can start the {state.jobType ==='Remote' ?
        (<>work from home</>):(<></>)} {(state.opportunityType).toLowerCase()}  {state.jobType ==='Remote' ?
        (<></>):(<>in {state.city}</>)
         } from {(state.startDate).slice(8,10) +"-"+ (state.startDate).slice(5,7) +"-"+ (state.startDate).slice(0,4)}
             
              <br></br>3. are available for duration of {(state.duration)} months
              <br></br>4. have relevant skills and interests
              <br></br>* Women wanting to start/restart their career can also
              apply.
            </h4>
            <div className="row py-2 ">
              <span>
                <span className="wfm mt-5 px-4 pt-4 ">Perks</span>
                <br></br>
                <span>
                  <button className="btn post mt-4 mx-4">{state.perks}</button>
                </span>
               
              </span>
            </div>
            <div className="row py-5 ">
              <span>
                <h3 className="wfm px-4 pt-2 ">Number of opening</h3>
                  <span className=" mt-2 mx-4">{state.numberOfOpeining}</span>
              </span>
            </div>
          
          </div>
        
       
        </div>
     
      </div>
    </div>
  );
};

export default EmployerPreview;
