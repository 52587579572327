import React, { useEffect, useState } from "react";
import "./css/Login.scss";
import Spinner from "../Layout/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, loginEmployer } from "../../actions/userActions";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { envelope, eyeClosed,eyeOpen } from "../../assets";
function Login() {
    const [users, setUser] = useState({
        email: "",
        password: "",
    });
    const [hideShowPassword,sethideShowPassword] = useState(false)

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const from = location.state?.from || "/";
    
    const { isAuthenticated, loading, user } = useSelector((state) => state.user);

    const handleUserChange = (e) => {
        setUser({
            ...users,
            [e.target.name]: e.target.value,
        });
    };

    const login = async (event) => {
        event.preventDefault();
        
        try {
            // Try logging in as an employer first
            const employerResult = await dispatch(loginEmployer(users, "Employer"));
            if (employerResult?.success) {
                toast.success("Logged in successfully");
                localStorage.setItem("userType", "Employer");
                return;
            }
        } catch (error) {
            try {
                // If employer login fails, try as a user
                const userResult = await dispatch(loginUser(users, "User"));
                if (userResult?.success) {
                    toast.success("Logged in successfully");
                    localStorage.setItem("userType", "User");
                    return;
                }
            } catch (finalError) {
                toast.error("Invalid details or User not exist.");
            }
        }
        
        // Clear password field after attempt
        setUser({ ...users, password: "" });
    };

    useEffect(() => {
        if (user && user._id) {
            localStorage.setItem("id", user._id);
        }
    }, [user]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            if (user?.role === "User") {
                navigate(from, { replace: true });
            } else if (user?.role === "Employer") {
                navigate("/employerApplication/Hourly");
            }
        }
    }, [isAuthenticated, user, navigate, from]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="login-component">
                <form className="form-box">
                    <div className="form">
                    <h1 className="title">Log in to Workcroft</h1>
                        <TextField
                            type="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={users.email}
                            onChange={handleUserChange}
                            name="email"
                            id="email"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img src={envelope} className="icons" alt="email" />
                                    </InputAdornment>
                                ),
                                style: { outline: "none" },
                            }}
                        />

                        <TextField
                            type={hideShowPassword ? "text" : "password"}
                            label="Password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={users.password}
                            onChange={handleUserChange}
                            name="password"
                            id="password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {hideShowPassword ? (<img src={eyeOpen} className="icons" style={{cursor:"pointer"}} alt="password" onClick={()=> sethideShowPassword(!hideShowPassword)}/>) : (<img src={eyeClosed} className="icons" alt="password" style={{cursor:"pointer"}} onClick={()=> sethideShowPassword(!hideShowPassword)}/>) }
                                        
                                        
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <div style={{ float: "right" }}>
                            <Link to="/recoveryemail">
                                <small style={{ fontSize: "13px", color: "gray" }}>
                                    Forgot Password?
                                </small>
                            </Link>
                        </div>

                        <button
                            onClick={login}
                            className="btn"
                            style={{ paddingTop: "16px", paddingBottom: "16px" }}
                        >
                            {loading ? <Spinner /> : <span style={{ fontWeight: "800" }}>Log In</span>}
                        </button>

                        <p className="text-center pt-4" style={{ fontSize: "13px", marginTop: "12px" }}>
                            Don't have an Account?{" "}
                            <Link 
                                to="/signup" 
                                state={{ from: location.state?.from || location.pathname }}
                                style={{ fontSize: "13px", fontWeight: "600",color:"#1F57C3" }}
                            >
                                Sign up
                            </Link>
                        </p>
                    </div>
                </form>
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default Login;