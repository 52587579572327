import React, { useState, useEffect, useRef } from "react";
import "../Employer/css/PersonalDetails.scss";
import "./EditResume.scss";
import "./AppliedJobReview.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Layout/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { IoArrowBackSharp, IoImageOutline, IoSettingsOutline } from "react-icons/io5";
import { RxCross1, RxPencil1 } from "react-icons/rx";
import { FiDownload, FiPlus } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxDotFilled } from "react-icons/rx";
import { MONTH_DETAILS } from "../data";
import { useNavigate } from "react-router-dom";
import { skillOptions } from "../data";
import { Degree } from "../data";
import Select from "react-select";
import MonthCalculator from "./MonthCalculator";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { FaArrowDown, FaArrowUp, FaImage, FaLink, FaTextHeight, FaTrash, FaVideo } from "react-icons/fa6";
import AvatarEditor from "react-avatar-editor";
import { MdContentCopy, MdClose } from "react-icons/md";
import MumbaiImage from "../../assets/images/mumbai.png";
import BriefCase from "../../assets/images/briefcase.PNG";
import portPublish from "../../assets/images/portpublish.PNG";
import { IoIosAddCircleOutline } from "react-icons/io";
import { BiSortAlt2 } from "react-icons/bi";
import { TbExternalLink, TbFileUpload } from "react-icons/tb";
import { HiOutlinePencilAlt } from "react-icons/hi";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import bootstrap from "bootstrap";
import Popup from "reactjs-popup";
import { v4 as uuidv4 } from "uuid";
import ReorderModal from "./ReorderModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaFileDownload } from "react-icons/fa";
import { addEllipsis, generateDataInSequence } from "../../utils/helper";
import CloseButton from "./CloseButton";
import SeeMoreLess from "./SeeMoreLess";
import Pagination from "./Pagination";
import SkillsSelector from "../SkillsSelector";
import EditSkillsSelector from "../EditSkillsSelector";
import AddSkillsSelector from "./AddSkillsSelector";
import DegreeSelector from "./DegreeSelector";
import PreviewModel from "../Modal/PreviewModel";

function EditResume() {
    const [profiles, setProfiles] = useState([]);
    const [filteredProfiles, setFilteredProfiles] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [isTyping1, setIsTyping1] = useState(false);
    const [pdskills, setPdskills] = useState([]);
    const navigate = useNavigate();
    const closePersonal = useRef(null);
    const closeAddress = useRef(null);
    const closeIden = useRef(null);
    const closeDoc = useRef(null);
    const frontPhotoInputRef = useRef(null);
    const backPhotoInputRef = useRef(null);
    const closeEducation = useRef(null);
    const closeJob = useRef(null);
    const closeInternship = useRef(null);
    const closeTraining = useRef(null);
    const closeAcademics = useRef(null);
    const closeSkills = useRef(null);
    const { user } = useSelector((state) => state.user);
    const [resumeId, setResumeId] = useState(null);
    const [cityOptions, setCityOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTerm2, setSearchTerm2] = useState("");
    const profileSummaryRef = useRef(null);
    const [message, setMessage] = useState("");
    const [imageWidth, setImageWidth] = useState(200);
    const [imageHeight, setImageHeight] = useState(200);

    useEffect(() => {
        const fetchMatchingCities = async () => {
            if (searchTerm.trim().length >= 2) {
                try {
                    const response = await axios.get(`/api/data/get-cities-data`, {
                        params: { keyword: searchTerm },
                    });
                    if (Array.isArray(response.data)) {
                        setCityOptions(response.data.map((city) => ({ label: city, value: city })));
                    } else {
                        console.error("Unexpected response format:", response.data);
                    }
                } catch (error) {
                    console.error("Error fetching cities:", error);
                }
            } else {
                setCityOptions([]);
            }
        };

        fetchMatchingCities();
    }, [searchTerm]);
    const noOptionsMessage = () => {
        return searchTerm.trim().length < 2 ? "Type a City Name" : "No Options";
    };
    const [isTyping, setIsTyping] = useState(false);
    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
        setIsTyping(newValue.length > 0);
    };
    // useEffect(() => {
    //   if (user && (!user.phone || !user.availability)) {
    //     toast.error("Please update your profile")
    //     navigate('/editProfileUser')
    //   }
    // },[user]);

    // useEffect(() => {
    //   async function getAllProfiles() {
    //     const response = await axios.get("/api/data/profiles");
    //     const data = await response.data;
    //     if (data) setProfiles(data);
    //   }
    //   //  async function getAllSkills() {
    //   //   const response = await axios.get("/api/data/skills");
    //   //   const data = await response.data;
    //   //   if (data) setPdskills(data);
    //   // }

    //   // getAllSkills();
    //   getAllProfiles();
    // }, []);
    const [profileCategories, setProfileCategories] = useState([{ id: 1, profile: null, specializations: [] }]);
    const [categoryCount, setCategoryCount] = useState(1);
    const [specializations, setSpecializations] = useState({});
    const [isEditingPhoto, setIsEditingPhoto] = useState(false);
    const [editorData, setEditorData] = useState(null); // To hold the data URL of the photo for editing
    const [isEditingPhotothumb, setIsEditingPhotothumb] = useState(false);
    const [editorDatathumb, setEditorDatathumb] = useState(null);
    const [isEditingPhotothumbDraft, setIsEditingPhotothumbDraft] = useState(false);
    const [editorDatathumbDraft, setEditorDatathumbDraft] = useState(null);
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    useEffect(() => {
        async function getAllProfiles() {
            const response = await axios.get("/api/data/project-category");
            const data = await response.data;
            if (data) {
                setProfiles(data);
            }
        }

        getAllProfiles();
    }, []);
    const addProfileCategory = () => {
        // if (categoryCount < 3) {
        //   setProfileCategories([...profileCategories, { id: categoryCount + 1, profile: null, specializations: [] }]);
        //   setCategoryCount(categoryCount + 1);
        // } else {
        //   toast.error("You can only add up to 3 profile categories.");
        // }
        setPersonalDetails((prevDetails) => ({
            ...prevDetails,
            profileCategories: [
                ...prevDetails.profileCategories,
                {
                    id: prevDetails.profileCategories.length + 1,
                    profile: null,
                    specializations: [],
                },
            ],
        }));
    };

    const removeProfileCategory = (id) => {
        // setProfileCategories(profileCategories.filter(category => category.id !== id));
        // setCategoryCount(categoryCount - 1);
        setPersonalDetails((prevDetails) => ({
            ...prevDetails,
            profileCategories: prevDetails.profileCategories.filter((category) => category.id !== id),
        }));
    };
    const fetchSpecializations = async (profileCategory) => {
        //console.log("calling");
        try {
            const encodedCategory = encodeURIComponent(profileCategory);
            const response = await axios.get(`/api/data/get-specialization?profileCategory=${encodedCategory}`);
            const data = await response.data;
            // console.log(data, "sepc data");
            setSpecializations((prevState) => ({
                ...prevState,
                [profileCategory]: data,
            }));
        } catch (error) {
            console.error("Error fetching specializations", error);
        }
    };

    const handleProfileCategoryChange = (selectedOption, index) => {
        console.log(selectedOption, "selectedOption");

        //   const updatedProfileCategories = profileCategories.map((category, i) => {
        //     if (i === index) {
        //       console.log(selectedOption.value,"selc value")
        //       fetchSpecializations(selectedOption.value);
        //       return { ...category, profile: selectedOption.value, specializations: [] };
        //     }
        //     return category;
        //   });
        //   setProfileCategories(updatedProfileCategories);
        //   setPersonalDetails({
        //     ...personalDetails,
        //     profileCategories: updatedProfileCategories
        // });
        const updatedCategories = personalDetails.profileCategories.map((category, i) => {
            if (i === index) {
                fetchSpecializations(selectedOption.value);
                return {
                    ...category,
                    profile: selectedOption.value,
                    specializations: [],
                };
            }
            return category;
        });

        console.log(updatedCategories, "updatedCategories");

        setPersonalDetails({
            ...personalDetails,
            profile: updatedCategories[0].profile,
            profileCategories: updatedCategories,
        });
    };

    const filterOptions = (inputValue) => {
        return profiles?.filter((profile) => profile.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const noOptionsMessage3 = () => {
        if (!inputValue) {
            return "Type Profile";
        }
        return "No Options";
    };
    const [selectedSkills, setSelectedSkills] = useState([]);
    // const handleSkillsChange = (selectedOptions) => {
    //   if (selectedOptions.length <= 5) {
    //     setSelectedSkills(selectedOptions);
    //   }
    // };
    const handleSkillsChange = (selectedOptions) => {
        if (selectedOptions.length <= 10) {
            setSelectedSkills(selectedOptions);
            setPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                skills: selectedOptions,
            }));
        }
    };

    async function fetchAllSkills() {
        if (searchTerm2.trim()) {
            try {
                const response = await axios.get(`/api/data/skills`, {
                    params: { keyword: searchTerm2 },
                });
                if (Array.isArray(response.data)) {
                    console.log(response.data, "skills");
                    setPdskills(response.data);
                } else {
                    console.error("Unexpected response format:", response.data);
                }
            } catch (error) {
                console.error("Error fetching skills:", error);
            }
        } else {
            setPdskills([]);
        }
    }
    useEffect(() => {
        async function fetchAllSkills() {
            if (searchTerm2.trim()) {
                try {
                    const response = await axios.get(`/api/data/skills`, {
                        params: { keyword: searchTerm2 },
                    });
                    if (Array.isArray(response.data)) {
                        setPdskills(response.data);
                    } else {
                        console.error("Unexpected response format:", response.data);
                    }
                } catch (error) {
                    console.error("Error fetching skills:", error);
                }
            } else {
                setPdskills([]);
            }
        }
        fetchAllSkills();
    }, [searchTerm2]);

    const [isTyping2, setIsTyping2] = useState(false);
    const handleInputChange2 = (newValue) => {
        setSearchTerm2(newValue);
        setIsTyping2(newValue.length > 0);
        // setTimeout(() => {
        //   console.log(newValue, "fuction called");
        //   fetchAllSkills();
        // }, 2000);
    };
    const noOptionsMessage2 = () => {
        return searchTerm2.trim().length < 2 ? "Type Skill" : "No Options";
    };
    const customStyles = {
        dropdownIndicator: (provided) => ({
            ...provided,
            display: "none",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
    };

    const pdfRef = useRef();
    const selectedCountry = "India";

    const downloadPDF = async () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4", true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 30;
            pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);
            pdf.save("invoice.pdf");
        });
    };

    const goBack = () => {
        console.log("clicked");
        window.location.href = document.referrer;
    };

    const calculateTotalExperience = (internships, jobs) => {
        let totalMonths = 0;

        // Function to calculate the difference in months between two dates
        function monthDiff(d1, d2) {
            let months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }

        // Calculate total months for internships
        internships.forEach((internship) => {
            const startDate = new Date(internship.startDate);
            const endDate = new Date(internship.endDate);
            totalMonths += monthDiff(startDate, endDate);
        });

        // Calculate total months for jobs
        jobs.forEach((job) => {
            const startDate = new Date(job.startDate);
            const endDate = new Date(job.endDate);
            totalMonths += monthDiff(startDate, endDate);
        });

        // Calculate years and remaining months
        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;

        // Construct the result string
        let result = "(";
        if (years === 1) {
            result += years + " YEAR ";
        } else if (years > 1) {
            result += years + " YEARS ";
        }

        if (months === 1) {
            result += months + " MONTH";
        } else if (months > 1) {
            result += months + " MONTHS";
        }
        result += ")";

        return result;
    };

    const [loading, setLoading] = useState(false);
    const [loading5, setLoading5] = useState(false);
    const [loading6, setLoading6] = useState(false);
    const [loading7, setLoading7] = useState(false);
    const [loading8, setLoading8] = useState(false);
    const [loader, setLoader] = useState(true);
    const [resumeData, setResumeData] = useState("");
    //console.log(resumeData, "resumeData");
    const [startDate, setStartDate] = useState(new Date());
    //console.log(resumeData, "resumeData");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // HOOKS FOR RESUME COMPONENTS:

    const [skillVissible, setSkillVissible] = useState(false);

    const [personalDetails, setPersonalDetails] = useState({
        _id: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        profile: "",
        availability: "",
        photo: "",
        professionalTitle: "",
        videoIntroduction: "",
        minimumRate: null,
        // profileCategories: [],
        profileCategories: [{ id: 1, profile: null, specializations: [] }],
        profileSummary: "",
    });

    const [addressDetails, setAddressDetails] = useState({
        _id: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        code: "",
        phone: "",
    });

    const [identityDetails, setIdentityDetails] = useState({
        _id: "",
        frontPhoto: "",
        backPhoto: "",
    });
    const [docDetails, setDocDetails] = useState({
        _id: "",
        document: "",
        fileName: "",
    });
    // console.log(identityDetails, "identityDetails");

    const [portfolioDetails, setPortfolioDetails] = useState({
        projectTitle: "",
        yourRole: "",
        projectDescription: "",
        skills: [],
        photo: [],
        videoLink: [],
        webLink: [],
        textContent: [],
        selectedThumbnail: "",
    });
    const [DataSequence, setDataSequence] = useState([]);
    const [portfolioDetailsData, setPortfolioDetailsData] = useState([]);
    const [editPortfolioDetails, setEditPortfolioDetails] = useState({
        projectTitle: "",
        yourRole: "",
        projectDescription: "",
        skills: [],
        photo: [],
        videoLink: [],
        webLink: [],
        textContent: [],
        selectedThumbnail: "",
    });
    //console.log(portfolioDetails, "portfolioDetails ");
    const portfolioModalRef = useRef(null);
    useEffect(() => {
        const handleModalHidden = () => {
            setPortfolioDetails({
                projectTitle: "",
                yourRole: "",
                projectDescription: "",
                skills: [],
                photo: [],
                videoLink: [],
                webLink: [],
                textContent: [],
                selectedThumbnail: "",
            });
            setContentItems([]);
        };

        const modalElement = portfolioModalRef.current;
        if (modalElement) {
            modalElement.addEventListener("hidden.bs.modal", handleModalHidden);
        }

        return () => {
            if (modalElement) {
                modalElement.removeEventListener("hidden.bs.modal", handleModalHidden);
            }
        };
    }, []);

    const resetPortfolioDetails = () => {
        setPortfolioDetails({
            projectTitle: "",
            yourRole: "",
            projectDescription: "",
            skills: [],
            photo: [],
            videoLink: [],
            webLink: [],
            textContent: "",
            selectedThumbnail: "",
        });
        setContentItems([]);
        setShowVideoPreview(false);
        setUploadedImages([]);
        setSelectedImageIndex(null);
        setShowContentIcons(false);
        setVideos([]);
        setSelectedVideoIndex(null);
        setTextBoxes([]);
        setSelectedTextBoxes(null);
        setSelectedImage(null);
        setHeading(null);
        setSelectedSkills([]);
        setUploadedImagesthumb([]);
        setCharCount({ projectTitle: 0, yourRole: 0, projectDescription: 0 });
    };

    const resetEditPortfolioDetails = () => {
        setEditPortfolioDetails({
            projectTitle: "",
            yourRole: "",
            projectDescription: "",
            skills: [],
            photo: [],
            videoLink: [],
            webLink: [],
            textContent: "",
            selectedThumbnail: "",
        });
        setContentItemsFromDetails([]);
        setShowVideoPreview(false);
        setUploadedImages([]);
        setSelectedImageIndex(null);
        setShowContentIcons(false);
        setVideos([]);
        setSelectedVideoIndex(null);
        setTextBoxes([]);
        setSelectedTextBoxes(null);
        setSelectedImage(null);
        setHeading(null);
        setSelectedSkills([]);
        setUploadedImagesthumb([]);
        setCharCount({ projectTitle: 0, yourRole: 0, projectDescription: 0 });
    };

    useEffect(() => {
        const fetchSpecializationsForSavedCategories = async () => {
            const fetchedSpecializations = {};
            for (const category of personalDetails.profileCategories) {
                if (category.profile) {
                    const encodedCategory = encodeURIComponent(category.profile);
                    try {
                        const response = await axios.get(`/api/data/get-specialization?profileCategory=${encodedCategory}`);
                        const data = await response.data;
                        fetchedSpecializations[category.profile] = data;
                    } catch (error) {
                        console.error("Error fetching specializations", error);
                    }
                }
            }
            setSpecializations(fetchedSpecializations);
        };

        fetchSpecializationsForSavedCategories();
    }, [personalDetails.profileCategories]);

    const [education, setEducation] = useState({
        _id: "",
        school: "",
        degree: "",
        startDate: "",
        endDate: "",
        performanceScale: "",
        performance: "",
        specialization: "",
    });

    const [job, setJob] = useState({
        _id: "",
        profile: "",
        organization: "",
        location: "",
        startDate: "",
        endDate: "",
        description: "",
    });

    const [internship, setInternship] = useState({
        _id: "",
        profile: "",
        organization: "",
        location: "",
        startDate: "",
        endDate: "",
        description: "",
    });

    const [training, setTraining] = useState({
        _id: "",
        program: "",
        organization: "",
        location: "",
        startDate: "",
        endDate: "",
        description: "",
    });

    const [academic, setAcademic] = useState({
        _id: "",
        title: "",
        startDate: "",
        endDate: "",
        description: "",
        link: "",
    });

    const [skill, setskill] = useState({
        _id: "",
        name: "",
        level: "Beginner",
    });

    // HANDLE INPUT FUNCTIONS FOR RESUME COMPONENTS:
    const [userMinimumRate, setUserMinimumRate] = useState(personalDetails?.minimumRate);

    const handlPersonalDetailsChange = (e) => {
        const { name, value } = e.target;

        //console.log(name, value, "dkfugkdfu");

        if (name === "profileSummary" && value.length > 5001) {
            return;
        }

        if (name === "profileSummary") {
            setMessage(value);
        }
        // if (name === 'profileSummary') {
        //   if (value.length > 5000) {
        //     setMessage(value.slice(0, 5000));
        //     return;
        //   } else {
        //     setMessage(value);
        //   }
        // }

        setPersonalDetails({
            ...personalDetails,
            [e.target.name]: e.target.value,
        });

        // if (/^\d*\.?\d*$/.test(e.target.value)) {
        //   console.log(e.target.value, "e.target.valuee.target.value");
        //   setPersonalDetails({
        //     ...personalDetails,
        //     [e.target.name]: e.target.value,
        //   });
        // }
    };

    const handlePortfolioChange = (e) => {
        const { name, value } = e.target;
        setPortfolioDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
        setCharCount({ ...charCount, [name]: value.length });
    };

    // const handlePortfolioChange = (e) => {
    //   const { name, value } = e.target;
    //   setPortfolioDetails({ ...portfolioDetails, [name]: value });
    //   setCharCount({ ...charCount, [name]: value.length });
    // };

    const handleEducationChange = (e) => {
        setEducation({
            ...education,
            [e.target.name]: e.target.value,
        });
    };

    const handleJobChange = (e) => {
        setJob({
            ...job,
            [e.target.name]: e.target.value,
        });
    };

    const handleInternshipChange = (e) => {
        setInternship({
            ...internship,
            [e.target.name]: e.target.value,
        });
    };

    const handleTrainingChange = (e) => {
        setTraining({
            ...training,
            [e.target.name]: e.target.value,
        });
    };

    const handleAcademicChange = (e) => {
        setAcademic({
            ...academic,
            [e.target.name]: e.target.value,
        });
    };

    const handleSkillChange = (e) => {
        setskill({
            ...skill,
            [e.target.name]: e.target.value,
        });
    };

    const isValidURL = (url) => {
        // Regular expression to validate URL
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/;
        return urlRegex.test(url);
    };
    // ADD FUNCTION FOR RESUME COMPONENTS:

    const updatePersonalDetails = async () => {
        setLoading(true);
        const youtubeUrlPattern = /^(https:\/\/www\.youtube\.com\/watch\?v=[\w-]+&?.*|https:\/\/youtu\.be\/[\w-]+\?.*)$/;
        if (personalDetails.firstName == "") {
            setLoading(false);
            toast.error("Enter first name");
        } else if (personalDetails.lastName == "") {
            setLoading(false);
            toast.error("Enter last name");
        } else if (personalDetails.phone == "") {
            setLoading(false);
            toast.error("Enter phone number");
        } else if (personalDetails.phone.length < 7 || personalDetails.phone.length > 16) {
            setLoading(false);
            toast.error("Please confirm your phone number");
        }
        // else if (personalDetails.profile == "") {
        //   setLoading(false);
        //   toast.error("Enter your profile")
        // }
        else if (personalDetails.address == "") {
            setLoading(false);
            toast.error("Enter your address");
        } else if (personalDetails.availability === "" || !personalDetails.availability) {
            setLoading(false);
            toast.error("Select availability");
        } else if (personalDetails.videoIntroduction && !isValidURL(personalDetails.videoIntroduction)) {
            setLoading(false);
            toast.error("Please enter a valid URL");
        } else if (personalDetails.minimumRate === null || !personalDetails.minimumRate) {
            setLoading(false);
            toast.error("Enter your minimum rate");
        } else if (personalDetails.profileCategories.length === 0 || personalDetails.profileCategories.some((category) => !category.profile)) {
            setLoading(false);
            toast.error("Select at least one profile category");
        } else if (personalDetails.profileCategories.some((category) => category.specializations.length === 0)) {
            setLoading(false);
            toast.error("Select at least one specialization for each profile category");
        } else if (personalDetails.professionalTitle === "" || !personalDetails.professionalTitle) {
            setLoading(false);
            toast.error("Enter your Professional Title");
        } else if (personalDetails.videoIntroduction && !youtubeUrlPattern.test(personalDetails.videoIntroduction)) {
            setLoading(false);
            toast.error("Please enter a valid YouTube URL");
        } else if (personalDetails.profileSummary.length > 5000) {
            setLoading(false);
            toast.error("Profile summary cannot exceed 5000 characters");
        } else {
            try {
                console.log(personalDetails);

                const { data } = await axios.post(
                    "/api/user/createResume/PersonalDetails",
                    {
                        firstName: personalDetails.firstName,
                        lastName: personalDetails.lastName,
                        email: personalDetails.email,
                        phone: personalDetails.phone,
                        address: personalDetails.address,
                        profile: personalDetails.profile,
                        availability: personalDetails.availability,
                        photo: personalDetails.photo,
                        professionalTitle: personalDetails.professionalTitle,
                        videoIntroduction: personalDetails.videoIntroduction,
                        minimumRate: personalDetails.minimumRate,
                        profileCategories: personalDetails.profileCategories,
                        profileSummary: personalDetails.profileSummary,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closePersonal.current.click();
                toast.success("Details Added Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
                fetchResumeId();
            } catch (error) {
                setLoading(false);
                //alert(error);
                console.log(error, "update error");
            }
        }
    };

    const updateAddressDetails = async () => {
        setLoading(true);
        if (addressDetails.address === "") {
            setLoading(false);
            toast.error("Enter Address");
        } else if (addressDetails.city === "") {
            setLoading(false);
            toast.error("Enter City");
        } else if (addressDetails.state === "") {
            setLoading(false);
            toast.error("Enter State");
        } else if (addressDetails.code === "") {
            setLoading(false);
            toast.error("Enter Zip code");
        } else if (addressDetails.phone === "") {
            setLoading(false);
            toast.error("Enter phone number");
        } else {
            try {
                const { data } = await axios.post(
                    "/api/user/createResume/AddressDetail",
                    {
                        address: addressDetails.address,
                        address2: addressDetails.address2,
                        city: addressDetails.city,
                        state: addressDetails.state,
                        code: addressDetails.code,
                        phone: addressDetails.phone,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeAddress.current.click();
                toast.success("Details Added Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
                fetchResumeId();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const updateIdentityDetails = async () => {
        setLoading(true);
        if (identityDetails.frontPhoto == "") {
            setLoading(false);
            toast.error("Upload Front Photo");
        }
        if (identityDetails.backPhoto == "") {
            setLoading(false);
            toast.error("Upload Back Photo");
        } else {
            try {
                const { data } = await axios.post(
                    "/api/user/createResume/IdentityDetails",
                    {
                        frontPhoto: identityDetails.frontPhoto,
                        backPhoto: identityDetails.backPhoto,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeIden.current.click();
                toast.success("Details Added Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
                fetchResumeId();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const updateDocDetails = async () => {
        setLoading(true);
        if (docDetails.document === "") {
            setLoading(false);
            toast.error("Upload Document");
        } else {
            try {
                const { data } = await axios.post(
                    "/api/user/createResume/DocDetails",
                    {
                        document: docDetails.document,
                        fileName: docDetails.fileName,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeDoc.current.click();
                toast.success("Details Added Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
                fetchResumeId();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const updateEducation = async () => {
        setLoading(true);
        if (education.school == "") {
            setLoading(false);
            toast.error("Enter school/college name");
        } else if (education.degree == "") {
            setLoading(false);
            toast.error("Enter degree name");
        } else if (education.specialization == "") {
            setLoading(false);
            toast.error("Enter course name");
        } else if (education.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (education.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (new Date(education.endDate && education.startDate) > new Date(education.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (education.performanceScale == "") {
            setLoading(false);
            toast.error("Enter performanceScale");
        } else if (education.performance == "") {
            setLoading(false);
            toast.error("Enter performance");
        } else {
            try {
                const { data } = await axios.post(
                    "/api/user/createResume/Education",
                    {
                        school: education.school,
                        degree: education.degree,
                        startDate: education.startDate,
                        endDate: education.endDate,
                        performanceScale: education.performanceScale,
                        performance: education.performance,
                        specialization: education.specialization,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeEducation.current.click();
                toast.success("Details Added Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const updateJob = async () => {
        setLoading(true);
        if (job.profile == "") {
            setLoading(false);
            toast.error("Enter profile name");
        } else if (job.organization == "") {
            setLoading(false);
            toast.error("Enter organization name");
        } else if (job.location == "") {
            setLoading(false);
            toast.error("Enter location");
        } else if (job.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (job.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (new Date(job.startDate) > new Date(job.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (job.description == "") {
            setLoading(false);
            toast.error("Enter description");
        } else {
            try {
                const { data } = await axios.post(
                    "/api/user/createResume/Job",
                    {
                        profile: job.profile,
                        organization: job.organization,
                        location: job.location,
                        startDate: job.startDate,
                        endDate: job.endDate,
                        description: job.description,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeJob.current.click();
                toast.success("Details Added Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const updateInternship = async () => {
        setLoading(true);
        if (internship.profile == "") {
            setLoading(false);
            toast.error("Enter profile name");
        } else if (internship.organization == "") {
            setLoading(false);
            toast.error("Enter organization name");
        } else if (internship.location == "") {
            setLoading(false);
            toast.error("Enter location");
        } else if (internship.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (internship.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (new Date(internship.startDate) > new Date(internship.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (internship.description == "") {
            setLoading(false);
            toast.error("Enter description");
        } else {
            try {
                const { data } = await axios.post(
                    "/api/user/createResume/Internship",
                    {
                        profile: internship.profile,
                        organization: internship.organization,
                        location: internship.location,
                        startDate: internship.startDate,
                        endDate: internship.endDate,
                        description: internship.description,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeInternship.current.click();
                toast.success("Details Added Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const updateTraining = async () => {
        setLoading(true);
        if (training.program == "") {
            setLoading(false);
            toast.error("Enter program name");
        } else if (training.organization == "") {
            setLoading(false);
            toast.error("Enter organization name");
        } else if (training.location == "") {
            setLoading(false);
            toast.error("Enter location");
        } else if (training.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (training.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (new Date(training.startDate) > new Date(training.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (training.description == "") {
            setLoading(false);
            toast.error("Enter description");
        } else {
            try {
                const { data } = await axios.post(
                    "/api/user/createResume/Training",
                    {
                        program: training.program,
                        organization: training.organization,
                        location: training.location,
                        startDate: training.startDate,
                        endDate: training.endDate,
                        description: training.description,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeTraining.current.click();
                toast.success("Details Added Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const updateAcademic = async () => {
        setLoading(true);
        if (academic.title == "") {
            setLoading(false);
            toast.error("Enter project title");
        } else if (academic.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (academic.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (new Date(academic.startDate) > new Date(academic.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (academic.description == "") {
            setLoading(false);
            toast.error("Enter description");
        } else if (academic.link == "") {
            setLoading(false);
            toast.error("Enter project link");
        } else {
            try {
                const { data } = await axios.post(
                    "/api/user/createResume/Academics",
                    {
                        title: academic.title,
                        startDate: academic.startDate,
                        endDate: academic.endDate,
                        description: academic.description,
                        link: academic.link,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeAcademics.current.click();
                toast.success("Details Added Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const updateSkill = async () => {
        setLoading(true);
        if (skill.name === "") {
            setLoading(false);
            toast.error("Enter skill name");
        } else {
            // Check if skill name already exists in resumeData.Skills
            const skillExists = resumeData.Skills.some((existingSkill) => existingSkill.name === skill.name);

            if (skillExists) {
                setLoading(false);
                toast.error("This skill is already added");
            } else {
                try {
                    const { data } = await axios.post(
                        "/api/user/createResume/Skills",
                        {
                            name: skill.name,
                            level: skill.level,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                token: localStorage.getItem("token"),
                            },
                        }
                    );
                    setLoading(false);
                    closeSkills.current.click();
                    toast.success("Details Added Successfully", {
                        style: {
                            padding: "18px",
                        },
                    });
                    getResumeData2();
                } catch (error) {
                    setLoading(false);
                    alert(error);
                }
            }
        }
    };

    // UPDATE FUCTIONS FOR RESUME COMPONENTS:

    const modifyPersonalDetails = async () => {
        setLoading(true);
        const youtubeUrlPattern = /^(https:\/\/www\.youtube\.com\/watch\?v=[\w-]+&?.*|https:\/\/youtu\.be\/[\w-]+\?.*)$/;
        if (personalDetails.firstName == "") {
            setLoading(false);
            toast.error("Enter first name");
        } else if (personalDetails.lastName == "") {
            setLoading(false);
            toast.error("Enter last name");
        }
        // else if (personalDetails.profile == "") {
        //   setLoading(false);
        //   toast.error("Enter your profile")
        // }
        else if (personalDetails.address == "") {
            setLoading(false);
            toast.error("Enter your address");
        } else if (personalDetails.phone == "") {
            setLoading(false);
            toast.error("Enter phone number");
        } else if (personalDetails.phone.length < 7 || personalDetails.phone.length > 16) {
            setLoading(false);
            toast.error("Please confirm your phone number");
        } else if (personalDetails.availability === "" || !personalDetails.availability) {
            setLoading(false);
            toast.error("Select availability");
        }
        // else if (personalDetails.videoIntroduction && !isValidURL(personalDetails.videoIntroduction)) {
        //   setLoading(false);
        //   toast.error("Please enter a valid URL");
        // }
        else if (personalDetails.minimumRate === null || !personalDetails.minimumRate) {
            setLoading(false);
            toast.error("Enter your minimum rate");
        } else if (personalDetails.profileCategories.length === 0 || personalDetails.profileCategories.some((category) => !category.profile)) {
            setLoading(false);
            toast.error("Select at least one profile category");
        } else if (personalDetails.profileCategories.some((category) => category.specializations.length === 0)) {
            setLoading(false);
            toast.error("Select at least one specialization for each profile category");
        } else if (personalDetails.professionalTitle === "" || !personalDetails.professionalTitle) {
            setLoading(false);
            toast.error("Enter your Professional Title");
        } else if (personalDetails.videoIntroduction && !youtubeUrlPattern.test(personalDetails.videoIntroduction)) {
            setLoading(false);
            toast.error("Please enter a valid YouTube URL");
        } else if (personalDetails.profileSummary.length > 5000) {
            setLoading(false);
            toast.error("Profile summary cannot exceed 5000 characters");
        } else {
            try {
                console.log(personalDetails.minimumRate, "rate");
                const { data } = await axios.put(
                    `/api/user/updateResume/PersonalDetails/${personalDetails._id}`,
                    {
                        firstName: personalDetails.firstName,
                        lastName: personalDetails.lastName,
                        email: personalDetails.email,
                        phone: personalDetails.phone,
                        address: personalDetails.address,
                        profile: personalDetails.profile,
                        availability: personalDetails.availability,
                        photo: personalDetails.photo,
                        professionalTitle: personalDetails.professionalTitle,
                        videoIntroduction: personalDetails.videoIntroduction,
                        minimumRate: personalDetails.minimumRate,
                        profileCategories: personalDetails.profileCategories,
                        profileSummary: personalDetails.profileSummary,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closePersonal.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                console.error("Error submitting data:", error);
                setLoading(false);
                alert(error);
            }
        }
    };

    const modifyAddresslDetails = async () => {
        setLoading(true);
        if (addressDetails.address === "") {
            setLoading(false);
            toast.error("Enter Address");
        } else if (addressDetails.city === "") {
            setLoading(false);
            toast.error("Enter City");
        } else if (addressDetails.state === "") {
            setLoading(false);
            toast.error("Enter State");
        } else if (addressDetails.code === "") {
            setLoading(false);
            toast.error("Enter Zip code");
        } else if (addressDetails.phone === "") {
            setLoading(false);
            toast.error("Enter phone number");
        } else {
            try {
                const { data } = await axios.put(
                    `/api/user/updateResume/AddressDetail/${addressDetails._id}`,
                    {
                        address: addressDetails.address,
                        address2: addressDetails.address2,
                        city: addressDetails.city,
                        state: addressDetails.state,
                        code: addressDetails.code,
                        phone: addressDetails.phone,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeAddress.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                console.error("Error submitting data:", error);
                setLoading(false);
                alert(error);
            }
        }
    };

    const modifyIdentityDetails = async () => {
        setLoading(true);
        if (identityDetails.frontPhoto == "") {
            setLoading(false);
            toast.error("Upload Front Photo");
        }
        if (identityDetails.backPhoto == "") {
            setLoading(false);
            toast.error("Upload Back Photo");
        } else {
            try {
                const { data } = await axios.put(
                    `/api/user/updateResume/IdentityDetails/${identityDetails._id}`,
                    {
                        frontPhoto: identityDetails.frontPhoto,
                        backPhoto: identityDetails.backPhoto,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeIden.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                console.error("Error submitting data:", error);
                setLoading(false);
                alert(error);
            }
        }
    };

    const modifyDocDetails = async () => {
        setLoading(true);
        if (docDetails.document === "") {
            setLoading(false);
            toast.error("Upload Document");
        } else {
            try {
                const { data } = await axios.put(
                    `/api/user/updateResume/DocDetails/${docDetails._id}`,
                    {
                        document: docDetails.document,
                        fileName: docDetails.fileName,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeDoc.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                console.error("Error submitting data:", error);
                setLoading(false);
                alert(error);
            }
        }
    };

    const modifyEducation = async () => {
        setLoading(true);
        if (education.school == "") {
            setLoading(false);
            toast.error("Enter school/college name");
        } else if (education.degree == "") {
            setLoading(false);
            toast.error("Enter degree name");
        } else if (education.specialization == "") {
            setLoading(false);
            toast.error("Enter course name");
        } else if (education.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (education.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (education.endDate && new Date(education.startDate) > new Date(education.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (education.performanceScale == "") {
            setLoading(false);
            toast.error("Enter performanceScale");
        } else if (education.performance == "") {
            setLoading(false);
            toast.error("Enter performance");
        } else {
            try {
                const { data } = await axios.put(
                    `/api/user/updateResume/Education/${education._id}`,
                    {
                        school: education.school,
                        degree: education.degree,
                        startDate: education.startDate,
                        endDate: education.endDate,
                        performanceScale: education.performanceScale,
                        performance: education.performance,
                        specialization: education.specialization,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeEducation.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const modifyJob = async () => {
        setLoading(true);
        if (job.profile == "") {
            setLoading(false);
            toast.error("Enter profile name");
        } else if (job.organization == "") {
            setLoading(false);
            toast.error("Enter organization name");
        } else if (job.location == "") {
            setLoading(false);
            toast.error("Enter location");
        } else if (job.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (job.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (new Date(job.startDate) > new Date(job.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (job.description == "") {
            setLoading(false);
            toast.error("Enter description");
        } else {
            try {
                const { data } = await axios.put(
                    `/api/user/updateResume/Job/${job._id}`,
                    {
                        profile: job.profile,
                        organization: job.organization,
                        location: job.location,
                        startDate: job.startDate,
                        endDate: job.endDate,
                        description: job.description,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeJob.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const modifyInternship = async () => {
        setLoading(true);
        if (internship.profile == "") {
            setLoading(false);
            toast.error("Enter profile name");
        } else if (internship.organization == "") {
            setLoading(false);
            toast.error("Enter organization name");
        } else if (internship.location == "") {
            setLoading(false);
            toast.error("Enter location");
        } else if (internship.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (internship.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (new Date(internship.startDate) > new Date(internship.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (internship.description == "") {
            setLoading(false);
            toast.error("Enter description");
        } else {
            try {
                const { data } = await axios.put(
                    `/api/user/updateResume/Internship/${internship._id}`,
                    {
                        profile: internship.profile,
                        organization: internship.organization,
                        location: internship.location,
                        startDate: internship.startDate,
                        endDate: internship.endDate,
                        description: internship.description,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeInternship.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const modifyTraining = async () => {
        setLoading(true);
        if (training.program == "") {
            setLoading(false);
            toast.error("Enter program name");
        } else if (training.organization == "") {
            setLoading(false);
            toast.error("Enter organization name");
        } else if (training.location == "") {
            setLoading(false);
            toast.error("Enter location");
        } else if (training.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (training.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (new Date(training.startDate) > new Date(training.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (training.description == "") {
            setLoading(false);
            toast.error("Enter description");
        } else {
            try {
                const { data } = await axios.put(
                    `/api/user/updateResume/Training/${training._id}`,
                    {
                        program: training.program,
                        organization: training.organization,
                        location: training.location,
                        startDate: training.startDate,
                        endDate: training.endDate,
                        description: training.description,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeTraining.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const modifyAcademic = async () => {
        setLoading(true);
        if (academic.title == "") {
            setLoading(false);
            toast.error("Enter project title");
        } else if (academic.startDate == "") {
            setLoading(false);
            toast.error("Enter start date");
        } else if (academic.endDate == "") {
            setLoading(false);
            toast.error("Enter end date");
        } else if (new Date(academic.startDate) > new Date(academic.endDate)) {
            setLoading(false);
            toast.error("Start date cannot be greater than end date");
        } else if (academic.description == "") {
            setLoading(false);
            toast.error("Enter description");
        } else if (academic.link == "") {
            setLoading(false);
            toast.error("Enter project link");
        } else {
            try {
                const { data } = await axios.put(
                    `/api/user/updateResume/Academics/${academic._id}`,
                    {
                        title: academic.title,
                        startDate: academic.startDate,
                        endDate: academic.endDate,
                        description: academic.description,
                        link: academic.link,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeAcademics.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    const modifySkill = async () => {
        setLoading(true);
        if (skill.name === "") {
            setLoading(false);
            toast.error("Enter skill name");
        } else {
            try {
                const { data } = await axios.put(
                    `/api/user/updateResume/Skills/${skill._id}`,
                    {
                        name: skill.name,
                        level: skill.level,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
                setLoading(false);
                closeSkills.current.click();
                toast.success("Details Updated Successfully", {
                    style: {
                        padding: "18px",
                    },
                });
                getResumeData2();
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    };

    // DELETE FUNCTION FOR RESUME COMPONENTS:

    const deleteEducation = async (_id) => {
        try {
            const { data } = await axios.delete(`/api/user/deleteResume/Education/${_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            toast.success("Details Deleted Successfully", {
                style: {
                    padding: "18px",
                },
            });
            getResumeData2();
        } catch (error) {}
    };

    const deleteJob = async (_id) => {
        try {
            const { data } = await axios.delete(`/api/user/deleteResume/Job/${_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            toast.success("Details Deleted Successfully", {
                style: {
                    padding: "18px",
                },
            });
            getResumeData2();
        } catch (error) {}
    };

    const deleteInternship = async (_id) => {
        try {
            const { data } = await axios.delete(`/api/user/deleteResume/Internship/${_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            toast.success("Details Deleted Successfully", {
                style: {
                    padding: "18px",
                },
            });
            getResumeData2();
        } catch (error) {}
    };

    const deleteTraining = async (_id) => {
        try {
            const { data } = await axios.delete(`/api/user/deleteResume/Training/${_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            toast.success("Details Deleted Successfully", {
                style: {
                    padding: "18px",
                },
            });
            getResumeData2();
        } catch (error) {}
    };

    const deleteAcademics = async (_id) => {
        try {
            const { data } = await axios.delete(`/api/user/deleteResume/Academics/${_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            toast.success("Details Deleted Successfully", {
                style: {
                    padding: "18px",
                },
            });
            getResumeData2();
        } catch (error) {}
    };

    const deleteSkills = async (_id) => {
        try {
            const { data } = await axios.delete(`/api/user/deleteResume/Skills/${_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            toast.success("Details Deleted Successfully", {
                style: {
                    padding: "18px",
                },
            });
            getResumeData2();
        } catch (error) {}
    };

    // EDIT FUNCTION FOR RESUME COMPONENTS:

    const editPersonalDetails = (data) => {
        setPersonalDetails({
            ...personalDetails,
            _id: data._id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            address: data.address,
            profile: data.profile,
            availability: data.availability,
            photo: data.photo,
            professionalTitle: data.professionalTitle,
            videoIntroduction: data.videoIntroduction,
            minimumRate: data.minimumRate,
            profileCategories: data.profileCategories,
            profileSummary: data.profileSummary,
        });
    };

    const editAddressDetails = (data) => {
        setAddressDetails({
            ...addressDetails,
            _id: data._id,
            address: data.address,
            address2: data.address2,
            city: data.city,
            state: data.state,
            code: data.code,
            phone: data.phone,
        });
    };
    const editIdentityDetails = (data) => {
        setIdentityDetails({
            ...identityDetails,
            _id: data._id,
            frontPhoto: data.frontPhoto,
            backPhoto: data.backPhoto,
        });
    };

    const editDocDetails = (data) => {
        setDocDetails({
            ...docDetails,
            _id: data._id,
            document: data.document,
            fileName: data.fileName,
        });
    };

    const editEducation = (data) => {
        setEducation({
            ...education,
            _id: data._id,
            school: data.school,
            degree: data.degree,
            startDate: data.startDate,
            endDate: data.endDate,
            performanceScale: data.performanceScale,
            performance: data.performance,
            specialization: data.specialization,
        });
    };

    const addEducation = () => {
        setEducation({
            ...education,
            _id: "",
            school: "",
            degree: "",
            startDate: "",
            endDate: "",
            performanceScale: "",
            performance: "",
            specialization: "",
        });
    };

    const editJob = (data) => {
        setJob({
            ...job,
            _id: data._id,
            profile: data.profile,
            organization: data.organization,
            location: data.location,
            startDate: data.startDate,
            endDate: data.endDate,
            description: data.description,
        });
    };

    const addJob = () => {
        setJob({
            ...job,
            _id: "",
            profile: "",
            organization: "",
            location: "",
            startDate: "",
            endDate: "",
            description: "",
        });
    };

    const editInternship = (data) => {
        setInternship({
            ...internship,
            _id: data._id,
            profile: data.profile,
            organization: data.organization,
            location: data.location,
            startDate: data.startDate,
            endDate: data.endDate,
            description: data.description,
        });
    };

    const addInternship = () => {
        setInternship({
            ...internship,
            _id: "",
            profile: "",
            organization: "",
            location: "",
            startDate: "",
            endDate: "",
            description: "",
        });
    };

    const editTraining = (data) => {
        setTraining({
            ...training,
            _id: data._id,
            program: data.program,
            organization: data.organization,
            location: data.location,
            startDate: data.startDate,
            endDate: data.endDate,
            description: data.description,
        });
    };

    const addTraining = () => {
        setTraining({
            ...training,
            _id: "",
            program: "",
            organization: "",
            location: "",
            startDate: "",
            endDate: "",
            description: "",
        });
    };

    const editAcademics = (data) => {
        setAcademic({
            ...academic,
            _id: data._id,
            title: data.title,
            startDate: data.startDate,
            endDate: data.endDate,
            description: data.description,
            link: data.link,
        });
    };

    const addAcademics = () => {
        setAcademic({
            ...academic,
            _id: "",
            title: "",
            startDate: "",
            endDate: "",
            description: "",
            link: "",
        });
    };

    const editSkill = (data) => {
        setskill({
            ...skill,
            _id: data._id,
            name: data.name,
            level: data.level,
        });
    };

    const addSkill = () => {
        setskill({
            ...skill,
            _id: "",
            name: "",
            level: "Beginner",
        });
    };

    // GETTING RESUME DATA:

    const getResumeData = async () => {
        setLoader(true);
        try {
            const { data } = await axios.get("/api/user/getResume", {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            setResumeData(data.resume);
            console.log(data, "get1");

            setLoader(false);
        } catch {
            console.log(error, "get1");
            toast.error("Something went wrong");
            setLoader(false);
        }
    };

    const getResumeData2 = async () => {
        try {
            const { data } = await axios.get("/api/user/getResume", {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            setResumeData(data.resume);
            console.log(data, "get1");
        } catch {
            console.log(error, "get2");

            toast.error("Something went wrong");
        }
    };
    // SEPRATOR:

    const seprator = (str) => {
        const arr = str.split(" ");
        return arr;
    };

    // USEEFFECT:

    useEffect(() => {
        if (user) {
            getResumeData();
        }

        setPersonalDetails({
            ...personalDetails,
            firstName: user && seprator(user.name)[0],
            lastName: user && seprator(user.name)[1],
            email: user && user.email,
            // phone: user && user.phone,
            // address: user && user.city,
            availability: user && user.availability,
        });
    }, [user]);

    const fetchResumeId = async () => {
        try {
            const response = await axios.get("/api/user/getResumeIdPersonal", {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            if (response.data.success) {
                setResumeId(response.data.resumeId);
            }
        } catch (error) {
            console.error("Error fetching resume ID:", error);
        }
    };

    useEffect(() => {
        // You can add additional logic here if needed
    }, [education]); // Add other dependencies as needed

    // const handlePhotoChange = (e) => {
    //   const file = e.target.files[0];
    //   setPersonalDetails({
    //     ...personalDetails,
    //     photo: file
    //   });
    // };
    const handlePhotoChange = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        // reader.readAsDataURL(file);
        // // reader.onloadend = () => {
        // //   setPersonalDetails({
        // //     ...personalDetails,
        // //     photo: reader.result, // base64 string
        // //   });
        // // };
        // // console.log(reader.result,"result")
        // // if (file) {
        // //   reader.readAsDataURL(file);
        // // }

        // reader.onload = () => {
        //   if (reader.readyState === 2) {
        //     if (reader.result) {
        //       setPersonalDetails({
        //         ...personalDetails,
        //         photo: reader.result,
        //       });
        //     }
        //   }
        // };
        reader.onload = (event) => {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = event.target.result;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Set the canvas dimensions to a fixed size (e.g., 150x150)
                const maxWidth = 521;
                const maxHeight = 352;
                // const maxWidth = 300;
                // const maxHeight = 300;

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                // ctx.imageSmoothingEnabled = false;
                // ctx.imageSmoothingQuality = 'high';

                ctx.drawImage(img, 0, 0, width, height);
                // const format = file.type;
                const format = "image/jpeg";
                const resizedDataUrl = canvas.toDataURL(format, 0.95); // You can adjust the quality here
                setImageWidth(width);
                setImageHeight(height);

                setPersonalDetails({
                    ...personalDetails,
                    photo: resizedDataUrl,
                });

                axios
                    .post(`/api/user/postingImageDimensions/${user._id}`, {
                        photo: resizedDataUrl,
                        width,
                        height,
                    })
                    .then((response) => {
                        // console.log("Photo data saved:", response.data);
                    })
                    .catch((error) => {
                        console.error("Error saving photo data:", error);
                    });
            };
        };

        reader.readAsDataURL(file);
    };

    const handleFrontPhotoChange = async (e) => {
        const file = e.target.files[0];
        if (file.size > 200 * 1024) {
            toast.error("Image size must be 200KB or less.");
            return;
        }
        const reader = new FileReader();

        reader.onload = (event) => {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = event.target.result;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const maxWidth = 521;
                const maxHeight = 352;

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                const format = "image/jpeg";
                const resizedDataUrl = canvas.toDataURL(format, 0.95);

                setIdentityDetails({
                    ...identityDetails,
                    frontPhoto: resizedDataUrl,
                });
            };
        };

        reader.readAsDataURL(file);
    };
    const handleBackPhotoChange = async (e) => {
        const file = e.target.files[0];
        if (file.size > 200 * 1024) {
            toast.error("Image size must be 200KB or less.");
            return;
        }
        const reader = new FileReader();

        reader.onload = (event) => {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = event.target.result;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const maxWidth = 521;
                const maxHeight = 352;

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                const format = "image/jpeg";
                const resizedDataUrl = canvas.toDataURL(format, 0.95);

                setIdentityDetails({
                    ...identityDetails,
                    backPhoto: resizedDataUrl,
                });
            };
        };

        reader.readAsDataURL(file);
    };

    const handleDocumentChange = async (e) => {
        const file = e.target.files[0];
        if (file.size > 200 * 1024) {
            toast.error("File size must be 200KB or less.");
            return;
        }
        // Check the file type
        if (file.type.startsWith("image/")) {
            // Handle image files
            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();
                img.crossOrigin = "anonymous";
                img.src = event.target.result;

                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    const maxWidth = 521;
                    const maxHeight = 352;

                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height = Math.round((height *= maxWidth / width));
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width = Math.round((width *= maxHeight / height));
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);

                    const format = "image/jpeg";
                    const resizedDataUrl = canvas.toDataURL(format, 0.95);

                    setDocDetails({
                        ...docDetails,
                        document: resizedDataUrl,
                        fileName: file.name,
                    });
                };
            };

            reader.readAsDataURL(file);
        } else {
            // Handle non-image files
            const reader = new FileReader();
            reader.onload = (event) => {
                setDocDetails({
                    ...docDetails,
                    document: event.target.result, // This will be the file content as a base64-encoded string
                    fileName: file.name,
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = docDetails.document;
        link.download = docDetails.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownload2 = async (fileId, fileName) => {
        try {
            const response = await axios({
                url: `/api/user/getDownloadFile/${fileId}?fileName=${fileName}`, // Your backend endpoint
                method: "GET",
                responseType: "blob", // Important: this ensures the response is processed as a blob (binary data)
            });

            // Create a link element, set its URL to the blob, and trigger the download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();

            // Cleanup
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Error downloading file:", error);
            alert("Failed to download the file.");
        }
    };

    //console.log(docDetails, "docDetails");
    // console.log(user, "uers");
    // console.log(personalDetails, "person");
    const getYouTubeEmbedUrl = (url) => {
        try {
            const urlObj = new URL(url);
            let videoId;

            if (urlObj.hostname === "youtu.be") {
                // Shortened YouTube URL (e.g., https://youtu.be/K0pbfQM1huY)
                videoId = urlObj.pathname.substring(1);
            } else if (urlObj.pathname.includes("/shorts/")) {
                // YouTube Shorts URL (e.g., https://youtube.com/shorts/6WKzH7jVhgs)
                videoId = urlObj.pathname.split("/shorts/")[1];
            } else {
                // Standard YouTube URL (e.g., https://www.youtube.com/watch?v=K0pbfQM1huY)
                videoId = urlObj.searchParams.get("v");
            }

            return `https://www.youtube.com/embed/${videoId}`;
        } catch (e) {
            console.error("Invalid URL", e);
            return null;
        }
    };
    const [videoUrl, setVideoUrl] = useState("");
    const [isValidUrl, setIsValidUrl] = useState(true);
    const handleInputChangePortfolioVideo = (e) => {
        const url = e.target.value;
        setVideoUrl(url);
        setIsValidUrl(getYouTubeEmbedUrl(url) !== null);
    };
    let editor = null;

    //   const saveEditedPhoto = () => {
    //     if (editor) {
    //       const canvas = editor.getImageScaledToCanvas().toDataURL(); // Get the edited image as a Data URL
    //       setPersonalDetails({
    //           ...personalDetails,
    //           photo: { ...personalDetails.photo, url: canvas }, // Update the photo URL with the edited image
    //       });
    //       setIsEditingPhoto(false);
    //   }
    // };

    const handleEditAndSave = async () => {
        if (selectedImage && selectedImage.includes("https://img.youtube.com/vi/")) {
            // Convert YouTube thumbnail URL to base64
            try {
                const base64Url = await getBase64FromUrl(selectedImage);
                setEditorDatathumb(base64Url);
            } catch (error) {
                console.error("Error converting YouTube thumbnail to base64:", error);
                return;
            }
        }

        setIsEditingPhotothumb(true);
    };

    const handleEditAndSaveDraft = async () => {
        if (selectedImage && selectedImage.includes("https://img.youtube.com/vi/")) {
            // Convert YouTube thumbnail URL to base64
            try {
                const base64Url = await getBase64FromUrl(selectedImage);
                setEditorDatathumbDraft(base64Url);
            } catch (error) {
                console.error("Error converting YouTube thumbnail to base64:", error);
                return;
            }
        }

        setIsEditingPhotothumbDraft(true);
    };

    // Function to get base64 data from URL using proxy
    const getBase64FromUrl = async (url) => {
        try {
            // const response = await axios.get(`http://localhost:3000/youtube-thumbnail-edit?url=${encodeURIComponent(url)}`);
            const response = await axios.get(`https://workcroft.com/youtube-thumbnail-edit?url=${encodeURIComponent(url)}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching YouTube thumbnail:", error);
            throw error;
        }
    };

    const saveEditedPhoto = async () => {
        if (editor) {
            const canvas = editor.getImageScaledToCanvas();
            const ctx = canvas.getContext("2d");

            // Resize the canvas to a fixed size (e.g., 150x150)
            // const maxWidth = 150;
            // const maxHeight = 150;
            const maxWidth = 521;
            const maxHeight = 352;

            let width = canvas.width;
            let height = canvas.height;
            //console.log(width, height, "canvasimage");
            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height *= maxWidth / width));
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width *= maxHeight / height));
                    height = maxHeight;
                }
            }

            const resizedCanvas = document.createElement("canvas");
            resizedCanvas.width = width;
            resizedCanvas.height = height;
            const resizedCtx = resizedCanvas.getContext("2d");
            resizedCtx.drawImage(canvas, 0, 0, width, height);

            // const format = 'image/png'; // You can adjust the format and quality here
            const format = "image/jpeg";
            const resizedDataUrl = resizedCanvas.toDataURL(format, 0.95); // Adjust quality if needed

            setPersonalDetails({
                ...personalDetails,
                photo: resizedDataUrl,
            });
            setIsEditingPhoto(false);
        }
    };

    const saveEditedPhotoThumb = async () => {
        if (editor) {
            const canvas = editor.getImageScaledToCanvas();
            const ctx = canvas.getContext("2d");
            const maxWidth = 521;
            const maxHeight = 352;

            let width = canvas.width;
            let height = canvas.height;
            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height *= maxWidth / width));
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width *= maxHeight / height));
                    height = maxHeight;
                }
            }

            const resizedCanvas = document.createElement("canvas");
            resizedCanvas.width = width;
            resizedCanvas.height = height;
            const resizedCtx = resizedCanvas.getContext("2d");
            resizedCtx.drawImage(canvas, 0, 0, width, height);

            const format = "image/jpeg";
            const resizedDataUrl = resizedCanvas.toDataURL(format, 0.95);

            setPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                selectedThumbnail: resizedDataUrl,
            }));
            setSelectedImage(resizedDataUrl);
            setIsEditingPhotothumb(false);
        }
    };

    const saveEditedPhotoThumbDraft = async () => {
        if (editor) {
            const canvas = editor.getImageScaledToCanvas();
            const ctx = canvas.getContext("2d");
            const maxWidth = 521;
            const maxHeight = 352;

            let width = canvas.width;
            let height = canvas.height;
            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height *= maxWidth / width));
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width *= maxHeight / height));
                    height = maxHeight;
                }
            }

            const resizedCanvas = document.createElement("canvas");
            resizedCanvas.width = width;
            resizedCanvas.height = height;
            const resizedCtx = resizedCanvas.getContext("2d");
            resizedCtx.drawImage(canvas, 0, 0, width, height);

            const format = "image/jpeg";
            const resizedDataUrl = resizedCanvas.toDataURL(format, 0.95);

            setEditPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                selectedThumbnail: resizedDataUrl,
            }));
            setSelectedImage(resizedDataUrl);
            setIsEditingPhotothumbDraft(false);
        }
    };

    const handlePositionChange = (position) => {
        setPosition(position);
    };
    useEffect(() => {
        if (personalDetails.profileCategories.length === 0) {
            setPersonalDetails((prevDetails) => ({
                ...prevDetails,
                profileCategories: [{ id: 1, profile: null, specializations: [] }],
            }));
        }
    }, [personalDetails.profileCategories.length]);

    useEffect(() => {
        // Fetch photo data on mount
        const fetchPhotoData = async () => {
            try {
                const response = await axios.get(`/api/user/gettingImageDimensions/${user._id}`);
                const { width, height } = response.data;
                setImageWidth(width);
                setImageHeight(height);
            } catch (error) {
                console.error("Error fetching photo data:", error);
            }
        };
        if (user && user._id) {
            fetchPhotoData();
        }
    }, [user]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [hoverText, setHoverText] = useState("Copy to clipboard!");
    const [linkCopied, setLinkCopied] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(resumeData?.PersonalDetails?.videoIntroduction);
        setLinkCopied(true);
        setHoverText("Copied!");
        setShowTooltip(true);
        setTimeout(() => {
            setHoverText("Copy to clipboard!");
            setLinkCopied(false);
            setShowTooltip(false);
        }, 800);
    };

    const tooltipStyles = {
        position: "absolute",
        top: "100%",
        left: "80%",
        transform: "translateX(-50%)",
        marginBottom: "8px",
        padding: "5px 10px",
        background: "#555",
        color: "#fff",
        borderRadius: "4px",
        fontSize: "12px",
        whiteSpace: "nowrap",
        zIndex: 10,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: adds shadow to tooltip
    };
    const [activePage, setActivePage] = useState(1);
    const [activePageDraft, setActivePageDraft] = useState(1);
    // const totalPages =5;
    const [activeTab, setActiveTab] = useState("published");

    const projects = [
        {
            id: 1,
            title: "Text Summarizing Tool to scrape and summarize pubmed medical papers",
            image: MumbaiImage,
        },
        {
            id: 2,
            title: "Airbyte with custom data connectors to manage CRM database",
            image: MumbaiImage,
        },
        {
            id: 3,
            title: "MVP for a software that analyses content from audio",
            image: MumbaiImage,
        },
        {
            id: 4,
            title: "Airbyte with custom data connectors to manage CRM database",
            image: MumbaiImage,
        },
        {
            id: 5,
            title: "Airbyte with custom data connectors to manage CRM databas",
            image: MumbaiImage,
        },
        {
            id: 6,
            title: "Airbyte with custom data connectors to manage CRM databas",
            image: MumbaiImage,
        },
        {
            id: 7,
            title: "Text Summarizing Tool to scrape and summarize pubmed medical papers",
            image: MumbaiImage,
        },
        {
            id: 8,
            title: "Airbyte with custom data connectors to manage CRM database",
            image: MumbaiImage,
        },
        {
            id: 9,
            title: "MVP for a software that analyses content from audio",
            image: MumbaiImage,
        },
        {
            id: 10,
            title: "Airbyte with custom data connectors to manage CRM database",
            image: MumbaiImage,
        },
        {
            id: 11,
            title: "Airbyte with custom data connectors to manage CRM databas",
            image: MumbaiImage,
        },
        {
            id: 12,
            title: "Airbyte with custom data connectors to manage CRM databas",
            image: MumbaiImage,
        },
        {
            id: 13,
            title: "Text Summarizing Tool to scrape and summarize pubmed medical papers",
            image: MumbaiImage,
        },
        {
            id: 14,
            title: "Airbyte with custom data connectors to manage CRM database",
            image: MumbaiImage,
        },
        {
            id: 15,
            title: "MVP for a software that analyses content from audio",
            image: MumbaiImage,
        },
        // { id: 16, title: 'Airbyte with custom data connectors to manage CRM database', image: MumbaiImage },
        // { id: 17, title: 'Airbyte with custom data connectors to manage CRM databas', image: MumbaiImage },
        // { id: 18, title: 'Airbyte with custom data connectors to manage CRM databas', image: MumbaiImage },
        // { id: 19, title: 'Text Summarizing Tool to scrape and summarize pubmed medical papers', image: MumbaiImage },
        // { id: 20, title: 'Airbyte with custom data connectors to manage CRM database', image: MumbaiImage },
        // { id: 21, title: 'MVP for a software that analyses content from audio', image: MumbaiImage },
        // { id: 22, title: 'Airbyte with custom data connectors to manage CRM database', image: MumbaiImage },
        // { id: 23, title: 'Airbyte with custom data connectors to manage CRM databas', image: MumbaiImage },
        // { id: 24, title: 'Airbyte with custom data connectors to manage CRM databas', image: MumbaiImage },
        // { id: 25, title: 'Text Summarizing Tool to scrape and summarize pubmed medical papers', image: MumbaiImage },
        // { id: 26, title: 'Airbyte with custom data connectors to manage CRM database', image: MumbaiImage },
        // { id: 27, title: 'MVP for a software that analyses content from audio', image: MumbaiImage },
        // { id: 28, title: 'Airbyte with custom data connectors to manage CRM database', image: MumbaiImage },
        // { id: 29, title: 'Airbyte with custom data connectors to manage CRM databas', image: MumbaiImage },
        // Add more projects as needed
    ];
    const [portfolio, setPortfolio] = useState([]);
    const [portfolioDraft, setPortfolioDraft] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedProjectthumb, setSelectedProjectthumb] = useState(null);
    const [selectedProjectthumbDraft, setSelectedProjectthumbDraft] = useState(null);
    const [hoveredProject, setHoveredProject] = useState(null);
    const [isReorderModalOpen, setIsReorderModalOpen] = useState(false);
    const [totalPages, settotalpages] = useState(1);
    const fetchPortfolioDetails = async () => {
        try {
            const response = await axios.get(`/api/user/getPortfolioDetails/${user._id}?page=${activePage}`);
            // console.log(response.data.processedPortfolio, "response port");
            settotalpages(response.data.totalPages);
            setPortfolio(response.data.processedPortfolio);
        } catch (error) {
            console.error("Error fetching portfolio details:", error);
            throw error;
        }
    };
    useEffect(() => {
        if (user && user?._id) {
            fetchPortfolioDetails();
        }
    }, [activePage, user]);
    // const projectsPerPage = 3;
    // const totalPages = Math.ceil(projects.length / projectsPerPage);

    // const displayedProjects = projects.slice(
    //   (activePage - 1) * projectsPerPage,
    //   activePage * projectsPerPage
    // );
    // const projectsPerPage = 3;
    // const totalPages = Math.ceil(portfolio.length / projectsPerPage);

    const displayedProjects = portfolio;

    const handleReorderSave = async (newOrder) => {
        // setPortfolio(newOrder);

        setLoader(true);
        try {
            const userId = user._id;
            const response = await axios.post("/api/user/saveReorderPortfolio", {
                userId,
                newOrder,
            });
            // return response.data;
            setPortfolio(newOrder);
            setIsReorderModalOpen(false);
            setLoader(false);
            toast.success(response.data.message);
        } catch (error) {
            console.error("Error saving reordered projects:", error);
            toast.error("Error saving reordered projects");
        }
    };

    const projectsPerPageDraft = 3;
    const totalPagesDraft = Math.ceil(portfolioDraft.length / projectsPerPageDraft);

    const displayedProjectsDraft = portfolioDraft
        ?.slice()
        .reverse()
        ?.slice((activePageDraft - 1) * projectsPerPageDraft, activePageDraft * projectsPerPageDraft);

    const handleProjectClick = async (project) => {
        //console.log(project, "jfkdk");
        setLoading(true);
        setSelectedProject(null);
        await new Promise((resolve) => setTimeout(resolve, 500));
        setSelectedProject(project);
        setLoading(false);
    };

    const [contentItems, setContentItems] = useState([]);
    const [showVideoPreview, setShowVideoPreview] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [showContentIcons, setShowContentIcons] = useState(false);
    const [videos, setVideos] = useState([]);
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);
    const [selectedWebIndex, setSelectedWebIndex] = useState(null);
    const [textBoxes, setTextBoxes] = useState([]);
    const [selectedTextBoxes, setSelectedTextBoxes] = useState(null);

    const [contentItemsFromDetails, setContentItemsFromDetails] = useState([]);
    // const handleImageUpload = (event) => {
    //   const files = event.target.files;
    //   const newImages = [];
    //   for (let i = 0; i < files.length; i++) {
    //     newImages.push(URL.createObjectURL(files[i]));
    //   }
    //   setUploadedImages([...uploadedImages, ...newImages]);
    // };

    // const handleImageUpload = (event) => {
    //   const files = event.target.files;
    //   const newImages = [];
    //   for (let i = 0; i < files.length; i++) {
    //     newImages.push({ type: 'image', src: URL.createObjectURL(files[i]) });
    //   }
    //   setContentItems([...contentItems, ...newImages]);
    // };
    // const handleImageUpload = (event) => {
    //   const files = event.target.files;
    //   const newImages = [];
    //   const newPhotoDataUrls = [];

    //   for (let i = 0; i < files.length; i++) {
    //     const file = files[i];

    //     if (file.size > 100 * 1024) {  // 100KB
    //       // Show error toast message
    //       toast.error("Image size must be 100KB or less.");
    //       continue; // Skip the current file
    //     }

    //     const reader = new FileReader();

    //     reader.onload = (event) => {
    //       const img = new Image();
    //       img.crossOrigin = "anonymous";
    //       img.src = event.target.result;

    //       img.onload = () => {
    //         const canvas = document.createElement('canvas');
    //         const ctx = canvas.getContext('2d');

    //         const maxWidth = 521;
    //         const maxHeight = 352;

    //         let width = img.width;
    //         let height = img.height;

    //         if (width > height) {
    //           if (width > maxWidth) {
    //             height = Math.round((height *= maxWidth / width));
    //             width = maxWidth;
    //           }
    //         } else {
    //           if (height > maxHeight) {
    //             width = Math.round((width *= maxHeight / height));
    //             height = maxHeight;
    //           }
    //         }

    //         canvas.width = width;
    //         canvas.height = height;
    //         ctx.drawImage(img, 0, 0, width, height);

    //         const format = 'image/jpeg';
    //         const resizedDataUrl = canvas.toDataURL(format, 0.95);

    //         // Push the image object to the newImages array
    //         newImages.push({ type: 'image', src: URL.createObjectURL(file) });
    //         // Push the resized Data URL to the newPhotoDataUrls array
    //         newPhotoDataUrls.push(resizedDataUrl);

    //         // Update the state only after all files are processed
    //         if (newPhotoDataUrls.length === files.length) {
    //           setPortfolioDetails((prevDetails) => ({
    //             ...prevDetails,
    //             photo: [...prevDetails.photo, ...newPhotoDataUrls],
    //           }));
    //           // setContentItems((prevItems) => [...prevItems, ...newImages]);
    //           setContentItems((prevItems) => [...prevItems, ...newImages]);
    //         }
    //       };
    //     };

    //     reader.readAsDataURL(file);
    //   }
    // };
    const handleImageUpload = (event) => {
        const files = event.target.files;
        const newImages = [];
        const newPhotoDataUrls = [];

        const processFile = (file) => {
            return new Promise((resolve, reject) => {
                if (file.size > 200 * 1024) {
                    toast.error("Image size must be 200KB or less.");
                    reject(new Error("Image size must be 200KB or less."));
                    return;
                }

                const reader = new FileReader();

                reader.onload = (event) => {
                    const img = new Image();
                    img.crossOrigin = "anonymous";
                    img.src = event.target.result;

                    img.onload = () => {
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");

                        const maxWidth = 521;
                        const maxHeight = 352;

                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > maxWidth) {
                                height = Math.round((height *= maxWidth / width));
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width = Math.round((width *= maxHeight / height));
                                height = maxHeight;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, width, height);

                        const format = "image/jpeg";
                        const resizedDataUrl = canvas.toDataURL(format, 0.8); // Adjusted quality to 0.8

                        // Get the current date and time
                        const timestamp = new Date().toLocaleString();

                        // Add image data with timestamp
                        newImages.push({ type: "image", src: resizedDataUrl, timestamp });
                        newPhotoDataUrls.push(resizedDataUrl);

                        resolve();
                    };
                };

                reader.readAsDataURL(file);
            });
        };

        const processFiles = async (files) => {
            for (const file of files) {
                await processFile(file);
            }
            setPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                photo: [
                    ...prevDetails.photo,
                    ...newPhotoDataUrls.map((url, index) => ({
                        url,
                        timestamp: newImages[index].timestamp,
                    })),
                ],
            }));
            setEditPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                photo: [
                    ...prevDetails.photo,
                    ...newPhotoDataUrls.map((url, index) => ({
                        url,
                        timestamp: newImages[index].timestamp,
                    })),
                ],
            }));
            setContentItems((prevItems) => [...prevItems, ...newImages]);
            setContentItemsFromDetails((prevItems) => [...prevItems, ...newImages]);
        };

        processFiles(Array.from(files));
    };

    const handleImageUploadDraft = (event) => {
        const files = event.target.files;
        const newImages = [];
        const newPhotoDataUrls = [];

        const processFile = (file) => {
            return new Promise((resolve, reject) => {
                if (file.size > 200 * 1024) {
                    toast.error("Image size must be 200KB or less.");
                    reject(new Error("Image size must be 200KB or less."));
                    return;
                }

                const reader = new FileReader();

                reader.onload = (event) => {
                    const img = new Image();
                    img.crossOrigin = "anonymous";
                    img.src = event.target.result;

                    img.onload = () => {
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");

                        const maxWidth = 521;
                        const maxHeight = 352;

                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > maxWidth) {
                                height = Math.round((height *= maxWidth / width));
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width = Math.round((width *= maxHeight / height));
                                height = maxHeight;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, width, height);

                        const format = "image/jpeg";
                        const resizedDataUrl = canvas.toDataURL(format, 0.8); // Adjusted quality to 0.8
                        const timestamp = new Date().toLocaleString();

                        // newImages.push({ type: 'image', src: URL.createObjectURL(file) });
                        newImages.push({ type: "image", src: resizedDataUrl, timestamp });
                        newPhotoDataUrls.push(resizedDataUrl);

                        // console.log("Processed image:", {
                        //   type: "image",
                        //   src: resizedDataUrl,
                        // });
                        resolve();
                    };
                };

                reader.readAsDataURL(file);
            });
        };

        const processFiles = async (files) => {
            for (const file of files) {
                await processFile(file);
            }
            setEditPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                photo: [
                    ...prevDetails.photo,
                    ...newPhotoDataUrls.map((url, index) => ({
                        url,
                        timestamp: newImages[index].timestamp,
                    })),
                ],
            }));
            setContentItemsFromDetails((prevItems) => [...prevItems, ...newImages]);

            // console.log("Final Edit Portfolio Details:", {
            //   ...editPortfolioDetails,
            //   photo: [...editPortfolioDetails.photo, ...newPhotoDataUrls],
            // });
            // console.log("Final Content Items:", [
            //   ...contentItemsFromDetails,
            //   ...newImages,
            // ]);
        };

        processFiles(Array.from(files));
    };

    // const handleImageUpload = (event) => {
    //   const files = event.target.files;
    //   const newImages = [];
    //   const newPhotoDataUrls = [];

    //   for (let i = 0; i < files.length; i++) {
    //     const file = files[i];
    //     const reader = new FileReader();

    //     reader.onload = (event) => {
    //       // Directly push the Data URL to the newPhotoDataUrls array
    //       newPhotoDataUrls.push(event.target.result);

    //       // Push the image object to the newImages array
    //       newImages.push({ type: 'image', src: URL.createObjectURL(file) });

    //       // Update the state only after all files are processed
    //       if (newPhotoDataUrls.length === files.length) {
    //         setPortfolioDetails((prevDetails) => ({
    //           ...prevDetails,
    //           photo: [...prevDetails.photo, ...newPhotoDataUrls],
    //         }));
    //         setContentItems((prevItems) => [...prevItems, ...newImages]);
    //       }
    //     };

    //     reader.readAsDataURL(file);
    //   }
    // };

    const handleDeleteImage = (index) => {
        const newImages = uploadedImages.filter((_, i) => i !== index);
        setUploadedImages(newImages);
        setSelectedImageIndex(null);
    };
    // const handleSelectImage = (index) => {
    //   setSelectedImageIndex(index);
    // };
    const handleAddVideo = (videoUrl) => {
        const timestamp = new Date().toLocaleString(); // Get the current date and time
        const newVideo = { type: "video", src: videoUrl, timestamp }; // Add timestamp to the video object

        setContentItems([...contentItems, newVideo]);

        setPortfolioDetails((prevDetails) => ({
            ...prevDetails,
            videoLink: [
                ...prevDetails.videoLink,
                { url: videoUrl, timestamp }, // Include the timestamp in the videoLink array
            ],
        }));
        setContentItemsFromDetails([...contentItemsFromDetails, { type: "video", src: videoUrl, timestamp }]);
    };

    const handleAddVideoDraft = (videoUrl) => {
        const timestamp = new Date().toLocaleString(); // Get the current date and time
        const newVideo = { type: "video", src: videoUrl, timestamp };
        setContentItemsFromDetails([...contentItemsFromDetails, newVideo]);

        setEditPortfolioDetails((prevDetails) => ({
            ...prevDetails,
            videoLink: [...prevDetails.videoLink, { url: videoUrl, timestamp }],
        }));
    };

    // const handleSelectVideo = (index) => {
    //   console.log(selectedVideoIndex,"videoIndex")
    //   setSelectedVideoIndex(index);
    // };
    const handleDeleteVideo = (index) => {
        const newVideos = videos.filter((_, i) => i !== index);
        setVideos(newVideos);
        setSelectedVideoIndex(null);
    };

    // const handleAddTextBox = () => {
    //   setTextBoxes([...textBoxes, { text: '' }]);
    // };

    const handleAddTextBox = () => {
        const timestamp = new Date().toLocaleString(); // Get the current date and time
        const newTextBox = { type: "text", content: "", heading: "", timestamp };
        setContentItems([...contentItems, newTextBox]);
        // if (!DataSequence?.some(sq=>sq==="text-content")) {
        //   setDataSequence([...DataSequence,"text-content"])
        // }
    };

    const handleAddTextBoxDraft = () => {
        const timestamp = new Date().toLocaleString(); // Get the current date and time
        const newTextBox = { type: "text", content: "", heading: "", timestamp };
        setContentItemsFromDetails([...contentItemsFromDetails, newTextBox]);
    };

    const handleDeleteTextBox = (index) => {
        const updatedTextBoxes = textBoxes.filter((_, i) => i !== index);
        setTextBoxes(updatedTextBoxes);
    };
    // const handleTextBoxChange = (index, value) => {
    //   const updatedTextBoxes = textBoxes.map((box, i) => (i === index ? { text: value } : box));
    //   setTextBoxes(updatedTextBoxes);
    // };
    // const handleTextBoxChange = (index, value) => {
    //   const updatedContentItems = contentItems.map((item, i) =>
    //     i === index && item.type === 'text' ? { ...item, content: value } : item
    //   );
    //   setContentItems(updatedContentItems);
    // };
    const showToast1 = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleTextBoxChange = (index, value, headingValue = "") => {
        if (headingValue && headingValue.length > 200) {
            showToast1("Heading should not exceed 200 characters.");
            return;
        }
        const timestamp = new Date().toLocaleString(); // Get the current date and time

        if (value && value.length > 5000) {
            showToast("Text content should not exceed 5000 characters.");
            return;
        }
        const updatedContentItems = contentItems.map((item, i) =>
            i === index && item.type === "text"
                ? {
                      ...item,
                      content: value,
                      heading: headingValue !== null ? headingValue : item.heading,
                      timestamp: timestamp,
                  }
                : item
        );
        setContentItems(updatedContentItems);

        // console.log(projectContentData, "contenetdsyfst");
        const updatedTextContent = updatedContentItems.map((item) => (item.type === "text" ? { content: item.content, heading: item.heading, timestamp } : null)).filter((item) => item !== null);
        // setContentItems(updatedContentItems);
        setContentItemsFromDetails([...contentItemsFromDetails, updatedTextContent]);
        setPortfolioDetails((prevDetails) => ({
            ...prevDetails,
            textContent: updatedTextContent,
        }));
    };
    const handleTextBoxChangeDraft = (index, value, headingValue = "") => {
        if (headingValue && headingValue.length > 200) {
            showToast1("Heading should not exceed 200 characters.");
            return;
        }
        const timestamp = new Date().toLocaleString(); // Get the current date and time

        if (value && value.length > 5000) {
            showToast("Text content should not exceed 5000 characters.");
            return;
        }
        const updatedContentItems = contentItemsFromDetails.map((item, i) =>
            i === index && item.type === "text"
                ? {
                      ...item,
                      content: value,
                      heading: headingValue !== null ? headingValue : item.heading,
                      timestamp: timestamp,
                  }
                : item
        );

        const updatedTextContent = updatedContentItems.map((item) => (item.type === "text" ? { content: item.content, heading: item.heading, timestamp } : null)).filter((item) => item !== null);
        setContentItemsFromDetails([...contentItemsFromDetails, updatedTextContent]);

        setEditPortfolioDetails((prevDetails) => ({
            ...prevDetails,
            textContent: updatedTextContent,
        }));
    };

    // const handleSelectTextBox = (index) => {
    //   setSelectedTextBoxes(index);
    // };

    // const handleDeleteContentItem = (index) => {
    //   const updatedContentItems = contentItems.filter((_, i) => i !== index);
    //   setContentItems(updatedContentItems);

    //   // const updatedPortfolioPhotos = portfolioDetails.photo.filter((_, i) => i !== index);
    //   // setPortfolioDetails(prevDetails => ({
    //   //   ...prevDetails,
    //   //   photo: updatedPortfolioPhotos
    //   // }));

    //   // const updatedPortfolioVideos = portfolioDetails.videoLink.filter((_, i) => i !== index);
    //   // setPortfolioDetails(prevDetails => ({
    //   //   ...prevDetails,
    //   //   videoLink: updatedPortfolioVideos
    //   // }));

    //   // const updatedTextContent = portfolioDetails.textContent.filter((_, i) => i !== index);
    //   // setPortfolioDetails(prevDetails => ({
    //   //   ...prevDetails,
    //   //   videoLink: updatedTextContent
    //   // }));
    //   const updatedPortfolioPhotos = portfolioDetails.photo.filter((_, i) => i !== index);
    //   const updatedPortfolioVideos = portfolioDetails.videoLink.filter((_, i) => i !== index);
    //   const updatedTextContent = portfolioDetails.textContent.filter((_, i) => i !== index);

    //   setPortfolioDetails(prevDetails => ({
    //     ...prevDetails,
    //     photo: updatedPortfolioPhotos,
    //     videoLink: updatedPortfolioVideos,
    //     textContent: updatedTextContent
    //   }));
    // };
    //   const handleDeleteContentItem = (index) => {
    //     const updatedContentItems = contentItems.filter((_, i) => i !== index);
    //     setContentItems(updatedContentItems);
    //     console.log(contentItems[index].type,"type")
    //     setPortfolioDetails(prevDetails => {
    //       let updatedPhotos = [...prevDetails.photo];
    //       let updatedVideos = [...prevDetails.videoLink];
    //       let updatedTextContent = [...prevDetails.textContent];

    //       if (contentItems[index].type === 'image') {
    //           updatedPhotos = updatedPhotos.filter((_, i) => i !== index);
    //       } else if (contentItems[index].type === 'video') {
    //           updatedVideos = updatedVideos.filter((_, i) => i !== index);
    //       } else if (contentItems[index].type === 'text') {
    //           updatedTextContent = updatedTextContent.filter((_, i) => i !== index);
    //       }

    //       return {
    //           ...prevDetails,
    //           photo: updatedPhotos,
    //           videoLink: updatedVideos,
    //           textContent: updatedTextContent
    //       };
    //   });
    // };
    const handleDeleteContentItem = (index) => {
        const updatedContentItems = contentItems.filter((_, i) => i !== index);
        const itemToDelete = contentItems[index];

        setContentItems(updatedContentItems);
        console.log("handleDelete itemtodelete", itemToDelete, index, contentItems);
        setPortfolioDetails((prevDetails) => {
            let updatedPhotos = [...prevDetails.photo];
            let updatedVideos = [...prevDetails.videoLink];
            let updatedweb = [...prevDetails.webLink];
            let updatedTextContent = [...prevDetails.textContent];

            if (itemToDelete.type === "image") {
                // const photoIndex = prevDetails.photo.indexOf(itemToDelete.src);
                const photoIndex = itemToDelete.timestamp;
                if (photoIndex !== -1) {
                    updatedPhotos = updatedPhotos.filter((_) => _.timestamp !== photoIndex);
                }
            } else if (itemToDelete.type === "video") {
                const videoIndex = itemToDelete.timestamp;
                if (videoIndex !== -1) {
                    updatedVideos = updatedVideos.filter((_) => _.timestamp !== videoIndex);
                }
            } else if (itemToDelete.type === "weblink") {
                const webIndex = itemToDelete.timestamp;
                if (webIndex !== -1) {
                    updatedweb = updatedweb.filter((_) => _.timestamp !== webIndex);
                }
            } else if (itemToDelete.type === "text") {
                const textIndex = itemToDelete.timestamp;
                if (textIndex !== -1) {
                    updatedTextContent = updatedTextContent.filter((_) => _.timestamp !== textIndex);
                }
            }
            console.log("updatedPhotos", updatedPhotos, updatedVideos, updatedweb, updatedTextContent);
            return {
                ...prevDetails,
                photo: updatedPhotos,
                videoLink: updatedVideos,
                webLink: updatedweb,
                textContent: updatedTextContent,
            };
        });
    };
    const [deletedItems, setDeletedItems] = useState([]);
    const handleDeleteContentItemDraft = (index) => {
        const updatedContentItems = contentItemsFromDetails.filter((_, i) => i !== index);
        const itemToDelete = contentItemsFromDetails[index];
        setContentItemsFromDetails(updatedContentItems);
        setDeletedItems((prevDeletedItems) => [...prevDeletedItems, itemToDelete]);
        console.log("handleDelete itemtodelete", itemToDelete, index, contentItemsFromDetails);
        setEditPortfolioDetails((prevDetails) => {
            let updatedPhotos = [...prevDetails.photo];
            let updatedVideos = [...prevDetails.videoLink];
            let updatedWebLink = [...(prevDetails.webLink || [])];
            let updatedTextContent = [...prevDetails.textContent];

            if (itemToDelete.type === "image") {
                const photoIndex = itemToDelete.timestamp;
                if (photoIndex !== -1) {
                    updatedPhotos = updatedPhotos.filter((_) => _.timestamp !== photoIndex);
                }
            } else if (itemToDelete.type === "video") {
                const videoIndex = itemToDelete.timestamp;
                if (videoIndex !== -1) {
                    updatedVideos = updatedVideos.filter((_) => _.timestamp !== videoIndex);
                }
            } else if (itemToDelete.type === "weblink") {
                const webIndex = itemToDelete.timestamp;
                if (webIndex !== -1) {
                    updatedWebLink = updatedWebLink.filter((_) => _.timestamp !== webIndex);
                }
            } else if (itemToDelete.type === "text") {
                const textIndex = itemToDelete.timestamp;
                if (textIndex !== -1) {
                    updatedTextContent = updatedTextContent.filter((_) => _.timestamp !== textIndex);
                }
            }

            return {
                ...prevDetails,
                photo: updatedPhotos,
                videoLink: updatedVideos,
                webLink: updatedWebLink,
                textContent: updatedTextContent,
            };
        });
    };

    // console.log(contentItems, "contentItems");
    const handleToggleContentIcons = () => {
        setShowContentIcons(!showContentIcons);
    };

    const handleMoveContentItem = (index, direction) => {
        const newContentItems = [...contentItems];
        const [movedItem] = newContentItems.splice(index, 1);
        newContentItems.splice(index + direction, 0, movedItem);
        setContentItems(newContentItems);
        console.log("editPortfolioDetails portfolioDetails", editPortfolioDetails, portfolioDetails);
        const firstitem = newContentItems[index];
        const seconditem = newContentItems[index + direction];
        setPortfolioDetails((prevDetails) => {
            let updatedPhotos = [...prevDetails.photo];
            let updatedVideos = [...prevDetails.videoLink];
            let updatedweb = [...prevDetails.webLink];
            let updatedTextContent = [...prevDetails.textContent];
            let firstitemtimestamp = firstitem.timestamp;
            let seconditemtimestamp = seconditem.timestamp;
            if (firstitem.type === "image") {
                // const photoIndex = prevDetails.photo.indexOf(itemToDelete.src);
                const photoIndex = firstitem.timestamp;
                if (photoIndex !== -1) {
                    updatedPhotos = updatedPhotos.map((_) => {
                        if (_.timestamp === photoIndex) {
                            return { ..._, timestamp: seconditemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (firstitem.type === "video") {
                const videoIndex = firstitem.timestamp;
                if (videoIndex !== -1) {
                    updatedVideos = updatedVideos.map((_) => {
                        if (_.timestamp === videoIndex) {
                            return { ..._, timestamp: seconditemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (firstitem.type === "weblink") {
                const webIndex = firstitem.timestamp;
                if (webIndex !== -1) {
                    updatedweb = updatedweb.map((_) => {
                        if (_.timestamp === firstitem.timestamp) {
                            return { ..._, timestamp: seconditemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (firstitem.type === "text") {
                const textIndex = firstitem.timestamp;
                if (textIndex !== -1) {
                    updatedTextContent = updatedTextContent.map((_) => {
                        if (_.timestamp === firstitem.timestamp) {
                            return { ..._, timestamp: seconditemtimestamp };
                        }
                        return _;
                    });
                }
            }
            if (seconditem.type === "image") {
                // const photoIndex = prevDetails.photo.indexOf(itemToDelete.src);
                const photoIndex = firstitem.timestamp;
                if (photoIndex !== -1) {
                    updatedPhotos = updatedPhotos.map((_) => {
                        if (_.timestamp === seconditemtimestamp) {
                            return { ..._, timestamp: firstitemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (seconditem.type === "video") {
                const videoIndex = firstitem.timestamp;
                if (videoIndex !== -1) {
                    updatedVideos = updatedVideos.map((_) => {
                        if (_.timestamp === seconditemtimestamp) {
                            return { ..._, timestamp: firstitemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (seconditem.type === "weblink") {
                const webIndex = firstitem.timestamp;
                if (webIndex !== -1) {
                    updatedweb = updatedweb.map((_) => {
                        if (_.timestamp === seconditemtimestamp) {
                            return { ..._, timestamp: firstitemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (seconditem.type === "text") {
                const textIndex = firstitem.timestamp;
                if (textIndex !== -1) {
                    updatedTextContent = updatedTextContent.map((_) => {
                        if (_.timestamp === seconditemtimestamp) {
                            return { ..._, timestamp: firstitemtimestamp };
                        }
                        return _;
                    });
                }
            }
            console.log("updatedPhotos", updatedPhotos, updatedVideos, updatedweb, updatedTextContent);
            return {
                ...prevDetails,
                photo: updatedPhotos,
                videoLink: updatedVideos,
                webLink: updatedweb,
                textContent: updatedTextContent,
            };
        });
    };

    const handleMoveContentItemDraft = (index, direction) => {
        const newContentItems = [...contentItemsFromDetails];
        const [movedItem] = newContentItems.splice(index, 1);
        newContentItems.splice(index + direction, 0, movedItem);
        setContentItemsFromDetails(newContentItems);
        const firstitem = newContentItems[index];
        const seconditem = newContentItems[index + direction];
        setEditPortfolioDetails((prevDetails) => {
            let updatedPhotos = [...prevDetails.photo];
            let updatedVideos = [...prevDetails.videoLink];
            let updatedweb = [...prevDetails.webLink];
            let updatedTextContent = [...prevDetails.textContent];
            let firstitemtimestamp = firstitem.timestamp;
            let seconditemtimestamp = seconditem.timestamp;
            if (firstitem.type === "image") {
                // const photoIndex = prevDetails.photo.indexOf(itemToDelete.src);
                const photoIndex = firstitem.timestamp;
                if (photoIndex !== -1) {
                    updatedPhotos = updatedPhotos.map((_) => {
                        if (_.timestamp === photoIndex) {
                            return { ..._, timestamp: seconditemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (firstitem.type === "video") {
                const videoIndex = firstitem.timestamp;
                if (videoIndex !== -1) {
                    updatedVideos = updatedVideos.map((_) => {
                        if (_.timestamp === videoIndex) {
                            return { ..._, timestamp: seconditemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (firstitem.type === "weblink") {
                const webIndex = firstitem.timestamp;
                if (webIndex !== -1) {
                    updatedweb = updatedweb.map((_) => {
                        if (_.timestamp === firstitem.timestamp) {
                            return { ..._, timestamp: seconditemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (firstitem.type === "text") {
                const textIndex = firstitem.timestamp;
                if (textIndex !== -1) {
                    updatedTextContent = updatedTextContent.map((_) => {
                        if (_.timestamp === firstitem.timestamp) {
                            return { ..._, timestamp: seconditemtimestamp };
                        }
                        return _;
                    });
                }
            }
            if (seconditem.type === "image") {
                // const photoIndex = prevDetails.photo.indexOf(itemToDelete.src);
                const photoIndex = firstitem.timestamp;
                if (photoIndex !== -1) {
                    updatedPhotos = updatedPhotos.map((_) => {
                        if (_.timestamp === seconditemtimestamp) {
                            return { ..._, timestamp: firstitemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (seconditem.type === "video") {
                const videoIndex = firstitem.timestamp;
                if (videoIndex !== -1) {
                    updatedVideos = updatedVideos.map((_) => {
                        if (_.timestamp === seconditemtimestamp) {
                            return { ..._, timestamp: firstitemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (seconditem.type === "weblink") {
                const webIndex = firstitem.timestamp;
                if (webIndex !== -1) {
                    updatedweb = updatedweb.map((_) => {
                        if (_.timestamp === seconditemtimestamp) {
                            return { ..._, timestamp: firstitemtimestamp };
                        }
                        return _;
                    });
                }
            } else if (seconditem.type === "text") {
                const textIndex = firstitem.timestamp;
                if (textIndex !== -1) {
                    updatedTextContent = updatedTextContent.map((_) => {
                        if (_.timestamp === seconditemtimestamp) {
                            return { ..._, timestamp: firstitemtimestamp };
                        }
                        return _;
                    });
                }
            }
            console.log("updatedPhotos", updatedPhotos, updatedVideos, updatedweb, updatedTextContent);
            return {
                ...prevDetails,
                photo: updatedPhotos,
                videoLink: updatedVideos,
                webLink: updatedweb,
                textContent: updatedTextContent,
            };
        });
    };

    const handleSelectImage = (index) => {
        setSelectedImageIndex(index);
        setSelectedVideoIndex(null);
        setSelectedTextBoxes(null);
        setSelectedWebIndex(null);
    };

    const handleSelectVideo = (index) => {
        setSelectedImageIndex(null);
        setSelectedVideoIndex(index);
        setSelectedTextBoxes(null);
        setSelectedWebIndex(null);
    };

    const handleSelectTextBox = (index) => {
        setSelectedImageIndex(null);
        setSelectedVideoIndex(null);
        setSelectedTextBoxes(index);
        setSelectedWebIndex(null);
    };

    const handleSelectWebLink = (index) => {
        setSelectedImageIndex(null);
        setSelectedVideoIndex(null);
        setSelectedTextBoxes(null);
        setSelectedWebIndex(index);
    };

    const [isMarkdown, setIsMarkdown] = useState(false);
    const [textBoxModes, setTextBoxModes] = useState(contentItems.map(() => ({ isMarkdown: false })));
    const [textBoxModesDraft, setTextBoxModesDraft] = useState(contentItemsFromDetails.map(() => ({ isMarkdown: false })));
    // useEffect(() => {
    //   setTextBoxModes(contentItems.map(() => ({ isMarkdown: false })));
    // }, [contentItems]);
    useEffect(() => {
        setTextBoxModes((prevModes) => {
            const newModes = contentItems.map((_, index) => {
                // Preserve existing mode if already present, otherwise initialize to false
                return prevModes[index] || { isMarkdown: false };
            });
            return newModes;
        });
    }, [contentItems]);
    useEffect(() => {
        setTextBoxModesDraft((prevModes) => {
            const newModes = contentItemsFromDetails.map((_, index) => {
                // Preserve existing mode if already present, otherwise initialize to false
                return prevModes[index] || { isMarkdown: false };
            });
            return newModes;
        });
    }, [contentItemsFromDetails]);

    const toggleTextMode = () => {
        setIsMarkdown(!isMarkdown);
    };
    const [heading, setHeading] = useState("");
    const [text, setText] = useState("");
    const textBoxRef = useRef(null);

    useEffect(() => {
        if (textBoxRef.current) {
            // Adjust the height based on content
            textBoxRef.current.style.height = isMarkdown ? "100px" : "200px";
        }
    }, [isMarkdown]);

    const [link, setLink] = useState("");
    const [previewData, setPreviewData] = useState(null);
    const [error, setError] = useState(false);

    const [link1, setLink1] = useState("");
    const [previewData1, setPreviewData1] = useState(null);
    const [error1, setError1] = useState(false);

    const [link2, setLink2] = useState("");
    const [previewData2, setPreviewData2] = useState(null);
    const [error2, setError2] = useState(false);

    const [link3, setLink3] = useState("");
    const [previewData3, setPreviewData3] = useState(null);
    const [error3, setError3] = useState(false);

    const fetchPreviewData = async () => {
        try {
            setError(false);
            const response = await axios.post("/api/user/gettingwebsiteorarticledata", { url: link });
            setPreviewData(response.data);
        } catch (error) {
            toast.error("Something went wrrong");
            console.error("Error fetching preview data:", error);
            setError(true);
        }
    };

    useEffect(() => {
        const fetchPreviewData1 = async (urls) => {
            try {
                setError1(false);
                const response = await axios.post("/api/user/gettingwebsiteorarticledata2", { urls });
                //console.log("Fetched preview data:", response.data);
                return response.data;
            } catch (error) {
                console.error("Error fetching preview data:", error);
                setError1(true);
                return [];
            }
        };

        const fetchAllPreviewData1 = async () => {
            if (portfolioDetails && portfolioDetails.webLink.length > 0) {
                const previews = await fetchPreviewData1(portfolioDetails.webLink);
                // console.log("Setting preview data:", previews);
                setPreviewData1(previews);
            }
        };

        fetchAllPreviewData1();
    }, [portfolioDetails]);

    useEffect(() => {
        const fetchPreviewData2 = async (urls) => {
            try {
                setError2(false);
                const response = await axios.post("/api/user/gettingwebsiteorarticledata3", { urls });
                // console.log("Fetched preview data:", response.data);
                return response.data;
            } catch (error) {
                console.error("Error fetching preview data:", error);
                setError2(true);
                return [];
            }
        };

        const fetchAllPreviewData2 = async () => {
            if (editPortfolioDetails && editPortfolioDetails.webLink && editPortfolioDetails.webLink?.length > 0) {
                const previews = await fetchPreviewData2(editPortfolioDetails.webLink);
                // console.log("Setting preview data:", previews);
                setPreviewData2(previews);
            }
        };

        fetchAllPreviewData2();
    }, [editPortfolioDetails]);

    useEffect(() => {
        const fetchPreviewData3 = async (urls) => {
            try {
                setError3(false);
                const response = await axios.post("/api/user/gettingwebsiteorarticledata4", { urls });
                //console.log("Fetched preview data:", response.data);
                return response.data;
            } catch (error) {
                console.error("Error fetching preview data:", error);
                setError3(true);
                return [];
            }
        };

        const fetchAllPreviewData3 = async () => {
            if (selectedProject && selectedProject?.webLink?.length > 0) {
                const previews = await fetchPreviewData3(selectedProject.webLink);
                //console.log("Setting preview data:", previews);
                setPreviewData3(previews);
            }
        };

        fetchAllPreviewData3();
    }, [selectedProject]);

    // const handleAddWeblink = (Url) => {
    //   const newlink = { type: 'weblink', src: Url };
    //   setContentItems([...contentItems, newlink]);

    //   setPortfolioDetails(prevDetails => ({
    //     ...prevDetails,
    //     webLink: [...prevDetails.webLink, Url]
    //   }));
    // };

    const handleAddWebLinkDraft = async (Url) => {
        // const newLink = { type: 'weblink', src: Url };
        try {
            // Fetch preview data for the URL
            const response = await axios.post("/api/user/gettingwebsiteorarticledata", { url: Url });
            const previewData = response.data;

            // Create a new link with the preview data
            const newlink = { type: "weblink", src: Url, previewData };

            setContentItemsFromDetails([...contentItemsFromDetails, newlink]);

            setEditPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                // webLink: [...prevDetails.webLink, Url]
                webLink: [...(prevDetails.webLink || []), Url],
            }));

            setLink("");
            setError(false);
        } catch (error) {
            console.error("Error fetching preview data:", error);
            setError(true);
        }
    };

    const handleAddWeblink = async () => {
        try {
            // // Fetch preview data for the URL
            // const response = await axios.post(
            //   "/api/user/gettingwebsiteorarticledata",
            //   { url: Url }
            // );
            // const previewData = response.data;
            console.log(previewData, "preview");

            const timestamp = new Date().toLocaleString(); // Get the current date and time
            // // Create a new link with the preview data
            const newlink = {
                type: "weblink",
                src: previewData?.url,
                previewData,
                timestamp,
            };

            console.log(newlink, "newlink");

            // Update contentItems state
            setContentItems([...contentItems, newlink]);
            // Update portfolioDetails state
            setPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                webLink: [...prevDetails.webLink, { url: previewData?.url, timestamp }],
            }));

            // Clear the input field and any error state
            setLink("");
            setError(false);
            setPreviewData(null);
        } catch (error) {
            console.error("Error fetching preview data:", error);
            setError(true);
        }
    };

    // const fetchLinkPreview = async (url) => {
    //   try {
    //     // Example API request to get link preview data.
    //     // Replace with your preferred service.
    //     const response = await axios.get(`https://api.linkpreview.net/?key=171a31549a405545a34642cd51e92d98&q=${url}`);
    //     setPreviewData(response.data);
    //     console.log(response.data,"preview data")
    //     setError(null);
    //   } catch (err) {
    //     console.error('Error fetching link preview:', err);
    //     setError('Unable to fetch preview data.');
    //     setPreviewData(null);
    //   }
    // };

    // useEffect(() => {
    //   if (link) {
    //     fetchLinkPreview(link);
    //   }
    // }, [link]);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const handleNextPreview = () => {
        //console.log("clicked preview");
        setShowPreviewModal(true);
    };

    const handleBack = () => {
        setShowPreviewModal(false);
    };

    const [selectedImage, setSelectedImage] = useState(null);
    useEffect(() => {
        // Set the default image or video thumbnail on initial render
        if (portfolioDetails.photo.length > 0) {
            setSelectedImage(portfolioDetails.photo[0]);
            setSelectedThumbnail(portfolioDetails.photo[0]);
        } else if (portfolioDetails.videoLink.length > 0) {
            setSelectedImage(getYouTubeThumbnail(portfolioDetails.videoLink[0].url));
            setSelectedThumbnail(getYouTubeThumbnail(portfolioDetails.videoLink[0].url));
        }
    }, []);

    // const handleImageSelect = (imgSrc) => {
    //   setPortfolioDetails(prevDetails => ({
    //     ...prevDetails,
    //     selectedThumbnail: imgSrc
    //   }));
    //   setSelectedImage(imgSrc);
    // };

    const [selectedThumbnail, setSelectedThumbnail] = useState("");

    const handleImageSelect = (imgSrc) => {
        setSelectedThumbnail(imgSrc);
        // const isUrl = imgSrc.startsWith('http');

        // if (isUrl) {
        //   setSelectedImage(imgSrc);
        // }

        // Create a new image element
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = imgSrc.url ? imgSrc.url : imgSrc;

        img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Set the canvas dimensions to a fixed size (e.g., 521x352)
            const maxWidth = 521;
            const maxHeight = 352;

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height *= maxWidth / width));
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width *= maxHeight / height));
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);
            const format = "image/jpeg";
            const resizedDataUrl = canvas.toDataURL(format, 0.8); // Adjust the quality here

            setPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                selectedThumbnail: resizedDataUrl,
            }));
            setSelectedImage(resizedDataUrl);
            console.log(selectedImage, imgSrc, "is this equal");
        };
    };

    const handleImageSelectDraft = (imgSrc) => {
        // Create a new image element
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = imgSrc;

        img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Set the canvas dimensions to a fixed size (e.g., 521x352)
            const maxWidth = 521;
            const maxHeight = 352;

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height *= maxWidth / width));
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width *= maxHeight / height));
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);
            const format = "image/jpeg";
            const resizedDataUrl = canvas.toDataURL(format, 0.8); // Adjust the quality here

            setEditPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                selectedThumbnail: resizedDataUrl,
            }));
            setSelectedImage(resizedDataUrl);
            setSelectedThumbnail(resizedDataUrl);
        };
    };

    const getYouTubeThumbnail = (url) => {
        const urlObj = new URL(url);
        let videoId;

        if (urlObj.hostname === "youtu.be") {
            videoId = urlObj.pathname.substring(1);
        } else if (urlObj.pathname.includes("/shorts/")) {
            videoId = urlObj.pathname.split("/shorts/")[1];
        } else {
            videoId = urlObj.searchParams.get("v");
        }

        return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    };
    const modalRef = useRef(null);
    // const onPublish = async () => {
    //   setLoading(true);
    //   try {
    //     const response = await axios.post('/api/user/postingPortfolioDetails', {
    //       userId: user._id,
    //       portfolioDetails,
    //     });

    //     if (response.status === 200) {
    //       setLoading(false);
    //       toast.success("Added Successfully")

    //       // Close the modals
    //       const thumbnailModal = new window.bootstrap.Modal(document.getElementById('thumbnailmodal'));
    //       const portfolioModal = new window.bootstrap.Modal(document.getElementById('portfoliomodal'));
    //       const videoModal = new window.bootstrap.Modal(document.getElementById('videomodal'));
    //       const articleModal = new window.bootstrap.Modal(document.getElementById('articlemodal'));
    //       const previewModal = new window.bootstrap.Modal(document.getElementById('previewmodal'));

    //       thumbnailModal.hide();
    //       portfolioModal.hide();
    //       videoModal.hide();
    //       articleModal.hide();
    //       previewModal.hide();
    //       resetPortfolioDetails();

    //     } else {
    //       setLoading(false);
    //       toast.error(response.data.message)
    //     }
    //   } catch (error) {
    //     setLoading(false);
    //     console.log(error.message)
    //     alert('Error: ' + error.message);
    //   }
    // };
    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupDelete, setOpenPopupDelete] = useState(false);
    const [openPopupDelete1, setOpenPopupDelete1] = useState(false);
    const onPublish = async () => {
        //alert("called");
        setLoader(true);
        setLoading7(true);
        if (portfolioDetails.selectedThumbnail === "" || portfolioDetails.selectedThumbnail.url === "") {
            toast.error("Select Thumbnail Image");
            setLoader(false);
            setLoading(false);
            return;
        }
        // const chunkSize = 1;
        // const photoBatches = chunkArray(portfolioDetails.photo, chunkSize);

        const uploadBatch = async (batch) => {
            //console.log(dd, "lsdkjfkdsj");

            const response = await axios.post("/api/user/postingPortfolioDetails", {
                userId: user._id,
                portfolioDetails: {
                    ...portfolioDetails,
                    photo: batch,
                    mediaSequence: portfolioDetailsData,
                },
            });

            //console.log(response, "publish response");

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            return response.data;
        };

        try {
            // for (const batch of photoBatches) {
            //     await uploadBatch(batch);
            // }
            // console.log(portfolioDetails);
            if (portfolioDetails.photo.length === 0) {
                // If photo array is empty, still make the API call
                await uploadBatch([]);
            } else {
                // If photo array is not empty, chunk it and make API calls
                const chunkSize = 1; // Set chunk size to 1 for even smaller batches
                const photoBatches = chunkArray(portfolioDetails.photo, chunkSize);
                console.log("photoBatches,portfolioDetails", photoBatches, portfolioDetails);
                // for (const batch of photoBatches) {
                await uploadBatch(portfolioDetails.photo);
                // }
            }

            setLoader(false);
            setLoading7(false);
            toast.success("Added Successfully");

            // Close the modals
            document.querySelector(".btnclosethumbnail").click();
            document.querySelector(".btncloseportfolio").click();
            document.querySelector(".btnclosevideo").click();
            document.querySelector(".btnclosearticle").click();
            document.querySelector(".btnclosepreview").click();
            resetPortfolioDetails();
            fetchPortfolioDetails();
            setOpenPopup(true);
        } catch (error) {
            setLoader(false);
            setLoading7(false);
            // console.log(error.message);
            alert("Error: " + error.message);
        }
    };

    const onPublishEdit = async () => {
        setLoader(true);
        setLoading8(true);
        if (editPortfolioDetails.selectedThumbnail === "" || editPortfolioDetails.selectedThumbnail.url === "") {
            toast.error("Upload Image");
            setLoader(false);
            setLoading8(false);
            return;
        }

        const uploadBatch = async (batch) => {
            const response = await axios.post("/api/user/postingResumePortfolioDetails", {
                userId: user._id,
                projectId: selectedProject._id,
                portfolioDetails: {
                    ...editPortfolioDetails,
                    photo: batch,
                },
            });

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            return response.data;
        };

        try {
            // for (const batch of photoBatches) {
            //     await uploadBatch(batch);
            // }
            // const existingPhotos = editPortfolioDetails.photo.filter(
            //   (photo) => typeof photo === "object" && photo.url
            // );
            // const newPhotos = editPortfolioDetails.photo.filter(
            //   (photo) => typeof photo === "string"
            // );

            // if (portfolioDetails.photo.length === 0) {
            //   // If photo array is empty, still make the API call
            //   await uploadBatch(existingPhotos);
            // } else {
            //   // If photo array is not empty, chunk it and make API calls
            //   const chunkSize = 1; // Set chunk size to 1 for even smaller batches
            //   const photoBatches = chunkArray(newPhotos, chunkSize);

            //   for (const batch of photoBatches) {
            //     await uploadBatch(editPortfolioDetails.photo);
            //   }
            // }
            uploadBatch(editPortfolioDetails.photo);
            setLoading8(false);
            setLoader(false);
            toast.success("Added Successfully");

            // Close the modals
            document.querySelector(".btnclosethumbnaildraft").click();
            document.querySelector(".btncloseeditport").click();
            document.querySelector(".btnclosevideo").click();
            document.querySelector(".btnclosearticle").click();
            document.querySelector(".btnclosepreviewdraft").click();
            resetEditPortfolioDetails();
            setActiveTab("published");
            setOpenPopup(true);
        } catch (error) {
            setLoading8(false);
            setLoader(false);
            //console.log(error.message);
            alert("Error: " + error.message);
        }
    };

    const onPublishFromDraft = async () => {
        setLoader(true);
        setLoading8(true);
        if (editPortfolioDetails.selectedThumbnail === "" || editPortfolioDetails.selectedThumbnail.url === "") {
            toast.error("Upload Image");
            setLoader(false);
            setLoading8(false);
            return;
        }

        const uploadBatch = async (batch) => {
            const response = await axios.post("/api/user/postingPortfolioDetails", {
                userId: user._id,
                portfolioDetails: {
                    ...editPortfolioDetails,
                    photo: batch,
                },
            });

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            return response.data;
        };

        try {
            // for (const batch of photoBatches) {
            //     await uploadBatch(batch);
            // }

            if (editPortfolioDetails.photo.length === 0) {
                // If photo array is empty, still make the API call
                await uploadBatch([]);
            } else {
                // If photo array is not empty, chunk it and make API calls
                const chunkSize = 1; // Set chunk size to 1 for even smaller batches
                const photoBatches = chunkArray(editPortfolioDetails.photo, chunkSize);

                for (const batch of photoBatches) {
                    await uploadBatch(batch);
                }
            }

            setLoading8(false);
            setLoader(false);
            toast.success("Added Successfully");

            // Close the modals
            document.querySelector(".btnclosethumbnaildraft").click();
            document.querySelector(".btncloseeditport").click();
            document.querySelector(".btnclosevideodraft").click();
            document.querySelector(".btnclosearticle").click();
            document.querySelector(".btnclosepreviewdraft").click();
            resetEditPortfolioDetails();
            setActiveTab("published");

            setOpenPopup(true);
        } catch (error) {
            setLoading8(false);
            setLoader(false);
            //console.log(error.message);
            alert("Error: " + error.message);
        }
    };

    const [charCount, setCharCount] = useState({
        projectTitle: 0,
        yourRole: 0,
        projectDescription: 0,
    });

    const [showPreviewModal1, setShowPreviewModal1] = useState(false);
    const handlePreviewClick = () => {
        const { projectTitle, yourRole, projectDescription, skills } = portfolioDetails;
        // working place console.log(contentItems, "contentItems");
        if (!projectTitle) {
            showToast("Project title is empty");
            return;
        }
        if (!projectDescription) {
            showToast("Project description is empty");
            return;
        }
        if (!skills.length) {
            showToast("Skills are empty");
            return;
        }
        if (!contentItems.length) {
            showToast("Please select at least one content item");
            setShowPreviewModal1(false);
            return;
        }
        // console.log(
        //   generateDataInSequence(DataSequence, portfolioDetails),
        //   "project content data sq"
        // );

        // console.log(previewData1, "data1");

        setPortfolioDetailsData(generateDataInSequence(DataSequence, portfolioDetails, previewData1));
        // console.log(contentItems, "contents data");
        // console.log(
        //   generateDataInSequence(DataSequence, portfolioDetails, contentItems),
        //   "details"
        // );
        setShowPreviewModal1(true);
        // Manually trigger the modal opening
        const previewModal = new window.bootstrap.Modal(document.getElementById("previewmodal"));
        // console.log(portfolioDetailsData, "sjkhdjshhsdf");
        previewModal.show();
    };

    const handlePreviewClickDraft = () => {
        const { projectTitle, yourRole, projectDescription, skills } = editPortfolioDetails;
        if (!projectTitle) {
            showToast("Project title is empty");
            return;
        }
        if (!projectDescription) {
            showToast("Project description is empty");
            return;
        }
        if (!skills.length) {
            showToast("Skills are empty");
            return;
        }
        if (!contentItemsFromDetails.length) {
            showToast("Please select at least one content item");
            setShowPreviewModal1(false);
            return;
        }
        setShowPreviewModal1(true);
        console.log("editportfoliodetails", editPortfolioDetails);
        // Manually trigger the modal opening
        const previewModal = new window.bootstrap.Modal(document.getElementById("previewmodaldraft"));
        previewModal.show();
    };

    const showToast = (message) => {
        toast.error(message);
    };

    const handleImageUploadforThumnail = (event) => {
        // const file = event.target.files[0];
        // if (file) {
        //     const reader = new FileReader();
        //     reader.onload = () => {
        //         const imageUrl = reader.result;
        //         setSelectedImage(imageUrl);
        //         setPortfolioDetails(prevDetails => ({
        //             ...prevDetails,
        //             selectedThumbnail: imageUrl
        //         }));
        //     };
        //     reader.readAsDataURL(file);
        // }
        const file = event.target.files[0];
        if (file) {
            if (file.size > 200 * 1024) {
                toast.error("Image size must be 200KB or less.");
                return;
            }
            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();
                img.crossOrigin = "anonymous";
                img.src = event.target.result;

                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    const maxWidth = 521;
                    const maxHeight = 352;

                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height = Math.round((height *= maxWidth / width));
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width = Math.round((width *= maxHeight / height));
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);

                    const format = "image/jpeg";
                    const resizedDataUrl = canvas.toDataURL(format, 0.95);

                    setSelectedImage(resizedDataUrl);
                    setPortfolioDetails((prevDetails) => ({
                        ...prevDetails,
                        selectedThumbnail: resizedDataUrl,
                    }));
                };
            };

            reader.readAsDataURL(file);
        }
    };
    const [uploadedImagesthumb, setUploadedImagesthumb] = useState([]);
    const handleImageUploadforThumnail2 = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 200 * 1024) {
                toast.error("Image size must be 200KB or less.");
                return;
            }
            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();
                img.crossOrigin = "anonymous";
                img.src = event.target.result;

                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    const maxWidth = 521;
                    const maxHeight = 352;

                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height = Math.round((height *= maxWidth / width));
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width = Math.round((width *= maxHeight / height));
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);

                    const format = "image/jpeg";
                    const resizedDataUrl = canvas.toDataURL(format, 0.95);

                    // setPortfolioDetails(prevDetails => ({
                    //     ...prevDetails,
                    //     photo: [...prevDetails.photo, resizedDataUrl],
                    // }));
                    setUploadedImagesthumb((prevImages) => [resizedDataUrl, ...prevImages]);
                };
            };

            reader.readAsDataURL(file);
        }
    };

    const handleSaveThumb = async () => {
        setLoading(true);

        try {
            const response = await axios.post("/api/user/updatingThumbnailPort", {
                userId: user._id,
                projectId: selectedProjectthumb._id,
                selectedThumbnail: selectedImage,
            });
            setLoading(false);
            toast.success(response.data.message);
            setUploadedImagesthumb([]);
            setSelectedImage(null);
            document.querySelector(".btnclosethumbnailchange").click();
        } catch (error) {
            setLoading(false);
            console.error("Error updating thumbnail", error);
        } finally {
            setLoading(false);
        }
    };
    const handleSaveThumbDraft = async () => {
        setLoading(true);

        try {
            const response = await axios.post("/api/user/updatingThumbnailPortDraft", {
                userId: user._id,
                projectId: selectedProjectthumbDraft.projectId,
                selectedThumbnail: selectedImage,
            });
            setLoading(false);
            toast.success(response.data.message);
            setUploadedImagesthumb([]);
            setSelectedImage(null);
            document.querySelector(".btnclosethumbnailchangedraft").click();
        } catch (error) {
            setLoading(false);
            console.error("Error updating thumbnail", error);
        } finally {
            setLoading(false);
        }
    };
    const [projectIdMain, setProjectIdMain] = useState(null);
    // const [editPortfolioDetails, setEditPortfolioDetails] = useState(null);

    const handleSaveAsDraft = async () => {
        setLoading5(true);
        const { projectTitle, yourRole, projectDescription, skills } = portfolioDetails;
        if (!projectTitle) {
            showToast("Project title is empty");
            setLoading5(false);
            return;
        }
        if (!projectDescription) {
            showToast("Project description is empty");
            setLoading5(false);
            return;
        }
        if (!skills.length) {
            showToast("Skills are empty");
            setLoading5(false);
            return;
        }
        if (!contentItems.length) {
            showToast("Please select at least one content item");
            setLoading5(false);
            return;
        }
        // let projectId = editPortfolioDetails.projectId;
        // let newProjectId = uuidv4();
        let newProjectId = uuidv4();
        setProjectIdMain(newProjectId);
        // let projectId  = uuidv4();;
        // editPortfolioDetails.projectId = projectId;
        console.log("Draft", portfolioDetails);
        editPortfolioDetails.projectId = newProjectId;
        const uploadBatch = async (batch) => {
            const response = await axios.post("/api/user/postingDraftPortfolioDetails", {
                userId: user._id,
                projectId: newProjectId,
                portfolioDetails: {
                    ...portfolioDetails,
                    photo: batch,
                    mediaSequence: portfolioDetailsData,
                },
            });

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            return response.data;
        };

        try {
            if (editPortfolioDetails.photo.length === 0) {
                await uploadBatch([]);
            } else {
                const chunkSize = 1;
                const photoBatches = chunkArray(editPortfolioDetails.photo, chunkSize);

                for (const batch of photoBatches) {
                    await uploadBatch(batch);
                }
            }
            const response = await axios.get(`/api/user/getDraftPortfolioDetails/${user._id}/${newProjectId}`);
            if (response.data.processedPortfolio.length > 0) {
                // console.log(response.data.processedPortfolio, "response from edit ");
                setEditPortfolioDetails(response.data.processedPortfolio[0]);
            }
            setLoading5(false);
            const editModal = document.querySelector("#editPortfolioModal");
            if (editModal) {
                editModal.classList.add("show");
                editModal.style.display = "block";
                editModal.setAttribute("aria-modal", true);
            }
            toast.success("Draft saved succesfully!");

            document.querySelector(".btnclosethumbnail").click();
            document.querySelector(".btncloseportfolio").click();
            document.querySelector(".btnclosevideo").click();
            document.querySelector(".btnclosearticle").click();
            document.querySelector(".btnclosepreview").click();
        } catch (error) {
            setLoading5(false);
            console.log(error.message);
        }
    };
    const handleSaveAsDraftForEdit = async () => {
        setLoading6(true);

        const { projectTitle, yourRole, projectDescription, skills } = editPortfolioDetails;
        if (!projectTitle) {
            showToast("Project title is empty");
            setLoading6(false);
            return;
        }
        if (!projectDescription) {
            showToast("Project description is empty");
            setLoading6(false);
            return;
        }
        if (!skills.length) {
            showToast("Skills are empty");
            setLoading6(false);
            return;
        }
        if (!contentItemsFromDetails.length) {
            showToast("Please select at least one content item");
            setLoading6(false);
            return;
        }
        const projectId = selectedProject?.projectId || selectedProject?._id || projectIdMain;

        // console.log("Determined Project ID:", projectId);
        const uploadBatch = async (batch) => {
            const response = await axios.post("/api/user/postingDraftPortfolioDetails", {
                userId: user._id,
                // projectId: projectIdMain,
                projectId: projectId,
                portfolioDetails: {
                    ...editPortfolioDetails,
                    photo: batch,
                },
            });

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            return response.data;
        };

        try {
            // if (editPortfolioDetails.photo.length === 0) {
            //   await uploadBatch([]);
            // } else {
            //   const chunkSize = 1;
            //   const photoBatches = chunkArray(editPortfolioDetails.photo, chunkSize);

            //   for (const batch of photoBatches) {
            //     await uploadBatch(batch);
            //   }
            // }
            const existingPhotos = editPortfolioDetails.photo.filter((photo) => typeof photo === "object" && photo.url && photo.public_id);
            const newPhotos = editPortfolioDetails.photo.filter((photo) => typeof photo === "string");

            if (newPhotos.length === 0) {
                await uploadBatch(existingPhotos);
            } else {
                const chunkSize = 1; // You can adjust the chunk size as needed
                const photoBatches = chunkArray(newPhotos, chunkSize);

                for (const batch of photoBatches) {
                    await uploadBatch([...existingPhotos, ...batch]);
                }
            }

            const response = await axios.get(`/api/user/getDraftPortfolioDetails/${user._id}/${projectId}`);
            if (response.data.processedPortfolio.length > 0) {
                //console.log(response.data.processedPortfolio, "response from edit ");
                setEditPortfolioDetails(response.data.processedPortfolio[0]);
            }
            setLoading6(false);
            const editModal = document.querySelector("#editPortfolioModal");
            if (editModal) {
                editModal.classList.add("show");
                editModal.style.display = "block";
                editModal.setAttribute("aria-modal", true);
            }
            toast.success("Draft saved succesfully!");

            document.querySelector(".btnclosethumbnail").click();
            document.querySelector(".btncloseportfolio").click();
            document.querySelector(".btnclosevideo").click();
            document.querySelector(".btnclosearticle").click();
            document.querySelector(".btnclosepreview").click();
        } catch (error) {
            setLoading6(false);
            console.log(error.message);
        }
    };

    //console.log(editPortfolioDetails, "editPortfolioDetails");

    // useEffect(() => {
    //   if (editPortfolioDetails) {
    //     console.log('editPortfolioDetails useEffect:', editPortfolioDetails);
    //     const newContentItems = [];

    //     // // Map photo items to contentItemsFromDetails
    //     // editPortfolioDetails?.photo?.forEach((item) => {
    //     //   // newContentItems.push({ type: 'image', src: item.url, public_id: item.public_id, _id: item._id });
    //     //     if (item.url && item.public_id && item._id) {
    //     //       newContentItems.push({ type: 'image', src: item.url, public_id: item.public_id, _id: item._id });
    //     //   } else {
    //     //       newContentItems.push({ type: 'image', src: item });
    //     //   }
    //     // });

    //     // // Map videoLink items to contentItemsFromDetails
    //     // editPortfolioDetails?.videoLink?.forEach((item) => {
    //     //   newContentItems.push({ type: 'video', src: item });
    //     // });

    //     // // Map textContent items to contentItemsFromDetails
    //     // editPortfolioDetails?.textContent?.forEach((item) => {
    //     //   newContentItems.push({ type: 'text', content: item.content, heading: item.heading });
    //     // });
    //      // Check if photo is an array before calling forEach
    //   if (Array.isArray(editPortfolioDetails.photo)) {
    //     editPortfolioDetails.photo.forEach((item) => {
    //       if (item.url && item.public_id && item._id) {
    //         newContentItems.push({ type: 'image', src: item.url, public_id: item.public_id, _id: item._id });
    //       } else {
    //         newContentItems.push({ type: 'image', src: item });
    //       }
    //     });
    //   }

    //   // Check if videoLink is an array before calling forEach
    //   if (Array.isArray(editPortfolioDetails.videoLink)) {
    //     editPortfolioDetails.videoLink.forEach((item) => {
    //       newContentItems.push({ type: 'video', src: item });
    //     });
    //   }

    //   if (Array.isArray(editPortfolioDetails.webLink)) {
    //     editPortfolioDetails.webLink.forEach((item) => {
    //       newContentItems.push({ type: 'weblink', src: item });
    //     });
    //   }

    //   // Check if textContent is an array before calling forEach
    //   if (Array.isArray(editPortfolioDetails.textContent)) {
    //     editPortfolioDetails.textContent.forEach((item) => {
    //       newContentItems.push({ type: 'text', content: item.content, heading: item.heading });
    //     });
    //   }

    //     // setContentItemsFromDetails(newContentItems);
    //     const filteredContentItems = newContentItems.filter(item =>
    //       !deletedItems.some(deletedItem =>
    //         deletedItem.type === item.type &&
    //         ((item.type === 'image' && deletedItem.src === item.src) ||
    //          (item.type === 'video' && deletedItem.src === item.src) ||
    //          (item.type === 'weblink' && deletedItem.src === item.src) ||
    //          (item.type === 'text' && deletedItem.content === item.content))
    //       )
    //     );

    //     console.log('newContentItems useEffect:', newContentItems);
    //     setContentItemsFromDetails(filteredContentItems);

    //   }
    // }, [editPortfolioDetails, deletedItems]);

    useEffect(() => {
        const fetchAllPreviewData = async () => {
            if (editPortfolioDetails) {
                const newContentItems = [];
                // console.log("editPortfolioDetails",editPortfolioDetails);
                // Process photos
                if (Array.isArray(editPortfolioDetails.photo)) {
                    editPortfolioDetails.photo.forEach((item) => {
                        if (item.url && item.public_id && item._id) {
                            newContentItems.push({
                                type: "image",
                                src: item.url,
                                public_id: item.public_id,
                                timestamp: item.timestamp ? item.timestamp : "",
                                _id: item._id,
                            });
                        } else {
                            newContentItems.push({
                                type: "image",
                                src: item,
                                timestamp: item.timestamp ? item.timestamp : "",
                            });
                        }
                    });
                }

                // Process video links
                if (Array.isArray(editPortfolioDetails.videoLink)) {
                    editPortfolioDetails.videoLink.forEach((item) => {
                        newContentItems.push({
                            type: "video",
                            src: item,
                            timestamp: item.timestamp ? item.timestamp : "",
                        });
                    });
                }

                // Process web links
                if (Array.isArray(editPortfolioDetails.webLink)) {
                    const webLinkPromises = editPortfolioDetails.webLink.map(async (item) => {
                        try {
                            const response = await axios.post("/api/user/gettingwebsiteorarticledata", { url: item.url });
                            const previewData = response.data;
                            return {
                                type: "weblink",
                                src: item,
                                previewData,
                                timestamp: item.timestamp ? item.timestamp : "",
                            };
                        } catch (error) {
                            console.error("Error fetching preview data for web link:", item, error);
                            return {
                                type: "weblink",
                                src: item,
                                previewData: null,
                                timestamp: item.timestamp ? item.timestamp : "",
                            };
                        }
                    });

                    const webLinks = await Promise.all(webLinkPromises);
                    newContentItems.push(...webLinks);
                }

                // Process text content
                if (Array.isArray(editPortfolioDetails.textContent)) {
                    editPortfolioDetails.textContent.forEach((item) => {
                        newContentItems.push({
                            type: "text",
                            content: item.content,
                            heading: item.heading,
                            timestamp: item.timestamp ? item.timestamp : "",
                        });
                    });
                }

                // Filter out deleted items
                const filteredContentItems = newContentItems.filter((item) => !deletedItems.some((deletedItem) => deletedItem.type === item.type && ((item.type === "image" && deletedItem.src === item.src) || (item.type === "video" && deletedItem.src === item.src) || (item.type === "weblink" && deletedItem.src === item.src) || (item.type === "text" && deletedItem.content === item.content))));
                filteredContentItems.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                console.log("filteredContentItems", filteredContentItems, editPortfolioDetails);
                setContentItemsFromDetails(filteredContentItems);
            }
        };

        fetchAllPreviewData();
    }, [editPortfolioDetails, deletedItems]);

    //console.log(contentItemsFromDetails, "contentItemsFromDetails");

    useEffect(() => {
        const fetchPortfolioDetailsDraft = async () => {
            try {
                const response = await axios.get(`/api/user/getDraftPortfolioDetailsAll/${user._id}`);
                setPortfolioDraft(response.data.processedPortfolio);
            } catch (error) {
                console.error("Error fetching portfolio details:", error);
                throw error;
            }
        };
        if (resumeData) {
            fetchPortfolioDetailsDraft();
        }
    }, [editPortfolioDetails, user, resumeData, loader]);

    useEffect(() => {
        if (selectedProject) {
            setEditPortfolioDetails({
                projectTitle: selectedProject.projectTitle || "",
                yourRole: selectedProject.yourRole || "",
                projectDescription: selectedProject.projectDescription || "",
                skills: selectedProject.skills || [],
                photo: selectedProject.photo || [],
                videoLink: selectedProject.videoLink || [],
                webLink: selectedProject.webLink || [],
                textContent: selectedProject.textContent || [],
                selectedThumbnail: selectedProject.selectedThumbnail || "",
            });
        }
    }, [selectedProject]);

    const handleDeleteProject = async () => {
        setLoader(true);
        try {
            const response = await axios.post("/api/user/deletePortfolio", {
                userId: user._id,
                projectId: selectedProject._id,
            });

            if (response.status === 200) {
                toast.success("Project deleted successfully!");

                setOpenPopupDelete(false);
                setLoader(false);
            } else {
                setLoading(false);
                toast.error("Failed to delete project");
            }
        } catch (error) {
            setLoader(false);
            toast.error("An error occurred. Please try again.");
        }
    };
    const handleDeleteProject1 = async () => {
        setLoader(true);
        try {
            const response = await axios.post("/api/user/deletePortfolioDraft", {
                userId: user._id,
                projectId: selectedProject.projectId,
            });

            if (response.status === 200) {
                toast.success("Draft deleted successfully!");

                setOpenPopupDelete1(false);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error("Failed to delete Draft");
            }
        } catch (error) {
            setLoader(false);
            toast.error("An error occurred. Please try again.");
        }
    };
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleIconMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleIconMouseLeave = () => {
        setDropdownVisible(false);
    };

    const handleMoveToDraft = async (project) => {
        setLoader(true);
        try {
            const response = await axios.post("/api/user/movePortfoliotoDraft", {
                userId: user._id,
                projectId: project._id,
            });

            if (response.status === 200) {
                toast.success(response.data.message);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            toast.error("Something went wrong");
        }
    };

    // const [charCount1, setCharCount1] = useState({
    //   projectTitle: 0,
    //   yourRole: 0,
    //   projectDescription: 0,
    // });
    const [charCount1, setCharCount1] = useState({
        projectTitle: editPortfolioDetails.projectTitle.length,
        yourRole: editPortfolioDetails.yourRole.length,
        projectDescription: editPortfolioDetails.projectDescription.length,
    });

    useEffect(() => {
        setCharCount1({
            projectTitle: editPortfolioDetails.projectTitle.length,
            yourRole: editPortfolioDetails.yourRole.length,
            projectDescription: editPortfolioDetails.projectDescription.length,
        });
    }, [editPortfolioDetails]);

    const [selectedSkills10, setSelectedSkills10] = useState(editPortfolioDetails.skills || []);
    useEffect(() => {
        setSelectedSkills10(editPortfolioDetails.skills);
    }, [editPortfolioDetails.skills]);

    const handleSkillsChange2 = (selectedOptions) => {
        if (selectedOptions.length <= 10) {
            setSelectedSkills10(selectedOptions);
            setEditPortfolioDetails((prevDetails) => ({
                ...prevDetails,
                skills: selectedOptions,
            }));
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const modalDialog = document.querySelector("#previewmodalresume .unique-modal-dialog");
            if (modalDialog) {
                if (window.innerWidth < 540) {
                    modalDialog.classList.remove("modal-dialog-centered");
                } else {
                    modalDialog.classList.add("modal-dialog-centered");
                }
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isLoggedIn = localStorage.getItem("userType") === "User" && localStorage.getItem("token");

    // console.log(selectedProject, "selectedProject");

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddressDetails({ ...addressDetails, [name]: value });
    };
    const handlePhoneChange = (value) => {
        setAddressDetails({ ...addressDetails, phone: value });
    };

    //console.log(displayedProjects, "this is  displayedProjects");
    //console.log(resumeData, "this is  totalPages");
    //console.log(resumeData?.AddressDetail);
    //console.log(resumeData, "resume data");
    //console.log(user, "user");

    return (
        <>
            <div className="editResumeBg pt-2" style={{ backgroundcolor: "white" }}>
                <div onClick={goBack} className="ResumeBackBtn">
                    <IoArrowBackSharp style={{ marginRight: "8px", fontSize: "17px" }} />
                    Back
                </div>

                {loader ? (
                    <>
                        <div className="" style={{ textAlign: "center", paddingBottom: "220px" }}>
                            <p
                                style={{
                                    color: "#4B92C8",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    marginLeft: "43px",
                                    marginBottom: "5px",
                                    marginTop: "220px",
                                }}
                            >
                                Loading..
                            </p>
                            <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "27px" }} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="text-center mt-4 mb-2">
                            <p style={{ fontSize: "30px", fontWeight: "500" }}>Profile</p>
                        </div>

                        <div className=" container pb-5 " style={{ padding: window.innerWidth <= 768 ? "0px" : "3rem", margin: "auto" }}>
                            <div ref={pdfRef} className="InvoiceDownload section31 my-4 " style={{ letterSpacing: "0.3px" }}>
                                <div class="card-body">
                                    <div class="container">
                                        {resumeData !== null ? (
                                            <>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                                                        <div>
                                                            <div className="d-flex" style={{ alignItems: "center", gap: "10px" }}>
                                                                <p
                                                                    style={{
                                                                        fontSize: "32px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    {resumeData?.PersonalDetails?.firstName} {resumeData?.PersonalDetails?.lastName}
                                                                </p>
                                                                <RxPencil1
                                                                    className="mx-2 resumePeronalDetailpencilicon"
                                                                    style={{
                                                                        color: "#2092c7",
                                                                        cursor: "pointer",
                                                                        fontSize: "25px",
                                                                        marginTop: "-3px",
                                                                    }}
                                                                    onClick={() => {
                                                                        editPersonalDetails(resumeData?.PersonalDetails);
                                                                    }}
                                                                    aria-hidden="true"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#personalDetails"
                                                                />
                                                            </div>
                                                            <p
                                                                style={{
                                                                    marginTop: "-13px",
                                                                    fontSize: "18px",
                                                                    color: "black",
                                                                }}
                                                            >
                                                                <b>{resumeData?.PersonalDetails?.professionalTitle}</b>
                                                            </p>
                                                            {resumeData?.PersonalDetails?.minimumRate && (
                                                                <p
                                                                    style={{
                                                                        marginTop: "-8px",
                                                                        fontSize: "17px",
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    <b>$</b>
                                                                    {/* <b>{resumeData.PersonalDetails.minimumRate}</b> */}
                                                                    <b>
                                                                        {resumeData?.PersonalDetails?.minimumRate}
                                                                        {/* {
                                  // Check if the minimumRate is a number and whether it includes a decimal point
                                  resumeData.PersonalDetails.minimumRate %
                                    1 !==
                                  0
                                    ? resumeData.PersonalDetails.minimumRate
                                    : `${resumeData.PersonalDetails.minimumRate}.00`
                                } */}
                                                                    </b>
                                                                    <b>/hour</b>
                                                                </p>
                                                            )}
                                                        </div>
                                                        {/* <RxPencil1 className= "mx-2 resumePeronalDetailpencilicon" style={{ color: "gray", cursor: 'pointer', fontSize: "25px", marginTop: '-3px' }} onClick={() => { editPersonalDetails(resumeData.PersonalDetails) }} aria-hidden="true" data-bs-toggle="modal" data-bs-target="#personalDetails" /> */}
                                                    </div>
                                                    {/* <span style={{fontSize: "15px",  cursor: 'pointer', color: "#2092c7" }}    onClick={downloadPDF}  className="aplied2 float-right">
              <FiDownload style={{ fontSize: "20px", marginTop: '-3px'}}/> Download
             </span>*/}
                                                    {resumeData?.PersonalDetails?.photo?.url !== "" && (
                                                        <div className="phototodisplayindesktop">
                                                            <img
                                                                src={resumeData?.PersonalDetails?.photo?.url}
                                                                alt="Profile"
                                                                // style={{ width: '131px',height:'137px', objectFit: 'cover', borderRadius:'50%', border:'2px solid gray'}}
                                                                className=""
                                                                style={{
                                                                    boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                                    // border: "4px solid white",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    borderRadius: "100%",
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                <div style={{ color: "gray" }}>
                                                    <div
                                                        style={{
                                                            marginTop: "-6px",
                                                            justifyContent: "center",
                                                        }}
                                                        className="phototodisplayinmobile"
                                                    >
                                                        {resumeData?.PersonalDetails?.photo?.url !== "" && <img src={resumeData?.PersonalDetails?.photo.url} alt="Profile" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "100%", objectFit: "cover" }} />}
                                                    </div>
                                                    <p
                                                        style={{
                                                            marginTop: "0px",
                                                            fontSize: "17px",
                                                            color: "black",
                                                        }}
                                                    >
                                                        {resumeData?.PersonalDetails?.email}
                                                    </p>
                                                    <p
                                                        style={{
                                                            marginTop: "-6px",
                                                            fontSize: "17px",
                                                            color: "black",
                                                        }}
                                                    >
                                                        {resumeData?.PersonalDetails?.phone}
                                                    </p>
                                                    <p
                                                        style={{
                                                            marginTop: "-6px",
                                                            fontSize: "17px",
                                                            color: "black",
                                                        }}
                                                    >
                                                        {resumeData?.PersonalDetails?.address}
                                                    </p>
                                                    {resumeData?.PersonalDetails?.videoIntroduction && (
                                                        <>
                                                            <p
                                                                style={{
                                                                    fontWeight: "500",
                                                                    marginTop: "20px",
                                                                    fontSize: "22px",
                                                                    color: "black",
                                                                }}
                                                            >
                                                                Video Introduction
                                                            </p>
                                                            {/* <p style={{ marginTop: '-6px', fontSize: "17px",}}><a href={resumeData.PersonalDetails.videoIntroduction} target='__blank' style={{textDecoration:'none', display:'flex', gap:'10px',alignItems:'center'}}>
                {'Your Video Introduction'} <FaLink />
                </a></p> */}

                                                            <p
                                                                style={{
                                                                    marginTop: "-6px",
                                                                    fontSize: "17px",
                                                                    display: "flex",
                                                                }}
                                                            >
                                                                <a
                                                                    href={resumeData?.PersonalDetails?.videoIntroduction}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{
                                                                        textDecoration: "none",
                                                                        display: "flex",
                                                                        gap: "10px",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {"Your Video Introduction"}
                                                                </a>
                                                                <span
                                                                    onClick={handleCopyToClipboard}
                                                                    onMouseEnter={() => {
                                                                        if (!linkCopied) setHoverText("Copy to clipboard!");
                                                                        setShowTooltip(true); // Show tooltip on hover
                                                                    }}
                                                                    onMouseLeave={() => setShowTooltip(false)} // Hide tooltip when mouse leaves
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: "5px",
                                                                        marginLeft: "10px",
                                                                        position: "relative",
                                                                        color: "#2092c7",
                                                                    }}
                                                                >
                                                                    <MdContentCopy />
                                                                    {showTooltip && <div style={tooltipStyles}>{hoverText}</div>}
                                                                </span>
                                                            </p>
                                                            <div className="video-wrapperresumevideo" style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden", width: "100%" }}>
                                                                <iframe
                                                                    src={getYouTubeEmbedUrl(resumeData?.PersonalDetails?.videoIntroduction)}
                                                                    title="Video Introduction"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: "100%",
                                                                        height: "100%",
                                                                    }}
                                                                ></iframe>
                                                            </div>
                                                        </>
                                                    )}
                                                    {/* <div style={{ marginTop: '-6px', fontSize: "15px", color: "#6E6E6E" , display:'flex', alignItems:'baseline', gap:'10px'}}>
                <p style={{fontWeight:'bold'}}>
                  Minimum rate ($/hour): 
                </p>
                <p style={{ marginTop: '-6px', fontSize: "15px", color: "#6E6E6E" }}>{resumeData.PersonalDetails.minimumRate}</p>
                </div> */}
                                                    {/* displaying profile category name and its specialization */}
                                                    {/* code start here */}
                                                    {resumeData?.PersonalDetails?.profileCategories && (
                                                        <p
                                                            style={{
                                                                fontWeight: "500",
                                                                marginTop: "18px",
                                                                fontSize: "22px",
                                                                color: "black",
                                                            }}
                                                        >
                                                            Profile Category
                                                        </p>
                                                    )}
                                                    {resumeData?.PersonalDetails?.profileCategories.map((category, index) => (
                                                        <div key={category.id} style={{ marginTop: "10px" }}>
                                                            <p
                                                                style={{
                                                                    fontSize: "17px",
                                                                    color: "black",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {category?.profile}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexWrap: "wrap",
                                                                }}
                                                            >
                                                                {category?.specializations?.map((spec, specIndex) => (
                                                                    <span key={specIndex} className="post1234forresume mr-4 mt-3" style={{ marginBottom: "10px" }}>
                                                                        {spec}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* code end here */}

                                                    {/* {
                  resumeData.PersonalDetails?.profileSummary &&
                  <div style={{ marginTop: '10px', fontSize: "15px",color: "black"}}>
                    <p style={{fontWeight:'500', marginTop: '18px', fontSize: "22px",color:'black'}}>
                      Profile Summary
                    </p>
                    <p style={{ marginTop: '-6px', fontSize: "17px",color: "black" }}>{resumeData.PersonalDetails.profileSummary}</p>
                  </div>

                } */}
                                                    {resumeData?.PersonalDetails?.profileSummary && (
                                                        <div
                                                            style={{
                                                                marginTop: "10px",
                                                                fontSize: "15px",
                                                                color: "black",
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    fontWeight: "500",
                                                                    marginTop: "18px",
                                                                    fontSize: "22px",
                                                                    color: "black",
                                                                }}
                                                            >
                                                                Profile Summary
                                                            </p>
                                                            <p
                                                                style={{
                                                                    marginTop: "-6px",
                                                                    fontSize: "17px",
                                                                    color: "black",
                                                                }}
                                                            >
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: isExpanded ? resumeData?.PersonalDetails?.profileSummary?.replace(/\n/g, "<br />") : resumeData?.PersonalDetails?.profileSummary.slice(0, 330).replace(/\n/g, "<br />"),
                                                                    }}
                                                                />
                                                                {/* {isExpanded ? resumeData.PersonalDetails.profileSummary : resumeData.PersonalDetails.profileSummary.slice(0, 600)} */}
                                                                {resumeData?.PersonalDetails?.profileSummary.length > 330 && (
                                                                    <>
                                                                        {/* <span
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ color: '#2092c7', cursor: 'pointer', marginLeft: '5px' ,textDecoration:'underline'}}
      >
        {isExpanded ? 'less' : '...more'}
      </span> */}
                                                                        {!isExpanded && <span>...</span>}
                                                                        <span
                                                                            onClick={() => setIsExpanded(!isExpanded)}
                                                                            style={{
                                                                                color: "#2092c7",
                                                                                cursor: "pointer",
                                                                                textDecoration: "underline",
                                                                            }}
                                                                        >
                                                                            <br />
                                                                            {isExpanded ? "less" : "more"}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {resumeData?.PersonalDetails?.availability && (
                                                        <div
                                                            style={{
                                                                marginTop: "10px",
                                                                fontSize: "15px",
                                                                color: "black",
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    fontWeight: "500",
                                                                    marginTop: "18px",
                                                                    fontSize: "22px",
                                                                    color: "black",
                                                                }}
                                                            >
                                                                Availability
                                                            </p>
                                                            <p
                                                                style={{
                                                                    marginTop: "-6px",
                                                                    fontSize: "17px",
                                                                    color: "black",
                                                                }}
                                                            >
                                                                {resumeData?.PersonalDetails?.availability}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div class="row">
                                                    <div class="col-3" style={{ minWidth: "250px" }}>
                                                        <p
                                                            style={{
                                                                color: "black",
                                                                fontSize: "22px",
                                                                letterSpacing: "0.5px",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            PERSONAL DETAILS
                                                        </p>
                                                    </div>
                                                    <div class="col-7">
                                                        <p class="btn p-0 " data-bs-toggle="modal" data-bs-target="#personalDetails" style={{ color: "#0196C5", fontSize: "15px" }}>
                                                            + Add Personal Details
                                                        </p>
                                                    </div>
                                                    <div class="col-2"></div>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <hr className="dropdown-divider1 mb-4 mt-5" style={{ height: "3.05px", color: "#000" }} />

                                    {/* Portfolio */}
                                    <div className="container" style={{ padding: "25px" }}>
                                        <div className="">
                                            <div className="" style={{ minWidth: "150px" }}>
                                                <p
                                                    style={{
                                                        color: "black",
                                                        fontSize: "22px",
                                                        fontWeight: "500",
                                                        marginLeft: "-8px",
                                                    }}
                                                >
                                                    PROJECT PORTFOLIO
                                                </p>
                                            </div>

                                            <div className="mobile-column-to-increase-width" style={{ marginLeft: "-8px" }}>
                                                <div className="portfolio-tabs">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            marginBottom: "15px",
                                                        }}
                                                    >
                                                        <ul className="nav nav-tabs tabs-portfolio">
                                                            <li className="nav-item item-portfolio">
                                                                <a
                                                                    className={`nav-link ${activeTab === "published" ? "link-portfolioactive" : "link-portfolio"}`}
                                                                    href="#published"
                                                                    data-toggle="tab"
                                                                    onClick={() => setActiveTab("published")}
                                                                    style={
                                                                        activeTab === "published"
                                                                            ? {
                                                                                  color: "#2092c7",
                                                                                  fontWeight: "bold",
                                                                                  border: "none",
                                                                                  borderBottom: "3px solid #2092c7",
                                                                              }
                                                                            : {}
                                                                    }
                                                                >
                                                                    Published
                                                                </a>
                                                            </li>
                                                            <li className="nav-item item-portfolio">
                                                                <a
                                                                    className={`nav-link ${activeTab === "drafts" ? "link-portfolioactive" : "link-portfolio"}`}
                                                                    href="#drafts"
                                                                    data-toggle="tab"
                                                                    onClick={() => setActiveTab("drafts")}
                                                                    style={
                                                                        activeTab === "drafts"
                                                                            ? {
                                                                                  color: "#2092c7",
                                                                                  fontWeight: "bold",
                                                                                  border: "none",
                                                                                  borderBottom: "3px solid #2092c7",
                                                                              }
                                                                            : {}
                                                                    }
                                                                >
                                                                    Drafts
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div style={{ display: "flex", gap: "8px" }}>
                                                            {user && (user.resume || resumeId) ? (
                                                                <IoIosAddCircleOutline
                                                                    style={{
                                                                        color: "#2092c7",
                                                                        fontSize: "35px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#portfoliomodal"
                                                                />
                                                            ) : (
                                                                <IoIosAddCircleOutline
                                                                    style={{
                                                                        color: "#2092c7",
                                                                        fontSize: "35px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        toast.error("Add personal details first!!");
                                                                    }}
                                                                />
                                                            )}
                                                            {activeTab === "published" && (
                                                                <BiSortAlt2
                                                                    style={{
                                                                        color: "#2092c7",
                                                                        fontSize: "35px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => setIsReorderModalOpen(true)}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="tab-content">
                                                        <div id="published" className="tab-pane active">
                                                            <div className="row">
                                                                {displayedProjects?.map((project) => (
                                                                    <div
                                                                        className="col-md-4"
                                                                        key={project?.id}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            positon: "relative",
                                                                        }}
                                                                        onMouseEnter={() => setHoveredProject(project?._id)}
                                                                        onMouseLeave={() => setHoveredProject(null)}
                                                                    >
                                                                        <div className="card portcard">
                                                                            {/* <img className="card-img-top"  src={project.selectedThumbnail.url} alt={`Project ${project._id}`} /> */}
                                                                            <img className={`card-img-top project-image ${hoveredProject === project?._id ? "dim-image" : ""}`} src={project?.selectedThumbnail?.url} alt={`Project ${project._id}`} />
                                                                            <div className="card-body">
                                                                                <p
                                                                                    className="card-text project-title"
                                                                                    style={{ color: "#2092c7" }}
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#previewmodalresume"
                                                                                    onClick={() => {
                                                                                        setSelectedProject(project);
                                                                                        console.log(project, "ppp");
                                                                                        // let prev = generateDataInSequence(
                                                                                        //   project?.mediaSequence,
                                                                                        //   project
                                                                                        // );
                                                                                        setPortfolioDetailsData(project?.mediaSequence);
                                                                                        // console.log(prev, "preview data");
                                                                                    }}
                                                                                >
                                                                                    {project?.projectTitle}
                                                                                </p>
                                                                            </div>
                                                                            {console.log("sdfs", project)}
                                                                            {hoveredProject === project?._id && (
                                                                                <RxPencil1
                                                                                    className="edit-project-draft"
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: "50px",
                                                                                        right: "120px",
                                                                                    }}
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#editPortfolioModal"
                                                                                    // onClick={() => setSelectedProject(project)}

                                                                                    onClick={() => {
                                                                                        setSelectedProject(project);
                                                                                        setEditPortfolioDetails({
                                                                                            projectTitle: project.projectTitle,
                                                                                            yourRole: project.yourRole,
                                                                                            projectDescription: project.projectDescription,
                                                                                            skills: project.skills,
                                                                                            photo: project.photo,
                                                                                            videoLink: project.videoLink,
                                                                                            webLink: project.webLink,
                                                                                            textContent: project.textContent,
                                                                                            selectedThumbnail: project.selectedThumbnail,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )}

                                                                            {/* {hoveredProject === project._id && (
                                          <RiDeleteBinLine
                                            className="delete-project-draft"
                                            style={{ position: 'absolute', top: '50px', right: '80px' }}
                                            onClick={() => {
                                              setSelectedProject(project);
                                              setOpenPopupDelete(true);
                                            }}
                                          />
                                        )} */}

                                                                            {hoveredProject === project._id && (
                                                                                <div
                                                                                    onMouseEnter={handleIconMouseEnter}
                                                                                    onMouseLeave={handleIconMouseLeave}
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: "50px",
                                                                                        right: "80px",
                                                                                    }}
                                                                                >
                                                                                    <IoSettingsOutline className="edit-project-draft" />
                                                                                    {dropdownVisible && (
                                                                                        <div className="dropdown-menu10">
                                                                                            <div
                                                                                                className="dropdown-item10"
                                                                                                onClick={() => {
                                                                                                    setSelectedProject(project);
                                                                                                    handleMoveToDraft(project);
                                                                                                }}
                                                                                            >
                                                                                                Move to draft
                                                                                            </div>
                                                                                            <div
                                                                                                className="dropdown-item10"
                                                                                                onClick={() => {
                                                                                                    setSelectedProject(project);
                                                                                                    setOpenPopupDelete(true);
                                                                                                }}
                                                                                            >
                                                                                                {/* <RiDeleteBinLine className="delete-project-draft" /> */}
                                                                                                Delete
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}

                                                                            {hoveredProject === project?._id && (
                                                                                <button
                                                                                    className="change-thumbnail-button"
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        top: "95px",
                                                                                        right: "45px",
                                                                                    }}
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#thumbnailmodalchange"
                                                                                    onClick={(e) => {
                                                                                        console.log(project, "pppppp");
                                                                                        setSelectedProjectthumb(project);
                                                                                    }}
                                                                                >
                                                                                    Change Thumbnail
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {/* custom paginATION */}
                                                            <div>{totalPages !== 0 && <Pagination totalPages={totalPages} currentPage={activePage} onPageChange={setActivePage} />}</div>
                                                            {/* <nav aria-label="Page navigation">
                            <ul className="pagination pagination-navigation">
                              <li
                                className={`page-item ${
                                  activePage === 1 ? "disabled" : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((prev) =>
                                      Math.max(prev - 1, 1)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === 1 ? "gray" : "#2092c7",
                                    pointerEvents:
                                      activePage === 1 ? "none" : "auto",
                                    cursor:
                                      activePage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <IoIosArrowBack />
                                </div>
                              </li>
                              <li
                                className={`page-item ${
                                  activePage === 1 ? "disabled" : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((prev) =>
                                      Math.max(prev - 1, 1)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === 1 ? "gray" : "#2092c7",
                                    pointerEvents:
                                      activePage === 1 ? "none" : "auto",
                                    cursor:
                                      activePage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Previous
                                </div>
                              </li>
                              
                              {[...Array(totalPages)].map((_, page) => (
                                <li
                                  key={page}
                                  className="page-item"
                                  onClick={() => setActivePage(page + 1)}
                                >
                                  <div
                                    className="page-link"
                                    style={{
                                      backgroundColor:
                                        activePage === page + 1
                                          ? "#2092c7"
                                          : "transparent",
                                      color:
                                        activePage === page + 1
                                          ? "white"
                                          : "#2092c7",
                                      borderRadius: "50%",
                                      height: "25px",
                                      width: "25px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {page + 1}
                                  </div>
                                </li>
                              ))}
                              <li
                                className={`page-item ${
                                  activePage === totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((next) =>
                                      Math.min(next + 1, totalPages)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === totalPages
                                        ? "gray"
                                        : "#2092c7",
                                    pointerEvents:
                                      activePage === totalPages
                                        ? "none"
                                        : "auto",
                                    cursor:
                                      activePage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Next
                                </div>
                              </li>
                              <li
                                className={`page-item ${
                                  activePage === totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((next) =>
                                      Math.min(next + 1, totalPages)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === totalPages
                                        ? "gray"
                                        : "#2092c7",
                                    pointerEvents:
                                      activePage === totalPages
                                        ? "none"
                                        : "auto",
                                    cursor:
                                      activePage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <IoIosArrowForward />
                                </div>
                              </li>
                            </ul>
                          </nav> */}
                                                        </div>

                                                        <div id="drafts" className="tab-pane">
                                                            {/* <div className="no-drafts">
                            <img src={BriefCase} alt="No Drafts" />
                            <p>No drafts yet. When you <a href="#" style={{ color: "#2092c7" }}>create a new project</a>, you can now save as you go.</p>
                          </div> */}
                                                            {displayedProjectsDraft?.length === 0 ? (
                                                                <div className="no-drafts">
                                                                    <img src={BriefCase} alt="No Drafts" />
                                                                    <p>
                                                                        No drafts yet. When you{" "}
                                                                        <a href="" style={{ color: "#2092c7" }} data-bs-toggle="modal"
                                                                    data-bs-target="#portfoliomodal">
                                                                            create a new project
                                                                        </a>
                                                                        , you can now save as you go.
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <div className="row">
                                                                    {displayedProjectsDraft?.map((project) => (
                                                                        <div
                                                                            className="col-md-4"
                                                                            key={project.id}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                position: "relative",
                                                                            }}
                                                                            onMouseEnter={() => setHoveredProject(project?.projectId)}
                                                                            onMouseLeave={() => setHoveredProject(null)}
                                                                        >
                                                                            <div className="card portcard">
                                                                                {project?.selectedThumbnail?.url ? (
                                                                                    <img className={`card-img-top project-image ${hoveredProject === project.projectId ? "dim-image" : ""}`} src={project?.selectedThumbnail?.url} alt={`Project ${project.projectId}`} />
                                                                                ) : (
                                                                                    <div
                                                                                        className={`card-img-top project-image ${hoveredProject === project?.projectId ? "dim-image" : ""}`}
                                                                                        style={{
                                                                                            backgroundColor: "#f9f9f9",
                                                                                        }}
                                                                                    ></div>
                                                                                )}
                                                                                {/* <img
                                      className={`card-img-top project-image ${hoveredProject === project.projectId ? 'dim-image' : ''}`}
                                      src={project.selectedThumbnail.url}
                                      alt={`Project ${project.projectId}`}
                                    /> */}
                                                                                <div className="card-body">
                                                                                    <p className="card-text project-title" style={{ color: "#2092c7" }} data-bs-toggle="modal" data-bs-target="#previewmodalresume" onClick={() => setSelectedProject(project)}>
                                                                                        {project?.projectTitle}
                                                                                    </p>
                                                                                </div>
                                                                                {hoveredProject === project?.projectId && (
                                                                                    <RxPencil1
                                                                                        className="edit-project-draft"
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: "50px",
                                                                                            right: "120px",
                                                                                        }}
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#editPortfolioModal"
                                                                                        // onClick={() => setSelectedProject(project)}
                                                                                        onClick={() => {
                                                                                            console.log("project", project);
                                                                                            setSelectedImage(project.selectedThumbnail?.url);
                                                                                            setSelectedProject(project);
                                                                                            setEditPortfolioDetails({
                                                                                                projectTitle: project.projectTitle,
                                                                                                yourRole: project.yourRole,
                                                                                                projectDescription: project.projectDescription,
                                                                                                skills: project.skills,
                                                                                                photo: project.photo,
                                                                                                videoLink: project.videoLink,
                                                                                                webLink: project.webLink,
                                                                                                textContent: project.textContent,
                                                                                                selectedThumbnail: project.selectedThumbnail,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                {hoveredProject === project.projectId && (
                                                                                    <RiDeleteBinLine
                                                                                        className="delete-project-draft"
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: "50px",
                                                                                            right: "80px",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            setSelectedProject(project);
                                                                                            setOpenPopupDelete1(true);
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                {hoveredProject === project.projectId && (
                                                                                    <button
                                                                                        className="change-thumbnail-button"
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: "95px",
                                                                                            right: "45px",
                                                                                        }}
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#thumbnailmodalchangedraft"
                                                                                        onClick={(e) => {
                                                                                            setSelectedProjectthumbDraft(project);
                                                                                        }}
                                                                                    >
                                                                                        Change Thumbnail
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    <div>{totalPagesDraft !== 0 && <Pagination totalPages={totalPagesDraft} currentPage={activePageDraft} onPageChange={setActivePageDraft} />}</div>
                                                                    {/* <nav aria-label="Page navigation">
                                <ul className="pagination pagination-navigation">
                                  <li
                                    className={`page-item ${
                                      activePageDraft === 1
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className="page-link"
                                      onClick={() =>
                                        setActivePage((prev) =>
                                          Math.max(prev - 1, 1)
                                        )
                                      }
                                      style={{
                                        borderRadius: "50%",
                                        color:
                                          activePageDraft === 1
                                            ? "gray"
                                            : "#2092c7",
                                        pointerEvents:
                                          activePageDraft === 1
                                            ? "none"
                                            : "auto",
                                        cursor:
                                          activePageDraft === 1
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <IoIosArrowBack />
                                    </div>
                                  </li>
                                  <li
                                    className={`page-item ${
                                      activePageDraft === 1
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className="page-link"
                                      onClick={() =>
                                        setActivePageDraft((prev) =>
                                          Math.max(prev - 1, 1)
                                        )
                                      }
                                      style={{
                                        borderRadius: "50%",
                                        color:
                                          activePageDraft === 1
                                            ? "gray"
                                            : "#2092c7",
                                        pointerEvents:
                                          activePageDraft === 1
                                            ? "none"
                                            : "auto",
                                        cursor:
                                          activePageDraft === 1
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      Previous
                                    </div>
                                  </li>
                                  {[...Array(totalPagesDraft)].map(
                                    (_, page) => (
                                      <li
                                        key={page}
                                        className="page-item"
                                        onClick={() =>
                                          setActivePageDraft(page + 1)
                                        }
                                      >
                                        <div
                                          className="page-link"
                                          style={{
                                            backgroundColor:
                                              activePageDraft === page + 1
                                                ? "#2092c7"
                                                : "transparent",
                                            color:
                                              activePageDraft === page + 1
                                                ? "white"
                                                : "#2092c7",
                                            borderRadius: "50%",
                                            height: "25px",
                                            width: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {page + 1}
                                        </div>
                                      </li>
                                    )
                                  )}
                                  <li
                                    className={`page-item ${
                                      activePageDraft === totalPagesDraft
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className="page-link"
                                      onClick={() =>
                                        setActivePageDraft((next) =>
                                          Math.min(
                                            next + 1,
                                            totalPagesDraft
                                          )
                                        )
                                      }
                                      style={{
                                        borderRadius: "50%",
                                        color:
                                          activePageDraft ===
                                          totalPagesDraft
                                            ? "gray"
                                            : "#2092c7",
                                        pointerEvents:
                                          activePageDraft ===
                                          totalPagesDraft
                                            ? "none"
                                            : "auto",
                                        cursor:
                                          activePageDraft ===
                                          totalPagesDraft
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      Next
                                    </div>
                                  </li>
                                  <li
                                    className={`page-item ${
                                      activePageDraft === totalPagesDraft
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className="page-link"
                                      onClick={() =>
                                        setActivePageDraft((next) =>
                                          Math.min(
                                            next + 1,
                                            totalPagesDraft
                                          )
                                        )
                                      }
                                      style={{
                                        borderRadius: "50%",
                                        color:
                                          activePageDraft ===
                                          totalPagesDraft
                                            ? "gray"
                                            : "#2092c7",
                                        pointerEvents:
                                          activePageDraft ===
                                          totalPagesDraft
                                            ? "none"
                                            : "auto",
                                        cursor:
                                          activePageDraft ===
                                          totalPagesDraft
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <IoIosArrowForward />
                                    </div>
                                  </li>
                                </ul>
                              </nav> */}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="dropdown-divider1 mb-4 " style={{ height: "3.05px", color: "#000" }} />

                                    <div class="container" style={{ padding: "25px" }}>
                                        <div class="row">
                                            <div class="col-3" style={{ minWidth: "150px" }}>
                                                <p
                                                    style={{
                                                        color: "black",
                                                        fontSize: "22px",
                                                        letterSpacing: "0.5px",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    EDUCATION
                                                </p>
                                            </div>
                                            <div class="col-9 mobile-column-to-increase-width">
                                                {resumeData && resumeData.Education ? (
                                                    <>
                                                        {resumeData.Education.map((data) => {
                                                            return (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-10 col-7">
                                                                            <div
                                                                                style={{
                                                                                    marginBottom: "30px",
                                                                                    color: "#4A4E50",
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: "15px",
                                                                                        color: "#404040",
                                                                                    }}
                                                                                >
                                                                                    <b>{data.degree}</b>
                                                                                </p>
                                                                                <div
                                                                                    style={{
                                                                                        color: "#6E6E6E",
                                                                                        fontSize: "15px",
                                                                                        marginTop: "-8px",
                                                                                    }}
                                                                                >
                                                                                    <p>{data.school}</p>
                                                                                    {data.specialization ? <p style={{ marginTop: "-8px" }}>({data.specialization})</p> : ""}
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.startDate.slice(0, 4)} - {data.endDate.slice(0, 4)}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.performanceScale}: {data.performance}%
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2 col-5">
                                                                            <div className="mobile-flex-container">
                                                                                <RxPencil1
                                                                                    class=" mr-4"
                                                                                    style={{
                                                                                        color: "#2092c7",
                                                                                        fontSize: "23px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        editEducation(data);
                                                                                    }}
                                                                                    aria-hidden="true"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#education"
                                                                                />
                                                                                <RiDeleteBinLine
                                                                                    className="ml-2"
                                                                                    style={{
                                                                                        color: "#646464",
                                                                                        fontSize: "21px",
                                                                                        cursor: "pointer",
                                                                                        marginTop: "-2px",
                                                                                    }}
                                                                                    aria-hidden="true"
                                                                                    onClick={() => {
                                                                                        deleteEducation(data._id);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {resumeData && resumeData.Education && resumeData.Education.length >= 4 ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {user && (user.resume || resumeId) ? (
                                                            <>
                                                                <p
                                                                    class="btn p-0 "
                                                                    onClick={() => {
                                                                        addEducation();
                                                                    }}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#education"
                                                                    style={{ color: "#0196C5", fontSize: "15px" }}
                                                                >
                                                                    + Add education
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p
                                                                    class="btn p-0 "
                                                                    onClick={() => {
                                                                        toast.error("Add personal details first!!");
                                                                    }}
                                                                    style={{ color: "#0196C5", fontSize: "15px" }}
                                                                >
                                                                    + Add education
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="dropdown-divider1 mb-4 mt-3" style={{ height: "0.05px", color: "rgb(185, 185, 185)" }} />

                                    <div class="container" style={{ padding: "25px" }}>
                                        <div class="row">
                                            <div class="col-3" style={{ minWidth: "150px" }}>
                                                <p
                                                    style={{
                                                        color: "black",
                                                        fontSize: "22px",
                                                        letterSpacing: "0.5px",
                                                        fontWeight: "500",
                                                        lineHeight: "24px",
                                                    }}
                                                >
                                                    WORK EXPERIENCE {resumeData && (resumeData.Job || resumeData.Internship) && calculateTotalExperience(resumeData.Job, resumeData.Internship)}
                                                </p>
                                            </div>
                                            <div class="col-9 mobile-column-to-increase-width">
                                                {resumeData && resumeData.Job ? (
                                                    <>
                                                        {resumeData.Job.map((data) => {
                                                            return (
                                                                <>
                                                                    <div className="row">
                                                                        {/* <div className="col-10"> */}
                                                                        <div className="col-md-10 col-7">
                                                                            <div style={{ marginBottom: "30px" }}>
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: "15px",
                                                                                        color: "#404040",
                                                                                    }}
                                                                                >
                                                                                    <b>{data.profile} </b>
                                                                                </p>
                                                                                <div
                                                                                    style={{
                                                                                        color: "#6E6E6E",
                                                                                        fontSize: "15px",
                                                                                        marginTop: "-8px",
                                                                                    }}
                                                                                >
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.organization}, {data.location}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        Job <RxDotFilled style={{ color: "#464545" }} /> {MONTH_DETAILS[parseInt(data.startDate.slice(5, 7), 10)] + " " + data.startDate.slice(0, 4)} - {MONTH_DETAILS[parseInt(data.endDate.slice(5, 7), 10)] + " " + data.endDate.slice(0, 4)} {<MonthCalculator startDate={data.startDate} endDate={data.endDate} />}
                                                                                    </p>
                                                                                    {/* <p style={{ marginTop: "-8px" }}>
                                          {addEllipsis(
                                            data?.description,
                                            370
                                          )}
                                        </p> */}

                                                                                    <SeeMoreLess text={data?.description} maxLength={350} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col-2" > */}
                                                                        <div className="col-md-2 col-5">
                                                                            <div className="mobile-flex-container">
                                                                                <RxPencil1
                                                                                    class=" mr-4"
                                                                                    style={{
                                                                                        color: "#2092c7",
                                                                                        fontSize: "23px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        editJob(data);
                                                                                    }}
                                                                                    aria-hidden="true"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#job"
                                                                                />
                                                                                <RiDeleteBinLine
                                                                                    className="ml-2"
                                                                                    style={{
                                                                                        color: "#646464",
                                                                                        fontSize: "21px",
                                                                                        cursor: "pointer",
                                                                                        marginTop: "-2px",
                                                                                    }}
                                                                                    aria-hidden="true"
                                                                                    onClick={() => {
                                                                                        deleteJob(data._id);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {resumeData && resumeData.Internship ? (
                                                    <>
                                                        {resumeData.Internship.map((data) => {
                                                            return (
                                                                <>
                                                                    <div className="row">
                                                                        {/* <div className="col-10"> */}
                                                                        <div className="col-md-10 col-7">
                                                                            <div
                                                                                style={{
                                                                                    marginBottom: "30px",
                                                                                    color: "#2C2C2C",
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: "15px",
                                                                                        color: "#404040",
                                                                                    }}
                                                                                >
                                                                                    <b>{data.profile} </b>
                                                                                </p>
                                                                                <div
                                                                                    style={{
                                                                                        color: "#6E6E6E",
                                                                                        fontSize: "15px",
                                                                                        marginTop: "-8px",
                                                                                    }}
                                                                                >
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.organization}, {data.location}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        Internship <RxDotFilled style={{ color: "#464545" }} /> {MONTH_DETAILS[parseInt(data.startDate.slice(5, 7), 10)] + " " + data.startDate.slice(0, 4)} - {MONTH_DETAILS[parseInt(data.endDate.slice(5, 7), 10)] + " " + data.endDate.slice(0, 4)} {<MonthCalculator startDate={data.startDate} endDate={data.endDate} />}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>{data.description}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col-2" > */}
                                                                        <div className="col-md-2 col-5">
                                                                            <div className="mobile-flex-container">
                                                                                <RxPencil1
                                                                                    class=" mr-4"
                                                                                    style={{
                                                                                        color: "#2092c7",
                                                                                        fontSize: "23px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        editInternship(data);
                                                                                    }}
                                                                                    aria-hidden="true"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#internship"
                                                                                />
                                                                                <RiDeleteBinLine
                                                                                    className="ml-2"
                                                                                    style={{
                                                                                        color: "#646464",
                                                                                        fontSize: "21px",
                                                                                        cursor: "pointer",
                                                                                        marginTop: "-2px",
                                                                                    }}
                                                                                    aria-hidden="true"
                                                                                    onClick={() => {
                                                                                        deleteInternship(data._id);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        gap: "30px",
                                                    }}
                                                >
                                                    {resumeData && resumeData.Job && resumeData.Job.length >= 3 ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            {user && (user.resume || resumeId) ? (
                                                                <>
                                                                    <p
                                                                        class="btn p-0 "
                                                                        onClick={() => {
                                                                            addJob();
                                                                        }}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#job"
                                                                        style={{
                                                                            color: "#0196C5",
                                                                            fontSize: "15px",
                                                                        }}
                                                                    >
                                                                        + Add job
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p
                                                                        class="btn p-0 "
                                                                        onClick={() => {
                                                                            toast.error("Add personal details first!!");
                                                                        }}
                                                                        style={{
                                                                            color: "#0196C5",
                                                                            fontSize: "15px",
                                                                        }}
                                                                    >
                                                                        + Add job
                                                                    </p>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    {resumeData && resumeData.Internship && resumeData.Internship.length >= 3 ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            {user && (user.resume || resumeId) ? (
                                                                <>
                                                                    <p
                                                                        class="btn p-0 "
                                                                        onClick={() => {
                                                                            addInternship();
                                                                        }}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#internship"
                                                                        style={{
                                                                            color: "#0196C5",
                                                                            fontSize: "15px",
                                                                        }}
                                                                    >
                                                                        + Add internship
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p
                                                                        class="btn p-0 "
                                                                        onClick={() => {
                                                                            toast.error("Add personal details first!!");
                                                                        }}
                                                                        style={{
                                                                            color: "#0196C5",
                                                                            fontSize: "15px",
                                                                        }}
                                                                    >
                                                                        + Add internship
                                                                    </p>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="dropdown-divider1 mb-4 mt-3" style={{ height: "0.05px", color: "rgb(185, 185, 185)" }} />

                                    {/* <div class="container" style={{ padding: "25px" }}>
                    <div class="row">
                      <div class="col-3" style={{ minWidth: "150px" }}>
                        <p
                          style={{
                            color: "black",
                            fontSize: "22px",
                            letterSpacing: "0.5px",
                            fontWeight: "500",
                            lineHeight: "24px",
                          }}
                        >
                          TRAININGS/
                          <br />
                          COURSES
                        </p>
                      </div>
                      <div class="col-9 mobile-column-to-increase-width">
                        {resumeData && resumeData.Training ? (
                          <>
                            {resumeData.Training.map((data) => {
                              return (
                                <>
                                  <div className="row">
                                    
                                    <div className="col-md-10 col-7">
                                      <div style={{ marginBottom: "30px" }}>
                                        <p
                                          style={{
                                            fontSize: "15px",
                                            color: "#404040",
                                          }}
                                        >
                                          <b>{data.program}</b>
                                        </p>
                                        <div
                                          style={{
                                            color: "#6E6E6E",
                                            fontSize: "15px",
                                            marginTop: "-8px",
                                          }}
                                        >
                                          <p style={{ marginTop: "-8px" }}>
                                            {data.organization}, {data.location}
                                          </p>
                                          <p style={{ marginTop: "-8px" }}>
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.startDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.startDate.slice(0, 4)}{" "}
                                            -{" "}
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.endDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.endDate.slice(0, 4)}
                                          </p>
                                          <SeeMoreLess
                                            text={data?.description}
                                            maxLength={350}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="col-md-2 col-5">
                                      <div className="mobile-flex-container">
                                        <RxPencil1
                                          class=" mr-4"
                                          style={{
                                            color: "#2092c7",
                                            fontSize: "23px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            editTraining(data);
                                          }}
                                          aria-hidden="true"
                                          data-bs-toggle="modal"
                                          data-bs-target="#training"
                                        />
                                        <RiDeleteBinLine
                                          className="ml-2"
                                          style={{
                                            color: "#646464",
                                            fontSize: "21px",
                                            cursor: "pointer",
                                            marginTop: "-2px",
                                          }}
                                          aria-hidden="true"
                                          onClick={() => {
                                            deleteTraining(data._id);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}

                        {resumeData &&
                        resumeData.Training &&
                        resumeData.Training.length >= 2 ? (
                          <></>
                        ) : (
                          <>
                            {user && (user.resume || resumeId) ? (
                              <>
                                <p
                                  class="btn p-0 "
                                  onClick={() => {
                                    addTraining();
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#training"
                                  style={{ color: "#0196C5", fontSize: "15px" }}
                                >
                                  + Add training / courses
                                </p>
                              </>
                            ) : (
                              <>
                                <p
                                  class="btn p-0 "
                                  onClick={() => {
                                    toast.error("Add personal details first!!");
                                  }}
                                  style={{ color: "#0196C5", fontSize: "15px" }}
                                >
                                  + Add training / courses
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr
                    className="dropdown-divider1 mb-4 mt-3"
                    style={{ height: "0.05px", color: "rgb(185, 185, 185)" }}
                  />

                  <div class="container" style={{ padding: "25px" }}>
                    <div class="row">
                      <div class="col-3" style={{ minWidth: "150px" }}>
                        <p
                          style={{
                            color: "black",
                            fontSize: "22px",
                            letterSpacing: "0.5px",
                            fontWeight: "500",
                            lineHeight: "24px",
                          }}
                        >
                          ACADEMICS/
                          <br /> PERSONAL PROJECTS
                        </p>
                      </div>
                      <div class="col-9 mobile-column-to-increase-width">
                        {resumeData && resumeData.Academics ? (
                          <>
                            {resumeData.Academics.map((data) => {
                              return (
                                <>
                                  <div className="row">
                                    
                                    <div className="col-md-10 col-7">
                                      <div
                                        style={{
                                          marginBottom: "30px",
                                          color: "#2C2C2C",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "15px",
                                            color: "#404040",
                                          }}
                                        >
                                          <b>{data.title}</b>
                                        </p>
                                        <div
                                          style={{
                                            color: "#6E6E6E",
                                            fontSize: "15px",
                                            marginTop: "-8px",
                                          }}
                                        >
                                          <p style={{ marginTop: "-8px" }}>
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.startDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.startDate.slice(0, 4)}{" "}
                                            -{" "}
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.endDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.endDate.slice(0, 4)}
                                          </p>
                                          <a
                                            target="_gray"
                                            href={data.link}
                                            style={{
                                              color: "#0885B7",
                                              display: "inline-block",
                                              maxWidth: "100%",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              textDecoration: "none",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {data.link}
                                          </a>
                                          <SeeMoreLess
                                            text={data?.description}
                                            maxLength={350}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="col-md-2 col-5">
                                      <div className="mobile-flex-container">
                                        <RxPencil1
                                          class=" mr-4"
                                          style={{
                                            color: "#2092c7",
                                            fontSize: "23px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            editAcademics(data);
                                          }}
                                          aria-hidden="true"
                                          data-bs-toggle="modal"
                                          data-bs-target="#project"
                                        />
                                        <RiDeleteBinLine
                                          className="ml-2"
                                          style={{
                                            color: "#646464",
                                            fontSize: "21px",
                                            cursor: "pointer",
                                            marginTop: "-2px",
                                          }}
                                          aria-hidden="true"
                                          onClick={() => {
                                            deleteAcademics(data._id);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}

                        {resumeData &&
                        resumeData.Academics &&
                        resumeData.Academics.length >= 4 ? (
                          <></>
                        ) : (
                          <>
                            {user && (user.resume || resumeId) ? (
                              <>
                                <p
                                  class="btn p-0"
                                  onClick={() => {
                                    addAcademics();
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#project"
                                  style={{ color: "#0196C5", fontSize: "15px" }}
                                >
                                  + Add academic / personal project
                                </p>
                              </>
                            ) : (
                              <>
                                <p
                                  class="btn p-0 "
                                  onClick={() => {
                                    toast.error("Add personal details first!!");
                                  }}
                                  style={{ color: "#0196C5", fontSize: "15px" }}
                                >
                                  + Add academic / personal project
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr
                    className="dropdown-divider1 mb-4 mt-3"
                    style={{ height: "0.05px", color: "rgb(185, 185, 185)" }}
                  /> */}

                                    <div class="container" style={{ padding: "25px" }}>
                                        <div class="row">
                                            <div class="col-3" style={{ minWidth: "200px" }}>
                                                <p
                                                    style={{
                                                        color: "black",
                                                        fontSize: "22px",
                                                        letterSpacing: "0.5px",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    SKILLS
                                                </p>
                                            </div>
                                            <div class="col-9">
                                                {resumeData && resumeData.Skills ? (
                                                    <>
                                                        <div className="row">
                                                            {resumeData.Skills.map((data) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-6" style={{ minWidth: "250px" }}>
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-7"
                                                                                    style={{
                                                                                        marginBottom: "10px",
                                                                                        fontSize: "17px",
                                                                                    }}
                                                                                >
                                                                                    <p
                                                                                        style={{
                                                                                            fontSize: "15px",
                                                                                            color: "#404040",
                                                                                        }}
                                                                                    >
                                                                                        <b>{data.name}</b>
                                                                                    </p>
                                                                                    <div
                                                                                        style={{
                                                                                            color: "black",
                                                                                            marginTop: "-8px",
                                                                                            marginRight: "30px",
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                color: "#6E6E6E",
                                                                                                fontSize: "15px",
                                                                                                marginTop: "-8px",
                                                                                            }}
                                                                                        >
                                                                                            <p style={{ marginTop: "10px" }}>{data.level}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5">
                                                                                    <div style={{ display: "flex" }}>
                                                                                        <RxPencil1
                                                                                            class=" mr-4"
                                                                                            style={{
                                                                                                color: "#2092c7",
                                                                                                fontSize: "20px",
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                editSkill(data);
                                                                                            }}
                                                                                            aria-hidden="true"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#skill"
                                                                                        />
                                                                                        <RiDeleteBinLine
                                                                                            className="ml-2"
                                                                                            style={{
                                                                                                color: "#646464",
                                                                                                fontSize: "18px",
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                            aria-hidden="true"
                                                                                            onClick={() => {
                                                                                                deleteSkills(data._id);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                        </div>{" "}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                {resumeData && resumeData.Skills && resumeData.Skills.length >= 20 ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {user && (user.resume || resumeId) ? (
                                                            <>
                                                                <p
                                                                    class="btn p-0 "
                                                                    onClick={() => {
                                                                        addSkill();
                                                                    }}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#skill"
                                                                    style={{ color: "#0196C5", fontSize: "15px" }}
                                                                >
                                                                    + Add skill
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p
                                                                    class="btn p-0 "
                                                                    onClick={() => {
                                                                        toast.error("Add personal details first!!");
                                                                    }}
                                                                    style={{ color: "#0196C5", fontSize: "15px" }}
                                                                >
                                                                    + Add skill
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-1"></div>
                                        </div>
                                    </div>
                                    <hr className="dropdown-divider1 mb-4 mt-5" style={{ height: "3.05px", color: "#000" }} />

                                    {/* Address */}
                                    <div className="container" style={{ padding: "25px" }}>
                                        {resumeData === null || resumeData?.AddressDetail == undefined ? (
                                            <div class="row">
                                                <div class="col-3" style={{ minWidth: "200px" }}>
                                                    <p
                                                        style={{
                                                            color: "black",
                                                            fontSize: "22px",
                                                            letterSpacing: "0.5px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        ADDRESS
                                                    </p>
                                                </div>
                                                {user && (user.resume || resumeId) ? (
                                                    <div class="col-7">
                                                        <p class="btn p-0 " data-bs-toggle="modal" data-bs-target="#addressDetails" style={{ color: "#0196C5", fontSize: "15px" }}>
                                                            + Add Address
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div class="col-7">
                                                        <p
                                                            class="btn p-0 "
                                                            onClick={() => {
                                                                toast.error("Add personal details first!!");
                                                            }}
                                                            style={{ color: "#0196C5", fontSize: "15px" }}
                                                        >
                                                            + Add Address
                                                        </p>
                                                    </div>
                                                )}
                                                <div class="col-2"></div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div
                                                    className=""
                                                    style={{
                                                        minWidth: "150px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            color: "black",
                                                            fontSize: "32px",
                                                            fontWeight: "500",
                                                            marginLeft: "-8px",
                                                        }}
                                                    >
                                                        Address
                                                    </p>
                                                    <RxPencil1
                                                        className="mx-2 resumePeronalDetailpencilicon"
                                                        style={{
                                                            color: "#2092c7",
                                                            cursor: "pointer",
                                                            fontSize: "25px",
                                                            marginTop: "-8px",
                                                        }}
                                                        onClick={() => {
                                                            editAddressDetails(resumeData?.AddressDetail);
                                                        }}
                                                        aria-hidden="true"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addressDetails"
                                                    />
                                                </div>
                                                <div className="mobile-column-to-increase-width" style={{ marginLeft: "-24px" }}>
                                                    <form>
                                                        <div className="form-group formgroupaddress">
                                                            <div className="col-12 col-md-8 formgroupaddress2 ">
                                                                <div class="form-group my-2">
                                                                    <label for="state" style={{ fontSize: "15px" }}>
                                                                        Address
                                                                    </label>
                                                                    <input
                                                                        style={{
                                                                            fontSize: "15px",
                                                                            paddingTop: "18px",
                                                                            paddingBottom: "18px",
                                                                        }}
                                                                        type="text"
                                                                        className="ModelInput form-control"
                                                                        id="address"
                                                                        name="address"
                                                                        value={resumeData?.AddressDetail?.address}
                                                                        readOnly
                                                                        placeholder="B-141/1, Street 18, Shyam Vihar Phase 1, Najafgarh"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-4 formgroupaddress2">
                                                                <div class="form-group my-2">
                                                                    <label for="state" style={{ fontSize: "15px" }}>
                                                                        Address 2 (Apartment, suite, etc)
                                                                    </label>
                                                                    <input
                                                                        style={{
                                                                            fontSize: "15px",
                                                                            paddingTop: "18px",
                                                                            paddingBottom: "18px",
                                                                        }}
                                                                        type="text"
                                                                        class="ModelInput form-control "
                                                                        id="address2"
                                                                        name="address2"
                                                                        placeholder="Apt/Suite"
                                                                        value={resumeData?.AddressDetail?.address2}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group formgroupaddress">
                                                            <div className="col-12 col-md-8 formgroupaddress2">
                                                                <div class="form-group my-2">
                                                                    <label for="state" style={{ fontSize: "15px" }}>
                                                                        City
                                                                    </label>
                                                                    <Select
                                                                        options={cityOptions}
                                                                        placeholder={<div style={{ fontSize: "13px" }}>Select current city</div>}
                                                                        // onChange={(selectedOption) => {
                                                                        //   setAddressDetails({ ...addressDetails, city: selectedOption?.value })
                                                                        // }}
                                                                        value={
                                                                            resumeData?.AddressDetail?.city
                                                                                ? {
                                                                                      value: resumeData.AddressDetail.city,
                                                                                      label: resumeData.AddressDetail.city,
                                                                                  }
                                                                                : null
                                                                        }
                                                                        // onInputChange={handleInputChange}
                                                                        // menuIsOpen={isTyping}
                                                                        styles={customStyles}
                                                                        isDisabled
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-4 formgroupaddress2">
                                                                <div class="form-group my-2">
                                                                    <label for="state" style={{ fontSize: "15px" }}>
                                                                        State/Province
                                                                    </label>
                                                                    <input
                                                                        style={{
                                                                            fontSize: "15px",
                                                                            paddingTop: "18px",
                                                                            paddingBottom: "18px",
                                                                        }}
                                                                        type="text"
                                                                        class="ModelInput form-control "
                                                                        placeholder="DL"
                                                                        id="state"
                                                                        name="state"
                                                                        value={resumeData?.AddressDetail?.state}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 formgroupaddress2">
                                                            <div class="form-group my-2">
                                                                <label for="code" style={{ fontSize: "15px" }}>
                                                                    ZIP/Postal Code
                                                                </label>
                                                                <input
                                                                    style={{
                                                                        fontSize: "15px",
                                                                        paddingTop: "18px",
                                                                        paddingBottom: "18px",
                                                                    }}
                                                                    type="text"
                                                                    class="ModelInput form-control "
                                                                    placeholder="110043"
                                                                    id="code"
                                                                    name="code"
                                                                    value={resumeData?.AddressDetail?.code}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col formgroupaddress2">
                                                            <div class="form-group my-2">
                                                                <label for="zip" style={{ fontSize: "15px" }}>
                                                                    Phone
                                                                </label>
                                                                <PhoneInput
                                                                    country={"in"}
                                                                    value={resumeData?.AddressDetail?.phone}
                                                                    disabled
                                                                    inputStyle={{
                                                                        fontSize: "15px",
                                                                        paddingTop: "18px",
                                                                        paddingBottom: "18px",
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <hr className="dropdown-divider1 mb-4 mt-5" style={{ height: "3.05px", color: "#000" }} />

                                    {/* Submit Identity */}
                                    <div class="container" style={{ marginTop: "10px" }}>
                                        <div className="" style={{ minWidth: "150px" }}>
                                            {/* <p style={{ color: "black", fontSize: "32px", fontWeight: "500", marginLeft: '-8px' }}>Submit Identity</p> */}
                                        </div>
                                    </div>
                                    <div class="container">
                                        {resumeData === null || resumeData?.IdentityDetails === null || resumeData?.IdentityDetails?.length === 0 || !resumeData?.IdentityDetails ? (
                                            <div class="row">
                                                <div class="col-4" style={{ minWidth: "200px" }}>
                                                    <p
                                                        style={{
                                                            color: "black",
                                                            fontSize: "22px",
                                                            letterSpacing: "0.5px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        UPLOAD IDENTITY DOCUMENTS
                                                    </p>
                                                </div>
                                                {user && (user.resume || resumeId) ? (
                                                    <div class="col-7">
                                                        <p class="btn p-0 " data-bs-toggle="modal" data-bs-target="#identityModal" style={{ color: "#0196C5", fontSize: "15px" }}>
                                                            + Add Identity
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div class="col-7">
                                                        <p
                                                            class="btn p-0 "
                                                            onClick={() => {
                                                                toast.error("Add personal details first!!");
                                                            }}
                                                            style={{ color: "#0196C5", fontSize: "15px" }}
                                                        >
                                                            + Add Identity
                                                        </p>
                                                    </div>
                                                )}
                                                <div class="col-2"></div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div
                                                    className=""
                                                    style={{
                                                        minWidth: "150px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            color: "black",
                                                            fontSize: "32px",
                                                            fontWeight: "500",
                                                            marginLeft: "-8px",
                                                        }}
                                                    >
                                                        Submit Identity
                                                    </p>
                                                    <RxPencil1
                                                        className="mx-2 resumePeronalDetailpencilicon"
                                                        style={{
                                                            color: "#2092c7",
                                                            cursor: "pointer",
                                                            fontSize: "25px",
                                                            marginTop: "-8px",
                                                        }}
                                                        onClick={() => {
                                                            editIdentityDetails(resumeData?.IdentityDetails);
                                                        }}
                                                        aria-hidden="true"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#identityModal"
                                                    />
                                                </div>
                                                <div className="mobile-column-to-increase-width">
                                                    <div
                                                        style={{
                                                            height: "max-content",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                fontSize: "17px",
                                                                fontWeight: "500",
                                                                marginBottom: "10px",
                                                                textAlign: "center",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            Front Photo
                                                        </p>
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                                marginBottom: "20px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <img src={identityDetails?.frontPhoto?.url || identityDetails?.frontPhoto || resumeData?.IdentityDetails?.frontPhoto.url} alt="Front" className="identityphotoclass" style={{ height: "250px", cursor: "pointer" }} />
                                                        </div>
                                                    </div>

                                                    <div style={{ height: "max-content" }}>
                                                        <p
                                                            style={{
                                                                fontSize: "17px",
                                                                fontWeight: "500",
                                                                marginBottom: "10px",
                                                                textAlign: "center",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            Back Photo
                                                        </p>
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                                marginBottom: "20px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <img src={identityDetails?.backPhoto?.url || identityDetails?.backPhoto || resumeData?.IdentityDetails?.backPhoto.url} alt="Back" className="identityphotoclass" style={{ height: "250px", cursor: "pointer" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div
                                            class="container"
                                            style={{
                                                marginTop: "20px",
                                                height: "fit-content",
                                                backgroundColor: "#f9f9f9",
                                                borderRadius: "10px",
                                                padding: "25px",
                                            }}
                                        >
                                            <p style={{ fontWeight: "500" }}>Submit Identity</p>
                                            <p style={{ lineHeight: "1.5" }}>The documents we accept include a passport, national ID card (NIC/CNIC), driver's license, tax ID, voter ID, postal ID, or any other valid government-issued photo ID. This ID must include your picture, signature, name, date of birth, and address.</p>
                                        </div>
                                    </div>
                                    <hr className="dropdown-divider1 mb-4 mt-5" style={{ height: "0.05px", color: "#000" }} />
                                    <div class="container" style={{ marginTop: "20px" }}>
                                        {resumeData === null || resumeData?.DocDetails === null || resumeData?.DocDetails?.length === 0 || !resumeData?.DocDetails ? (
                                            <div class="row">
                                                <div class="col-4" style={{ minWidth: "150px" }}>
                                                    <p
                                                        style={{
                                                            color: "black",
                                                            fontSize: "22px",
                                                            letterSpacing: "0.5px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        A RECENT BILLING STATEMENT
                                                    </p>
                                                </div>
                                                {user && (user.resume || resumeId) ? (
                                                    <div class="col-7">
                                                        <p class="btn p-0 " data-bs-toggle="modal" data-bs-target="#documentModal" style={{ color: "#0196C5", fontSize: "15px" }}>
                                                            + Upload Address proof document
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div class="col-7">
                                                        <p
                                                            class="btn p-0 "
                                                            onClick={() => {
                                                                toast.error("Add personal details first!!");
                                                            }}
                                                            style={{ color: "#0196C5", fontSize: "15px" }}
                                                        >
                                                            + Upload Address proof document
                                                        </p>
                                                    </div>
                                                )}
                                                <div class="col-2"></div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div
                                                    className=""
                                                    style={{
                                                        minWidth: "150px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            color: "black",
                                                            fontSize: "32px",
                                                            fontWeight: "500",
                                                            marginLeft: "-8px",
                                                        }}
                                                    >
                                                        A Recent Billing Statement
                                                    </p>
                                                    <RxPencil1
                                                        className="mx-2 resumePeronalDetailpencilicon"
                                                        style={{
                                                            color: "#2092c7",
                                                            cursor: "pointer",
                                                            fontSize: "25px",
                                                            marginTop: "-8px",
                                                        }}
                                                        onClick={() => {
                                                            editDocDetails(resumeData?.DocDetails[0]);
                                                        }}
                                                        aria-hidden="true"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#documentModal"
                                                    />
                                                </div>
                                                <div className="mobile-column-to-increase-width">
                                                    <div style={{ height: "max-content" }}>
                                                        <p
                                                            style={{
                                                                fontSize: "17px",
                                                                fontWeight: "500",
                                                                marginBottom: "10px",
                                                            }}
                                                        >
                                                            Document
                                                        </p>
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                                marginBottom: "20px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {/* Display the file Name with some style and a download option of the file */}
                                                            {resumeData?.DocDetails.map((doc, index) => (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        marginBottom: "10px",
                                                                        width: "auto",
                                                                        height: "max-content",
                                                                        padding: "20px",
                                                                        backgroundColor: "#f9f9f9",
                                                                        borderRadius: "10px",
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <p style={{ marginBottom: "5px" }}>{doc.fileName}</p>
                                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                                        <button
                                                                            onClick={() => handleDownload2(doc.document, doc.fileName)}
                                                                            style={{
                                                                                color: "#0196C5",
                                                                                cursor: "pointer",
                                                                                background: "none",
                                                                                border: "none",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            Download
                                                                        </button>
                                                                        <FaFileDownload
                                                                            style={{
                                                                                fontSize: "22px",
                                                                                color: "#0196C5",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => handleDownload2(doc.document, doc.fileName)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div
                                            class="container"
                                            style={{
                                                marginTop: "20px",
                                                height: "fit-content",
                                                backgroundColor: "#f9f9f9",
                                                borderRadius: "10px",
                                                padding: "25px",
                                            }}
                                        >
                                            <p style={{ fontWeight: "500" }}>Upload Address proof document</p>
                                            <ol style={{ lineHeight: "1.5" }}>
                                                <li style={{ marginBottom: "10px" }}>Acceptable documents include: bank statements, credit card statements, utility bills, or any other monthly bill that meets the name, address, and date criteria below</li>
                                                <li style={{ marginBottom: "10px" }}>The statement must include your name and address, as well as the utility/bank address. The dates on your scanned statement must be within six months of the date you upload them</li>
                                                <li style={{ marginBottom: "10px" }}>Your name on the statement must match both your name on the platform and your government ID. Your account may be a joint account, but your name must be on the account and statement</li>
                                                <li style={{ marginBottom: "10px" }}>Your address must match the location information you entered on the platform</li>
                                            </ol>
                                        </div>
                                    </div>

                                    <hr className="dropdown-divider1 mb-4 mt-5" style={{ height: "3.05px", color: "#000" }} />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/* Reorder Modal */}
                {isReorderModalOpen && <ReorderModal projects={portfolio} onSave={handleReorderSave} onClose={() => setIsReorderModalOpen(false)} />}

                {/* Identity Modal */}
                <div class="  modal fade" id="identityModal" tabindex="-1" aria-labelledby="identityModalLabel" aria-hidden="true">
                    <div class="modal-dialog  custom-modal-dialog">
                        <div
                            class="modal-content px-3 py-3 custom-scrollbarresume"
                            style={{
                                borderRadius: "15px",
                                marginTop: "20px",
                                overflowY: "auto",
                            }}
                        >
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <div style={{ textAlign: "end" }}>
                                        <button type="button" class="btn-close" ref={closeIden} data-bs-dismiss="modal" style={{ marginRight: "-10px" }} aria-label="Close"></button>
                                    </div>
                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div>
                                                <div className="" style={{ minWidth: "150px" }}>
                                                    <p
                                                        style={{
                                                            color: "black",
                                                            fontSize: "32px",
                                                            fontWeight: "500",
                                                            marginLeft: "-23px",
                                                        }}
                                                    >
                                                        Upload Identity
                                                    </p>
                                                </div>
                                                <div className="mobile-column-to-increase-width" style={{ marginLeft: "-24px" }}>
                                                    <div style={{ height: "max-content" }}>
                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                            <p
                                                                style={{
                                                                    fontSize: "17px",
                                                                    fontWeight: "500",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={() => document.getElementById("photo-uploadFrontIden").click()}
                                                            >
                                                                Front Photo
                                                            </p>
                                                            {identityDetails.frontPhoto && (
                                                                <button
                                                                    style={{
                                                                        padding: "5px",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "#2092c7",
                                                                        color: "white",
                                                                        borderRadius: "12px",
                                                                        fontSize: "13px",
                                                                        border: "none",
                                                                        marginBottom: "7px",
                                                                    }}
                                                                    onClick={() => document.getElementById("photo-uploadFrontIden").click()}
                                                                >
                                                                    Change Photo
                                                                </button>
                                                            )}
                                                        </div>

                                                        {/* <p style={{fontSize:'17px', fontWeight:'500', marginBottom:'10px'}}>Front Photo</p> */}

                                                        {identityDetails.frontPhoto ? (
                                                            <div onClick={() => document.getElementById("photo-uploadFrontIden").click()}>
                                                                <img
                                                                    src={identityDetails?.frontPhoto?.url || identityDetails.frontPhoto}
                                                                    alt="Front"
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "250px",
                                                                        cursor: "pointer",
                                                                        borderRadius: "10px",
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    textAlign: "center",
                                                                    marginBottom: "20px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100px",
                                                                    border: "2px dashed #2092c7",
                                                                    borderRadius: "10px",
                                                                    width: "100%",
                                                                }}
                                                                onClick={() => document.getElementById("photo-uploadFrontIden").click()}
                                                            >
                                                                <div style={{ marginBottom: "10px" }}>
                                                                    <IoImageOutline
                                                                        style={{
                                                                            fontSize: "37px",
                                                                            color: "white",
                                                                            backgroundColor: "#2092c7",
                                                                            borderRadius: "50%",
                                                                            padding: "5px",
                                                                        }}
                                                                    />
                                                                </div>
                                                                <p style={{ marginBottom: "10px" }}>Upload Front Photo</p>
                                                                <input type="file" id="photo-uploadFrontIden" style={{ display: "none" }} onChange={handleFrontPhotoChange} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                                            </div>
                                                        )}
                                                        <input type="file" id="photo-uploadFrontIden" style={{ display: "none" }} onChange={handleFrontPhotoChange} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                                    </div>

                                                    <div style={{ height: "max-content", marginTop: "20px" }}>
                                                        {/* <p style={{fontSize:'17px', fontWeight:'500', marginBottom:'10px'}}>Back Photo</p> */}
                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                            <p
                                                                style={{
                                                                    fontSize: "17px",
                                                                    fontWeight: "500",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={() => document.getElementById("photo-uploadBackIden").click()}
                                                            >
                                                                Back Photo
                                                            </p>
                                                            {identityDetails.frontPhoto && (
                                                                <button
                                                                    style={{
                                                                        padding: "5px",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "#2092c7",
                                                                        color: "white",
                                                                        borderRadius: "12px",
                                                                        fontSize: "13px",
                                                                        border: "none",
                                                                        marginBottom: "7px",
                                                                    }}
                                                                    onClick={() => document.getElementById("photo-uploadBackIden").click()}
                                                                >
                                                                    Change Photo
                                                                </button>
                                                            )}
                                                        </div>

                                                        {identityDetails.backPhoto ? (
                                                            <div onClick={() => document.getElementById("photo-uploadBackIden").click()}>
                                                                <img
                                                                    src={identityDetails?.backPhoto?.url || identityDetails.backPhoto}
                                                                    alt="Back"
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "250px",
                                                                        cursor: "pointer",
                                                                        borderRadius: "10px",
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    textAlign: "center",
                                                                    marginBottom: "20px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100px",
                                                                    border: "2px dashed #2092c7",
                                                                    borderRadius: "10px",
                                                                    width: "100%",
                                                                }}
                                                                onClick={() => document.getElementById("photo-uploadBackIden").click()}
                                                            >
                                                                <div style={{ marginBottom: "10px" }}>
                                                                    <IoImageOutline
                                                                        style={{
                                                                            fontSize: "37px",
                                                                            color: "white",
                                                                            backgroundColor: "#2092c7",
                                                                            borderRadius: "50%",
                                                                            padding: "5px",
                                                                        }}
                                                                    />
                                                                </div>
                                                                <p style={{ marginBottom: "10px" }}>Upload Back Photo</p>
                                                                <input type="file" id="photo-uploadBackIden" style={{ display: "none" }} onChange={handleBackPhotoChange} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                                            </div>
                                                        )}
                                                        <input type="file" id="photo-uploadBackIden" style={{ display: "none" }} onChange={handleBackPhotoChange} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {identityDetails._id ? (
                                        <>
                                            <button onClick={modifyIdentityDetails} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={updateIdentityDetails} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Document Modal */}
                <div class="  modal fade" id="documentModal" tabindex="-1" aria-labelledby="documentModalLabel" aria-hidden="true">
                    <div class="modal-dialog  custom-modal-dialog">
                        <div
                            class="modal-content px-3 py-3 custom-scrollbarresume"
                            style={{
                                borderRadius: "15px",
                                marginTop: "20px",
                                overflowY: "auto",
                            }}
                        >
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <div style={{ marginRight: "-20px", marginTop: "-10px" }}>
                                        <CloseButton ref={closeDoc} />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  class="btn-close"
                  ref={closeDoc}
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div>
                                                <div className="" style={{ minWidth: "150px" }}>
                                                    <p
                                                        style={{
                                                            color: "black",
                                                            fontSize: "32px",
                                                            fontWeight: "500",
                                                            marginLeft: "-23px",
                                                        }}
                                                    >
                                                        Upload Address proof document
                                                    </p>
                                                </div>
                                                <div className="mobile-column-to-increase-width" style={{ marginLeft: "-24px" }}>
                                                    <div style={{ height: "max-content" }}>
                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                            <p
                                                                style={{
                                                                    fontSize: "17px",
                                                                    fontWeight: "500",
                                                                    marginBottom: "10px",
                                                                }}
                                                                onClick={() => document.getElementById("photo-uploadDocIden").click()}
                                                            >
                                                                Document
                                                            </p>
                                                            {(resumeData?.DocDetails?.document || docDetails?.document) && (
                                                                <button
                                                                    style={{
                                                                        padding: "5px",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "#2092c7",
                                                                        color: "white",
                                                                        borderRadius: "12px",
                                                                        fontSize: "13px",
                                                                        border: "none",
                                                                        marginBottom: "7px",
                                                                    }}
                                                                    onClick={() => document.getElementById("photo-uploadDocIden").click()}
                                                                >
                                                                    Change Document
                                                                </button>
                                                            )}
                                                        </div>

                                                        {resumeData?.DocDetails?.document || docDetails?.document ? (
                                                            <div
                                                                style={{
                                                                    textAlign: "center",
                                                                    marginBottom: "20px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100px",
                                                                    border: "2px dashed #2092c7",
                                                                    borderRadius: "10px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <p>{resumeData?.DocDetails?.fileName || docDetails?.fileName}</p>
                                                                <button
                                                                    // onClick={handleDownload}
                                                                    onClick={() => {
                                                                        if (docDetails?.document.startsWith("data:")) {
                                                                            handleDownload();
                                                                        } else {
                                                                            handleDownload2(docDetails.document, docDetails.fileName);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        color: "#2092c7",
                                                                        textDecoration: "none",
                                                                        background: "none",
                                                                        border: "none",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    Download
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    textAlign: "center",
                                                                    marginBottom: "20px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100px",
                                                                    border: "2px dashed #2092c7",
                                                                    borderRadius: "10px",
                                                                    width: "100%",
                                                                }}
                                                                onClick={() => document.getElementById("photo-uploadDocIden").click()}
                                                            >
                                                                <div style={{ marginBottom: "10px" }}>
                                                                    <TbFileUpload
                                                                        style={{
                                                                            fontSize: "37px",
                                                                            color: "white",
                                                                            backgroundColor: "#2092c7",
                                                                            borderRadius: "50%",
                                                                            padding: "5px",
                                                                        }}
                                                                    />
                                                                </div>
                                                                <p style={{ marginBottom: "10px" }}>Upload Address proof document</p>
                                                                <input type="file" id="photo-uploadDocIden" style={{ display: "none" }} onChange={handleDocumentChange} accept=".jpeg,.jpg,.png,.gif,.bmp,.pdf,.doc,.docx,.txt" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" />
                                                            </div>
                                                        )}
                                                        <input type="file" id="photo-uploadDocIden" style={{ display: "none" }} onChange={handleDocumentChange} accept=".jpeg,.jpg,.png,.gif,.bmp,.pdf,.doc,.docx,.txt" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {docDetails._id ? (
                                        <>
                                            <button onClick={modifyDocDetails} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={updateDocDetails} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Address Modal */}
                <div class="  modal fade" id="addressDetails" tabindex="-1" aria-labelledby="addressModalLabel" aria-hidden="true">
                    <div class="modal-dialog  custom-modal-dialog">
                        <div
                            class="modal-content px-3 py-3 custom-scrollbarresume"
                            style={{
                                borderRadius: "15px",
                                marginTop: "20px",
                                overflowY: "auto",
                            }}
                        >
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <div style={{ marginRight: "-20px", marginTop: "-10px" }}>
                                        <CloseButton ref={closeAddress} />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  class="btn-close"
                  ref={closeAddress}
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div>
                                                <div className="" style={{ minWidth: "150px" }}>
                                                    <p
                                                        style={{
                                                            color: "black",
                                                            fontSize: "32px",
                                                            fontWeight: "500",
                                                            marginLeft: "-8px",
                                                        }}
                                                    >
                                                        Address
                                                    </p>
                                                </div>
                                                <div className="mobile-column-to-increase-width" style={{ marginLeft: "-24px" }}>
                                                    <form>
                                                        {/* <div className="form-group formgroupaddress">
                        <div className='col-12 col-md-8 formgroupaddress2 '>
                          <div class="form-group my-2">
                            <label for="state" style={{ fontSize: "15px" }}>Address</label>
                            <input
                              style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                              type="text"
                              className="ModelInput form-control"
                              id="address"
                              name="address"
                              value={addressDetails.address}
                              onChange={handleAddressChange}
                              placeholder="B-141/1, Street 18, Shyam Vihar Phase 1, Najafgarh"
                            />
                          </div>
                        </div>

                        <div className='col-12 col-md-4 formgroupaddress2'>
                          <div class="form-group my-2">
                            <label for="state" style={{ fontSize: "15px" }}>Address 2 (Apartment, suite, etc)</label>
                            <input
                              style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                              type="text"
                              class="ModelInput form-control "
                              id="address2"
                              name="address2"
                              placeholder="Apt/Suite"
                              value={addressDetails.address2}
                              onChange={handleAddressChange}
                            />
                          </div>
                        </div>

                      </div> */}
                                                        <div className="col formgroupaddress2 ">
                                                            <div class="form-group my-2">
                                                                <label for="state" style={{ fontSize: "15px" }}>
                                                                    Address
                                                                </label>
                                                                <input
                                                                    style={{
                                                                        fontSize: "15px",
                                                                        paddingTop: "18px",
                                                                        paddingBottom: "18px",
                                                                    }}
                                                                    type="text"
                                                                    className="ModelInput form-control"
                                                                    id="address"
                                                                    name="address"
                                                                    value={addressDetails.address}
                                                                    onChange={handleAddressChange}
                                                                    placeholder="B-141/1, Street 18, Shyam Vihar Phase 1, Najafgarh"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col formgroupaddress2">
                                                            <div class="form-group my-2">
                                                                <label for="state" style={{ fontSize: "15px" }}>
                                                                    Address 2 (Apartment, suite, etc)
                                                                </label>
                                                                <input
                                                                    style={{
                                                                        fontSize: "15px",
                                                                        paddingTop: "18px",
                                                                        paddingBottom: "18px",
                                                                    }}
                                                                    type="text"
                                                                    class="ModelInput form-control "
                                                                    id="address2"
                                                                    name="address2"
                                                                    placeholder="Apt/Suite"
                                                                    value={addressDetails.address2}
                                                                    onChange={handleAddressChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group formgroupaddress">
                                                            <div className="col-12 col-md-8 formgroupaddress2">
                                                                <div class="form-group my-2">
                                                                    <label for="state" style={{ fontSize: "15px" }}>
                                                                        City
                                                                    </label>
                                                                    <Select
                                                                        options={cityOptions}
                                                                        placeholder={<div style={{ fontSize: "13px" }}>Select current city</div>}
                                                                        onChange={(selectedOption) => {
                                                                            setAddressDetails({
                                                                                ...addressDetails,
                                                                                city: selectedOption?.value,
                                                                            });
                                                                        }}
                                                                        value={
                                                                            addressDetails.city
                                                                                ? {
                                                                                      value: addressDetails.city,
                                                                                      label: addressDetails.city,
                                                                                  }
                                                                                : null
                                                                        }
                                                                        onInputChange={handleInputChange}
                                                                        menuIsOpen={isTyping}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-4 formgroupaddress2">
                                                                <div class="form-group my-2">
                                                                    <label for="state" style={{ fontSize: "15px" }}>
                                                                        State/Province
                                                                    </label>
                                                                    <input
                                                                        style={{
                                                                            fontSize: "15px",
                                                                            paddingTop: "18px",
                                                                            paddingBottom: "18px",
                                                                        }}
                                                                        type="text"
                                                                        class="ModelInput form-control "
                                                                        placeholder="DL"
                                                                        id="state"
                                                                        name="state"
                                                                        value={addressDetails.state}
                                                                        onChange={handleAddressChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 formgroupaddress2">
                                                            <div class="form-group my-2">
                                                                <label for="code" style={{ fontSize: "15px" }}>
                                                                    ZIP/Postal Code
                                                                </label>
                                                                <input
                                                                    style={{
                                                                        fontSize: "15px",
                                                                        paddingTop: "18px",
                                                                        paddingBottom: "18px",
                                                                    }}
                                                                    type="text"
                                                                    class="ModelInput form-control "
                                                                    placeholder="110043"
                                                                    id="code"
                                                                    name="code"
                                                                    value={addressDetails.code}
                                                                    onChange={handleAddressChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col formgroupaddress2">
                                                            <div class="form-group my-2">
                                                                <label for="zip" style={{ fontSize: "15px" }}>
                                                                    Phone
                                                                </label>
                                                                <PhoneInput
                                                                    country={"in"}
                                                                    value={addressDetails.phone}
                                                                    onChange={handlePhoneChange}
                                                                    inputStyle={{
                                                                        fontSize: "15px",
                                                                        paddingTop: "18px",
                                                                        paddingBottom: "18px",
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {addressDetails._id ? (
                                        <>
                                            <button onClick={modifyAddresslDetails} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={updateAddressDetails} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Model 1: Personal Details */}
                <div className="modal fade" id="personalDetails" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div
                        class="modal-dialog  custom-modal-dialog"
                        style={{
                            backgroundColor: "white",
                            height: "94vh",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                padding: "2px",
                                cursor: "pointer",
                                zIndex: 100,
                            }}
                        >
                            <CloseButton ref={closePersonal} />
                        </div>
                        {/* <div
          style={{
            textAlign: "end",
            cursor: "pointer",
            zIndex: 10,
            paddingTop: "10px",
          }}
        >
          <button
            type="button"
            class="btn-close"
            ref={closePersonal}
            data-bs-dismiss="modal"
            style={{
              marginRight: "10px",
            }}
            aria-label="Close"
          ></button>
        </div> */}
                        <div
                            className=" modal-content px-3 py-3 custom-scrollbarresume"
                            style={{
                                overflowY: "auto",
                                height: "98%",
                                backgroundColor: "white",
                                borderColor: "white",
                            }}
                        >
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <p
                                        className=" title text-center mx-auto pb-3"
                                        style={{
                                            color: "#444343",
                                            marginTop: "10px",
                                            marginBottom: "15px",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Personal Details
                                    </p>
                                    {/* Photo Upload Field */}

                                    <div className="text-center mb-4">
                                        <div style={{ position: "relative", display: "inline-block" }}>
                                            <label htmlFor="photo-upload" style={{ cursor: "pointer" }}>
                                                <div
                                                    className="rounded-circle"
                                                    style={{
                                                        width: "150px",
                                                        height: "150px",
                                                        backgroundColor: "#eaeaea",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        overflow: "hidden",
                                                        borderRadius: "50%",
                                                        boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                        border: "4px solid white",
                                                    }}
                                                >
                                                    {personalDetails?.photo !== "" ? (
                                                        <img
                                                            src={personalDetails?.photo?.url || personalDetails?.photo}
                                                            alt=""
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: "cover",
                                                                borderRadius: "50%",
                                                            }}
                                                        />
                                                    ) : (
                                                        <span style={{ color: "#aaa" }}>Upload Photo</span>
                                                    )}
                                                </div>
                                            </label>

                                            {/* Edit Icon */}
                                            <div
                                                onClick={() => document.getElementById("photo-upload").click()}
                                                style={{
                                                    position: "absolute",
                                                    bottom: "18%",
                                                    right: "17px",
                                                    backgroundColor: "white",
                                                    borderRadius: "50%",
                                                    padding: "5px",
                                                    cursor: "pointer",
                                                    zIndex: 10,
                                                    boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                    border: "2px solid white",
                                                }}
                                            >
                                                <HiOutlinePencilAlt style={{ color: "#2092c7", fontSize: "21px" }} />
                                            </div>

                                            {personalDetails.photo && (
                                                <div className="text-center mt-2">
                                                    <button
                                                        onClick={() => {
                                                            setEditorData(personalDetails.photo?.url || personalDetails?.photo);
                                                            setIsEditingPhoto(true);
                                                        }}
                                                        className="btn btn-secondary"
                                                    >
                                                        Edit Photo
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        <input type="file" id="photo-upload" style={{ display: "none" }} onChange={handlePhotoChange} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                    </div>

                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            First Name
                                                        </label>
                                                        <input
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                            type="text"
                                                            class="ModelInput form-control "
                                                            placeholder="Enter first name"
                                                            value={user && personalDetails.firstName}
                                                            onChange={handlPersonalDetailsChange}
                                                            id="firstName"
                                                            name="firstName"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            Last Name
                                                        </label>
                                                        <input
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                            type="text"
                                                            class="ModelInput form-control "
                                                            placeholder="Enter last name"
                                                            value={user && personalDetails.lastName}
                                                            onChange={handlPersonalDetailsChange}
                                                            id="lastName"
                                                            name="lastName"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mt-4 mb-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Minimum rate ($/hour)
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    //type="number"
                                                    className="ModelInput form-control "
                                                    placeholder="eg. 10"
                                                    value={user && personalDetails.minimumRate}
                                                    onChange={handlPersonalDetailsChange}
                                                    id="minimumRate"
                                                    name="minimumRate"
                                                    onWheel={(event) => {
                                                        event.preventDefault();
                                                    }}
                                                    required
                                                />
                                            </div>
                                            <div class="form-group mt-4 mb-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Email
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="email"
                                                    class="ModelInput form-control "
                                                    placeholder="demo@gmail.com"
                                                    value={user && personalDetails.email}
                                                    onChange={handlPersonalDetailsChange}
                                                    id="email"
                                                    name="email"
                                                    readOnly
                                                />
                                            </div>
                                            <div class="form-group mt-4 mb-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Professional Title
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="MERN Stack"
                                                    value={personalDetails?.professionalTitle}
                                                    onChange={handlPersonalDetailsChange}
                                                    id="professionalTitle"
                                                    name="professionalTitle"
                                                    required
                                                />
                                            </div>
                                            <div class="form-group my-4">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Contact number
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="number"
                                                    class="ModelInput form-control "
                                                    placeholder="Enter Phone Number"
                                                    value={user && personalDetails.phone}
                                                    onChange={handlPersonalDetailsChange}
                                                    id="phone"
                                                    name="phone"
                                                    maxLength={10}
                                                />
                                            </div>
                                            <div class="form-group mt-4 mb-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Location
                                                </label>
                                                <Select
                                                    // options={selectedCountry && citiesData[selectedCountry]?.map((city) => ({ value: city, label: city }))}
                                                    options={cityOptions}
                                                    placeholder={<div style={{ fontSize: "13px" }}>Select current city</div>}
                                                    onChange={(selectedOption) => {
                                                        setPersonalDetails({
                                                            ...personalDetails,
                                                            address: selectedOption?.value,
                                                        });
                                                    }}
                                                    value={
                                                        personalDetails.address
                                                            ? {
                                                                  value: personalDetails.address,
                                                                  label: personalDetails.address,
                                                              }
                                                            : null
                                                    }
                                                    onInputChange={handleInputChange}
                                                    // noOptionsMessage={noOptionsMessage}
                                                    menuIsOpen={isTyping}
                                                    styles={customStyles}
                                                />
                                            </div>
                                            <div class="form-group mt-4 mb-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Video Introduction (YouTube Url)
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    className="ModelInput form-control  "
                                                    placeholder="eg. youtube link of your video"
                                                    value={user && personalDetails.videoIntroduction}
                                                    onChange={handlPersonalDetailsChange}
                                                    id="videoIntroduction"
                                                    name="videoIntroduction"
                                                />
                                            </div>
                                            {user && personalDetails.videoIntroduction && (
                                                <div className="form-group mt-4 mb-2 ">
                                                    <iframe
                                                        width="100%"
                                                        height="250"
                                                        style={{
                                                            borderRadius: "10px",
                                                        }}
                                                        src={getYouTubeEmbedUrl(user && personalDetails.videoIntroduction)}
                                                        title="Video Introduction"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            )}
                                            <div class="form-group mt-4 mb-2">
                                                {/* <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Profile category</label> */}
                                                {/* <Select
                      options={profiles.map(profile => ({ value: profile, label: profile }))}
                      placeholder={<div style={{ fontSize: '13px' }}>Select profile</div>}
                      onChange={(selectedOption) => {
                        setPersonalDetails({ ...personalDetails, profile: selectedOption?.value })
                      }}
                      value={personalDetails.profile ? { value: personalDetails.profile, label: personalDetails.profile } : null}
                    /> 
                    <p class="btn p-0 " style={{ color: "#0196C5", fontSize: '15px', float:'right',marginTop:'8px', fontWeight:'bold' }} >+ Add More</p> */}
                                                {/* {profileCategories.map((category, index) => (
                      <div key={category.id} style={{ marginTop: category.id !== 1 && '10px' }}>
                        <label for={`profileCategory-${category.id}`} style={{ fontSize: "15px" }}>Profile category</label>
                        <Select
                          id={`profileCategory-${category.id}`}
                          options={profiles.map(profile => ({ value: profile, label: profile }))}
                          placeholder={<div style={{ fontSize: '13px' }}>Select profile</div>}
                          onChange={(selectedOption) => handleProfileCategoryChange(selectedOption, index)}
                          value={category.profile ? { value: category.profile, label: category.profile } : null}
                        />
                        {category.profile && (
                          <div style={{ marginTop: "10px" }}>
                            <label for={`specializations-${category.id}`} style={{ fontSize: "15px" }}>Profile Specialization</label>
                            <Select
                              id={`specializations-${category.id}`}
                              isMulti
                              options={specializations[category.profile]?.map(spec => ({ value: spec, label: spec })) || []}
                              placeholder={<div style={{ fontSize: '13px' }}>Select specializations</div>}
                              onChange={(selectedOptions) => {
                                const updatedCategories = profileCategories.map((cat, i) => {
                                  if (i === index) {
                                    return { ...cat, specializations: selectedOptions.map(option => option.value) };
                                  }
                                  return cat;
                                });
                                setProfileCategories(updatedCategories);
                              }}
                              value={category.specializations.map(spec => ({ value: spec, label: spec }))}
                            />
                          </div>
                        )}
                        {index > 0 && (
                          <button type="button" className="btn btn-danger mt-2" onClick={() => removeProfileCategory(category.id)}>Remove</button>
                        )}
                      </div>
                    ))}
                    {categoryCount < 3 && (
                      <p className="btn p-0" style={{ color: "#0196C5", fontSize: '15px', float: 'right', marginTop: '8px', fontWeight: 'bold' }} onClick={addProfileCategory}>+ Add More</p>
                    )} */}
                                                {personalDetails.profileCategories.map((category, index) => (
                                                    <div key={category.id} style={{ marginTop: index !== 0 && "10px" }}>
                                                        <label htmlFor={`profileCategory-${index}`} style={{ fontSize: "15px" }}>
                                                            Profile category
                                                        </label>
                                                        <Select
                                                            id={`profileCategory-${index}`}
                                                            options={profiles.map((profile) => ({
                                                                value: profile,
                                                                label: profile,
                                                            }))}
                                                            placeholder={<div style={{ fontSize: "13px" }}>Select profile</div>}
                                                            onChange={(selectedOption) => handleProfileCategoryChange(selectedOption, index)}
                                                            value={
                                                                category.profile
                                                                    ? {
                                                                          value: category.profile,
                                                                          label: category.profile,
                                                                      }
                                                                    : null
                                                            }
                                                        />
                                                        {category.profile && (
                                                            <div style={{ marginTop: "10px" }}>
                                                                <label htmlFor={`specializations-${index}`} style={{ fontSize: "15px" }}>
                                                                    Profile Specialization
                                                                </label>
                                                                <Select
                                                                    id={`specializations-${index}`}
                                                                    isMulti
                                                                    options={specializations[category.profile]?.map((spec) => ({ value: spec, label: spec })) || []}
                                                                    placeholder={<div style={{ fontSize: "13px" }}>Select specializations</div>}
                                                                    onChange={(selectedOptions) => {
                                                                        const updatedCategories = personalDetails.profileCategories.map((cat, i) => {
                                                                            if (i === index) {
                                                                                return {
                                                                                    ...cat,
                                                                                    specializations: selectedOptions.map((option) => option.value),
                                                                                };
                                                                            }
                                                                            return cat;
                                                                        });
                                                                        setPersonalDetails({
                                                                            ...personalDetails,
                                                                            profileCategories: updatedCategories,
                                                                        });
                                                                    }}
                                                                    value={category.specializations.map((spec) => ({ value: spec, label: spec }))}
                                                                />
                                                            </div>
                                                        )}
                                                        {index > 0 && (
                                                            <button type="button" className="btn btn-danger mt-2" onClick={() => removeProfileCategory(category.id)}>
                                                                Remove
                                                            </button>
                                                        )}
                                                    </div>
                                                ))}
                                                {personalDetails.profileCategories.length < 3 && (
                                                    <p
                                                        className="btn p-0"
                                                        style={{
                                                            color: "#0196C5",
                                                            fontSize: "15px",
                                                            float: "right",
                                                            marginTop: "8px",
                                                            fontWeight: "bold",
                                                        }}
                                                        onClick={addProfileCategory}
                                                    >
                                                        + Add More
                                                    </p>
                                                )}
                                            </div>
                                            <div class="form-group mt-4 mb-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Profile Summary
                                                </label>
                                                <textarea
                                                    class="ModelInput form-control "
                                                    style={{
                                                        fontSize: "15px",
                                                        lineHeight: "20px",
                                                        borderColor: message.length > 5000 ? "red" : "",
                                                        borderWidth: message.length > 5000 ? "1.5px" : "",
                                                    }}
                                                    rows="6"
                                                    placeholder="Short description of work done..."
                                                    cols="50"
                                                    value={user && personalDetails.profileSummary}
                                                    onChange={handlPersonalDetailsChange}
                                                    ref={profileSummaryRef}
                                                    maxLength="5001"
                                                    id="profileSummary"
                                                    name="profileSummary"
                                                />
                                            </div>
                                            {message.length > 5000 && (
                                                <p className="text-end" style={{ fontSize: "13px", color: "red" }}>
                                                    Please limit your input to 5000 characters or less
                                                </p>
                                            )}
                                            <div class="form-group mt-4 mb-2">
                                                <label for="exampleInputEmail1 mt-2">Availability</label>
                                                <select
                                                    aria-label="Default select example"
                                                    class="form-select mt-1"
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "8px",
                                                        paddingBottom: "6px",
                                                        color: user && personalDetails.availability ? "black" : "gray",
                                                    }}
                                                    onChange={handlPersonalDetailsChange}
                                                    id="availability"
                                                    name="availability"
                                                    value={user && personalDetails.availability}
                                                    // placeholder={<div style={{ fontSize: '13px' }}>Select availability</div>}
                                                >
                                                    <option disabled selected value="" style={{ color: "gray" }}>
                                                        Select Availability
                                                    </option>
                                                    <option value="Immediate">Immediate</option>
                                                    <option value="Within 1 Week" style={{ color: "black" }}>
                                                        Within 1 Week
                                                    </option>
                                                    <option value="Within 2 Weeks" style={{ color: "black" }}>
                                                        Within 2 Weeks
                                                    </option>
                                                    <option value="Within 3 Weeks" style={{ color: "black" }}>
                                                        Within 3 Weeks
                                                    </option>
                                                    <option value="Within 4 Weeks" style={{ color: "black" }}>
                                                        Within 4 Weeks
                                                    </option>
                                                    <option value="Within 5 Weeks" style={{ color: "black" }}>
                                                        Within 5 Weeks
                                                    </option>
                                                    <option value="Within 6 Weeks" style={{ color: "black" }}>
                                                        Within 6 Weeks
                                                    </option>
                                                    <option value="Within 7 Weeks" style={{ color: "black" }}>
                                                        Within 7 Weeks
                                                    </option>
                                                    <option value="Within 8 Weeks" style={{ color: "black" }}>
                                                        Within 8 Weeks
                                                    </option>
                                                </select>
                                            </div>
                                            {/*  <div class="form-group mt-4 mb-2">
                    <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Profile</label>
                    <input
                    type="text"
                    style={{ fontSize: '15px' }}
                    class="ModelInput form-control "
                    placeholder="eg. MERN Developer"
                    value={user && personalDetails.profile}
                    onChange={handlPersonalDetailsChange}
                    id="profile"
                    name="profile"
                    />
                    </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {personalDetails._id ? (
                                        <>
                                            <button onClick={modifyPersonalDetails} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={updatePersonalDetails} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save data</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal for editing the photo */}
                {isEditingPhoto && (
                    <div className="modal fade show" style={{ display: "block" }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit Photo</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsEditingPhoto(false)}></button>
                                </div>
                                <div className="modal-body text-center">
                                    <AvatarEditor
                                        ref={(ref) => (editor = ref)}
                                        crossOrigin="anonymous"
                                        image={editorData}
                                        // image={personalDetails.photo?.url || personalDetails.photo}
                                        // width={200}
                                        // height={200}
                                        width={imageWidth}
                                        height={imageHeight}
                                        border={10}
                                        color={[218, 230, 242, 0.45]}
                                        scale={scale}
                                        rotate={rotate}
                                        position={position}
                                        onPositionChange={handlePositionChange}
                                    />
                                    <div className="mt-3">
                                        <label>Zoom: </label>
                                        <input type="range" min="1" max="3" step="0.1" value={scale} onChange={(e) => setScale(parseFloat(e.target.value))} />
                                    </div>
                                    <div className="mt-2">
                                        <label>Rotate: </label>
                                        <input type="range" min="0" max="360" step="1" value={rotate} onChange={(e) => setRotate(parseFloat(e.target.value))} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setIsEditingPhoto(false)}>
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={saveEditedPhoto}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Porfolio modal */}
                <div className="modal fade " id="portfoliomodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={portfolioModalRef}>
                    <div className="modal-dialog   " style={{ maxWidth: "90%" }}>
                        <div
                            className="modal-content custom-modal-dialog px-3 py-3"
                            style={{
                                borderRadius: "15px",
                                marginTop: "20px",
                                height: "94vh",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <div>
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "27px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Add a new portfolio project
                                    </p>
                                    <p
                                        className="mx-auto pb-3"
                                        style={{
                                            fontSize: "13px",
                                            color: "#404040",
                                            marginTop: "-8px",
                                        }}
                                    >
                                        All fields are required unless otherwise indicated
                                    </p>
                                </div>
                                <CloseButton className="btncloseportfolio" />
                            </div>
                            <div
                                className="modal-body"
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    overflowY: "auto",
                                }}
                            >
                                <div className="container" style={{ maxWidth: "100%" }}>
                                    <form style={{ flex: 1, overflowY: "auto" }}>
                                        <div class="form-group my-2" style={{ marginBottom: "27px" }}>
                                            <label htmlFor="projectTitle" className="form-label" style={{ fontSize: "15px" }}>
                                                Project title
                                            </label>

                                            <input
                                                style={{
                                                    fontSize: "15px",
                                                    paddingTop: "18px",
                                                    paddingBottom: "18px",
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter a brief but descriptive title"
                                                name="projectTitle"
                                                id="projectTitle"
                                                maxLength="100"
                                                value={portfolioDetails.projectTitle}
                                                onChange={handlePortfolioChange}
                                            />
                                            <small className="text-muted" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {100 - charCount.projectTitle} characters left
                                            </small>
                                        </div>

                                        <div className="mb-3 flex-container-portfolio">
                                            <div className=" left-section">
                                                <div>
                                                    <div class="form-group my-2" style={{ marginBottom: "27px" }}>
                                                        <label htmlFor="yourRole" className="form-label" style={{ fontSize: "15px" }}>
                                                            Your role (optional)
                                                        </label>

                                                        <input
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                            type="text"
                                                            className="form-control"
                                                            name="yourRole"
                                                            id="yourRole"
                                                            maxLength="100"
                                                            placeholder="e.g., Front-end engineer or Marketing analyst"
                                                            value={portfolioDetails.yourRole}
                                                            onChange={handlePortfolioChange}
                                                        />
                                                        <small
                                                            className="text-muted"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            {100 - charCount.yourRole} characters left
                                                        </small>
                                                    </div>
                                                    <div class="form-group my-2">
                                                        <label htmlFor="projectTitle" className="form-label" style={{ fontSize: "15px" }}>
                                                            Project description
                                                        </label>

                                                        <textarea
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                            className="form-control"
                                                            name="projectDescription"
                                                            id="projectDescription"
                                                            rows="3"
                                                            maxLength="600"
                                                            placeholder="Briefly describe the project's goals, your solution and the impact you made here"
                                                            value={portfolioDetails.projectDescription}
                                                            onChange={handlePortfolioChange}
                                                        />
                                                        <small
                                                            className="text-muted"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            {600 - charCount.projectDescription} characters left
                                                        </small>
                                                    </div>

                                                    <div className="form-group my-2">
                                                        <label htmlFor="skillsDeliverables" className="form-label" style={{ fontSize: "15px" }}>
                                                            Skills and deliverables
                                                        </label>

                                                        {/* <Select
                          options={pdskills.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          placeholder="List the key skills and deliverables"
                          onChange={handleSkillsChange}
                          value={portfolioDetails.skills}
                          isMulti
                          onInputChange={handleInputChange2}
                          menuIsOpen={isTyping2}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        /> */}
                                                        <SkillsSelector value={portfolioDetails.skills} handleSelect={(dt) => handleSkillsChange(dt)} />

                                                        <small
                                                            className="text-muted"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            {10 - selectedSkills.length} skills left
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-section">
                                                <div
                                                    className="mb-3"
                                                    style={{
                                                        minHeight: "240px",
                                                        marginTop: "35px",
                                                    }}
                                                >
                                                    {contentItems.map((item, index) => {
                                                        if (item.type === "image") {
                                                            return (
                                                                <div key={index} style={{ display: "flex", gap: "5px" }}>
                                                                    <div
                                                                        onClick={() => {
                                                                            handleSelectImage(index);
                                                                        }}
                                                                        className="d-flex flex-column justify-content-center align-items-center"
                                                                        style={{
                                                                            flex: 1,
                                                                            border: selectedImageIndex === index ? "2px solid #2092c7" : "2px solid transparent",
                                                                            borderRadius: "8px",
                                                                            minHeight: "200px",
                                                                            marginBottom: "20px",
                                                                            overflow: "hidden",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={item.src}
                                                                            alt={`uploaded ${index}`}
                                                                            className="uploaded-image"
                                                                            // style={{ maxHeight: "800px" }}
                                                                        />
                                                                    </div>
                                                                    {selectedImageIndex === index && (
                                                                        <div className="image-actions d-flex flex-column justify-content-center align-items-center" style={{ gap: "10px" }}>
                                                                            <FaArrowUp
                                                                                style={{
                                                                                    cursor: index === 0 ? "not-allowed" : "pointer",
                                                                                    color: index === 0 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index > 0 && handleMoveContentItem(index, -1)}
                                                                            />
                                                                            <FaArrowDown
                                                                                style={{
                                                                                    cursor: index === contentItems.length - 1 ? "not-allowed" : "pointer",
                                                                                    color: index === contentItems.length - 1 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index < contentItems.length - 1 && handleMoveContentItem(index, 1)}
                                                                            />
                                                                            <FaTrash
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    color: "#2092c7",
                                                                                }}
                                                                                onClick={() => handleDeleteContentItem(index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        } else if (item.type === "video") {
                                                            return (
                                                                <div className="video-container" style={{ display: "flex", gap: "5px" }} key={index}>
                                                                    <div
                                                                        onClick={() => handleSelectVideo(index)}
                                                                        className="d-flex flex-column justify-content-center align-items-center"
                                                                        style={{
                                                                            flex: 1,
                                                                            border: selectedVideoIndex === index ? "2px solid #2092c7" : "2px solid transparent",
                                                                            borderRadius: "8px",
                                                                            minHeight: "200px",
                                                                            marginBottom: "20px",
                                                                        }}
                                                                    >
                                                                        <iframe
                                                                            width="100%"
                                                                            height="450"
                                                                            src={getYouTubeEmbedUrl(item.src)}
                                                                            title={`Video ${index}`}
                                                                            frameBorder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowFullScreen
                                                                            style={{
                                                                                pointerEvents: "none",
                                                                                borderRadius: "8px",
                                                                            }}
                                                                        ></iframe>
                                                                        {/* <iframe
                                  width="100%"
                                  height="500"
                                  src={getYouTubeEmbedUrl(item.src)}
                                  title={`Video ${index}`}
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe> */}
                                                                        {/* <div
                                  className="video-overlay"
                                  style={{
                                    width:
                                      selectedVideoIndex === index
                                        ? "calc(100% - 18px)"
                                        : "100%",
                                  }}
                                >
                                  Video player interactions are disabled in
                                  edit mode.
                                </div> */}
                                                                    </div>

                                                                    {selectedVideoIndex === index && (
                                                                        <div className="video-actions d-flex flex-column justify-content-center align-items-center" style={{ gap: "10px" }}>
                                                                            <FaArrowUp
                                                                                style={{
                                                                                    cursor: index === 0 ? "not-allowed" : "pointer",
                                                                                    color: index === 0 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index > 0 && handleMoveContentItem(index, -1)}
                                                                            />
                                                                            <FaArrowDown
                                                                                style={{
                                                                                    cursor: index === contentItems.length - 1 ? "not-allowed" : "pointer",
                                                                                    color: index === contentItems.length - 1 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index < contentItems.length - 1 && handleMoveContentItem(index, 1)}
                                                                            />
                                                                            <FaTrash
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    color: "#2092c7",
                                                                                }}
                                                                                onClick={() => handleDeleteContentItem(index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        } else if (item.type === "weblink") {
                                                            return (
                                                                <div className="text-box-container" style={{ display: "flex", gap: "5px" }} key={index}>
                                                                    <div
                                                                        onClick={() => handleSelectWebLink(index)}
                                                                        className="d-flex flex-column justify-content-center align-items-center"
                                                                        style={{
                                                                            flex: 1,
                                                                            border: selectedWebIndex === index ? "2px solid #2092c7" : "2px solid transparent",
                                                                            borderRadius: "8px",
                                                                            minHeight: "240px",
                                                                            marginBottom: "20px",
                                                                        }}
                                                                    >
                                                                        {item.previewData && (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    height: "100%",
                                                                                    width: "100%",
                                                                                    alignItems: "center",
                                                                                    borderRadius: "10px",
                                                                                    backgroundColor: "#f9f9f9",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="preview-container form-group mt-4 mb-2"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        aldingItems: "center",
                                                                                        backgroundColor: "#f9f9f9",
                                                                                        paddingTop: "10px",
                                                                                        paddingBottom: "10px",
                                                                                        paddingLeft: "5px",
                                                                                        paddingRight: "10px",
                                                                                        borderRadius: "8px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            gap: "10px",
                                                                                        }}
                                                                                    >
                                                                                        {item.previewData.image && (
                                                                                            <img
                                                                                                src={item.previewData.image}
                                                                                                alt="Link preview"
                                                                                                style={{
                                                                                                    width: "100px",
                                                                                                    height: "100px",
                                                                                                    borderRadius: "5px",
                                                                                                }}
                                                                                            />
                                                                                        )}

                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "column",
                                                                                            }}
                                                                                        >
                                                                                            <h3
                                                                                                style={{
                                                                                                    fontSize: "16px",
                                                                                                    color: "#2092c7",
                                                                                                    fontWeight: "500",
                                                                                                    textDecoration: "underline",
                                                                                                    marginBottom: "8px",
                                                                                                }}
                                                                                            >
                                                                                                {item.previewData.title}
                                                                                            </h3>
                                                                                            <p
                                                                                                style={{
                                                                                                    fontSize: "14px",
                                                                                                    color: "#555",
                                                                                                }}
                                                                                            >
                                                                                                {item.previewData.description}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {selectedWebIndex === index && (
                                                                        <div className="video-actions d-flex flex-column justify-content-center align-items-center" style={{ gap: "10px" }}>
                                                                            <FaArrowUp
                                                                                style={{
                                                                                    cursor: index === 0 ? "not-allowed" : "pointer",
                                                                                    color: index === 0 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index > 0 && handleMoveContentItem(index, -1)}
                                                                            />
                                                                            <FaArrowDown
                                                                                style={{
                                                                                    cursor: index === contentItems.length - 1 ? "not-allowed" : "pointer",
                                                                                    color: index === contentItems.length - 1 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index < contentItems.length - 1 && handleMoveContentItem(index, 1)}
                                                                            />
                                                                            <FaTrash
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    color: "#2092c7",
                                                                                }}
                                                                                onClick={() => handleDeleteContentItem(index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        } else if (item.type === "text") {
                                                            return (
                                                                <div className="text-box-container" style={{ display: "flex", gap: "5px" }} key={index}>
                                                                    <div
                                                                        className="d-flex flex-column"
                                                                        onClick={() => handleSelectTextBox(index)}
                                                                        style={{
                                                                            paddingTop: "15px",
                                                                            flex: 1,
                                                                            border: selectedTextBoxes === index ? "2px solid #2092c7" : "2px solid transparent",
                                                                            borderRadius: "8px",
                                                                            marginBottom: "20px",
                                                                            backgroundColor: "#f9f9f9",
                                                                            transition: "height 0.3s ease-in-out",
                                                                            minHeight: !isMarkdown ? "180px" : "100px",
                                                                        }}
                                                                    >
                                                                        <div className="d-flex justify-content-end mb-2" style={{ marginRight: "115px" }}>
                                                                            {/* <span
                                  className={`text-mode-tab ${!isMarkdown ? 'active' : ''}`}
                                  onClick={() => setIsMarkdown(false)}
                                  style={{ cursor: 'pointer', padding: '5px 10px', borderBottom: !isMarkdown ? '2px solid #2092c7' : 'none', color: !isMarkdown && '#2092c7' }}
                                > */}
                                                                            <span
                                                                                className={`text-mode-tab ${!textBoxModes[index]?.isMarkdown ? "active" : ""}`}
                                                                                onClick={() => {
                                                                                    const newModes = [...textBoxModes];
                                                                                    newModes[index].isMarkdown = false;
                                                                                    setTextBoxModes(newModes);
                                                                                }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    padding: "5px 10px",
                                                                                    borderBottom: !textBoxModes[index]?.isMarkdown ? "2px solid #2092c7" : "none",
                                                                                    color: !textBoxModes[index]?.isMarkdown && "#2092c7",
                                                                                }}
                                                                            >
                                                                                Plain text
                                                                            </span>
                                                                            {/* <span
                                  className={`text-mode-tab ${isMarkdown ? 'active' : ''}`}
                                  onClick={() => setIsMarkdown(true)}
                                  style={{ cursor: 'pointer', padding: '5px 10px', borderBottom: isMarkdown ? '2px solid #2092c7' : 'none', color: isMarkdown && '#2092c7' }}
                                > */}
                                                                            <span
                                                                                className={`text-mode-tab ${textBoxModes[index]?.isMarkdown ? "active" : ""}`}
                                                                                onClick={() => {
                                                                                    const newModes = [...textBoxModes];
                                                                                    newModes[index].isMarkdown = true;
                                                                                    setTextBoxModes(newModes);
                                                                                }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    padding: "5px 10px",
                                                                                    borderBottom: textBoxModes[index]?.isMarkdown ? "2px solid #2092c7" : "none",
                                                                                    color: textBoxModes[index]?.isMarkdown && "#2092c7",
                                                                                }}
                                                                            >
                                                                                Markdown
                                                                            </span>
                                                                        </div>
                                                                        {/* {!isMarkdown && ( */}
                                                                        {!textBoxModes[index]?.isMarkdown && (
                                                                            <div>
                                                                                <input
                                                                                    type="text"
                                                                                    // value={heading}
                                                                                    value={item.heading}
                                                                                    onChange={(e) => {
                                                                                        // setHeading(e.target.value);
                                                                                        handleTextBoxChange(index, item.content, e.target.value);
                                                                                    }}
                                                                                    placeholder="Heading"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        padding: "10px",
                                                                                        fontSize: "23px",
                                                                                        marginBottom: "20px",
                                                                                        backgroundColor: "#f9f9f9",
                                                                                        outline: "none",
                                                                                        border: "none",
                                                                                    }}
                                                                                    maxLength={200}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <textarea
                                                                            type="text"
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "12rem",
                                                                                border: "none",
                                                                                paddingLeft: "10px",
                                                                                fontSize: "16px",
                                                                                borderRadius: "8px",
                                                                                outline: "none",
                                                                                backgroundColor: "#f9f9f9",
                                                                            }}
                                                                            value={item.content}
                                                                            // onChange={(e) => handleTextBoxChange(index, e.target.value)}
                                                                            // onChange={(e) => handleTextBoxChange(index, e.target.value, heading)}
                                                                            onChange={(e) => handleTextBoxChange(index, e.target.value, item.heading)}
                                                                            // placeholder={isMarkdown ? "Enter your Markdown" : "Enter your text"}
                                                                            placeholder={textBoxModes[index]?.isMarkdown ? "Enter your Markdown" : "Enter your text"}
                                                                            maxLength={5000}
                                                                        />
                                                                    </div>
                                                                    {selectedTextBoxes === index && (
                                                                        <div className="text-box-actions d-flex flex-column justify-content-center align-items-center" style={{ gap: "10px" }}>
                                                                            <FaArrowUp
                                                                                style={{
                                                                                    cursor: index === 0 ? "not-allowed" : "pointer",
                                                                                    color: index === 0 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index > 0 && handleMoveContentItem(index, -1)}
                                                                            />
                                                                            <FaArrowDown
                                                                                style={{
                                                                                    cursor: index === contentItems.length - 1 ? "not-allowed" : "pointer",
                                                                                    color: index === contentItems.length - 1 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index < contentItems.length - 1 && handleMoveContentItem(index, 1)}
                                                                            />
                                                                            <FaTrash
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    color: "#2092c7",
                                                                                }}
                                                                                onClick={() => handleDeleteContentItem(index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                    <div
                                                        className="d-flex flex-column justify-content-center align-items-center"
                                                        style={{
                                                            border: "1px dashed #2092c7",
                                                            borderRadius: "8px",
                                                            padding: "20px",
                                                            minHeight: "240px",
                                                        }}
                                                    >
                                                        {!contentItems.length ? (
                                                            <div className="d-flex justify-content-around w-75 mb-3">
                                                                <FaImage
                                                                    className="portfolio-icons"
                                                                    onClick={() => {
                                                                        document.getElementById("imageUpload").click();
                                                                        if (!DataSequence?.some((sq) => sq === "image")) {
                                                                            setDataSequence([...DataSequence, "image"]);
                                                                        }
                                                                    }}
                                                                />
                                                                <FaVideo className="portfolio-icons" data-bs-toggle="modal" data-bs-target="#videomodal" />
                                                                <FaTextHeight
                                                                    className="portfolio-icons"
                                                                    onClick={() => {
                                                                        if (!DataSequence?.some((sq) => sq === "text-content")) {
                                                                            setDataSequence([...DataSequence, "text-content"]);
                                                                        }
                                                                        handleAddTextBox();
                                                                    }}
                                                                />
                                                                <FaLink className="portfolio-icons" data-bs-toggle="modal" data-bs-target="#articlemodal" />
                                                                {/* <FaFileAudio className='portfolio-icons' />
                          <FaMusic className='portfolio-icons' /> */}
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex flex-column justify-content-around w-75 mb-3" style={{ alignItems: "center", gap: "45px" }}>
                                                                {!showContentIcons ? (
                                                                    <IoIosAddCircleOutline
                                                                        style={{
                                                                            fontSize: "40px",
                                                                            color: "#2092c7",
                                                                        }}
                                                                        onClick={handleToggleContentIcons}
                                                                        className="toggle-cross"
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <RxCross1
                                                                            style={{
                                                                                fontSize: "24px",
                                                                                color: "#2092c7",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={handleToggleContentIcons}
                                                                            className={`toggle-cross ${showContentIcons ? "visible" : "hidden"}`}
                                                                        />
                                                                        <div className={`d-flex justify-content-around w-75 mb-3 icon-container ${showContentIcons ? "visible" : ""}`}>
                                                                            <FaImage
                                                                                className="portfolio-icons"
                                                                                onClick={() => {
                                                                                    document.getElementById("imageUpload").click();
                                                                                    if (!DataSequence?.some((sq) => sq === "image")) {
                                                                                        setDataSequence([...DataSequence, "image"]);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <FaVideo className="portfolio-icons" data-bs-toggle="modal" data-bs-target="#videomodal" />
                                                                            <FaTextHeight
                                                                                className="portfolio-icons"
                                                                                onClick={() => {
                                                                                    if (!DataSequence?.some((sq) => sq === "text-content")) {
                                                                                        setDataSequence([...DataSequence, "text-content"]);
                                                                                    }
                                                                                    handleAddTextBox();
                                                                                }}
                                                                            />
                                                                            <FaLink className="portfolio-icons" data-bs-toggle="modal" data-bs-target="#articlemodal" />
                                                                            {/* <FaFileAudio className='portfolio-icons' />
                                <FaMusic className='portfolio-icons' /> */}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                        {/* <label
                              className="form-label"
                              style={{ marginTop: "10px" }}
                            >
                              Add content
                            </label> */}
                                                        <input type="file" id="imageUpload" style={{ display: "none" }} accept="image/*" onChange={handleImageUpload} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="footer-conatiner">
                                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{ borderRadius: '5px',fontSize:'16px' }}>Save as draft</button> */}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={handleSaveAsDraft}
                                    disabled={loading5}
                                >
                                    {loading5 ? (
                                        <div className="spinner-border text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        "Save as Draft"
                                    )}
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-success ms-2"
                                    onClick={handlePreviewClick}
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                >
                                    Next: Preview
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Edit Portfolio modal */}
                <div className="modal fade" id="editPortfolioModal" tabIndex="-1" aria-labelledby="editPortfolioModalLabel" aria-hidden="true">
                    <div className="modal-dialog custom-modal-dialog" style={{ maxWidth: "95%" }}>
                        <div
                            className="modal-content px-3 py-3"
                            style={{
                                borderRadius: "15px",
                                marginTop: "20px",
                                height: "94vh",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px",
                                    paddingRight: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <div>
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Edit portfolio project
                                    </p>
                                    <p
                                        className="mx-auto pb-3"
                                        style={{
                                            fontSize: "13px",
                                            color: "#404040",
                                            marginTop: "-8px",
                                        }}
                                    >
                                        All fields are required unless otherwise indicated
                                    </p>
                                </div>
                                <div>
                                    <CloseButton
                                        className="btncloseeditport"
                                        onClick={() => {
                                            const editModal = document.querySelector("#editPortfolioModal");
                                            if (editModal) {
                                                editModal.classList.remove("show");
                                                editModal.style.display = "none";
                                                editModal.setAttribute("aria-modal", false);
                                                editModal.removeAttribute("aria-hidden");
                                            }
                                        }}
                                    />
                                    {/* <button
                type="button"
                className="btn-close btncloseeditport"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  const editModal = document.querySelector(
                    "#editPortfolioModal"
                  );
                  if (editModal) {
                    editModal.classList.remove("show");
                    editModal.style.display = "none";
                    editModal.setAttribute("aria-modal", false);
                    editModal.removeAttribute("aria-hidden");
                  }
                }}
              ></button> */}
                                </div>
                            </div>
                            <div
                                className="modal-body"
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    overflowY: "auto",
                                }}
                            >
                                <div className="container" style={{ maxWidth: "100%" }}>
                                    <form style={{ flex: 1, overflowY: "auto" }}>
                                        <div class="form-group my-2" style={{ marginBottom: "27px" }}>
                                            <label htmlFor="projectTitle" className="form-label" style={{ fontSize: "15px" }}>
                                                Project title
                                            </label>

                                            <input
                                                style={{
                                                    fontSize: "15px",
                                                    paddingTop: "18px",
                                                    paddingBottom: "18px",
                                                }}
                                                type="text"
                                                className="form-control"
                                                name="editProjectTitle"
                                                id="editProjectTitle"
                                                maxLength="100"
                                                value={editPortfolioDetails?.projectTitle}
                                                onChange={(e) => {
                                                    setEditPortfolioDetails({
                                                        ...editPortfolioDetails,
                                                        projectTitle: e.target.value,
                                                    });
                                                    setCharCount1({
                                                        ...charCount1,
                                                        projectTitle: e.target.value.length,
                                                    });
                                                }}
                                            />
                                            <small className="text-muted" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {100 - charCount1.projectTitle} characters left
                                            </small>
                                        </div>
                                        {console.log("editPortfolioDetails", editPortfolioDetails, contentItemsFromDetails)}
                                        <div className="mb-3 flex-container-portfolio">
                                            <div style={{ flex: 1 }}>
                                                <div class="form-group my-2" style={{ marginBottom: "27px" }}>
                                                    <label htmlFor="yourRole" className="form-label" style={{ fontSize: "15px" }}>
                                                        Your role (optional)
                                                    </label>

                                                    <input
                                                        style={{
                                                            fontSize: "15px",
                                                            paddingTop: "18px",
                                                            paddingBottom: "18px",
                                                        }}
                                                        type="text"
                                                        className="form-control"
                                                        name="editYourRole"
                                                        id="editYourRole"
                                                        maxLength="100"
                                                        value={editPortfolioDetails?.yourRole}
                                                        onChange={(e) => {
                                                            setEditPortfolioDetails({
                                                                ...editPortfolioDetails,
                                                                yourRole: e.target.value,
                                                            });
                                                            setCharCount1({
                                                                ...charCount1,
                                                                yourRole: e.target.value.length,
                                                            });
                                                        }}
                                                    />
                                                    <small
                                                        className="text-muted"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        {100 - charCount1.yourRole} characters left
                                                    </small>
                                                </div>
                                                <div class="form-group my-2">
                                                    <label htmlFor="projectTitle" className="form-label" style={{ fontSize: "15px" }}>
                                                        Project description
                                                    </label>

                                                    <textarea
                                                        style={{
                                                            fontSize: "15px",
                                                            paddingTop: "18px",
                                                            paddingBottom: "18px",
                                                        }}
                                                        className="form-control"
                                                        name="editProjectDescription"
                                                        id="editProjectDescription"
                                                        rows="3"
                                                        maxLength="600"
                                                        value={editPortfolioDetails?.projectDescription}
                                                        onChange={(e) => {
                                                            setEditPortfolioDetails({
                                                                ...editPortfolioDetails,
                                                                projectDescription: e.target.value,
                                                            });
                                                            setCharCount1({
                                                                ...charCount1,
                                                                projectDescription: e.target.value.length,
                                                            });
                                                        }}
                                                    />
                                                    <small
                                                        className="text-muted"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        {600 - charCount1.projectDescription} characters left
                                                    </small>
                                                </div>

                                                <div className="form-group my-2">
                                                    <label htmlFor="skillsDeliverables" className="form-label" style={{ fontSize: "15px" }}>
                                                        Skills and deliverables
                                                    </label>
                                                    {/* <Select
                        options={pdskills.map((data) => ({
                          value: data,  
                          label: data,
                        }))}
                        placeholder="List the key skills and deliverables"
                        // onChange={(selectedOptions) => setEditPortfolioDetails({ ...editPortfolioDetails, skills: selectedOptions })}
                        onChange={handleSkillsChange2}
                        value={editPortfolioDetails?.skills}
                        isMulti
                        onInputChange={handleInputChange2}
                        menuIsOpen={isTyping2}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      /> */}
                                                    <EditSkillsSelector handleSelect={(dt) => handleSkillsChange2(dt)} data={editPortfolioDetails?.skills} />
                                                    <small
                                                        className="text-muted"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        {10 - selectedSkills10.length} skills left
                                                    </small>
                                                </div>
                                            </div>

                                            <div
                                                className="mb-3"
                                                style={{
                                                    flex: 2,
                                                    minHeight: "240px",
                                                    marginTop: "35px",
                                                }}
                                            >
                                                {contentItemsFromDetails.length !== 0 &&
                                                    contentItemsFromDetails.map((item, index) => {
                                                        if (item.type === "image") {
                                                            return (
                                                                <div key={index} style={{ display: "flex", gap: "5px" }}>
                                                                    <div
                                                                        onClick={() => handleSelectImage(index)}
                                                                        className="d-flex flex-column justify-content-center align-items-center"
                                                                        style={{
                                                                            flex: 1,
                                                                            border: selectedImageIndex === index ? "2px solid #2092c7" : "2px solid transparent",
                                                                            borderRadius: "8px",
                                                                            minHeight: "240px",
                                                                            marginBottom: "20px",
                                                                        }}
                                                                    >
                                                                        <img src={item && item.src} alt={`uploaded ${index}`} className="uploaded-image w-full h-full" />
                                                                    </div>
                                                                    {selectedImageIndex === index && (
                                                                        <div className="image-actions d-flex flex-column justify-content-center align-items-center" style={{ gap: "10px" }}>
                                                                            <FaArrowUp
                                                                                style={{
                                                                                    cursor: index === 0 ? "not-allowed" : "pointer",
                                                                                    color: index === 0 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index > 0 && handleMoveContentItemDraft(index, -1)}
                                                                            />
                                                                            <FaArrowDown
                                                                                style={{
                                                                                    cursor: index === contentItemsFromDetails.length - 1 ? "not-allowed" : "pointer",
                                                                                    color: index === contentItemsFromDetails.length - 1 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index < contentItemsFromDetails.length - 1 && handleMoveContentItemDraft(index, 1)}
                                                                            />
                                                                            <FaTrash
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    color: "#2092c7",
                                                                                }}
                                                                                onClick={() => handleDeleteContentItemDraft(index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        } else if (item.type === "video") {
                                                            console.log("contentItemsFromDetails", contentItemsFromDetails);
                                                            return (
                                                                <div className="video-container" style={{ display: "flex", gap: "5px" }} key={index}>
                                                                    <div
                                                                        onClick={() => handleSelectVideo(index)}
                                                                        className="d-flex flex-column justify-content-center align-items-center"
                                                                        style={{
                                                                            flex: 1,
                                                                            border: selectedVideoIndex === index ? "2px solid #2092c7" : "2px solid transparent",
                                                                            borderRadius: "8px",
                                                                            minHeight: "240px",
                                                                            marginBottom: "20px",
                                                                        }}
                                                                    >
                                                                        <iframe
                                                                            width="100%"
                                                                            height="450"
                                                                            src={getYouTubeEmbedUrl(item.src.url)}
                                                                            title={`Video ${index}`}
                                                                            frameBorder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowFullScreen
                                                                            style={{
                                                                                pointerEvents: "none",
                                                                                borderRadius: "8px",
                                                                            }}
                                                                        ></iframe>
                                                                        {/* <div
                                className="video-overlay"
                                style={{
                                  width:
                                    selectedVideoIndex === index
                                      ? "calc(100% - 18px)"
                                      : "100%",
                                }}
                              >
                                Video player interactions are disabled in
                                edit mode.
                              </div> */}
                                                                    </div>
                                                                    {selectedVideoIndex === index && (
                                                                        <div className="video-actions d-flex flex-column justify-content-center align-items-center" style={{ gap: "10px" }}>
                                                                            <FaArrowUp
                                                                                style={{
                                                                                    cursor: index === 0 ? "not-allowed" : "pointer",
                                                                                    color: index === 0 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index > 0 && handleMoveContentItemDraft(index, -1)}
                                                                            />
                                                                            <FaArrowDown
                                                                                style={{
                                                                                    cursor: index === contentItemsFromDetails.length - 1 ? "not-allowed" : "pointer",
                                                                                    color: index === contentItemsFromDetails.length - 1 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index < contentItemsFromDetails.length - 1 && handleMoveContentItemDraft(index, 1)}
                                                                            />
                                                                            <FaTrash
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    color: "#2092c7",
                                                                                }}
                                                                                onClick={() => handleDeleteContentItemDraft(index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        } else if (item.type === "weblink") {
                                                            return (
                                                                <div className="text-box-container" style={{ display: "flex", gap: "5px" }} key={index}>
                                                                    <div
                                                                        onClick={() => handleSelectWebLink(index)}
                                                                        className="d-flex flex-column justify-content-center align-items-center"
                                                                        style={{
                                                                            flex: 1,
                                                                            border: selectedWebIndex === index ? "2px solid #2092c7" : "2px solid transparent",
                                                                            borderRadius: "8px",
                                                                            minHeight: "240px",
                                                                            marginBottom: "20px",
                                                                        }}
                                                                    >
                                                                        {item.previewData && (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    height: "100%",
                                                                                    width: "100%",
                                                                                    alignItems: "center",
                                                                                    borderRadius: "10px",
                                                                                    backgroundColor: "#f9f9f9",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="preview-container form-group mt-4 mb-2"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        aldingItems: "center",
                                                                                        backgroundColor: "#f9f9f9",
                                                                                        paddingTop: "10px",
                                                                                        paddingBottom: "10px",
                                                                                        paddingLeft: "5px",
                                                                                        paddingRight: "10px",
                                                                                        borderRadius: "8px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            gap: "10px",
                                                                                        }}
                                                                                    >
                                                                                        {item.previewData.image && (
                                                                                            <img
                                                                                                src={item.previewData.image}
                                                                                                alt="Link preview"
                                                                                                style={{
                                                                                                    width: "100px",
                                                                                                    height: "100px",
                                                                                                    borderRadius: "5px",
                                                                                                }}
                                                                                            />
                                                                                        )}

                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                flexDirection: "column",
                                                                                            }}
                                                                                        >
                                                                                            <h3
                                                                                                style={{
                                                                                                    fontSize: "16px",
                                                                                                    color: "#2092c7",
                                                                                                    fontWeight: "500",
                                                                                                    textDecoration: "underline",
                                                                                                    marginBottom: "8px",
                                                                                                }}
                                                                                            >
                                                                                                {item.previewData.title}
                                                                                            </h3>
                                                                                            <p
                                                                                                style={{
                                                                                                    fontSize: "14px",
                                                                                                    color: "#555",
                                                                                                }}
                                                                                            >
                                                                                                {item.previewData.description}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {selectedWebIndex === index && (
                                                                        <div className="video-actions d-flex flex-column justify-content-center align-items-center" style={{ gap: "10px" }}>
                                                                            <FaArrowUp
                                                                                style={{
                                                                                    cursor: index === 0 ? "not-allowed" : "pointer",
                                                                                    color: index === 0 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index > 0 && handleMoveContentItemDraft(index, -1)}
                                                                            />
                                                                            <FaArrowDown
                                                                                style={{
                                                                                    cursor: index === contentItemsFromDetails.length - 1 ? "not-allowed" : "pointer",
                                                                                    color: index === contentItemsFromDetails.length - 1 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index < contentItemsFromDetails.length - 1 && handleMoveContentItemDraft(index, 1)}
                                                                            />
                                                                            <FaTrash
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    color: "#2092c7",
                                                                                }}
                                                                                onClick={() => handleDeleteContentItemDraft(index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        } else if (item.type === "text") {
                                                            return (
                                                                <div className="text-box-container" style={{ display: "flex", gap: "5px" }} key={index}>
                                                                    <div
                                                                        className="d-flex flex-column"
                                                                        onClick={() => handleSelectTextBox(index)}
                                                                        style={{
                                                                            paddingTop: "15px",
                                                                            flex: 1,
                                                                            border: selectedTextBoxes === index ? "2px solid #2092c7" : "2px solid transparent",
                                                                            borderRadius: "8px",
                                                                            marginBottom: "20px",
                                                                            backgroundColor: "#f9f9f9",
                                                                            transition: "height 0.3s ease-in-out",
                                                                            minHeight: !isMarkdown ? "180px" : "100px",
                                                                        }}
                                                                    >
                                                                        <div className="d-flex justify-content-end mb-2" style={{ marginRight: "115px" }}>
                                                                            <span
                                                                                className={`text-mode-tab ${!textBoxModesDraft[index]?.isMarkdown ? "active" : ""}`}
                                                                                onClick={() => {
                                                                                    const newModes = [...textBoxModesDraft];
                                                                                    newModes[index].isMarkdown = false;
                                                                                    setTextBoxModesDraft(newModes);
                                                                                }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    padding: "5px 10px",
                                                                                    borderBottom: !textBoxModesDraft[index]?.isMarkdown ? "2px solid #2092c7" : "none",
                                                                                    color: !textBoxModesDraft[index]?.isMarkdown && "#2092c7",
                                                                                }}
                                                                            >
                                                                                Plain text
                                                                            </span>

                                                                            <span
                                                                                className={`text-mode-tab ${textBoxModesDraft[index]?.isMarkdown ? "active" : ""}`}
                                                                                onClick={() => {
                                                                                    const newModes = [...textBoxModesDraft];
                                                                                    newModes[index].isMarkdown = true;
                                                                                    setTextBoxModesDraft(newModes);
                                                                                }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    padding: "5px 10px",
                                                                                    borderBottom: textBoxModesDraft[index]?.isMarkdown ? "2px solid #2092c7" : "none",
                                                                                    color: textBoxModesDraft[index]?.isMarkdown && "#2092c7",
                                                                                }}
                                                                            >
                                                                                Markdown
                                                                            </span>
                                                                        </div>

                                                                        {!textBoxModesDraft[index]?.isMarkdown && (
                                                                            <div>
                                                                                <input
                                                                                    type="text"
                                                                                    value={item.heading}
                                                                                    onChange={(e) => {
                                                                                        handleTextBoxChangeDraft(index, item.content, e.target.value);
                                                                                    }}
                                                                                    placeholder="Heading"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        padding: "10px",
                                                                                        fontSize: "23px",
                                                                                        marginBottom: "20px",
                                                                                        backgroundColor: "#f9f9f9",
                                                                                        outline: "none",
                                                                                        border: "none",
                                                                                    }}
                                                                                    maxLength={200}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <textarea
                                                                            type="text"
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "25rem",
                                                                                border: "none",
                                                                                paddingLeft: "10px",
                                                                                fontSize: "16px",
                                                                                borderRadius: "8px",
                                                                                backgroundColor: "#f9f9f9",
                                                                                outline: "none",
                                                                            }}
                                                                            value={item.content}
                                                                            onChange={(e) => handleTextBoxChangeDraft(index, e.target.value, item.heading)}
                                                                            placeholder={textBoxModes[index]?.isMarkdown ? "Enter your Markdown" : "Enter your text"}
                                                                            maxLength={5000}
                                                                        />
                                                                    </div>
                                                                    {selectedTextBoxes === index && (
                                                                        <div className="text-box-actions d-flex flex-column justify-content-center align-items-center" style={{ gap: "10px" }}>
                                                                            <FaArrowUp
                                                                                style={{
                                                                                    cursor: index === 0 ? "not-allowed" : "pointer",
                                                                                    color: index === 0 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index > 0 && handleMoveContentItemDraft(index, -1)}
                                                                            />
                                                                            <FaArrowDown
                                                                                style={{
                                                                                    cursor: index === contentItemsFromDetails.length - 1 ? "not-allowed" : "pointer",
                                                                                    color: index === contentItemsFromDetails.length - 1 ? "grey" : "#2092c7",
                                                                                }}
                                                                                onClick={() => index < contentItemsFromDetails.length - 1 && handleMoveContentItemDraft(index, 1)}
                                                                            />
                                                                            <FaTrash
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    color: "#2092c7",
                                                                                }}
                                                                                onClick={() => handleDeleteContentItemDraft(index)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                <div
                                                    className="d-flex flex-column justify-content-center align-items-center"
                                                    style={{
                                                        border: "1px dashed #2092c7",
                                                        borderRadius: "8px",
                                                        padding: "20px",
                                                        minHeight: "240px",
                                                    }}
                                                >
                                                    {!contentItemsFromDetails.length ? (
                                                        <div className="d-flex justify-content-around w-75 mb-3">
                                                            <FaImage className="portfolio-icons" onClick={() => document.getElementById("imageUpload").click()} />
                                                            <FaVideo className="portfolio-icons" data-bs-toggle="modal" data-bs-target="#videomodaldraft" />
                                                            <FaTextHeight className="portfolio-icons" onClick={handleAddTextBoxDraft} />
                                                            <FaLink className="portfolio-icons" data-bs-toggle="modal" data-bs-target="#articlemodaldraft" />
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex flex-column justify-content-around w-75 mb-3" style={{ alignItems: "center", gap: "45px" }}>
                                                            {!showContentIcons ? (
                                                                <IoIosAddCircleOutline style={{ fontSize: "40px", color: "#2092c7" }} onClick={handleToggleContentIcons} className="toggle-cross" />
                                                            ) : (
                                                                <>
                                                                    <RxCross1
                                                                        style={{
                                                                            fontSize: "24px",
                                                                            color: "#2092c7",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={handleToggleContentIcons}
                                                                        className={`toggle-cross ${showContentIcons ? "visible" : "hidden"}`}
                                                                    />
                                                                    <div className={`d-flex justify-content-around w-75 mb-3 icon-container ${showContentIcons ? "visible" : ""}`}>
                                                                        <FaImage className="portfolio-icons" onClick={() => document.getElementById("imageUpload").click()} />
                                                                        <FaVideo className="portfolio-icons" data-bs-toggle="modal" data-bs-target="#videomodaldraft" />
                                                                        <FaTextHeight className="portfolio-icons" onClick={handleAddTextBoxDraft} />
                                                                        <FaLink className="portfolio-icons" data-bs-toggle="modal" data-bs-target="#articlemodaldraft" />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                    <label className="form-label" style={{ marginTop: "10px" }}>
                                                        Add content
                                                    </label>
                                                    <input type="file" id="imageUpload" style={{ display: "none" }} accept="image/*" onChange={handleImageUploadDraft} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="footer-conatiner">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={handleSaveAsDraftForEdit}
                                    disabled={loading6}
                                >
                                    {loading6 ? (
                                        <div className="spinner-border text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        "Save as Draft"
                                    )}
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-success ms-2"
                                    onClick={handlePreviewClickDraft}
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                >
                                    Next: Preview
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* video modal */}
                <div className="modal fade custom-backdrop" id="videomodal" tabIndex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
                    {/* <div className="modal-dialog" style={{ maxWidth: '40%' }}> */}
                    <div className="modal-dialog modal-dialog-centered">
                        {/* <div className="modal-content" style={{ borderRadius: '10px' ,height:'68vh'}}> */}
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-body" style={{ overflowY: "auto" }}>
                                <div className="step-content container">
                                    <div style={{ marginRight: "-15px" }}>
                                        <CloseButton className="btnclosevideo" />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  className="btn-close btnclosevideo"
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Add video link
                                    </p>
                                    <p className="mx-auto pb-3" style={{ fontSize: "13px", color: "#404040" }}>
                                        Link to a video or upload a video. Only one option can be used at a time
                                    </p>
                                    <div class="form-group my-2">
                                        <label htmlFor="videoLink" className="form-label" style={{ fontSize: "15px" }}>
                                            Paste a link to your YouTube
                                        </label>
                                        <input
                                            style={{
                                                fontSize: "15px",
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                                borderRadius: "5px",
                                            }}
                                            type="text"
                                            className={`form-control ${isValidUrl ? "" : "is-invalid"}`}
                                            name="videoLink"
                                            id="videoLink"
                                            placeholder="YouTube video link"
                                            value={videoUrl}
                                            onChange={handleInputChangePortfolioVideo}
                                        />
                                        {!isValidUrl && (
                                            <div className="invalid-feedback" style={{ marginLeft: "2px" }}>
                                                Insert video link from YouTube and YouTube Shorts.
                                            </div>
                                        )}
                                    </div>
                                    {isValidUrl && videoUrl && (
                                        <div className="form-group mt-4 mb-2">
                                            <iframe width="100%" height="250" src={getYouTubeEmbedUrl(videoUrl)} title="Video Introduction" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    )}
                                    <hr className="dropdown-divider1 mb-4 mt-3" style={{ height: "0.05px", color: "rgb(185, 185, 185)" }} />
                                    {/* <div className="mb-3">
                <label className="form-label" style={{ fontSize: '15px' }}>Upload your video</label>
                <p className="text-muted" style={{ fontSize: '12px' }}>Up to 100 MB. Maximum 2 videos.</p>
                <button type="button" className="btn btn-outline-success" style={{ fontSize: '14px', borderRadius: '8px', borderColor: '#2092c7' }}>
                  <span style={{ display: 'flex', gap: '5px', color: '#2092c7' }}>
                    <MdOutlineFileUpload style={{ fontSize: '19px' }} />
                    Upload video
                  </span>

                </button>
              </div> */}
                                    <div className="form-check mb-3">
                                        <a
                                            className="form-check-label"
                                            htmlFor="videoGuidelines"
                                            style={{
                                                marginLeft: "-13px",
                                                fontSize: "13px",
                                                color: "#2092c7",
                                                display: "flex",
                                                gap: "7px",
                                            }}
                                            href="/privacy"
                                            target="__blank"
                                        >
                                            <TbExternalLink style={{ fontSize: "17px" }} />
                                            Does your video meet Workcroft's guidelines?
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-end" style={{ marginTop: "15px" }}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{
                                        borderRadius: "5px",
                                        marginRight: "10px",
                                        fontSize: "16px",
                                    }}
                                >
                                    Cancel
                                </button>
                                {/* <button type="button" className="btn btn-success" style={{ backgroundColor: '#2092c7', borderRadius: '5px' }}>Add</button> */}
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={() => {
                                        handleAddVideo(videoUrl);
                                        setVideoUrl("");
                                        // setVideos([...videos, videoUrl]);
                                        setShowVideoPreview(true);
                                        if (!DataSequence?.some((sq) => sq === "video")) {
                                            setDataSequence([...DataSequence, "video"]);
                                        }
                                        // Close the modal (optional)
                                        // document.getElementById('videomodal').classList.remove('show');
                                        // document.querySelector('.modal-backdrop').remove();
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* video modal draft */}
                <div className="modal fade custom-backdrop" id="videomodaldraft" tabIndex="-1" aria-labelledby="videoModaldraftLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-body" style={{ overflowY: "auto" }}>
                                <div className="step-content container">
                                    <div style={{ marginRight: "-15px" }}>
                                        <CloseButton className="btnclosevideodraft" />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  className="btn-close btnclosevideodraft"
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Add video link
                                    </p>
                                    <p className="mx-auto pb-3" style={{ fontSize: "13px", color: "#404040" }}>
                                        Link to a video or upload a video. Only one option can be used at a time
                                    </p>
                                    <div class="form-group my-2">
                                        <label htmlFor="videoLink" className="form-label" style={{ fontSize: "15px" }}>
                                            Paste a link to your YouTube
                                        </label>
                                        <input
                                            style={{
                                                fontSize: "15px",
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                                borderRadius: "5px",
                                            }}
                                            type="text"
                                            className={`form-control ${isValidUrl ? "" : "is-invalid"}`}
                                            name="videoLink"
                                            id="videoLink"
                                            placeholder="YouTube video link"
                                            value={videoUrl}
                                            onChange={handleInputChangePortfolioVideo}
                                        />
                                        {!isValidUrl && (
                                            <div className="invalid-feedback" style={{ marginLeft: "2px" }}>
                                                Insert video link from YouTube and YouTube Shorts.
                                            </div>
                                        )}
                                    </div>
                                    {isValidUrl && videoUrl && (
                                        <div className="form-group mt-4 mb-2">
                                            <iframe width="100%" height="250" src={getYouTubeEmbedUrl(videoUrl)} title="Video Introduction" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    )}
                                    <hr className="dropdown-divider1 mb-4 mt-3" style={{ height: "0.05px", color: "rgb(185, 185, 185)" }} />
                                    <div className="form-check mb-3">
                                        <a
                                            className="form-check-label"
                                            htmlFor="videoGuidelines"
                                            style={{
                                                marginLeft: "-13px",
                                                fontSize: "13px",
                                                color: "#2092c7",
                                                display: "flex",
                                                gap: "7px",
                                            }}
                                            href="/privacy"
                                            target="__blank"
                                        >
                                            <TbExternalLink style={{ fontSize: "17px" }} />
                                            Does your video meet Workcroft's guidelines?
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-end" style={{ marginTop: "15px" }}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{
                                        borderRadius: "5px",
                                        marginRight: "10px",
                                        fontSize: "16px",
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={() => {
                                        handleAddVideoDraft(videoUrl);
                                        setVideoUrl("");
                                        setShowVideoPreview(true);
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Article modal */}
                <div className="modal fade custom-backdrop" id="articlemodal" tabIndex="-1" aria-labelledby="articleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-body" style={{ overflowY: "auto" }}>
                                <div className="step-content container">
                                    <div style={{ marginRight: "-15px" }}>
                                        <CloseButton className="btnclosearticle" />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  className="btn-close btnclosearticle"
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Add a web link
                                    </p>
                                    <p className="mx-auto pb-3" style={{ fontSize: "13px", color: "#404040" }}>
                                        Only one link can be added at a time.
                                    </p>
                                    <div class="form-group my-2">
                                        <label htmlFor="webLink" className="form-label" style={{ fontSize: "15px" }}>
                                            Paste a web link to an article or website
                                        </label>
                                        <input
                                            style={{
                                                fontSize: "15px",
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                                borderRadius: "5px",
                                            }}
                                            type="text"
                                            className={`form-control ${!error ? "" : "is-invalid"}`}
                                            name="webLink"
                                            id="webLink"
                                            placeholder="Article or website link"
                                            value={link}
                                            // onChange={(e) => setLink(e.target.value)}
                                            onChange={(e) => {
                                                setLink(e.target.value);
                                                setTimeout(() => {
                                                    fetchPreviewData();
                                                }, 1000);
                                            }}
                                            onBlur={() => {
                                                if (link) {
                                                    setTimeout(() => {
                                                        fetchPreviewData();
                                                    }, 1000);
                                                    return;
                                                }
                                                setError(false);
                                            }}
                                        />
                                    </div>
                                    {error && (
                                        <div className="invalid-feedback" style={{ marginLeft: "2px" }}>
                                            Unable to get a preview. Please try again later.
                                        </div>
                                    )}

                                    {previewData && !error && (
                                        <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                            <div
                                                className="preview-container form-group mt-4 mb-2"
                                                style={{
                                                    backgroundColor: "#f9f9f9",
                                                    paddingTop: "10px",
                                                    paddingBottom: "10px",
                                                    paddingLeft: "5px",
                                                    paddingRight: "10px",
                                                    borderRadius: "8px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    {previewData.image && (
                                                        <img
                                                            src={previewData.image}
                                                            alt="Link preview"
                                                            style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                borderRadius: "5px",
                                                            }}
                                                        />
                                                    )}

                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <h3
                                                            style={{
                                                                fontSize: "16px",
                                                                color: "#2092c7",
                                                                fontWeight: "500",
                                                                textDecoration: "underline",
                                                                marginBottom: "8px",
                                                            }}
                                                        >
                                                            {previewData.title}
                                                        </h3>
                                                        <p style={{ fontSize: "14px", color: "#555" }}>{previewData.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    )}

                                    <hr className="dropdown-divider1 mb-4 mt-3" style={{ height: "0.05px", color: "rgb(185, 185, 185)" }} />

                                    <div className="form-check mb-3">
                                        <a
                                            className="form-check-label"
                                            htmlFor="videoGuidelines"
                                            style={{
                                                marginLeft: "-13px",
                                                fontSize: "13px",
                                                color: "#2092c7",
                                                display: "flex",
                                                gap: "7px",
                                            }}
                                            href="/privacy"
                                            target="__blank"
                                        >
                                            <TbExternalLink style={{ fontSize: "17px" }} />
                                            Does your link meet Workcroft's guidelines?
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-end" style={{ marginTop: "15px" }}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{
                                        borderRadius: "5px",
                                        marginRight: "10px",
                                        fontSize: "16px",
                                    }}
                                >
                                    Cancel
                                </button>
                                {/* <button type="button" className="btn btn-success" style={{ backgroundColor: '#2092c7', borderRadius: '5px' }}>Add</button> */}
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={() => {
                                        //console.log(previewData, "khsdka");
                                        handleAddWeblink(link);
                                        // setLink('');
                                        if (!DataSequence?.some((sq) => sq === "artical")) {
                                            setDataSequence([...DataSequence, "artical"]);
                                        }
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Article modal Draft*/}
                <div className="modal fade custom-backdrop" id="articlemodaldraft" tabIndex="-1" aria-labelledby="articleModaldraftLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-body" style={{ overflowY: "auto" }}>
                                <div className="step-content container">
                                    <div style={{ marginRight: "-15px" }}>
                                        <CloseButton className=" btnclosearticledraft" />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  className="btn-close btnclosearticledraft"
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Add a web link
                                    </p>
                                    <p className="mx-auto pb-3" style={{ fontSize: "13px", color: "#404040" }}>
                                        Only one link can be added at a time.
                                    </p>
                                    <div class="form-group my-2">
                                        <label htmlFor="webLink" className="form-label" style={{ fontSize: "15px" }}>
                                            Paste a web link to an article or website
                                        </label>
                                        <input
                                            style={{
                                                fontSize: "15px",
                                                paddingTop: "18px",
                                                paddingBottom: "18px",
                                                borderRadius: "5px",
                                            }}
                                            type="text"
                                            className={`form-control ${!error ? "" : "is-invalid"}`}
                                            name="webLink"
                                            id="webLink"
                                            placeholder="Article or website link"
                                            value={link}
                                            // onChange={(e) => setLink(e.target.value)}
                                            onChange={(e) => {
                                                setLink(e.target.value);
                                                fetchPreviewData();
                                            }}
                                            onBlur={fetchPreviewData}
                                        />
                                    </div>
                                    {error && (
                                        <div className="invalid-feedback" style={{ marginLeft: "2px" }}>
                                            Unable to get a preview. Please try again later.
                                        </div>
                                    )}

                                    {previewData && !error && (
                                        <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                            <div
                                                className="preview-container form-group mt-4 mb-2"
                                                style={{
                                                    backgroundColor: "#f9f9f9",
                                                    paddingTop: "10px",
                                                    paddingBottom: "10px",
                                                    paddingLeft: "5px",
                                                    paddingRight: "10px",
                                                    borderRadius: "8px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <div style={{ display: "flex", gap: "10px" }}>
                                                    {previewData.image && (
                                                        <img
                                                            src={previewData.image}
                                                            alt="Link preview"
                                                            style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                borderRadius: "5px",
                                                            }}
                                                        />
                                                    )}

                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <h3
                                                            style={{
                                                                fontSize: "16px",
                                                                color: "#2092c7",
                                                                fontWeight: "500",
                                                                textDecoration: "underline",
                                                                marginBottom: "8px",
                                                            }}
                                                        >
                                                            {previewData.title}
                                                        </h3>
                                                        <p style={{ fontSize: "14px", color: "#555" }}>{previewData.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    )}

                                    <hr className="dropdown-divider1 mb-4 mt-3" style={{ height: "0.05px", color: "rgb(185, 185, 185)" }} />

                                    <div className="form-check mb-3">
                                        <a
                                            className="form-check-label"
                                            htmlFor="videoGuidelines"
                                            style={{
                                                marginLeft: "-13px",
                                                fontSize: "13px",
                                                color: "#2092c7",
                                                display: "flex",
                                                gap: "7px",
                                            }}
                                            href="/privacy"
                                            target="__blank"
                                        >
                                            <TbExternalLink style={{ fontSize: "17px" }} />
                                            Does your link meet Workcroft's guidelines?
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-end" style={{ marginTop: "15px" }}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{
                                        borderRadius: "5px",
                                        marginRight: "10px",
                                        fontSize: "16px",
                                    }}
                                >
                                    Cancel
                                </button>
                                {/* <button type="button" className="btn btn-success" style={{ backgroundColor: '#2092c7', borderRadius: '5px' }}>Add</button> */}
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={() => {
                                        handleAddWebLinkDraft(link);
                                        setLink("");
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Preview Modal */}

                <div className="modal fade" id="previewmodal" tabIndex="-1" aria-labelledby="previewModalLabel" aria-hidden="true">
                    <div className="modal-dialog " style={{ maxWidth: "96%" }}>
                        <div
                            className="modal-content px  py-3 "
                            style={{
                                borderRadius: "15px",
                                marginTop: "10px",
                                height: "95vh",
                            }}
                        >
                            <div className="preview-header">
                                <h2 style={{ fontSize: "28px" }}>{portfolioDetails?.projectTitle}</h2>
                                <CloseButton className="btnclosepreview" />
                            </div>
                            <div
                                className="modal-body"
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    overflowY: "auto",
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                }}
                            >
                                <div className="mb-3 flex-container-portfolio">
                                    <div className="left-section">
                                        <div>
                                            {portfolioDetails && portfolioDetails.yourRole !== "" && (
                                                <div style={{ marginBottom: "25px" }}>
                                                    <p style={{ fontSize: "16px" }}>
                                                        <b>My role:</b> {portfolioDetails.yourRole}
                                                    </p>
                                                </div>
                                            )}
                                            {portfolioDetails && portfolioDetails.projectDescription !== "" && (
                                                <div style={{ marginBottom: "25px" }}>
                                                    <p style={{ fontSize: "16px" }}>
                                                        <b>Project Description:</b>{" "}
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: portfolioDetails.projectDescription.replace(/\n/g, "<br />"),
                                                            }}
                                                        />
                                                    </p>
                                                </div>
                                            )}
                                            <div style={{ marginBottom: "25px" }}>
                                                <h4>Skills and deliverables </h4>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "3px",
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    {portfolioDetails &&
                                                        portfolioDetails.skills.map((skill) => (
                                                            <span key={skill.value} className="post1234forresume  " style={{ marginBottom: "10px" }}>
                                                                {skill.label}
                                                            </span>
                                                        ))}
                                                </div>
                                            </div>

                                            <hr
                                                // className="dropdown-divider1 mb-4 mt-3"
                                                style={{
                                                    height: "0.05px",
                                                    color: "rgb(185, 185, 185)",
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="right-section">
                                        <div className="mb-3" style={{ minHeight: "240px" }}>
                                            {/* {portfolioDetailsData?.map((item, index) => (
                    <div key={index}>
                      {item?.type === "image" && (
                        <div>
                          {item?.images?.map((img, idx) => (
                            <div
                              key={idx}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "25px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                key={idx}
                                src={img}
                                alt={`Project Photo ${idx + 1}`}
                                style={{
                                  marginBottom: "10px",
                                  height: "400px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      {item?.type === "video" && (
                        <div>
                          {item?.videos?.map((vd, idx) => (
                            <div key={idx} style={{ marginBottom: "10px" }}>
                              <iframe
                                width="100%"
                                height="400"
                                src={getYouTubeEmbedUrl(vd)}
                                title="Preview Introduction"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          ))}
                        </div>
                      )}
                      {item?.type === "text-content" && (
                        <div>
                          {item?.textContents?.map((txt, idx) => (
                            <div key={idx}>
                              <h5
                                style={{
                                  fontSize: "25px",
                                  fontWeight: "500",
                                  marginBottom: "15px",
                                  textAlign: "justify",
                                }}
                              >
                                {txt.heading}
                              </h5>
                              <p
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "15px",
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: txt.content.replace(
                                      /\n/g,
                                      "<br />"
                                    ),
                                  }}
                                />
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                      {item?.type === "artical" && (
                        <div>
                          {item?.webLinks?.map((link, idx) => (
                            <div key={idx} style={{ marginBottom: "25px" }}>
                              <a
                                href={link?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                <div
                                  className="preview-container form-group mt-4 mb-2"
                                  style={{
                                    backgroundColor: "#f9f9f9",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingLeft: "5px",
                                    paddingRight: "10px",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    style={{ display: "flex", gap: "10px" }}
                                  >
                                    {link.image && (
                                      <img
                                        src={link.image}
                                        alt="Link preview"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    )}
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <h3
                                        style={{
                                          fontSize: "16px",
                                          color: "#2092c7",
                                          fontWeight: "500",
                                          textDecoration: "underline",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        {link.title}
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          color: "#555",
                                        }}
                                      >
                                        {link.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))} */}

                                            {/* Display Text Content
                      {portfolioDetails &&
                        portfolioDetails.textContent.length > 0 && (
                          <div style={{ marginBottom: "25px" }}>
                            {portfolioDetails.textContent.map((text, index) => (
                              <div key={index}>
                                <h5
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "500",
                                    marginBottom: "15px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {text.heading}
                                </h5>
                                <p
                                  style={{
                                    fontSize: "18px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: text.content.replace(
                                        /\n/g,
                                        "<br />"
                                      ),
                                    }}
                                  />
                                </p>
                              </div>
                            ))}
                          </div>
                        )}

                      {portfolioDetails &&
                        portfolioDetails.photo.length > 0 && (
                          <div style={{ marginBottom: "25px" }}>
                            {portfolioDetails.photo.map((imgSrc, index) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "25px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  key={index}
                                  src={imgSrc.url}
                                  alt={`Project Photo ${index + 1}`}
                                  className="uploaded-image"
                                  // style={{
                                  //   marginBottom: "10px",
                                  //   maxHeight: "800px",
                                  //   alignItems: "center",
                                  //   justifyContent: "center",
                                  // }}
                                />
                              </div>
                            ))}
                          </div>
                        )}

                      {/* Display Video Links 
                      {portfolioDetails &&
                        portfolioDetails.videoLink.length > 0 && (
                          <div style={{ marginBottom: "25px" }}>
                            {portfolioDetails.videoLink.map((link, index) => (
                              <div key={index} style={{ marginBottom: "10px" }}>
                                <iframe
                                  width="100%"
                                  height="450"
                                  src={getYouTubeEmbedUrl(link.url)}
                                  title="Preview Introduction"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                  style={{ borderRadius: "8px" }}
                                ></iframe>
                              </div>
                            ))}
                          </div>
                        )}

                      {portfolioDetails &&
                        portfolioDetails.webLink.length > 0 && (
                          <div style={{ marginBottom: "25px" }}>
                            {portfolioDetails.webLink.map((link, index) => (
                              <div key={index} style={{ marginBottom: "25px" }}>
                                {previewData1 &&
                                previewData1[index] &&
                                !error1 ? ( // Add check for previewData1
                                  <a
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <div
                                      className="preview-container form-group mt-4 mb-2"
                                      style={{
                                        backgroundColor: "#f9f9f9",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        paddingLeft: "5px",
                                        paddingRight: "10px",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        {previewData1[index].image && (
                                          <img
                                            src={previewData1[index].image}
                                            alt="Link preview"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h3
                                            style={{
                                              fontSize: "16px",
                                              color: "#2092c7",
                                              fontWeight: "500",
                                              textDecoration: "underline",
                                              marginBottom: "8px",
                                            }}
                                          >
                                            {previewData1[index].title}
                                          </h3>
                                          <p
                                            style={{
                                              fontSize: "14px",
                                              color: "#555",
                                            }}
                                          >
                                            {previewData1[index].description}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                ) : (
                                  <p>Loading...</p>
                                )}
                              </div>
                            ))}
                          </div>
                        )} */}
                                            <PreviewModel portfolioDetails={portfolioDetails} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer" style={{ marginTop: "15px", justifyContent: "space-between" }}>
                                <div className="text-start">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        style={{
                                            borderRadius: "7px",
                                            fontSize: "16px",
                                            color: "#2092c7",
                                            backgroundColor: "white",
                                            borderColor: "#f9f9f9",
                                        }}
                                    >
                                        Back
                                    </button>
                                </div>
                                <div className="text-end">
                                    {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{ borderRadius: '7px', fontSize: '16px', color: '#2092c7', backgroundColor: 'white' }}>Save as draft</button> */}
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        style={{
                                            backgroundColor: "#2092c7",
                                            borderRadius: "5px",
                                            fontSize: "16px",
                                        }}
                                        onClick={handleSaveAsDraft}
                                        disabled={loading5}
                                    >
                                        {loading5 ? (
                                            <div className="spinner-border text-light" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : (
                                            "Save as Draft"
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-success ms-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#thumbnailmodal"
                                        style={{
                                            backgroundColor: "#2092c7",
                                            borderRadius: "7px",
                                            fontSize: "16px",
                                        }}
                                    >
                                        Next: Thumbnail
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Preview Modal Draft */}
                <div className="modal fade" id="previewmodaldraft" tabIndex="-1" aria-labelledby="previewModaldraftLabel" aria-hidden="true">
                    <div className="modal-dialog custom-modal-dialog" style={{ maxWidth: "98%" }}>
                        <div
                            className="modal-content px-3 py-3"
                            style={{
                                borderRadius: "15px",
                                marginTop: "20px",
                                height: "94vh",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: "10px",
                                    paddingLeft: "50px",
                                    paddingBottom: "10px",
                                }}
                            >
                                <div>{editPortfolioDetails && <h2 style={{ fontSize: "28px" }}>{editPortfolioDetails.projectTitle}</h2>}</div>
                                <CloseButton className="btnclosepreviewdraft" />
                            </div>
                            <div
                                className="modal-body"
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    overflowY: "auto",
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                }}
                            >
                                {/* <div style={{ textAlign: "end" }}>
              <button
                type="button"
                className="btn-close btnclosepreviewdraft"
                data-bs-dismiss="modal"
                style={{ marginRight: "-10px" }}
                aria-label="Close"
              ></button>
            </div> */}

                                <div className="mb-3 flex-container-portfolio">
                                    <div style={{ flex: 1, marginRight: "50px" }}>
                                        {/* <div style={{ marginTop: "15px", marginBottom: "40px" }}>
                  {editPortfolioDetails && (
                    <h2 style={{ fontSize: "28px" }}>
                      {editPortfolioDetails.projectTitle}
                    </h2>
                  )}
                </div> */}
                                        {editPortfolioDetails && editPortfolioDetails.yourRole !== "" && (
                                            <div style={{ marginBottom: "25px" }}>
                                                <p style={{ fontSize: "16px" }}>
                                                    <b>My role:</b> {editPortfolioDetails.yourRole}
                                                </p>
                                            </div>
                                        )}
                                        {editPortfolioDetails && editPortfolioDetails.projectDescription !== "" && (
                                            <div style={{ marginBottom: "25px" }}>
                                                <p style={{ fontSize: "16px" }}>
                                                    <b>Project Description:</b>{" "}
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: editPortfolioDetails.projectDescription.replace(/\n/g, "<br />"),
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                        )}
                                        <div style={{ marginBottom: "25px" }}>
                                            <h4>Skills and deliverables</h4>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "3px",
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                {editPortfolioDetails &&
                                                    editPortfolioDetails.skills.map((skill) => (
                                                        <span key={skill.value} className="post1234forresume mr-4 mt-3" style={{ marginBottom: "10px" }}>
                                                            {skill.label}
                                                        </span>
                                                    ))}
                                            </div>
                                        </div>

                                        <hr className="dropdown-divider1 mb-4 mt-3" style={{ height: "0.05px", color: "rgb(185, 185, 185)" }} />
                                    </div>

                                    <div className="mb-3" style={{ flex: 2, minHeight: "240px" }}>
                                        {/* Display Text Content */}
                                        {/* {editPortfolioDetails &&
                      editPortfolioDetails.textContent.length > 0 && (
                        <div style={{ marginBottom: "25px" }}>
                          {editPortfolioDetails.textContent.map(
                            (text, index) => (
                              <div key={index}>
                                <h5
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "500",
                                    marginBottom: "15px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {text.heading}
                                </h5>
                                <p
                                  style={{
                                    fontSize: "18px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {/* <span
                            dangerouslySetInnerHTML={{
                              __html: isExpanded1
                                ? text.content.replace(/\n/g, '<br />')
                                : text.content.slice(0, 600).replace(/\n/g, '<br />')
                            }}
                          /> */}
                                        {/* {text.content} */}
                                        {/* {text.content.length > 600 && (
                            <>
                              {!isExpanded1 && <span style={{ marginLeft: '5px' }}> ...</span>}
                              <span
                                onClick={() => setIsExpanded1(!isExpanded1)}
                                style={{ color: '#2092c7', cursor: 'pointer', marginLeft: '5px', textDecoration: 'underline' }}
                              >
                                <br />
                                {isExpanded1 ? 'less' : 'more'}
                              </span>
                            </> */}
                                        {/* <span
                                    dangerouslySetInnerHTML={{
                                      __html: text.content.replace(
                                        /\n/g,
                                        "<br />"
                                      ),
                                    }}
                                  /> */}
                                        {/* )} */}
                                        {/* </p>
                              </div>
                            )
                          )}
                        </div>
                      )} */}

                                        {/* {editPortfolioDetails && editPortfolioDetails.photo.length > 0 && (
                  <div style={{ marginBottom: '25px' }}>
                    {editPortfolioDetails.photo.map((imgSrc, index) => (
                      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '25px', alignItems: 'center', justifyContent: 'center' }}>
                        <img key={index} src={imgSrc || imgSrc.url} alt={`Project Photo ${index + 1}`} style={{ marginBottom: '10px', maxHeight: '800px', alignItems: 'center', justifyContent: 'center' }} />
                      </div>
                    ))}
                  </div>
                )} */}
                                        {/* {editPortfolioDetails &&
                      editPortfolioDetails.photo.length > 0 && (
                        <div style={{ marginBottom: "25px" }}>
                          {editPortfolioDetails.photo.map((imgSrc, index) => {
                            // Determine if imgSrc is a string or an object
                            const imageUrl =
                              typeof imgSrc === "string" ? imgSrc : imgSrc.url;
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "25px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={imageUrl}
                                  alt={`Project Photo ${index + 1}`}
                                  style={{
                                    marginBottom: "10px",
                                    maxHeight: "800px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )} */}

                                        {/* Display Video Links */}
                                        {/* {editPortfolioDetails &&
                      editPortfolioDetails.videoLink?.length > 0 && (
                        <div style={{ marginBottom: "25px" }}>
                          {editPortfolioDetails.videoLink.map((link, index) => (
                            <div key={index} style={{ marginBottom: "10px" }}>
                              <iframe
                                width="100%"
                                height="500"
                                src={getYouTubeEmbedUrl(link)}
                                title="Preview Introduction"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          ))}
                        </div>
                      )} */}

                                        {/* {editPortfolioDetails &&
                      editPortfolioDetails?.webLink?.length > 0 && (
                        <div style={{ marginBottom: "25px" }}>
                          {editPortfolioDetails?.webLink.map((link, index) => (
                            <div key={index} style={{ marginBottom: "25px" }}>
                              {previewData2 &&
                              previewData2[index] &&
                              !error2 ? ( // Add check for previewData1
                                <a
                                  href={link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ textDecoration: "none" }}
                                >
                                  <div
                                    className="preview-container form-group mt-4 mb-2"
                                    style={{
                                      backgroundColor: "#f9f9f9",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      paddingLeft: "5px",
                                      paddingRight: "10px",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      {previewData2[index].image && (
                                        <img
                                          src={previewData2[index].image}
                                          alt="Link preview"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h3
                                          style={{
                                            fontSize: "16px",
                                            color: "#2092c7",
                                            fontWeight: "500",
                                            textDecoration: "underline",
                                            marginBottom: "8px",
                                          }}
                                        >
                                          {previewData2[index].title}
                                        </h3>
                                        <p
                                          style={{
                                            fontSize: "14px",
                                            color: "#555",
                                          }}
                                        >
                                          {previewData2[index].description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              ) : (
                                <p>Loading...</p>
                              )}
                            </div>
                          ))}
                        </div>
                      )} */}
                                        <PreviewModel portfolioDetails={editPortfolioDetails} />
                                        {/* {contentItemsFromDetails.map((item, index) => {
                      if (item.type === "image") {
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", gap: "5px" }}
                          >
                            <div
                              onClick={() => handleSelectImage(index)}
                              className="d-flex flex-column justify-content-center align-items-center"
                              style={{
                                flex: 1,
                                border:
                                  selectedImageIndex === index
                                    ? "2px solid #2092c7"
                                    : "2px solid transparent",
                                borderRadius: "8px",
                                minHeight: "240px",
                                marginBottom: "20px",
                              }}
                            >
                              <img
                                src={item.src ? item.src : item}
                                alt={`uploaded ${index}`}
                                className="uploaded-image w-full h-full"
                              />
                            </div>
                            {selectedImageIndex === index && (
                              <div
                                className="image-actions d-flex flex-column justify-content-center align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <FaArrowUp
                                  style={{
                                    cursor:
                                      index === 0 ? "not-allowed" : "pointer",
                                    color: index === 0 ? "grey" : "#2092c7",
                                  }}
                                  onClick={() =>
                                    index > 0 &&
                                    handleMoveContentItemDraft(index, -1)
                                  }
                                />
                                <FaArrowDown
                                  style={{
                                    cursor:
                                      index ===
                                      contentItemsFromDetails.length - 1
                                        ? "not-allowed"
                                        : "pointer",
                                    color:
                                      index ===
                                      contentItemsFromDetails.length - 1
                                        ? "grey"
                                        : "#2092c7",
                                  }}
                                  onClick={() =>
                                    index <
                                      contentItemsFromDetails.length - 1 &&
                                    handleMoveContentItemDraft(index, 1)
                                  }
                                />
                                <FaTrash
                                  style={{
                                    cursor: "pointer",
                                    color: "#2092c7",
                                  }}
                                  onClick={() =>
                                    handleDeleteContentItemDraft(index)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      } else if (item.type === "video") {
                        console.log(
                          "contentItemsFromDetails",
                          contentItemsFromDetails
                        );
                        return (
                          <div
                            className="video-container"
                            style={{ display: "flex", gap: "5px" }}
                            key={index}
                          >
                            <div
                              onClick={() => handleSelectVideo(index)}
                              className="d-flex flex-column justify-content-center align-items-center"
                              style={{
                                flex: 1,
                                border:
                                  selectedVideoIndex === index
                                    ? "2px solid #2092c7"
                                    : "2px solid transparent",
                                borderRadius: "8px",
                                minHeight: "240px",
                                marginBottom: "20px",
                              }}
                            >
                              <iframe
                                width="100%"
                                height="450"
                                src={getYouTubeEmbedUrl(item.src.url)}
                                title={`Video ${index}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{
                                  pointerEvents: "none",
                                  borderRadius: "8px",
                                }}
                              ></iframe>
                              {/* <div
                                className="video-overlay"
                                style={{
                                  width:
                                    selectedVideoIndex === index
                                      ? "calc(100% - 18px)"
                                      : "100%",
                                }}
                              >
                                Video player interactions are disabled in
                                edit mode.
                              </div> //*}
                            </div>
                            {selectedVideoIndex === index && (
                              <div
                                className="video-actions d-flex flex-column justify-content-center align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <FaArrowUp
                                  style={{
                                    cursor:
                                      index === 0 ? "not-allowed" : "pointer",
                                    color: index === 0 ? "grey" : "#2092c7",
                                  }}
                                  onClick={() =>
                                    index > 0 &&
                                    handleMoveContentItemDraft(index, -1)
                                  }
                                />
                                <FaArrowDown
                                  style={{
                                    cursor:
                                      index ===
                                      contentItemsFromDetails.length - 1
                                        ? "not-allowed"
                                        : "pointer",
                                    color:
                                      index ===
                                      contentItemsFromDetails.length - 1
                                        ? "grey"
                                        : "#2092c7",
                                  }}
                                  onClick={() =>
                                    index <
                                      contentItemsFromDetails.length - 1 &&
                                    handleMoveContentItemDraft(index, 1)
                                  }
                                />
                                <FaTrash
                                  style={{
                                    cursor: "pointer",
                                    color: "#2092c7",
                                  }}
                                  onClick={() =>
                                    handleDeleteContentItemDraft(index)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      } else if (item.type === "weblink") {
                        return (
                          <div
                            className="text-box-container"
                            style={{ display: "flex", gap: "5px" }}
                            key={index}
                          >
                            <div
                              onClick={() => handleSelectWebLink(index)}
                              className="d-flex flex-column justify-content-center align-items-center"
                              style={{
                                flex: 1,
                                border:
                                  selectedWebIndex === index
                                    ? "2px solid #2092c7"
                                    : "2px solid transparent",
                                borderRadius: "8px",
                                minHeight: "240px",
                                marginBottom: "20px",
                              }}
                            >
                              {item.previewData && (
                                <div
                                  style={{
                                    display: "flex",
                                    height: "100%",
                                    width: "100%",
                                    alignItems: "center",
                                    borderRadius: "10px",
                                    backgroundColor: "#f9f9f9",
                                  }}
                                >
                                  <div
                                    className="preview-container form-group mt-4 mb-2"
                                    style={{
                                      display: "flex",
                                      aldingItems: "center",
                                      backgroundColor: "#f9f9f9",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      paddingLeft: "5px",
                                      paddingRight: "10px",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                    >
                                      {item.previewData.image && (
                                        <img
                                          src={item.previewData.image}
                                          alt="Link preview"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      )}

                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h3
                                          style={{
                                            fontSize: "16px",
                                            color: "#2092c7",
                                            fontWeight: "500",
                                            textDecoration: "underline",
                                            marginBottom: "8px",
                                          }}
                                        >
                                          {item.previewData.title}
                                        </h3>
                                        <p
                                          style={{
                                            fontSize: "14px",
                                            color: "#555",
                                          }}
                                        >
                                          {item.previewData.description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            {selectedWebIndex === index && (
                              <div
                                className="video-actions d-flex flex-column justify-content-center align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <FaArrowUp
                                  style={{
                                    cursor:
                                      index === 0 ? "not-allowed" : "pointer",
                                    color: index === 0 ? "grey" : "#2092c7",
                                  }}
                                  onClick={() =>
                                    index > 0 &&
                                    handleMoveContentItemDraft(index, -1)
                                  }
                                />
                                <FaArrowDown
                                  style={{
                                    cursor:
                                      index ===
                                      contentItemsFromDetails.length - 1
                                        ? "not-allowed"
                                        : "pointer",
                                    color:
                                      index ===
                                      contentItemsFromDetails.length - 1
                                        ? "grey"
                                        : "#2092c7",
                                  }}
                                  onClick={() =>
                                    index <
                                      contentItemsFromDetails.length - 1 &&
                                    handleMoveContentItemDraft(index, 1)
                                  }
                                />
                                <FaTrash
                                  style={{
                                    cursor: "pointer",
                                    color: "#2092c7",
                                  }}
                                  onClick={() =>
                                    handleDeleteContentItemDraft(index)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      } else if (item.type === "text") {
                        return (
                          <div
                            className="text-box-container"
                            style={{ display: "flex", gap: "5px" }}
                            key={index}
                          >
                            <div
                              className="d-flex flex-column"
                              onClick={() => handleSelectTextBox(index)}
                              style={{
                                paddingLeft: "44px",
                                paddingTop: "10px",
                                flex: 1,
                                border:
                                  selectedTextBoxes === index
                                    ? "2px solid #2092c7"
                                    : "2px solid transparent",
                                borderRadius: "8px",
                                marginBottom: "20px",
                                backgroundColor: "#f9f9f9",
                                transition: "height 0.3s ease-in-out",
                                minHeight: !isMarkdown ? "180px" : "100px",
                              }}
                            >
                              <div
                                className="d-flex justify-content-end mb-2"
                                style={{ marginRight: "115px" }}
                              >
                                <span
                                  className={`text-mode-tab ${
                                    !textBoxModesDraft[index]?.isMarkdown
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    const newModes = [...textBoxModesDraft];
                                    newModes[index].isMarkdown = false;
                                    setTextBoxModesDraft(newModes);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    padding: "5px 10px",
                                    borderBottom: !textBoxModesDraft[index]
                                      ?.isMarkdown
                                      ? "2px solid #2092c7"
                                      : "none",
                                    color:
                                      !textBoxModesDraft[index]?.isMarkdown &&
                                      "#2092c7",
                                  }}
                                >
                                  Plain text
                                </span>

                                <span
                                  className={`text-mode-tab ${
                                    textBoxModesDraft[index]?.isMarkdown
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    const newModes = [...textBoxModesDraft];
                                    newModes[index].isMarkdown = true;
                                    setTextBoxModesDraft(newModes);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    padding: "5px 10px",
                                    borderBottom: textBoxModesDraft[index]
                                      ?.isMarkdown
                                      ? "2px solid #2092c7"
                                      : "none",
                                    color:
                                      textBoxModesDraft[index]?.isMarkdown &&
                                      "#2092c7",
                                  }}
                                >
                                  Markdown
                                </span>
                              </div>

                              {!textBoxModesDraft[index]?.isMarkdown && (
                                <div>
                                  <input
                                    type="text"
                                    value={item.heading}
                                    onChange={(e) => {
                                      handleTextBoxChangeDraft(
                                        index,
                                        item.content,
                                        e.target.value
                                      );
                                    }}
                                    placeholder="Heading"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      fontSize: "23px",
                                      marginBottom: "20px",
                                      backgroundColor: "#f9f9f9",
                                      outline: "none",
                                      border: "none",
                                    }}
                                    maxLength={200}
                                  />
                                </div>
                              )}
                              <textarea
                                type="text"
                                style={{
                                  width: "100%",
                                  height: "25rem",
                                  border: "none",
                                  paddingLeft: "10px",
                                  fontSize: "16px",
                                  borderRadius: "8px",
                                  backgroundColor: "#f9f9f9",
                                  outline: "none",
                                }}
                                value={item.content}
                                onChange={(e) =>
                                  handleTextBoxChangeDraft(
                                    index,
                                    e.target.value,
                                    item.heading
                                  )
                                }
                                placeholder={
                                  textBoxModes[index]?.isMarkdown
                                    ? "Enter your Markdown"
                                    : "Enter your text"
                                }
                                maxLength={5000}
                              />
                            </div>
                            {selectedTextBoxes === index && (
                              <div
                                className="text-box-actions d-flex flex-column justify-content-center align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <FaArrowUp
                                  style={{
                                    cursor:
                                      index === 0 ? "not-allowed" : "pointer",
                                    color: index === 0 ? "grey" : "#2092c7",
                                  }}
                                  onClick={() =>
                                    index > 0 &&
                                    handleMoveContentItemDraft(index, -1)
                                  }
                                />
                                <FaArrowDown
                                  style={{
                                    cursor:
                                      index ===
                                      contentItemsFromDetails.length - 1
                                        ? "not-allowed"
                                        : "pointer",
                                    color:
                                      index ===
                                      contentItemsFromDetails.length - 1
                                        ? "grey"
                                        : "#2092c7",
                                  }}
                                  onClick={() =>
                                    index <
                                      contentItemsFromDetails.length - 1 &&
                                    handleMoveContentItemDraft(index, 1)
                                  }
                                />
                                <FaTrash
                                  style={{
                                    cursor: "pointer",
                                    color: "#2092c7",
                                  }}
                                  onClick={() =>
                                    handleDeleteContentItemDraft(index)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })} */}
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer" style={{ marginTop: "15px", justifyContent: "space-between" }}>
                                <div className="text-start">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        style={{
                                            borderRadius: "7px",
                                            fontSize: "16px",
                                            color: "#2092c7",
                                            backgroundColor: "white",
                                            borderColor: "#f9f9f9",
                                        }}
                                    >
                                        Back
                                    </button>
                                </div>
                                <div className="text-end">
                                    {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{ borderRadius: '7px', fontSize: '16px', color: '#2092c7', backgroundColor: 'white' }}>Save as draft</button> */}
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        style={{
                                            backgroundColor: "#2092c7",
                                            borderRadius: "5px",
                                            fontSize: "16px",
                                        }}
                                        onClick={handleSaveAsDraftForEdit}
                                        disabled={loading6}
                                    >
                                        {loading6 ? (
                                            <div className="spinner-border text-light" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : (
                                            "Save as Draft"
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-success ms-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#thumbnailmodaldraft"
                                        style={{
                                            backgroundColor: "#2092c7",
                                            borderRadius: "7px",
                                            fontSize: "16px",
                                        }}
                                    >
                                        Next: Thumbnail
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Thumbnail modal */}
                <div className="modal fade custom-backdrop" id="thumbnailmodal" tabIndex="-1" aria-labelledby="thumbnailModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-body" style={{ overflowY: "auto" }}>
                                <div className="step-content container" style={{ paddingBottom: "0px" }}>
                                    <CloseButton className="btnclosethumbnail" />
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  className="btn-close btnclosethumbnail"
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Thumbnail Preview
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {selectedImage && <img src={selectedImage} style={{ maxWidth: "100%", height: "300px" }} alt="" />}
                                        {!selectedImage && portfolioDetails.photo.length === 0 && portfolioDetails.videoLink.length === 0 && (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "300px",
                                                    border: "2px dashed #2092c7",
                                                    borderRadius: "10px",
                                                    width: "100%",
                                                }}
                                                onClick={() => document.getElementById("photo-uploadthumbnail").click()}
                                            >
                                                <div style={{ marginBottom: "10px" }}>
                                                    <IoImageOutline
                                                        style={{
                                                            fontSize: "37px",
                                                            color: "white",
                                                            backgroundColor: "#2092c7",
                                                            borderRadius: "50%",
                                                            padding: "5px",
                                                        }}
                                                    />
                                                </div>
                                                <p style={{ marginBottom: "10px" }}>Upload Images (upto 100 KB)</p>
                                                <input type="file" id="photo-uploadthumbnail" style={{ display: "none" }} onChange={handleImageUploadforThumnail} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                            </div>
                                        )}
                                    </div>
                                    {/* Area to be modified */}
                                    {/* {(selectedImage || (portfolioDetails.photo.length > 0 || portfolioDetails.videoLink.length > 0)) && ( */}
                                    {selectedImage && (
                                        <div className="text-center mt-2">
                                            <button
                                                // onClick={() => {
                                                //   setEditorDatathumb(selectedImage);
                                                //   setIsEditingPhotothumb(true);
                                                // }}
                                                onClick={() => {
                                                    if (selectedImage.includes("https://img.youtube.com/vi/")) {
                                                        handleEditAndSave();
                                                    } else {
                                                        setEditorDatathumb(selectedImage);
                                                        setIsEditingPhotothumb(true);
                                                    }
                                                }}
                                                className="btn btn-secondary"
                                            >
                                                Edit Photo
                                            </button>
                                        </div>
                                    )}
                                    {/*  */}
                                    <div
                                        className="thumbnail-options"
                                        style={{
                                            display: "flex",
                                            overflowX: "scroll",
                                            gap: "5px",
                                            marginTop: "40px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "20px",
                                            paddingBottom: "17px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: "center",
                                                border: "2px solid gray",
                                                borderRadius: "10px",
                                                // marginBottom: "17px",
                                                width: "77px",
                                                height: "73px",
                                            }}
                                            onClick={() => document.getElementById("photo-uploadthumbnail2").click()}
                                        >
                                            <div
                                                style={{
                                                    marginBottom: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <FiPlus
                                                    style={{
                                                        marginTop: "15px",
                                                        fontSize: "37px",
                                                        color: "gray",
                                                        borderRadius: "50%",
                                                        padding: "5px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                    }}
                                                />
                                            </div>
                                            <input type="file" id="photo-uploadthumbnail2" style={{ display: "none" }} onChange={handleImageUploadforThumnail2} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                        </div>
                                        {uploadedImagesthumb?.map((imgSrc, index) => (
                                            <img
                                                key={index}
                                                src={imgSrc.url ? imgSrc.url : imgSrc}
                                                alt={`Uploaded ${index + 1}`}
                                                style={{
                                                    maxHeight: "76px",
                                                    cursor: "pointer",
                                                    borderRadius: "20px",
                                                    maxWidth: "100px",
                                                    objectFit: "cover",
                                                    width: "109px",
                                                    marginBottom: "20px",
                                                    borderWidth: "3px",
                                                    borderColor: "red",
                                                }}
                                                onClick={() => handleImageSelect(imgSrc)}
                                            />
                                        ))}
                                        {portfolioDetails.photo.map((imgSrc, index) => (
                                            <div
                                                style={{
                                                    backgroundColor: "rgba(5, 149, 207, 0.998)",
                                                    padding: selectedThumbnail === imgSrc ? "2px" : "0px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <img
                                                    key={index}
                                                    src={imgSrc.url ? imgSrc.url : imgSrc}
                                                    alt={`Project ${index + 1}`}
                                                    style={{
                                                        maxHeight: "76px",
                                                        height: "76px",
                                                        cursor: "pointer",
                                                        borderRadius: "10px",
                                                        maxWidth: "100px",
                                                        objectFit: "cover",
                                                        width: "109px",
                                                    }}
                                                    onClick={() => {
                                                        handleImageSelect(imgSrc);
                                                    }}
                                                />
                                            </div>
                                        ))}
                                        {portfolioDetails.videoLink.map((link, index) => (
                                            <div
                                                style={{
                                                    backgroundColor: "rgba(5, 149, 207, 0.998)",
                                                    padding: selectedThumbnail === getYouTubeThumbnail(link?.url ? link.url : Object.values(link).join("")) ? "2px" : "0px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <img
                                                    key={index}
                                                    src={getYouTubeThumbnail(link?.url ? link.url : Object.values(link).join(""))}
                                                    alt={`YouTube Thumbnail ${index + 1}`}
                                                    style={{
                                                        maxHeight: "100px",
                                                        cursor: "pointer",
                                                        borderRadius: "10px",
                                                        maxWidth: "100px",
                                                        objectFit: "cover",
                                                    }}
                                                    // onClick={() => handleImageSelect(getYouTubeThumbnail(link))}
                                                    onClick={() => {
                                                        const thumbnailUrl = getYouTubeThumbnail(link.url ? link.url : link);
                                                        setSelectedThumbnail(thumbnailUrl);
                                                        setEditorDatathumb(thumbnailUrl);
                                                        setSelectedImage(thumbnailUrl);
                                                        setPortfolioDetails((prevDetails) => ({
                                                            ...prevDetails,
                                                            selectedThumbnail: thumbnailUrl,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-end" style={{ marginTop: "15px" }}>
                                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{ borderRadius: '5px', marginRight: '10px', fontSize: '16px' }}>Save as Draft</button> */}
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={handleSaveAsDraft}
                                    disabled={loading5}
                                >
                                    {loading5 ? (
                                        <div className="spinner-border text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        "Save as Draft"
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={onPublish}
                                >
                                    {loading7 ? <Spinner /> : "Publish"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Thumbnail modal draft */}
                <div className="modal fade custom-backdrop" id="thumbnailmodaldraft" tabIndex="-1" aria-labelledby="thumbnaildraftModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-body" style={{ overflowY: "auto" }}>
                                <div className="step-content container" style={{ paddingBottom: "0px" }}>
                                    <CloseButton className="btnclosethumbnaildraft" />
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  className="btn-close btnclosethumbnaildraft"
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Thumbnail Preview
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {selectedImage && <img src={selectedImage} style={{ maxWidth: "100%", height: "300px" }} alt="" />}
                                        {!selectedImage && editPortfolioDetails.photo.length === 0 && editPortfolioDetails.videoLink.length === 0 && (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    marginBottom: "20px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "300px",
                                                    border: "2px dashed #2092c7",
                                                    borderRadius: "10px",
                                                    width: "100%",
                                                }}
                                                onClick={() => document.getElementById("photo-uploadthumbnail").click()}
                                            >
                                                <div style={{ marginBottom: "10px" }}>
                                                    <IoImageOutline
                                                        style={{
                                                            fontSize: "37px",
                                                            color: "white",
                                                            backgroundColor: "#2092c7",
                                                            borderRadius: "50%",
                                                            padding: "5px",
                                                        }}
                                                    />
                                                </div>
                                                <p style={{ marginBottom: "10px" }}>Upload Images (upto 100 KB)</p>
                                                <input type="file" id="photo-uploadthumbnail" style={{ display: "none" }} onChange={handleImageUploadforThumnail} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                            </div>
                                        )}
                                    </div>
                                    {/* Area to be modified */}
                                    {selectedImage && (
                                        <div className="text-center mt-2">
                                            <button
                                                onClick={() => {
                                                    if (selectedImage.includes("https://img.youtube.com/vi/")) {
                                                        handleEditAndSaveDraft();
                                                    } else {
                                                        setEditorDatathumbDraft(selectedImage);
                                                        setIsEditingPhotothumbDraft(true);
                                                    }
                                                }}
                                                className="btn btn-secondary"
                                            >
                                                Edit Photo
                                            </button>
                                        </div>
                                    )}
                                    {/*  */}
                                    <div
                                        className="thumbnail-options"
                                        style={{
                                            display: "flex",
                                            overflowX: "scroll",
                                            gap: "10px",
                                            marginTop: "40px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "20px",
                                            paddingBottom: "17px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: "center",
                                                border: "2px solid gray",
                                                borderRadius: "10px",
                                                width: "77px",
                                                height: "73px",
                                            }}
                                            onClick={() => document.getElementById("photo-uploadthumbnail2").click()}
                                        >
                                            <div
                                                style={{
                                                    marginBottom: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <FiPlus
                                                    style={{
                                                        marginTop: "15px",
                                                        fontSize: "37px",
                                                        color: "gray",
                                                        borderRadius: "50%",
                                                        padding: "5px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                    }}
                                                />
                                            </div>
                                            <input type="file" id="photo-uploadthumbnail2" style={{ display: "none" }} onChange={handleImageUploadforThumnail2} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                        </div>
                                        {uploadedImagesthumb?.map((imgSrc, index) => (
                                            <img
                                                key={index}
                                                src={imgSrc}
                                                alt={`Uploaded ${index + 1}`}
                                                style={{
                                                    maxHeight: "76px",
                                                    cursor: "pointer",
                                                    borderRadius: "20px",
                                                    maxWidth: "100px",
                                                    objectFit: "cover",
                                                    width: "109px",
                                                    marginBottom: "20px",
                                                }}
                                                onClick={() => handleImageSelectDraft(imgSrc)}
                                            />
                                        ))}
                                        {/* {editPortfolioDetails.photo.map((imgSrc, index) => (
                  <img
                    key={index}
                    src={imgSrc}
                    alt={`Project ${index + 1}`}
                    style={{ maxHeight: '76px', cursor: 'pointer', borderRadius: '20px', maxWidth: '100px', objectFit: 'cover', width: '109px', marginBottom: '20px' }}
                    onClick={() => handleImageSelectDraft(imgSrc)}
                  />
                ))} */}
                                        {editPortfolioDetails && editPortfolioDetails.photo.length > 0 && (
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                {editPortfolioDetails.photo.map((imgSrc, index) => {
                                                    // Determine if imgSrc is a string or an object
                                                    const imageUrl = typeof imgSrc === "string" ? imgSrc : imgSrc.url;
                                                    // console.log(
                                                    //   selectedImage,
                                                    //   imageUrl,
                                                    //   imgSrc,
                                                    //   "isequal"
                                                    // );
                                                    //setSelectedImage(imageUrl);
                                                    return (
                                                        <div
                                                            style={{
                                                                backgroundColor: "rgba(5, 149, 207, 0.998)",
                                                                padding: selectedImage === imageUrl ? "2px" : "0px",
                                                                borderRadius: "10px",
                                                            }}
                                                        >
                                                            <img
                                                                key={index}
                                                                src={imageUrl}
                                                                alt={`Project ${index + 1}`}
                                                                style={{
                                                                    height: "76px",
                                                                    cursor: "pointer",
                                                                    borderRadius: "10px",
                                                                    maxWidth: "100px",
                                                                    objectFit: "cover",
                                                                    width: "109px",
                                                                }}
                                                                onClick={() => {
                                                                    //alert("klsjdkasjdksd");

                                                                    handleImageSelectDraft(imageUrl);
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}

                                        {editPortfolioDetails.videoLink.length > 0 && (
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                {" "}
                                                {editPortfolioDetails.videoLink.map((link, index) => (
                                                    <div
                                                        style={{
                                                            backgroundColor: "rgba(5, 149, 207, 0.998)",
                                                            padding: selectedImage === getYouTubeThumbnail(link?.url ? link.url : Object.values(link).join("")) ? "2px" : "0px",
                                                            borderRadius: "10px",
                                                        }}
                                                    >
                                                        <img
                                                            key={index}
                                                            src={getYouTubeThumbnail(link?.url ? link.url : Object.values(link).join(""))}
                                                            alt={`YouTube Thumbnail ${index + 1}`}
                                                            style={{
                                                                maxHeight: "100px",
                                                                cursor: "pointer",
                                                                borderRadius: "10px",
                                                                maxWidth: "100px",
                                                                objectFit: "cover",
                                                            }}
                                                            // onClick={() => handleImageSelect(getYouTubeThumbnail(link))}
                                                            onClick={() => {
                                                                const thumbnailUrl = getYouTubeThumbnail(link.url ? link.url : link);
                                                                setSelectedThumbnail(thumbnailUrl);
                                                                setSelectedImage(thumbnailUrl);
                                                                setEditorDatathumbDraft(thumbnailUrl);
                                                                setEditPortfolioDetails((prevDetails) => ({
                                                                    ...prevDetails,
                                                                    selectedThumbnail: thumbnailUrl,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-end" style={{ marginTop: "15px" }}>
                                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{ borderRadius: '5px', marginRight: '10px', fontSize: '16px' }}>Save as Draft</button> */}
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={handleSaveAsDraftForEdit}
                                    disabled={loading6}
                                >
                                    {loading6 ? (
                                        <div className="spinner-border text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        "Save as Draft"
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    // onClick={onPublishFromDraft}
                                    onClick={() => {
                                        alert("skhdfjhdjhdfhsj");
                                        if (selectedProject && selectedProject._id) {
                                            onPublishEdit();
                                        } else {
                                            onPublishFromDraft();
                                        }
                                    }}
                                >
                                    {loading8 ? <Spinner /> : "Publish"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Popup component */}
                <Popup open={openPopup} onClose={() => setOpenPopup(false)} modal closeOnDocumentClick>
                    <div className="overlay" style={{ textAlign: "center", background: "rgba(0, 0, 0, 0.4)" }}>
                        <div className="popup-containernew" style={{ padding: "50px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ marginBottom: "30px", justifyContent: "center" }}>
                                    <img src={portPublish} alt="Portfolio project published" style={{ maxWidth: "100%" }} />
                                    <h3 style={{ marginBottom: "20px", fontSize: "24px" }}>Your portfolio project is published!</h3>
                                    <h5 style={{ marginBottom: "20px", fontSize: "18px" }}>Anyone viewing your profile will be able to see it now.</h5>
                                </div>
                                <button
                                    onClick={() => setOpenPopup(false)}
                                    style={{
                                        width: "fit-content",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#2092c7",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "10px 20px",
                                        border: "none",
                                        marginTop: "20px",
                                    }}
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>

                {/* Delete project popup */}
                <Popup open={openPopupDelete} onClose={() => setOpenPopupDelete(false)} modal closeOnDocumentClick>
                    <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                        <div className="popup-container" style={{ padding: "40px" }}>
                            <div className="close-button123" onClick={() => setOpenPopupDelete(false)}>
                                <MdClose className="close-btn" />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ marginBottom: "30px", justifyContent: "center" }}>
                                    <div
                                        style={{
                                            marginBottom: "20px",
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Delete Portfolio Project
                                    </div>
                                    <p style={{ marginBottom: "20px", fontSize: "18px" }}>This action will delete {selectedProject?.projectTitle} from all of your profiles. Are you sure to want to delete this portfolio project?</p>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        alignSelf: "flex-end",
                                        gap: "10px",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <button
                                        className="employerbuttonbacktodash"
                                        style={{
                                            width: "fit-content",
                                            backgroundColor: "white",
                                            color: "#2092c7",
                                            borderRadius: "10px",
                                            padding: "10px 20px",
                                            border: "2px solid #2092c7",
                                            marginTop: "20px",
                                        }}
                                        onClick={() => setOpenPopupDelete(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="employerbuttontobuyinformation"
                                        style={{
                                            width: "fit-content",
                                            backgroundColor: "#2092c7",
                                            color: "white",
                                            borderRadius: "10px",
                                            padding: "10px 20px",
                                            border: "none",
                                            marginTop: "20px",
                                        }}
                                        onClick={handleDeleteProject}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>

                {/* Delete Draft project popup */}
                <Popup open={openPopupDelete1} onClose={() => setOpenPopupDelete1(false)} modal closeOnDocumentClick>
                    <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                        <div className="popup-container" style={{ padding: "40px" }}>
                            <div className="close-button123" onClick={() => setOpenPopupDelete1(false)}>
                                <RxCross1 />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ marginBottom: "30px", justifyContent: "center" }}>
                                    <div
                                        style={{
                                            marginBottom: "20px",
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Delete Portfolio Project
                                    </div>
                                    <p style={{ marginBottom: "20px", fontSize: "18px" }}>This action will delete {selectedProject?.projectTitle} from all of your profiles. Are you sure to want to delete this portfolio project?</p>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        alignSelf: "flex-end",
                                        gap: "10px",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <button
                                        className="employerbuttonbacktodash"
                                        style={{
                                            width: "fit-content",
                                            backgroundColor: "white",
                                            color: "#2092c7",
                                            borderRadius: "10px",
                                            padding: "10px 20px",
                                            border: "2px solid #2092c7",
                                            marginTop: "20px",
                                        }}
                                        onClick={() => setOpenPopupDelete1(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="employerbuttontobuyinformation"
                                        style={{
                                            width: "fit-content",
                                            backgroundColor: "#2092c7",
                                            color: "white",
                                            borderRadius: "10px",
                                            padding: "10px 20px",
                                            border: "none",
                                            marginTop: "20px",
                                        }}
                                        onClick={handleDeleteProject1}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>

                {/* Preview Modal resume page */}

                {/* <div className="modal fade" id="previewmodalresume" tabIndex="-1" aria-labelledby="previewModalresumeLabel" aria-hidden="true">
      <div className="modal-dialog custom-modal-dialog modal-dialog-centered" style={{ maxWidth: '80%' }}>
        <div className="modal-content px-3 py-3" style={{ borderRadius: '15px', marginTop: "20px", height: '80vh' }}>
          <div className="modal-body" style={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', padding: '50px' }}>
            <div style={{ textAlign: "end" }}>
              <button type="button" className="btn-close btnclosepreview" data-bs-dismiss="modal" style={{ marginRight: "-10px" }} aria-label="Close"></button>
            </div>

            {selectedProject && (
              <>
                <div className='mb-3 flex-container-portfolio'>
                  <div style={{ flex: 1, marginRight: '50px' }}>
                    <div style={{ marginTop: '15px', marginBottom: '40px' }}>
                      <h2 style={{ fontSize: '28px' }}>{selectedProject.projectTitle}</h2>
                    </div>
                    <div style={{ marginBottom: '25px' }}>
                      <h3 style={{ fontSize: '16px' }}>My role: {selectedProject.yourRole}</h3>
                    </div>
                    <div style={{ marginBottom: '25px' }}>
                      <p style={{ fontSize: '16px', textAlign: 'justify' }}>{selectedProject.projectDescription}</p>
                    </div>
                    <div style={{ marginBottom: '25px' }}>
                      <h4>Skills and deliverables</h4>
                      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {selectedProject.skills.map((skill) => (
                          <span key={skill.value} className="post1234forresume mr-4 mt-3" style={{ marginBottom: '10px' }}>{skill.label}</span>
                        ))}
                      </div>
                    </div>

                    <hr className="dropdown-divider1 mb-4 mt-3" style={{ height: '0.05px', color: "rgb(185, 185, 185)" }} />
                  </div>

                  <div className="mb-3" style={{ flex: 2, minHeight: '240px', marginTop: '35px', flexDirection: 'column' }}>
                    {selectedProject.textContent && selectedProject.textContent.length > 0 && (
                      <div style={{ marginBottom: '25px' }}>
                        {selectedProject.textContent.map((text, index) => (
                          <div key={index}>
                            <h5 style={{ fontSize: '24px', fontWeight: '500', marginBottom: '15px' }}>{text.heading}</h5>
                            <p style={{ fontSize: '17px', marginBottom: '15px' }}>{text.content}</p>
                          </div>
                        ))}
                      </div>
                    )}

                    {selectedProject.photo && selectedProject.photo.length > 0 && (
                      <div style={{ marginBottom: '25px' }}>
                        {selectedProject.photo.map((imgSrc, index) => (
                          <div key={index} style={{ display: 'flex', flexDirection: 'column', marginBottom: '25px', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={imgSrc.url} alt={`Project Photo ${index + 1}`} style={{ marginBottom: '10px', maxHeight: '800px', alignItems: 'center', justifyContent: 'center' }} />
                          </div>
                        ))}
                      </div>
                    )}

                    {selectedProject.videoLink && selectedProject.videoLink.length > 0 && (
                      <div style={{ marginBottom: '25px' }}>
                        {selectedProject.videoLink.map((link, index) => (
                          <div key={index} style={{ marginBottom: '10px' }}>
                            <iframe
                              width="100%"
                              height="550"
                              src={getYouTubeEmbedUrl(link)}
                              title="Preview Introduction"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        ))}
                      </div>
                    )}
                    <hr className="dropdown-divider1 mb-4 mt-3" style={{ height: '0.05px', color: "rgb(185, 185, 185)" }} />
                    <div className="mb-3" style={{ marginTop: '35px' }}>
                    <p style={{fontSize: "18px" ,marginBottom:'15px'}}>More by <span style={{color:"#2092c7"}}>{resumeData.PersonalDetails.firstName} {resumeData.PersonalDetails.lastName}</span></p>
                      <div className="row">
                        {portfolio
                          ?.filter((project) => project._id !== selectedProject._id)
                          .slice(0, 3)
                          .map((project) => (
                            <div className="col-md-4" key={project._id} style={{ cursor: 'pointer' }}>
                              <div className="card">
                                <img className="card-img-top" src={project.selectedThumbnail.url} alt={`Project ${project._id}`} />
                                <div className="card-body">
                                  <p className="card-text" style={{ color: '#2092c7' }}>{project.projectTitle}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                </div>

              </>
            )}
          </div>
        </div>
      </div>
    </div> */}

                <div className="modal fade" id="previewmodalresume" tabIndex="-1" aria-labelledby="previewModalresumeLabel" aria-hidden="true">
                    <div className="modal-dialog custom-modal-dialog unique-modal-dialog modal-dialog-centered width-settingofmodal">
                        <div
                            className="modal-content px-3 py-3"
                            style={{
                                borderRadius: "15px",
                                // // marginTop: "10px",
                                height: "95vh",
                            }}
                        >
                            <div className="preview-header">
                                <h2 style={{ fontSize: "28px" }}>{selectedProject?.projectTitle}</h2>

                                <CloseButton className="btnclosepreview" />
                            </div>
                            <div
                                className="modal-body"
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    overflowY: "auto",
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                }}
                            >
                                {loading ? (
                                    <div className="" style={{ textAlign: "center", paddingBottom: "220px" }}>
                                        <p
                                            style={{
                                                color: "#4B92C8",
                                                fontWeight: "500",
                                                fontSize: "20px",
                                                marginLeft: "43px",
                                                marginBottom: "5px",
                                                marginTop: "220px",
                                            }}
                                        >
                                            Loading..
                                        </p>
                                        <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "27px" }} />
                                    </div>
                                ) : (
                                    selectedProject && (
                                        <div className={`fade-in ${!loading ? "show" : ""}`}>
                                            <div className="mb-3 flex-container-portfolio">
                                                <div
                                                    className="leftsectionpreviewport"
                                                    style={{
                                                        flex: 1,
                                                        marginRight: "20px",
                                                        position: "sticky",
                                                        top: "0",
                                                        alignSelf: "flex-start",
                                                    }}
                                                >
                                                    {/* <div
                        style={{ marginTop: "15px", marginBottom: "40px" }}
                      >

                      modfhdjhfujdyfydyfu
                        <h2 style={{ fontSize: "28px" }}>
                          {selectedProject.projectTitle}
                        </h2>
                      </div> */}
                                                    {selectedProject.yourRole !== "" && (
                                                        <div style={{ marginBottom: "25px" }}>
                                                            <p style={{ fontSize: "16px" }}>
                                                                <b>My role:</b> {selectedProject.yourRole}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {selectedProject.projectDescription !== "" && (
                                                        <div style={{ marginBottom: "25px" }}>
                                                            <p style={{ fontSize: "16px" }}>
                                                                <b>Project Description:</b>{" "}
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: selectedProject.projectDescription.replace(/\n/g, "<br />"),
                                                                    }}
                                                                />
                                                                {/* {selectedProject.projectDescription} */}
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div style={{ marginBottom: "25px" }}>
                                                        <h4>Skills and deliverables</h4>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexWrap: "wrap",
                                                                gap: "3px",
                                                            }}
                                                        >
                                                            {selectedProject.skills.map((skill) => (
                                                                <span key={skill.value} className="post1234forresume  mt-3">
                                                                    {skill.label}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <hr
                                                        //className="dropdown-divider1 mb-4 mt-3"
                                                        style={{
                                                            height: "0.1px",
                                                            color: "rgb(185, 185, 185)",
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="mb-3 rightsectionpreviewport"
                                                    style={{
                                                        flex: 2,
                                                        minHeight: "240px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    {/* {portfolioDetailsData?.map((item, index) => (
                        <div key={index}>
                          {item?.type === "image" && (
                            <div>
                              {item?.images?.map((img, idx) => (
                                <div
                                  key={idx}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "25px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    key={idx}
                                    src={img}
                                    alt={`Project Photo ${idx + 1}`}
                                    style={{
                                      marginBottom: "10px",
                                      height: "400px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                          {item?.type === "video" && (
                            <div>
                              {item?.videos?.map((vd, idx) => (
                                <div
                                  key={idx}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <iframe
                                    width="100%"
                                    height="400"
                                    src={getYouTubeEmbedUrl(vd)}
                                    title="Preview Introduction"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              ))}
                            </div>
                          )}
                          {item?.type === "text-content" && (
                            <div>
                              {item?.textContents?.map((txt, idx) => (
                                <div key={idx}>
                                  <h5
                                    style={{
                                      fontSize: "25px",
                                      fontWeight: "500",
                                      marginBottom: "15px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {txt.heading}
                                  </h5>
                                  <p
                                    style={{
                                      fontSize: "18px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: txt.content.replace(
                                          /\n/g,
                                          "<br />"
                                        ),
                                      }}
                                    />
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                          {item?.type === "artical" && (
                            <div>
                              {item?.webLinks?.map((link, idx) => (
                                <div
                                  key={idx}
                                  style={{ marginBottom: "25px" }}
                                >
                                  <a
                                    href={link?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <div
                                      className="preview-container form-group mt-4 mb-2"
                                      style={{
                                        backgroundColor: "#f9f9f9",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        paddingLeft: "5px",
                                        paddingRight: "10px",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                        }}
                                      >
                                        {link.image && (
                                          <img
                                            src={link.image}
                                            alt="Link preview"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h3
                                            style={{
                                              fontSize: "16px",
                                              color: "#2092c7",
                                              fontWeight: "500",
                                              textDecoration: "underline",
                                              marginBottom: "8px",
                                            }}
                                          >
                                            {link.title}
                                          </h3>
                                          <p
                                            style={{
                                              fontSize: "14px",
                                              color: "#555",
                                            }}
                                          >
                                            {link.description}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))} */}
                                                    {/* 
                          {selectedProject.textContent &&
                            selectedProject.textContent.length > 0 && (
                              <div style={{ marginBottom: "25px" }}>
                                {selectedProject.textContent.map(
                                  (text, index) => (
                                    <div key={index}>
                                      <h5
                                        style={{
                                          fontSize: "25px",
                                          fontWeight: "500",
                                          marginBottom: "15px",
                                          textAlign: "justify",
                                        }}
                                      >
                                        {text.heading}
                                      </h5>
                                      <p
                                        style={{
                                          fontSize: "18px",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: text.content.replace(
                                              /\n/g,
                                              "<br />"
                                            ),
                                          }}
                                        />
                                      </p>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          {selectedProject.photo &&
                            selectedProject.photo.length > 0 && (
                              <div style={{ marginBottom: "25px" }}>
                                {selectedProject.photo.map((imgSrc, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginBottom: "25px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={imgSrc.url}
                                      alt={`Project Photo ${index + 1}`}
                                      style={{
                                        marginBottom: "10px",
                                        height: "400px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          {selectedProject.videoLink &&
                            selectedProject.videoLink.length > 0 && (
                              <div style={{ marginBottom: "25px" }}>
                                {selectedProject.videoLink.map(
                                  (link, index) => (
                                    <div
                                      key={index}
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <iframe
                                        width="100%"
                                        height="400"
                                        src={getYouTubeEmbedUrl(link)}
                                        title="Preview Introduction"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      ></iframe>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                          {selectedProject?.webLink &&
                            selectedProject?.webLink.length > 0 && (
                              <div style={{ marginBottom: "25px" }}>
                                {selectedProject?.webLink.map((link, index) => (
                                  <div
                                    key={index}
                                    style={{ marginBottom: "25px" }}
                                  >
                                    {previewData3 &&
                                    previewData3[index] &&
                                    !error3 ? ( // Add check for previewData1
                                      <a
                                        href={link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: "none" }}
                                      >
                                        <div
                                          className="preview-container form-group mt-4 mb-2"
                                          style={{
                                            backgroundColor: "#f9f9f9",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingLeft: "5px",
                                            paddingRight: "10px",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "10px",
                                            }}
                                          >
                                            {previewData3[index].image && (
                                              <img
                                                src={previewData3[index].image}
                                                alt="Link preview"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  borderRadius: "5px",
                                                }}
                                              />
                                            )}
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  fontSize: "16px",
                                                  color: "#2092c7",
                                                  fontWeight: "500",
                                                  textDecoration: "underline",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                {previewData3[index].title}
                                              </h3>
                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  color: "#555",
                                                }}
                                              >
                                                {
                                                  previewData3[index]
                                                    .description
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    ) : (
                                      <p>Loading...</p>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )} */}
                                                    <PreviewModel portfolioDetails={selectedProject} />

                                                    <hr
                                                        className="dropdown-divider1 mb-4 mt-3"
                                                        style={{
                                                            height: "0.05px",
                                                            color: "rgb(185, 185, 185)",
                                                        }}
                                                    />
                                                    <div className="mb-3" style={{ marginTop: "35px" }}>
                                                        {/* <p style={{ fontSize: "18px", marginBottom: '15px' }}>More by <span style={{ color: "#2092c7" }}>{resumeData.PersonalDetails.firstName} {resumeData.PersonalDetails.lastName}</span></p> */}
                                                        <p style={{ fontSize: "18px", marginBottom: "15px" }}>
                                                            More by{" "}
                                                            <a href={isLoggedIn ? "/addresume" : "/login"} style={{ color: "#2092c7" }}>
                                                                {resumeData.PersonalDetails.firstName} {resumeData.PersonalDetails.lastName}
                                                            </a>
                                                        </p>
                                                        <div className="row">
                                                            {portfolio
                                                                ?.filter((project) => project._id !== selectedProject._id) // Exclude selected project
                                                                .slice(0, 3) // Get next 3 projects
                                                                .map((project) => (
                                                                    <div className="col-md-4" key={project._id} style={{ cursor: "pointer" }} onClick={() => handleProjectClick(project)}>
                                                                        <div className="card">
                                                                            <img className="card-img-top" src={project.selectedThumbnail.url} alt={`Project ${project._id}`} />
                                                                            <div className="card-body">
                                                                                <p className="card-text" style={{ color: "#2092c7" }}>
                                                                                    {project.projectTitle}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Thumbnail modal to change thumbnail */}
                <div className="modal fade custom-backdrop" id="thumbnailmodalchange" tabIndex="-1" aria-labelledby="thumbnailModalchangeLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-body" style={{ overflowY: "auto" }}>
                                <div className="step-content container" style={{ paddingBottom: "0px" }}>
                                    <CloseButton className="btnclosethumbnailchange" />
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  className="btn-close btnclosethumbnailchange"
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Thumbnail Preview
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {selectedImage ? <img src={selectedImage} style={{ maxWidth: "100%", height: "300px" }} alt="" /> : selectedProjectthumb?.selectedThumbnail?.url && <img src={selectedProjectthumb.selectedThumbnail.url || selectedImage} style={{ maxWidth: "100%", height: "300px" }} alt="" />}
                                    </div>
                                    {(selectedImage || selectedProjectthumb?.selectedThumbnail?.url) && (
                                        <div className="text-center mt-2">
                                            <button
                                                onClick={() => {
                                                    setEditorDatathumb(selectedImage || selectedProjectthumb?.selectedThumbnail?.url);
                                                    setIsEditingPhotothumb(true);
                                                }}
                                                className="btn btn-secondary"
                                            >
                                                Edit Photo
                                            </button>
                                        </div>
                                    )}
                                    <div
                                        className="thumbnail-options"
                                        style={{
                                            display: "flex",
                                            overflowX: "scroll",
                                            gap: "10px",
                                            marginTop: "40px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "20px",
                                            paddingBottom: "15px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: "center",
                                                border: "2px solid gray",
                                                borderRadius: "10px",
                                                width: "77px",
                                                height: "73px",
                                            }}
                                            onClick={() => document.getElementById("photo-uploadthumbnail2").click()}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <FiPlus
                                                    style={{
                                                        marginTop: "15px",
                                                        fontSize: "37px",
                                                        color: "gray",
                                                        borderRadius: "50%",
                                                        padding: "5px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                    }}
                                                />
                                            </div>
                                            <input type="file" id="photo-uploadthumbnail2" style={{ display: "none" }} onChange={handleImageUploadforThumnail2} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                        </div>
                                        {uploadedImagesthumb?.map((imgSrc, index) => (
                                            <img
                                                key={index}
                                                src={imgSrc}
                                                alt={`Uploaded ${index + 1}`}
                                                style={{
                                                    height: "76px",
                                                    cursor: "pointer",
                                                    borderRadius: "10px",
                                                    maxWidth: "100px",
                                                    objectFit: "cover",
                                                    width: "109px",
                                                    marginBottom: "20px",
                                                }}
                                                onClick={() => handleImageSelect(imgSrc)}
                                            />
                                        ))}
                                        {selectedProjectthumb?.photo?.map((imgSrc, index) => (
                                            <div
                                                style={{
                                                    backgroundColor: "rgba(5, 149, 207, 0.998)",
                                                    padding: selectedImage === imgSrc ? "2px" : "0px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <img
                                                    key={index}
                                                    src={imgSrc.url}
                                                    alt={`Project ${index + 1}`}
                                                    style={{
                                                        height: "76px",
                                                        cursor: "pointer",
                                                        borderRadius: "10px",
                                                        maxWidth: "100px",
                                                        objectFit: "cover",
                                                        width: "109px",
                                                    }}
                                                    onClick={() => handleImageSelect(imgSrc.url)}
                                                />
                                            </div>
                                        ))}
                                        {selectedProjectthumb?.videoLink?.map((link, index) => (
                                            <div
                                                style={{
                                                    backgroundColor: "rgba(5, 149, 207, 0.998)",
                                                    padding: selectedProjectthumb.selectedThumbnail.url === getYouTubeThumbnail(link?.url ? link.url : Object.values(link).join("")) ? "2px" : "0px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <img
                                                    key={index}
                                                    src={getYouTubeThumbnail(link?.url ? link.url : Object.values(link).join(""))}
                                                    alt={`YouTube Thumbnail ${index + 1}`}
                                                    style={{
                                                        maxHeight: "100px",
                                                        cursor: "pointer",
                                                        borderRadius: "10px",
                                                        maxWidth: "100px",
                                                        objectFit: "cover",
                                                    }}
                                                    onClick={() => {
                                                        const thumbnailUrl = getYouTubeThumbnail(link.url ? link.url : link);
                                                        setSelectedImage(thumbnailUrl);
                                                        setPortfolioDetails((prevDetails) => ({
                                                            ...prevDetails,
                                                            selectedThumbnail: thumbnailUrl,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-end" style={{ marginTop: "15px" }}>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={handleSaveThumb}
                                >
                                    {loading ? <Spinner /> : "Save"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal for editing the photo thumbnail*/}
                {isEditingPhotothumb && (
                    <div className="modal fade show" style={{ display: "block" }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit Photo</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsEditingPhotothumb(false)}></button>
                                </div>
                                <div className="modal-body text-center">
                                    <AvatarEditor
                                        ref={(ref) => (editor = ref)}
                                        // crossOrigin="anonymous"
                                        crossOrigin={editorDatathumb.includes("https://img.youtube.com/vi/") ? undefined : "anonymous"}
                                        image={editorDatathumb}
                                        width={imageWidth}
                                        height={imageHeight}
                                        border={10}
                                        color={[218, 230, 242, 0.45]}
                                        scale={scale}
                                        rotate={rotate}
                                        position={position}
                                        onPositionChange={handlePositionChange}
                                    />
                                    <div className="mt-3">
                                        <label>Zoom: </label>
                                        <input type="range" min="1" max="3" step="0.1" value={scale} onChange={(e) => setScale(parseFloat(e.target.value))} />
                                    </div>
                                    <div className="mt-2">
                                        <label>Rotate: </label>
                                        <input type="range" min="0" max="360" step="1" value={rotate} onChange={(e) => setRotate(parseFloat(e.target.value))} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setIsEditingPhotothumb(false)}>
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={saveEditedPhotoThumb}>
                                        Save
                                    </button>
                                    {/* <button type="button" className="btn btn-primary" 
               onClick={() => {
          if (editorDatathumb.includes('https://img.youtube.com/vi/')) {
            // Directly update the image without using the canvas
            const img = new Image();
            img.crossOrigin = undefined;
            img.src = editorDatathumb;

            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);

              const editedDataUrl = canvas.toDataURL('image/jpeg', 0.95);

              setPortfolioDetails(prevDetails => ({
                ...prevDetails,
                selectedThumbnail: editedDataUrl
              }));
              setSelectedImage(editedDataUrl);
              setIsEditingPhotothumb(false);
            };

            img.onerror = () => {
              console.error("Failed to load image for editing.");
            };
          } else {
            saveEditedPhotoThumb();
          }
        }}
              >Save</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Thumbnail modal to change thumbnail Draft*/}
                <div className="modal fade custom-backdrop" id="thumbnailmodalchangedraft" tabIndex="-1" aria-labelledby="thumbnailModalchangedraftLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-body" style={{ overflowY: "auto" }}>
                                <div className="step-content container" style={{ paddingBottom: "0px" }}>
                                    <CloseButton className="btnclosethumbnailchangedraft" />
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  className="btn-close btnclosethumbnailchangedraft"
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className="title mx-auto"
                                        style={{
                                            color: "#444343",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Thumbnail Preview
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {selectedImage ? <img src={selectedImage} style={{ maxWidth: "100%", height: "300px" }} alt="" /> : selectedProjectthumbDraft?.selectedThumbnail?.url && <img src={selectedProjectthumbDraft.selectedThumbnail.url || selectedImage} style={{ maxWidth: "100%", height: "300px" }} alt="" />}
                                    </div>
                                    {(selectedImage || selectedProjectthumbDraft?.selectedThumbnail?.url) && (
                                        <div className="text-center mt-2">
                                            <button
                                                onClick={() => {
                                                    setEditorDatathumbDraft(selectedImage || selectedProjectthumbDraft?.selectedThumbnail?.url);
                                                    setIsEditingPhotothumbDraft(true);
                                                }}
                                                className="btn btn-secondary"
                                            >
                                                Edit Photo
                                            </button>
                                        </div>
                                    )}
                                    <div
                                        className="thumbnail-options"
                                        style={{
                                            display: "flex",
                                            overflowX: "scroll",
                                            gap: "10px",
                                            marginTop: "40px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            paddingBottom: "17px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: "center",
                                                border: "2px solid gray",
                                                borderRadius: "10px",

                                                width: "77px",
                                                height: "73px",
                                            }}
                                            onClick={() => document.getElementById("photo-uploadthumbnail2").click()}
                                        >
                                            <div
                                                style={{
                                                    marginBottom: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <FiPlus
                                                    style={{
                                                        marginTop: "15px",
                                                        fontSize: "37px",
                                                        color: "gray",
                                                        borderRadius: "50%",
                                                        padding: "5px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                    }}
                                                />
                                            </div>
                                            <input type="file" id="photo-uploadthumbnail2" style={{ display: "none" }} onChange={handleImageUploadforThumnail2} accept=".jpeg,.jpg,.png,.gif,.bmp" multiple={false} data-show-preview="false" data-show-upload="false" aria-invalid="false" capture="camera" />
                                        </div>
                                        {uploadedImagesthumb?.map((imgSrc, index) => (
                                            <img
                                                key={index}
                                                src={imgSrc}
                                                alt={`Uploaded ${index + 1}`}
                                                style={{
                                                    maxHeight: "76px",
                                                    cursor: "pointer",
                                                    borderRadius: "20px",
                                                    maxWidth: "100px",
                                                    objectFit: "cover",
                                                    width: "109px",
                                                    marginBottom: "20px",
                                                }}
                                                onClick={() => handleImageSelectDraft(imgSrc)}
                                            />
                                        ))}
                                        {selectedProjectthumbDraft?.photo?.map((imgSrc, index) => (
                                            <div
                                                style={{
                                                    backgroundColor: "rgba(5, 149, 207, 0.998)",
                                                    padding: selectedThumbnail === imgSrc.url ? "2px" : "0px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <img
                                                    key={index}
                                                    src={imgSrc.url}
                                                    alt={`Project ${index + 1}`}
                                                    style={{
                                                        height: "76px",
                                                        cursor: "pointer",
                                                        borderRadius: "10px",
                                                        maxWidth: "100px",
                                                        objectFit: "cover",
                                                        width: "109px",
                                                    }}
                                                    onClick={() => handleImageSelectDraft(imgSrc.url)}
                                                />
                                            </div>
                                        ))}
                                        {selectedProjectthumbDraft?.videoLink?.map((link, index) => (
                                            <div
                                                style={{
                                                    backgroundColor: "rgba(5, 149, 207, 0.998)",
                                                    padding: selectedThumbnail === getYouTubeThumbnail(link?.url ? link.url : Object.values(link).join("")) ? "2px" : "0px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <img
                                                    key={index}
                                                    src={getYouTubeThumbnail(link.url ? link.url : link)}
                                                    alt={`YouTube Thumbnail ${index + 1}`}
                                                    style={{
                                                        maxHeight: "100px",
                                                        cursor: "pointer",
                                                        borderRadius: "10px",
                                                        maxWidth: "100px",
                                                        objectFit: "cover",
                                                    }}
                                                    onClick={() => {
                                                        const thumbnailUrl = getYouTubeThumbnail(link.url ? link.url : link);
                                                        setSelectedImage(thumbnailUrl);
                                                        setEditPortfolioDetails((prevDetails) => ({
                                                            ...prevDetails,
                                                            selectedThumbnail: thumbnailUrl,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-end" style={{ marginTop: "15px" }}>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{
                                        backgroundColor: "#2092c7",
                                        borderRadius: "5px",
                                        fontSize: "16px",
                                    }}
                                    onClick={handleSaveThumbDraft}
                                >
                                    {loading ? <Spinner /> : "Save"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal for editing the photo thumbnail Draft*/}
                {isEditingPhotothumbDraft && (
                    <div className="modal fade show" style={{ display: "block" }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit Photo</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsEditingPhotothumbDraft(false)}></button>
                                </div>
                                <div className="modal-body text-center">
                                    <AvatarEditor
                                        ref={(ref) => (editor = ref)}
                                        // crossOrigin="anonymous"
                                        crossOrigin={editorDatathumbDraft.includes("https://img.youtube.com/vi/") ? undefined : "anonymous"}
                                        image={editorDatathumbDraft}
                                        // image={personalDetails.photo?.url || personalDetails.photo}
                                        // width={200}
                                        // height={200}
                                        width={imageWidth}
                                        height={imageHeight}
                                        border={10}
                                        color={[218, 230, 242, 0.45]}
                                        scale={scale}
                                        rotate={rotate}
                                        position={position}
                                        onPositionChange={handlePositionChange}
                                    />
                                    <div className="mt-3">
                                        <label>Zoom: </label>
                                        <input type="range" min="1" max="3" step="0.1" value={scale} onChange={(e) => setScale(parseFloat(e.target.value))} />
                                    </div>
                                    <div className="mt-2">
                                        <label>Rotate: </label>
                                        <input type="range" min="0" max="360" step="1" value={rotate} onChange={(e) => setRotate(parseFloat(e.target.value))} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setIsEditingPhotothumbDraft(false)}>
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={saveEditedPhotoThumbDraft}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Model 2: Education */}
                <div class="  modal fade" id="education" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog  custom-modal-dialog">
                        <div class="modal-content px-3 py-3" style={{ borderRadius: "15px", marginTop: "20px" }}>
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <div style={{ marginRight: "-20px", marginTop: "-10px" }}>
                                        <CloseButton ref={closeEducation} />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  class="btn-close"
                  ref={closeEducation}
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className=" title text-center mx-auto pb-3"
                                        style={{
                                            color: "#444343",
                                            marginTop: "10px",
                                            marginBottom: "15px",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Education Details
                                    </p>
                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    School/College
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. Delhi university"
                                                    value={education.school}
                                                    onChange={handleEducationChange}
                                                    id="school"
                                                    name="school"
                                                />
                                            </div>

                                            <div class="form-group mt-3 mb-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "14px" }}>
                                                    Degree
                                                </label>
                                                {/* <DegreeSelector
                          education={education}
                          setEducation={setEducation}
                        /> */}
                                                <Select
                                                    options={Degree?.map((data) => ({
                                                        value: data,
                                                        label: data,
                                                    }))}
                                                    placeholder={<div style={{ fontSize: "13px" }}>Select degree</div>}
                                                    onChange={(selectedOption) => {
                                                        setEducation({
                                                            ...education,
                                                            degree: selectedOption?.value,
                                                        });
                                                    }}
                                                    value={
                                                        education.degree && {
                                                            value: education.degree,
                                                            label: education.degree,
                                                        }
                                                    }
                                                />
                                            </div>

                                            <div class="form-group mt-3 mb-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Specialization
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. Computer Science"
                                                    value={education.specialization}
                                                    onChange={handleEducationChange}
                                                    id="specialization"
                                                    name="specialization"
                                                />
                                            </div>

                                            {/* <div class="form-group my-2">
                  <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>Degree</label>
                  <input
                        style={{ fontSize: '15px', paddingTop: '18px', paddingBottom: '18px' }}
                    type="text"
                    class="ModelInput form-control "
                    placeholder="e.g. MCA"
                    value={education.degree}
                    onChange={handleEducationChange}
                    id="degree"
                    name="degree"
                  />
                </div>*/}
                                            <div className="row">
                                                <div className="col-6">
                                                    <div class=" form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            Start date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={education.startDate && new Date(education.startDate).toISOString().split("T")[0]}
                                                            onChange={(e) =>
                                                                setEducation({
                                                                    ...education,
                                                                    startDate: e.target.value,
                                                                })
                                                            }
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for=" exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            End date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={education.endDate && new Date(education.endDate).toISOString().split("T")[0]}
                                                            onChange={(e) =>
                                                                setEducation({
                                                                    ...education,
                                                                    endDate: e.target.value,
                                                                })
                                                            }
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>

                                                    {/*  <div key={education._id} className="form-check text-end float-right mr-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={education.pursuing}
                          id={education._id}
                          onChange={() => handleCheckboxChange(education._id)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={education._id}
                          style={{ marginTop: '-5px', marginLeft: '-10px', fontSize: '15px', fontWeight: '400' }}
                        >
                          Currently pursuing
                        </label>
                       </div> */}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div class=" form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            Performence Scale
                                                        </label>
                                                        <Select
                                                            options={[
                                                                { value: "Percentage", label: "Percentage" },
                                                                {
                                                                    value: "CGPA (Scale of 10)",
                                                                    label: "CGPA (Scale of 10)",
                                                                },
                                                                {
                                                                    value: "CGPA (Scale of 9)",
                                                                    label: "CGPA (Scale of 9)",
                                                                },
                                                                {
                                                                    value: "CGPA (Scale of 8)",
                                                                    label: "CGPA (Scale of 8)",
                                                                },
                                                                {
                                                                    value: "CGPA (Scale of 7)",
                                                                    label: "CGPA (Scale of 7)",
                                                                },
                                                                {
                                                                    value: "CGPA (Scale of 5)",
                                                                    label: "CGPA (Scale of 5)",
                                                                },
                                                                {
                                                                    value: "CGPA (Scale of 4)",
                                                                    label: "CGPA (Scale of 4)",
                                                                },
                                                            ]}
                                                            placeholder={<div style={{ fontSize: "13px" }}>Percentage</div>}
                                                            onChange={(selectedOption) => {
                                                                setEducation({
                                                                    ...education,
                                                                    performanceScale: selectedOption?.value,
                                                                });
                                                            }}
                                                            value={
                                                                education.performanceScale
                                                                    ? {
                                                                          value: education.performanceScale,
                                                                          label: education.performanceScale,
                                                                      }
                                                                    : null
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for=" exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            Performence
                                                        </label>
                                                        <input
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                            type="number"
                                                            className=" ModelInput form-control"
                                                            placeholder="0.00"
                                                            value={education.performance}
                                                            onChange={handleEducationChange}
                                                            id="performance"
                                                            name="performance"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {education._id ? (
                                        <>
                                            <button onClick={modifyEducation} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}{" "}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={updateEducation} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Model 3: Job */}
                <div class="  modal fade" id="job" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog  custom-modal-dialog">
                        <div class="modal-content px-3 py-3" style={{ borderRadius: "15px", marginTop: "20px" }}>
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <div style={{ marginRight: "-20px", marginTop: "-10px" }}>
                                        <CloseButton ref={closeJob} />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  class="btn-close"
                  ref={closeJob}
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className=" title text-center mx-auto pb-3"
                                        style={{
                                            color: "#444343",
                                            marginTop: "10px",
                                            marginBottom: "15px",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Job details
                                    </p>
                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Profile
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. Software Developer"
                                                    value={job.profile}
                                                    onChange={handleJobChange}
                                                    id="profile"
                                                    name="profile"
                                                />
                                            </div>
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Organization
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. ABC Company"
                                                    value={job.organization}
                                                    onChange={handleJobChange}
                                                    id="organization"
                                                    name="organization"
                                                />
                                            </div>
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Location
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. Mumbai"
                                                    value={job.location}
                                                    onChange={handleJobChange}
                                                    id="location"
                                                    name="location"
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            Start date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={job.startDate && new Date(job.startDate).toISOString().split("T")[0]}
                                                            onChange={(e) => setJob({ ...job, startDate: e.target.value })}
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            End date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={job.endDate && new Date(job.endDate).toISOString().split("T")[0]}
                                                            onChange={(e) => setJob({ ...job, endDate: e.target.value })}
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group ">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Description
                                                </label>
                                                <textarea class="ModelInput form-control " style={{ fontSize: "15px", lineHeight: "20px" }} rows="6" placeholder="Short description of work done..." cols="50" value={job.description} onChange={handleJobChange} id="description" name="description" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {job._id ? (
                                        <>
                                            <button onClick={modifyJob} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={updateJob} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Model 4: Internship */}
                <div class="  modal fade" id="internship" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog  custom-modal-dialog">
                        <div class="modal-content px-3 py-3" style={{ borderRadius: "15px", marginTop: "20px" }}>
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <div style={{ marginRight: "-20px", marginTop: "-10px" }}>
                                        <CloseButton ref={closeInternship} />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  class="btn-close"
                  ref={closeInternship}
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className=" title text-center mx-auto pb-3"
                                        style={{
                                            color: "#444343",
                                            marginTop: "10px",
                                            marginBottom: "15px",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Internship details
                                    </p>
                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Profile
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. Software Developer"
                                                    value={internship.profile}
                                                    onChange={handleInternshipChange}
                                                    id="profile"
                                                    name="profile"
                                                />
                                            </div>
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Organization
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. ABC Company"
                                                    value={internship.organization}
                                                    onChange={handleInternshipChange}
                                                    id="organization"
                                                    name="organization"
                                                />
                                            </div>
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Location
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. Mumbai"
                                                    value={internship.location}
                                                    onChange={handleInternshipChange}
                                                    id="location"
                                                    name="location"
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            Start date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={internship.startDate && new Date(internship.startDate).toISOString().split("T")[0]}
                                                            onChange={(e) =>
                                                                setInternship({
                                                                    ...internship,
                                                                    startDate: e.target.value,
                                                                })
                                                            }
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            End date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={internship.endDate && new Date(internship.endDate).toISOString().split("T")[0]}
                                                            onChange={(e) =>
                                                                setInternship({
                                                                    ...internship,
                                                                    endDate: e.target.value,
                                                                })
                                                            }
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group ">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Description
                                                </label>
                                                <textarea rows="6" placeholder="Short description of work done..." cols="50" class="ModelInput form-control " style={{ fontSize: "15px", lineHeight: "20px" }} value={internship.description} onChange={handleInternshipChange} id="description" name="description" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {internship._id ? (
                                        <>
                                            <button type="button " onClick={modifyInternship} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button type="button " onClick={updateInternship} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Model 5: Position of Responsibility 
  <div class="  modal fade" id="por" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel"> </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div className="step-content container " >
            <h3 className=" title text-center mx-auto pb-3" style={{ marginTop: "30px", marginBottom: "20px" }}>Position of Responsibility</h3>
            <div className="sectionn ml-auto " >
              <div className=" mx-auto " >
                <div class="form-group ">
                  <label for="exampleInputEmail1" style={{ fontSize: '15px' }}>
                    Description
                  </label>
                  <p style={{ color: '#808486', fontSize: "15px" }}>If you have been/are an active part of societies, conducted any events or led a team, add details here</p>
                  <textarea rows="6" placeholder="e.g. Led a team of 5 volunteers to plan and conduct activities for literary event in college fest" cols="50"  class="ModelInput form-control " style={{ fontSize: "15px" }} />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "-36px" }}></div>
        </div>
        <div class="modal-footer">
          <button type="button " class="btn btn-info" style={{ fontSize: "15px", color: "white", borderRadius: '5px', paddingLeft: "35px", paddingRight: "35px", backgroundColor: "#4AA9E0" }}>Update</button>
        </div>
      </div>
    </div>
  </div>
*/}

                {/* Model 6: Training/Courses */}
                <div class="  modal fade" id="training" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog  custom-modal-dialog">
                        <div class="modal-content px-3 py-3" style={{ borderRadius: "15px", marginTop: "20px" }}>
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <div style={{ marginRight: "-20px", marginTop: "-10px" }}>
                                        <CloseButton ref={closeTraining} />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  class="btn-close"
                  ref={closeTraining}
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className=" title text-center mx-auto pb-3"
                                        style={{
                                            color: "#444343",
                                            marginTop: "10px",
                                            marginBottom: "15px",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Training details
                                    </p>
                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Training program
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. Analytics"
                                                    value={training.program}
                                                    onChange={handleTrainingChange}
                                                    id="program"
                                                    name="program"
                                                />
                                            </div>
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Organization
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. ABC Company"
                                                    value={training.organization}
                                                    onChange={handleTrainingChange}
                                                    id="organization"
                                                    name="organization"
                                                />
                                            </div>
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Location
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. Mumbai"
                                                    value={training.location}
                                                    onChange={handleTrainingChange}
                                                    id="location"
                                                    name="location"
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            Start date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={training.startDate && new Date(training.startDate).toISOString().split("T")[0]}
                                                            onChange={(e) =>
                                                                setTraining({
                                                                    ...training,
                                                                    startDate: e.target.value,
                                                                })
                                                            }
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            End date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={training.endDate && new Date(training.endDate).toISOString().split("T")[0]}
                                                            onChange={(e) =>
                                                                setTraining({
                                                                    ...training,
                                                                    endDate: e.target.value,
                                                                })
                                                            }
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group ">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Description
                                                </label>
                                                <textarea rows="6" placeholder="Short description about training..." cols="50" class="ModelInput form-control " style={{ fontSize: "15px", lineHeight: "20px" }} value={training.description} onChange={handleTrainingChange} id="description" name="description" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {training._id ? (
                                        <>
                                            <button type="button " onClick={modifyTraining} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button type="button " onClick={updateTraining} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Model 7: Academic/personal project */}
                <div class="  modal fade" id="project" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog  custom-modal-dialog">
                        <div class="modal-content px-3 py-3" style={{ borderRadius: "15px", marginTop: "20px" }}>
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <div style={{ marginRight: "-20px", marginTop: "-10px" }}>
                                        <CloseButton ref={closeAcademics} />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  class="btn-close"
                  ref={closeAcademics}
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className=" title text-center mx-auto pb-3"
                                        style={{
                                            color: "#444343",
                                            marginTop: "10px",
                                            marginBottom: "15px",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Project details
                                    </p>
                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Title
                                                </label>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. Face recognization system"
                                                    value={academic.title}
                                                    onChange={handleAcademicChange}
                                                    id="title"
                                                    name="title"
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            Start date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={academic.startDate && new Date(academic.startDate).toISOString().split("T")[0]}
                                                            onChange={(e) =>
                                                                setAcademic({
                                                                    ...academic,
                                                                    startDate: e.target.value,
                                                                })
                                                            }
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div class="form-group my-2">
                                                        <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                            End date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            value={academic.endDate && new Date(academic.endDate).toISOString().split("T")[0]}
                                                            onChange={(e) =>
                                                                setAcademic({
                                                                    ...academic,
                                                                    endDate: e.target.value,
                                                                })
                                                            }
                                                            className="ModelInput form-control"
                                                            style={{
                                                                fontSize: "15px",
                                                                paddingTop: "18px",
                                                                paddingBottom: "18px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group ">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Description
                                                </label>
                                                <textarea rows="6" placeholder="Short description about project..." cols="50" class="ModelInput form-control " style={{ fontSize: "15px", lineHeight: "20px" }} value={academic.description} onChange={handleAcademicChange} id="description" name="description" />
                                            </div>
                                            <div class="form-group my-2">
                                                <label for="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Project link
                                                </label>
                                                <p style={{ color: "#808486", fontSize: "14px" }}>If you have multiple project links or an offline project, upload and provide link to google drive</p>
                                                <input
                                                    style={{
                                                        fontSize: "15px",
                                                        paddingTop: "18px",
                                                        paddingBottom: "18px",
                                                    }}
                                                    type="text"
                                                    class="ModelInput form-control "
                                                    placeholder="e.g. http://myproject.com"
                                                    value={academic.link}
                                                    onChange={handleAcademicChange}
                                                    id="link"
                                                    name="link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {academic._id ? (
                                        <>
                                            <button type="button " onClick={modifyAcademic} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button type="button " onClick={updateAcademic} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Model 8: Skills */}
                <div class="  modal fade" id="skill" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog  custom-modal-dialog">
                        <div class="modal-content px-3 py-3" style={{ borderRadius: "15px", marginTop: "20px" }}>
                            <div class="modal-body">
                                <div className="step-content container ">
                                    <div style={{ marginRight: "-20px", marginTop: "-10px" }}>
                                        <CloseButton ref={closeSkills} />
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                <button
                  type="button"
                  class="btn-close"
                  ref={closeSkills}
                  data-bs-dismiss="modal"
                  style={{ marginRight: "-10px" }}
                  aria-label="Close"
                ></button>
              </div> */}
                                    <p
                                        className=" title text-center mx-auto pb-3"
                                        style={{
                                            color: "#444343",
                                            marginTop: "10px",
                                            marginBottom: "15px",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Skills
                                    </p>
                                    <div className="sectionn ml-auto ">
                                        <div className=" mx-auto ">
                                            <div className="form-group my-2">
                                                <label htmlFor="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Add Skills
                                                </label>
                                                <AddSkillsSelector setSkill={setskill} skill={skill} setSkillVissible={setSkillVissible} skillVissible={skillVissible} />
                                                {/* <Select
                          name="name"
                          value={
                            skillVissible
                              ? { label: "", value: "" }
                              : skill.name === "Other"
                              ? null
                              : {
                                  label: skill.name,
                                  value: skill.name,
                                }
                          }
                          onChange={(selectedSkill) => {
                            if (
                              selectedSkill &&
                              selectedSkill.value === "Other"
                            ) {
                              setskill({ ...skill, name: "" });
                              setSkillVissible(true);
                            } else {
                              setSkillVissible(false);
                              setskill({ ...skill, name: selectedSkill.value });
                            }
                          }}
                          options={pdskills?.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          placeholder="e.g. Adobe photoshop"
                          style={{ fontSize: "15px" }}
                          onInputChange={handleInputChange2}
                          styles={customStyles}
                          // noOptionsMessage={noOptionsMessage2}
                          //menuIsOpen={isTyping2}
                        /> */}
                                            </div>
                                            {skillVissible && (
                                                <div className="form-group mt-4 mb-2">
                                                    <label htmlFor="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                        Please Specify
                                                    </label>
                                                    <input style={{ fontSize: "15px" }} type="text" className="ModelInput form-control py-3" placeholder="e.g. Adobe photoshop" value={skill.name} onChange={handleSkillChange} id="name" name="name" />
                                                </div>
                                            )}
                                            <div className="form-group my-2">
                                                <label htmlFor="exampleInputEmail1" style={{ fontSize: "15px" }}>
                                                    Level
                                                </label>
                                                <Select
                                                    name="level"
                                                    value={{ label: skill.level, value: skill.level }}
                                                    onChange={(selectedLevel) => {
                                                        setskill({ ...skill, level: selectedLevel.value });
                                                    }}
                                                    options={[
                                                        { label: "Beginner", value: "Beginner" }, // Option for Beginner level
                                                        { label: "Intermediate", value: "Intermediate" }, // Option for intermediate level
                                                        { label: "Advanced", value: "Advanced" }, // Option for advanced level
                                                    ]}
                                                    style={{ fontSize: "15px" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-36px", textAlign: "end" }}>
                                    {skill._id ? (
                                        <>
                                            <button type="button " onClick={modifySkill} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Update</span>}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button type="button " onClick={updateSkill} class="saveUpdate">
                                                {loading ? <Spinner /> : <span>Save</span>}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} />
        </>
    );
}

export default EditResume;
