import React, { useEffect, useState, useRef } from "react";
import citiesData from "../Employer/citiesData.json";
import Dropdown from "../EmployerNew/Employertemplates/Dropdown.jsx";
import { CiCalendar } from "react-icons/ci";
import { Tooltip } from "@material-tailwind/react";
import Calendar from "react-calendar";
import { RxCross1 } from "react-icons/rx";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../Student_New/UserApplications.scss";
import "../Student_New/Jobs.scss";
import PropagateLoader from "react-spinners/PropagateLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { MONTH_DETAILS } from "../data";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Grid } from "@mui/material";
import { LuClipboardEdit } from "react-icons/lu";
import { CgFileDocument } from "react-icons/cg";
import "../EmployerNew/css/postInternship.css";
import { useSelector } from "react-redux";
import { skillOptions, options, predefinedOptions } from "../data";
import "react-country-state-city/dist/react-country-state-city.css";
import "./AdminDashboard.css";
import { Spinner } from "react-bootstrap";
import { DeleteForever } from "@mui/icons-material";
import { RiDeleteBinLine } from "react-icons/ri";
import PaginationWithLastButton from "../Student_New/PaginationWithLastButton.jsx";

function AdminPostedJobsAndInternships() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [jobData, setJobData] = useState("");
    const [totalItem, setTotalItem] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const navigate = useNavigate();
    const [cityOptions, setCityOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [isTyping1, setIsTyping1] = useState(false);
    const openPreviewPageInNewTab = (state) => {
        const data = state;
        window.open(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());
    };

    const GetJobData = async (searchInput = "") => {
        setLoading(true);
        console.log(currentPage, "currentPage");
        try {
            const { data } = await axios.post("/api/user//getJobsForAdmin", {
                // searchInput: search,
                searchInput,
                page: currentPage,
                pageSize: 25, // Display 15 data per page
            });
            setLoading(false);
            setJobData(data.data);
            setTotalPages(data.totalPages);
            setTotalItem(data.totalItems);
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data, {
                style: {
                    padding: "18px",
                },
            });
        }
    };
    console.log(jobData);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    useEffect(() => {
        GetJobData();
        window.scrollTo(0, 0);
    }, [currentPage]);

    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
        setIsTyping(newValue.length > 0);
    };
    useEffect(() => {
        const fetchMatchingCities = async () => {
            if (searchTerm.trim().length >= 2) {
                try {
                    const response = await axios.get(`/api/data/get-cities-data`, {
                        params: { keyword: searchTerm },
                    });
                    if (Array.isArray(response.data)) {
                        setCityOptions(response.data.map((city) => ({ label: city, value: city })));
                    } else {
                        console.error("Unexpected response format:", response.data);
                    }
                } catch (error) {
                    console.error("Error fetching cities:", error);
                }
            } else {
                setCityOptions([]);
            }
        };

        fetchMatchingCities();
    }, [searchTerm]);
    const noOptionsMessage = () => {
        return searchTerm.trim().length < 2 ? "Type a City Name" : "No Options";
    };

    // APPROVED REJECT JOBS AND INTERNSHIPS OPTION:

    const [id, setId] = useState("");
    const [userStatus, setUserStatus] = useState("");

    const idSelector = (_id) => {
        setId(_id);
    };

    const ApprovedJob = async () => {
        window.scrollTo(0, 0);
        setLoading(true);
        try {
            const { data } = await axios.post("/api/user/approvereject", {
                id: id,
                status: "Approved",
            });
            // GetJobData();
            const userIndex = jobData.findIndex((user) => user._id === id);
            // If the user is found, update the active field directly
            if (userIndex !== -1) {
                jobData[userIndex].status = "Approved";
            }
            toast.success("Application is approved");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(error.response.data, {
                style: {
                    padding: "18px",
                },
            });
        }
    };

    const RejectJob = async () => {
        window.scrollTo(0, 0);
        setLoading(true);
        try {
            const { data } = await axios.post("/api/user/approvereject", {
                id: id,
                status: "Rejected",
            });
            // GetJobData();
            const userIndex = jobData.findIndex((user) => user._id === id);
            // If the user is found, update the active field directly
            if (userIndex !== -1) {
                jobData[userIndex].status = "Rejected";
            }
            toast.success("Application is rejected");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(error.response.data, {
                style: {
                    padding: "18px",
                },
            });
        }
    };

    // SEARCH OPTION:

    const [search, setSearch] = useState("");
    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };

    // const handleSearch = () =>{
    //   GetJobData();
    //   setSearch("");
    // }
    const handleSearch = () => {
        if (search.trim() !== "") {
            // Only call GetJobData with search input if it's not empty
            GetJobData(search.trim());
            setSearch("");
        } else {
            setCurrentPage(1);
            if (currentPage !== 1) {
                // Check if current page is not already 1
                setCurrentPage(1); // Set current page to 1
            }
            GetJobData(); // If search input is empty, fetch all data
            // setCurrentPage(1, () => {
            //   GetJobData("");
            // });
            setSearch("");
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    // FOR AUTHENTICATION
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (!localStorage.getItem("token") || !localStorage.getItem("userType") || localStorage.getItem("userType") !== "Admin") {
            navigate("/admin_login");
        }
    });
    //  END

    //  EDIT JOBS AND INTERNSHIP POPUP:
    const { user } = useSelector((state) => state.user);
    const textareaRef = useRef(null);
    const borderRemoveRef = useRef(null);

    const month = (data) => {
        const num = ["0", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"];
        if (!data) {
            return 0;
        } else {
            return num[data];
        }
    };

    const fetchData = async (_id) => {
        borderRemoveRef.current.scrollTop = 0;
        const response = await axios.post(
            "/api/employer/getJob",
            { _id },
            {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            }
        );

        const data = await response.data;
        setJob({
            _id: data._id,
            email: data.email,
            opportunityType: data.opportunityType,
            jobType: data.jobType,
            profile: data.profile,
            skill: data?.skill,
            numberOfOpeining: data.numberOfOpeining,
            city: data.city,
            country: "India",
            isPartTimeAllowed: data.isPartTimeAllowed,
            duration: data.duration,
            durationType: data.durationType,
            salaryProfile: data.salaryProfile,
            salary: data.salary,
            salaryMax: data.salaryMax,
            perks: data.perks,
            jobDescription: data.jobDescription,
            dayOfWeek: data.dayOfWeek,
            startDate: data.startDate,
            preference: data?.preference,
            stipendAmountType: data.stipendAmountType,
            incentive: data?.incentive,
            incentiveType: data?.incentiveType,
            question: {
                q1: data?.question?.q1,
                q2: data?.question?.q2,
            },
            hrContact: data?.hrContact,
            minExperience: data?.minExperience,
            maxExperience: data?.maxExperience,
        });
        if (data?.question?.q1) {
            setShowQuestion([true, false]);
        }

        if (data?.question?.q2) {
            setShowQuestion([true, true]);
        }

        if (data?.skill?.length > 0) {
            setSelectedSkill(data?.skill.map((item) => ({ label: item, value: item })));
        }

        if (data?.city?.length > 0) {
            setSelectedCity(data?.city.map((item) => ({ label: item, value: item })));
        }
    };

    const initialForm = {
        _id: "",
        email: "",
        opportunityType: "Internship",
        jobType: "",
        profile: "",
        skill: [],
        numberOfOpeining: "",
        city: [],
        country: "India",
        isPartTimeAllowed: "No",
        duration: "",
        durationType: "month",
        salaryProfile: "Fixed",
        salary: "",
        salaryMax: "",
        perks: [],
        jobDescription: "",
        dayOfWeek: "",
        startDate: "Immediately (within next 30 days)",
        internshipFrom: "",
        internshipTo: "",
        preference: "",
        stipendAmountType: "month",
        incentive: "",
        incentiveType: "",
        question: {
            q1: "",
            q2: "",
        },
        hrContact: user?.phone,
        minExperience: "",
        maxExperience: "",
    };

    const [job, setJob] = useState(initialForm);

    useEffect(() => {
        borderRemoveRef.current.scrollTop = 0;
    }, [job._id]);

    const [prev, setPrev] = useState({
        orgLogo: "",
    });

    const [loading22, setLoading22] = useState(false);
    const [users, setUser] = useState({
        name: "",
        email: "",
        phone: "",
        city: "",
    });

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showQuestion, setShowQuestion] = useState([false, false]);
    const [numberOfDays, setNumberOfDays] = useState(null);
    const [isloading, setIsLoading] = useState(false);
    const closeDilog = () => {
        setShowSuccessPopup(false);
    };

    const handlepostnewButton = () => {
        window.location = "/newpost/0";
    };

    const handleviepostedjobButton = (data) => {
        navigate(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());
    };

    const selectedCountry = "India";
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedSkill, setSelectedSkill] = useState("");

    const handleCityChange = (selectedOptions) => {
        setSelectedCity(selectedOptions);
        setJob({
            ...job,
            city: selectedOptions.map((option) => option.value),
        });
    };

    const handleSkillChange = (selectedOptions) => {
        setSelectedSkill(selectedOptions);
        setJob({
            ...job,
            skill: selectedOptions.map((option) => option.value),
        });
    };

    const handlePerksChange = (value) => {
        if (!job.perks.includes(value)) {
            setJob((prev) => ({ ...prev, perks: [...prev.perks, value] }));
        } else {
            setJob((prev) => ({ ...prev, perks: prev.perks.filter((i) => i !== value) }));
        }
    };

    useEffect(() => {
        setUser(user && user);
        setJob((prev) => ({ ...prev, hrContact: user?.phone }));
        setPrev({
            ...prev,
            orgLogo: (user && user.organisationAvatar != undefined) || "" ? user.organisationAvatar.url : "",
        });
    }, [user]);

    const handleJobChange = (e) => {
        if (e.target.name === "opportunityType") {
            setShowQuestion([false, false]);
            setSelectedCity("");
            setSelectedSkill("");
            setJob({
                ...initialForm,
                email: user.email,
                [e.target.name]: e.target.value,
            });
        } else {
            setJob({
                ...job,
                email: user.email,
                [e.target.name]: e.target.value,
            });

            if (e.target.name == "internshipFrom" && job.internshipTo !== "" && new Date(e.target.value) > new Date(job.internshipTo)) {
                setJob({ ...job, email: user.email, internshipFrom: e.target.value, internshipTo: e.target.value });
            }
        }
    };

    const addProfile = async (value) => {
        await axios.post("/api/data/update-profiles", { value }).catch((err) => console.log(err));
    };

    const [justCreated, setJustCreated] = useState({});
    const handleJobUpdate = async (e) => {
        e.preventDefault();
        setLoading22(true);

        if (job.jobDescription.length > 1900) {
            setLoading22(false);
            textareaRef.current.focus();
            return;
        } else if (!job.opportunityType) {
            setLoading22(false);
            toast.error("Opportunity type is required!!");
        } else if (job.opportunityType == "Job" && job.skill.length == 0) {
            setLoading22(false);
            toast.error("skill is required!!");
        } else if (job.opportunityType == "Job" && !job.minExperience) {
            setLoading22(false);
            toast.error("Provide minimum experience required for job");
        } else if (!job.jobType) {
            setLoading22(false);
            toast.error(`${job.opportunityType} type is required!!`);
        } else if (job.jobType != "Remote" && job.city.length == 0) {
            setLoading22(false);
            toast.error("City is required!!");
        } else if (job.jobType == "Hybrid" && !job.dayOfWeek) {
            setLoading22(false);
            toast.error("Please select no. of in office days in a week");
        } else if (job.opportunityType != "Job" && !job.duration) {
            setLoading22(false);
            toast.error("Duration is required!!");
        } else if (!job.salary) {
            setLoading22(false);
            toast.error("Salary is required!!");
        } else if (job.opportunityType == "Job" && job.salaryMax < job.salary) {
            setLoading22(false);
            toast.error("Maximum salary should be greater than or equal to salary");
        } else {
            console.log(job);
            try {
                const { data } = await axios.post("/api/employer/updateJob", job, {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                });

                if (data.success) {
                    addProfile(data.job.profile);
                    setLoading22(false);
                    document.querySelector(".btn-close").click();
                    toast.success("Application has been updated");
                    GetJobData();
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                setLoading22(false);
                toast.success("Server Error");
            }
        }
    };

    const days = [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
    ];

    const durationList = [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
    ];

    const durationTypeList = [
        { value: "months", label: "months" },
        { value: "weeks", label: "weeks" },
    ];

    const minexperience = [
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
    ];

    const maxexperience = [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
    ];

    const handleAddQuestion = () => {
        if (!showQuestion[0] && !showQuestion[1]) {
            setShowQuestion([true, false]);
        } else if (showQuestion[0] && !showQuestion[1]) {
            setShowQuestion([true, true]);
        }
    };

    const handleRemoveQuestion = (id) => {
        setJob((prev) => ({ ...prev, question: initialForm.question }));
        if (showQuestion[0] && showQuestion[1]) {
            setShowQuestion([true, false]);
        } else {
            setShowQuestion([false, false]);
        }
    };

    const [allskills, setAllSkills] = useState([]);
    const [allprofiles, setAllProfiles] = useState([]);
    const [searchTerm2, setSearchTerm2] = useState("");

    useEffect(() => {
        // async function fetchAllSkills() {
        //   const response = await axios.get(`/api/data/skills`);
        //   const data = await response.data;
        //   setAllSkills(data.map((i) => ({ label: i, value: i })));
        // }

        async function fetchAllProfiles() {
            const response = await axios.get(`/api/data/profiles`);
            const data = await response.data;
            setAllProfiles(data);
        }

        // fetchAllSkills();
        fetchAllProfiles();
    }, []);

    useEffect(() => {
        async function fetchAllSkills() {
            if (searchTerm2.trim()) {
                try {
                    const response = await axios.get(`/api/data/skills`, {
                        params: { keyword: searchTerm2 },
                    });
                    if (Array.isArray(response.data)) {
                        setAllSkills(response.data.map((skill) => ({ label: skill, value: skill })));
                    } else {
                        console.error("Unexpected response format:", response.data);
                    }
                } catch (error) {
                    console.error("Error fetching skills:", error);
                }
            } else {
                setAllSkills([]);
            }
        }
        fetchAllSkills();
    }, [searchTerm2]);
    const handleInputChange2 = (newValue) => {
        setSearchTerm2(newValue);
        setIsTyping1(newValue.length > 0);
    };
    const customStyles2 = {
        dropdownIndicator: (provided) => ({
            ...provided,
            display: "none",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
    };
    const noOptionsMessage2 = () => {
        return searchTerm2.trim().length < 2 ? "Type Skill" : "No Options";
    };
    const handleUpdateDeadline = async (days) => {
        console.log(days);

        setIsLoading(true);
        try {
            const response = await axios.post("/api/employer/extendjobinternshipdeadline", { numberOfDays: days });
            toast.success("Deadline Successfully Updated");
            console.log(response.data.message); // Log success message
        } catch (error) {
            // Handle error
            toast.error("Error updating");
            console.error("Error updating deadlines:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const getInternshipJobDeadline = async () => {
        try {
            const response = await axios.get("/api/employer/getjobinternshipdeadlineFromadminside");
            const { data } = response;
            setNumberOfDays(data.deadlinedays);
        } catch (error) {
            toast.error("Error updating");
            console.error("Error updating deadlines:", error);
        }
    };
    useEffect(() => {
        getInternshipJobDeadline();
    }, []);

    const handleSelectChange = (selectedOption) => {
        if (selectedOption) {
            setNumberOfDays(selectedOption.value); // Only set when the user selects
        } else {
            setNumberOfDays(null); // Reset if needed
        }
    };
    const options = Array.from({ length: 60 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "164px",
            minHeight: "44px", // Adjust the height as needed
        }),
        menu: (provided, state) => ({
            ...provided,
            width: "100%", // Set menu width to match the width of the select box
            position: "absolute", // Position menu absolutely
            zIndex: 9999, // Ensure menu appears above other elements
        }),
        menuList: (provided, state) => ({
            ...provided,
            width: "100%", // Set menu list width to match the width of the select box
        }),
    };

    const [showModal, setShowModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handleDeleteConfirmation = (id) => {
        setIdToDelete(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        await handleDeletePost(idToDelete);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handleDeletePost = async (id) => {
        setLoading(true);
        try {
            const response = await axios.post("/api/admin/deleteInternshipJobAdmin", { id });

            console.log(response.data.message);
            // GetJobData();
            // setJobData(prevJobData => prevJobData.filter(job => job.id !== id));
            // Find the index of the job to be deleted
            const jobIndex = jobData.findIndex((job) => job._id === id);

            // If the job is found, update the jobData state by removing that job
            if (jobIndex !== -1) {
                const updatedJobData = [...jobData];
                updatedJobData.splice(jobIndex, 1);
                setJobData(updatedJobData);
            }
            toast.success("Application Deleted Successfully");
            setLoading(false);
        } catch (error) {
            console.error("Error deleting job:", error);
        }
    };
    return (
        <>
            <div className="my-applications">
                {loading ? (
                    <>
                        <div className="my-auto" style={{ textAlign: "center" }}>
                            <p
                                className="loaderLoader"
                                style={{
                                    color: "#4B92C8",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    marginLeft: "16px",
                                    marginBottom: "5px",
                                }}
                            >
                                Loading..
                            </p>
                            <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "-4px" }} />
                        </div>
                    </>
                ) : (
                    <>
                        <p className="borderremove" style={{ color: "#494949", fontSize: "20px", fontWeight: "500", backgroundColor: "white", borderRadius: "10px", paddingLeft: "10%", paddingRight: "10%", paddingTop: "1%", paddingBottom: "1%" }}>
                            Hourly/Fixed Cost Project Details
                        </p>
                        <div className="AdminSearchBoxmain">
                            <div className="AdminSearchBoxsubmain">
                                <h3>Hourly & Fixed Project deadline</h3>
                                <div className="AdminSearchBox2">
                                    <Select options={options} onChange={handleSelectChange} value={numberOfDays ? { value: numberOfDays, label: `${numberOfDays}` } : null} placeholder="Choose days..." styles={customStyles} menuPortalTarget={document.body} />

                                    <button className="AdminSearchButton" onClick={() => handleUpdateDeadline(numberOfDays)}>
                                        {isloading ? <Spinner /> : <span>Update</span>}
                                    </button>
                                </div>
                            </div>
                            <div className="AdminSearchBox3">
                                <input className="AdminSearchInput" type="text" placeholder="Search by profile, company" onChange={handleSearchInput} value={search} onKeyPress={handleKeyPress} />
                                <button className="AdminSearchButton" onClick={handleSearch}>
                                    Search
                                </button>
                            </div>
                        </div>

                        {/* mobile */}
                        <div className="AdminSearchBoxsubmain-mobile">
                            <h3>Hourly & Fixed Project deadline</h3>
                            <div className="AdminSearchBox2-mobile">
                                <Select
                                    options={options}
                                    onChange={handleSelectChange}
                                    // value={numberOfDays ? { value: numberOfDays, label: `${numberOfDays}` } : null} // No default selection
                                    placeholder="Choose days..." // Placeholder will show until the user selects
                                    styles={customStyles}
                                    menuPortalTarget={document.body}
                                />

                                <button className="AdminSearchButton" onClick={() => handleUpdateDeadline(numberOfDays)}>
                                    {isloading ? <Spinner /> : <span>Update</span>}
                                </button>
                            </div>
                        </div>
                        <div className="AdminSearchBox-mobile">
                            <input className="AdminSearchInput" type="text" placeholder="Search by profile, company" onChange={handleSearchInput} value={search} onKeyPress={handleKeyPress} />
                            <button className="AdminSearchButton" onClick={handleSearch}>
                                Search
                            </button>
                        </div>

                        <div className="table-box table-responsive border borderremove px-0">
                            <table className="applications table">
                                <thead>
                                    <tr>
                                        <th className="tableHead" style={{ textTransform: "capitalize", minWidth: "300px" }}>
                                            Profile
                                        </th>
                                        <th className="tableHead" style={{ textTransform: "capitalize" }}>
                                            Type
                                        </th>
                                        <th style={{ textAlign: "center", textTransform: "capitalize" }} className="tableHead">
                                            Posted By
                                        </th>
                                        <th style={{ textAlign: "center", textTransform: "capitalize" }} className="tableHead">
                                            Posted Date
                                        </th>
                                        <th style={{ textAlign: "center", textTransform: "capitalize" }} className="tableHead">
                                            Status
                                        </th>
                                        <th style={{ textAlign: "center", textTransform: "capitalize" }} className="tableHead">
                                            Edit Hourly&Fixed Post
                                        </th>
                                        <th style={{ textAlign: "center", textTransform: "capitalize" }} className="tableHead">
                                            Delete Hourly&Fixed Post
                                        </th>
                                        <th style={{ textAlign: "center", textTransform: "capitalize" }} className="tableHead">
                                            Review Application
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading2 ? (
                                        <>
                                            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center" style={{ paddingLeft: "5%" }}>
                                                <div className=" mx-auto" style={{ marginBottom: "20px", marginTop: "20px", justifyContent: "center", justifyItem: "center", textAlign: "center" }}>
                                                    <ScaleLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" />
                                                </div>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            {jobData.length != 0 ? (
                                                <>
                                                    {jobData.map((data, index) => (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", marginLeft: "20px", marginRight: "10px" }} className="tableheadingstyle15">
                                                                {data.profile}
                                                            </td>
                                                            <td style={{ fontSize: "14px" }} className="tableHead">
                                                                {data.opportunityType}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle">
                                                                {data.organisationName}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle">
                                                                {data.datePosted.slice(8, 10) + " " + MONTH_DETAILS[parseInt(data.datePosted.slice(5, 7), 10)] + "' " + data.datePosted.slice(2, 4)}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle">
                                                                <div class="dropdown3 ml-4" style={{ borderRadius: "5px", border: "solid 1px #4B92C8", marginBottom: "-10px" }}>
                                                                    <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" onClick={() => idSelector(data._id)} style={{ fontSize: "15px", width: "100%" }}>
                                                                        {data.status == "Approved" ? "Approved" : "Rejected"}
                                                                    </button>
                                                                    <ul class="dropdown-menu dropdown-menu-light px-2 custom-dropdown-menu1" aria-labelledby="dropdownMenuButton2">
                                                                        {data.status == "Rejected" ? (
                                                                            <li>
                                                                                <a class="dropdown-item border text-center py-2" style={{ backgroundColor: "#4B92C8", color: "white", borderRadius: "5px", cursor: "pointer", fontSize: "15px" }} onClick={ApprovedJob}>
                                                                                    Approve
                                                                                </a>
                                                                            </li>
                                                                        ) : (
                                                                            <li>
                                                                                <a class="dropdown-item border text-center py-2" style={{ backgroundColor: "#4B92C8", color: "white", borderRadius: "5px", cursor: "pointer", fontSize: "15px" }} onClick={RejectJob}>
                                                                                    Reject
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle">
                                                                <LuClipboardEdit className="docIcon" style={{ fontSize: "21px", color: "#4B92C8" }} onClick={() => fetchData(data._id)} data-bs-toggle="modal" data-bs-target="#exampleModal" />
                                                            </td>
                                                            {/* <td style={{fontSize: "14px", textAlign: "center"}} className="tableheadingstyle deletehovering" onClick={()=>handleDeletePost(data._id)}><RiDeleteBinLine className="docIcon" style= {{fontSize: "23px",color:'#646464'}}/></td> */}
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle deletehovering">
                                                                <RiDeleteBinLine className="docIcon" style={{ fontSize: "23px", color: "#646464" }} onClick={() => handleDeleteConfirmation(data._id)} />
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle">
                                                                <CgFileDocument className="docIcon" style={{ color: "#4B92C8" }} onClick={() => openPreviewPageInNewTab(data)} />{" "}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    <p style={{ padding: "15px  20px", minWidth: "200px", paddingBottom: "10", color: "red" }}>No data found!</p>
                                                </>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {/* pagination */}
                        <div className="pagination" style={{ width: "100%", maxWidth: "1200px" }}>
                            <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{ marginBottom: "1%", paddingRight: "5%" }}>
                                {totalPages > 1 ? (
                                    <></>
                                ) : (
                                    <>
                                        <div></div>
                                    </>
                                )}
                            </Grid>
                            {totalPages > 1 ? (
                                <>
                                    <div style={{ textAlign: "center", maxWidth: "1200px", marginInline: "auto" }}>
                                        <PaginationWithLastButton totalPages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                )}
            </div>

            {/* EDIT PROFILE MODEL */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content borderremove123" ref={borderRemoveRef} style={{ overflowY: "auto", borderRadius: "2px", height: "95vh" }}>
                        <div class="modal-body">
                            <div style={{ textAlign: "end", paddingRight: "30px" }}>
                                <button style={{ position: "fixed" }} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            {/*EDIT APPLICATION CODE START HERE*/}
                            <div className="container">
                                <p className="text-center mx-auto" style={{ margin: "40px 0px", fontSize: "20px", fontWeight: "500", color: "#5F5F5F" }}>
                                    Edit Jobs/Internships
                                </p>
                                <div className="section mx-auto" style={{ width: "90%" }}>
                                    <form onSubmit={handleJobUpdate} className="col">
                                        {/* <div className="sub-section">
              <div className="title" style={{fontSize: "19px", fontWeight: '500'}}>Type of Opportunity</div>
              <div className="container fields">
                <div className="d-flex gap-5 align-items-center">
                  <div className="d-flex gap-2 align-items-center  justify-content-center">
                    <input
                      type="radio"
                      className="check-input"
                      name="opportunityType"
                      value="Internship"
                      onChange={handleJobChange}
                      checked={job.opportunityType === "Internship"}
                    />
                    <div className="check-labels" htmlFor="opportunityType">
                      Internships
                    </div>
                  </div>
                  <div className="d-flex gap-2 align-items-center justify-content-center">
                    <input
                      type="radio"
                      className="check-input"
                      name="opportunityType"
                      value="Job"
                      onChange={handleJobChange}
                      checked={job.opportunityType === "Job"}
                    />
                    <div className="check-labels" htmlFor="opportunityType">
                      Jobs
                    </div>
                  </div>
                </div>
              </div>
            </div>
            */}

                                        <div className="sub-section">
                                            <div className="title" style={{ fontSize: "19px", fontWeight: "500" }}>
                                                {" "}
                                                {job.opportunityType} details
                                            </div>
                                            <div className="container fields">
                                                {/* Profile */}
                                                <div className="details">
                                                    <label htmlFor="profile" className="sub-title">
                                                        {job.opportunityType} Role
                                                    </label>
                                                    <Dropdown type={job.opportunityType === "Job"} placeholder="e.g. Web development" category="profile" actual={job} setActual={setJob} options={allprofiles} />
                                                </div>

                                                {/* Required Experience */}
                                                {job.opportunityType == "Job" && (
                                                    <div className="details">
                                                        <div className="sub-title">Experience Required</div>
                                                        <div className="d-flex align-items-center gap-3 w-full">
                                                            <Select
                                                                className="w-100"
                                                                value={job.minExperience != "" ? { label: job.minExperience, value: job.minExperience } : { label: "Min", value: job.minExperience }}
                                                                onChange={(exp) => {
                                                                    setJob({
                                                                        ...job,
                                                                        minExperience: exp.value,
                                                                    });
                                                                }}
                                                                options={minexperience}
                                                            />
                                                            <span>to</span>
                                                            <Select
                                                                className="w-100"
                                                                value={job.maxExperience != "" ? { label: job.maxExperience, value: job.maxExperience } : { label: "Max", value: job.maxExperience }}
                                                                onChange={(exp) => {
                                                                    setJob({
                                                                        ...job,
                                                                        maxExperience: exp.value,
                                                                    });
                                                                }}
                                                                options={maxexperience}
                                                            />
                                                            <span>year(s)</span>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Skills */}
                                                <div className="details">
                                                    <label htmlFor="profile" className="sub-title">
                                                        Skills required {job.opportunityType != "Job" && <span style={{ fontSize: "12px", color: "#bbb" }}>(Optional)</span>}
                                                    </label>
                                                    <Select
                                                        placeholder="e.g. Python"
                                                        className="select-tag"
                                                        isMulti
                                                        value={selectedSkill}
                                                        onChange={handleSkillChange}
                                                        options={allskills}
                                                        onInputChange={handleInputChange2}
                                                        styles={customStyles2}
                                                        menuIsOpen={isTyping1}
                                                        // noOptionsMessage={noOptionsMessage2}
                                                    />
                                                </div>

                                                {/* Type */}
                                                <div className="details">
                                                    <div className="sub-title mb-3">{job.opportunityType} type</div>
                                                    <div className="d-flex gap-5 align-items-center">
                                                        <div className="d-flex gap-2 align-items-center  justify-content-center">
                                                            <input type="radio" className="check-input" name="jobType" value="Office" onChange={handleJobChange} checked={job.jobType === "Office"} />
                                                            <div className="check-labels" htmlFor="jobType">
                                                                In-office
                                                            </div>
                                                        </div>
                                                        <div className="d-flex gap-2 align-items-center justify-content-center">
                                                            <input type="radio" className="check-input" name="jobType" value="Remote" onChange={handleJobChange} checked={job.jobType === "Remote"} />
                                                            <div className="check-labels" htmlFor="jobType">
                                                                Remote-based
                                                            </div>
                                                        </div>
                                                        <div className="d-flex gap-2 align-items-center justify-content-center">
                                                            <input type="radio" className="check-input" name="jobType" value="Hybrid" onChange={handleJobChange} checked={job.jobType === "Hybrid"} />
                                                            <div className="check-labels" htmlFor="jobType">
                                                                Combination
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* No of days in a week */}
                                                {job.jobType == "Hybrid" && (
                                                    <div className="details w-50" style={{ maxWidth: "300px" }}>
                                                        <div htmlFor="city" className="sub-title">
                                                            No. of in-office days in a week:
                                                        </div>
                                                        <Select
                                                            className="select-tag"
                                                            required
                                                            value={job.dayOfWeek != "" ? { label: job.dayOfWeek, value: job.dayOfWeek } : { label: "Please Select", value: job.dayOfWeek }}
                                                            onChange={(day) => {
                                                                setJob({
                                                                    ...job,
                                                                    dayOfWeek: day.value,
                                                                });
                                                            }}
                                                            options={days}
                                                        />
                                                    </div>
                                                )}

                                                {/* Partime/Fulltime */}
                                                <div className="details">
                                                    <div className="sub-title mb-3">Full-time/Part-time</div>
                                                    <div className="d-flex gap-5 align-items-center">
                                                        <div className="d-flex gap-2 align-items-center justify-content-center">
                                                            <input type="radio" className="check-input" name="isPartTimeAllowed" value="No" onChange={handleJobChange} checked={job.isPartTimeAllowed === "No"} />
                                                            <div className="check-labels" htmlFor="isPartTimeAllowed">
                                                                Full-time
                                                            </div>
                                                        </div>
                                                        <div className="d-flex gap-2 align-items-center  justify-content-center">
                                                            <input type="radio" className="check-input" name="isPartTimeAllowed" value="Yes" onChange={handleJobChange} checked={job.isPartTimeAllowed === "Yes"} />
                                                            <div className="check-labels" htmlFor="isPartTimeAllowed">
                                                                Part-time
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* City Select */}
                                                {(job.jobType == "Hybrid" || job.jobType == "Office") && (
                                                    <div className="details">
                                                        <div htmlFor="city" className="sub-title">
                                                            City/Cities
                                                        </div>
                                                        <Select
                                                            placeholder="e.g. Mumbai"
                                                            isMulti
                                                            // options={selectedCountry && citiesData[selectedCountry].map((city) => ({ value: city, label: city }))}
                                                            options={cityOptions}
                                                            value={selectedCity}
                                                            onChange={handleCityChange}
                                                            onInputChange={handleInputChange}
                                                            // noOptionsMessage={noOptionsMessage}
                                                            styles={customStyles2}
                                                            menuIsOpen={isTyping}
                                                        />
                                                    </div>
                                                )}

                                                {/* No of Openings */}
                                                <div className="details">
                                                    <div className="sub-title">Number of openings</div>
                                                    <input type="number" placeholder="e.g. 50" min="0" className="input-text" value={job.numberOfOpeining} onChange={handleJobChange} name="numberOfOpeining" required />
                                                </div>

                                                {/* Intership Start Date */}
                                                {job.opportunityType !== "Job" && (
                                                    <>
                                                        <div className="details">
                                                            <div className="sub-title mb-3">Internship start date</div>
                                                            <div className="d-flex gap-5 align-items-center">
                                                                <div className="d-flex gap-2 align-items-center  justify-content-center">
                                                                    <input type="radio" className="check-input" name="startDate" value="Immediately (within next 30 days)" onChange={handleJobChange} checked={job.startDate === "Immediately (within next 30 days)"} />
                                                                    <div className="check-labels" htmlFor="isPartTimeAllowed">
                                                                        Immediately (Within the next 30 days)
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex gap-2 align-items-center justify-content-center">
                                                                    <input type="radio" className="check-input" name="startDate" value="Later" onChange={handleJobChange} checked={job.startDate === "Later"} />
                                                                    <div className="check-labels" htmlFor="isPartTimeAllowed">
                                                                        Later
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {job.startDate === "Later" && (
                                                            <div className="d-flex flex-column gap-2" style={{ width: "fit-content" }}>
                                                                <div className="take-date">
                                                                    <span>From</span>
                                                                    <input required={job.startDate === "Later"} value={job.internshipFrom} name="internshipFrom" onChange={handleJobChange} type="date" min={new Date().toISOString().split("T")[0]} />
                                                                    <div></div>
                                                                </div>

                                                                <div className="take-date">
                                                                    <span>To</span>
                                                                    <input required={job.startDate === "Later"} value={job.internshipTo} name="internshipTo" onChange={handleJobChange} type="date" min={job.internshipFrom} />
                                                                    <div></div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                {/* Durtaion of Intenship */}
                                                {job.opportunityType == "Internship" && (
                                                    <div className="details">
                                                        <label htmlFor="duration" className="sub-title">
                                                            {" "}
                                                            {job.opportunityType} duration
                                                        </label>
                                                        <span style={{ color: "#bbb", fontSize: "14px" }}>The shorter the duration, the more applications we receive.</span>
                                                        <div className="d-flex align-items-center justify-content-center gap-3">
                                                            <Select
                                                                value={job.duration > 0 ? { label: job.duration, value: job.duration } : { label: "Choose duration", value: job.duration }}
                                                                onChange={(month) => {
                                                                    setJob({
                                                                        ...job,
                                                                        duration: month.value,
                                                                    });
                                                                }}
                                                                options={durationList}
                                                                className="w-75"
                                                            />
                                                            <Select
                                                                className="w-25"
                                                                value={job.duration > 0 ? { label: job.durationType, value: job.durationType } : { label: "months", value: job.durationType }}
                                                                onChange={(type) => {
                                                                    setJob({
                                                                        ...job,
                                                                        durationType: type.value,
                                                                    });
                                                                }}
                                                                options={durationTypeList}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {/* job description and responsibilities */}
                                                <div className="details">
                                                    <label htmlFor="jobDescription" className="sub-title">
                                                        {job.opportunityType == "Job" ? "Job Description" : "Intern’s responsibilities"}
                                                    </label>
                                                    <textarea
                                                        placeholder="Key Responsibilities                   
1.
2.
3."
                                                        type="text"
                                                        rows={5}
                                                        className="input-text"
                                                        style={{ borderColor: job.jobDescription.length > 1900 ? "red" : "" }}
                                                        value={job.jobDescription}
                                                        onChange={handleJobChange}
                                                        name="jobDescription"
                                                        ref={textareaRef}
                                                        required
                                                    ></textarea>
                                                    {job.jobDescription.length > 1900 ? (
                                                        <p className="text-end" style={{ fontSize: "13px", color: "red" }}>
                                                            Please limit your input to 1900 characters or less
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                {/* WHo can apply */}
                                                {job.opportunityType !== "Job" && (
                                                    <div className="details">
                                                        <div className="sub-title"> Who can apply (prefilled as per earlier inputs): </div>
                                                        <div className="px-3 py-4" style={{ backgroundColor: "#f8f8f8", fontSize: "14px", border: "1px solid #ddd" }}>
                                                            Only those candidates can apply who:
                                                            <br />
                                                            1. are available for {job.isPartTimeAllowed == "No" ? "full-time" : "part-time"} {job.jobType == "Remote" ? "work from home" : "In office"} internship
                                                            <br />
                                                            2. have relevant skills and interests
                                                            <br />
                                                            3. can start the {job.jobType == "Remote" ? "work from home" : "In office"} internhship {job.startDate}
                                                            <br />
                                                            4. are available for duration of {job.duration} {job.durationType}
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Preferences */}
                                                <div className="details">
                                                    <label htmlFor="preference" className="sub-title">
                                                        Additional candidate preference:
                                                    </label>
                                                    <textarea
                                                        placeholder={`1. e.g. ${job.opportunityType !== "Job" ? "Candidates pursuing Computer Science Engineering" : "Computer Science Graduate Preferred"}.
2.
3.`}
                                                        type="text"
                                                        rows={4}
                                                        className="input-text"
                                                        value={job.preference}
                                                        onChange={handleJobChange}
                                                        name="preference"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sub-section">
                                            <div className="title" style={{ fontSize: "19px", fontWeight: "500" }}>
                                                {" "}
                                                {job.opportunityType == "Job" ? "Salary" : "Stipend"} & Perks
                                            </div>
                                            <div className="container fields">
                                                {job.opportunityType != "Job" && (
                                                    <div className="details">
                                                        <div className="sub-title mb-3">{job.opportunityType == "Job" ? "Salary" : "Stipend"}</div>
                                                        <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-5 align-items-start align-align-items-lg-center ">
                                                            <div className="d-flex gap-2 align-items-center  justify-content-center">
                                                                <input type="radio" className="check-input" name="salaryProfile" value="Fixed" onChange={handleJobChange} checked={job.salaryProfile === "Fixed"} />
                                                                <div className="check-labels" htmlFor="salaryProfile">
                                                                    Fixed
                                                                </div>
                                                            </div>
                                                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                                                <input type="radio" className="check-input" name="salaryProfile" value="Negotiable" onChange={handleJobChange} checked={job.salaryProfile === "Negotiable"} />
                                                                <div className="check-labels" htmlFor="salaryProfile">
                                                                    Negotiable
                                                                </div>
                                                            </div>
                                                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                                                <input type="radio" className="check-input" name="salaryProfile" value="Performance based" onChange={handleJobChange} checked={job.salaryProfile === "Performance based"} />
                                                                <div className="check-labels" htmlFor="salaryProfile">
                                                                    Peformance based
                                                                </div>
                                                            </div>

                                                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                                                <input type="radio" className="check-input" name="salaryProfile" value="Unpaid" onChange={handleJobChange} checked={job.salaryProfile === "Unpaid"} />
                                                                <div className="check-labels" htmlFor="salaryProfile">
                                                                    Unpaid
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {job.salaryProfile !== "Unpaid" && job.salaryProfile !== "Performance based" ? (
                                                    <div className="details">
                                                        {job.opportunityType == "Job" && <div className="sub-title mb-3">CTC</div>}
                                                        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-4">
                                                            <input className="input-text" name="currency" style={{ cursor: "pointer", maxWidth: "50px" }} value="₹" />
                                                            <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center flex-wrap gap-3">
                                                                <input type="number" min="0" className="input-text" placeholder="10,000" value={job.salary} onChange={handleJobChange} name="salary" required />

                                                                {(job.opportunityType == "Job" || job.salaryProfile == "Negotiable") && (
                                                                    <>
                                                                        <span>to</span>
                                                                        <input readOnly={!job.salary ? true : false} type="number" min={job.salary} className="input-text" value={job.salaryMax} onChange={handleJobChange} name="salaryMax" required />
                                                                    </>
                                                                )}
                                                                <span className="">
                                                                    {job.opportunityType == "Job" ? (
                                                                        "Yearly"
                                                                    ) : (
                                                                        <select style={{ cursor: "pointer" }} className="input-text" value={job.stipendAmountType} required onChange={handleJobChange} name="stipendAmountType">
                                                                            <option value="week">/week</option>
                                                                            <option value="month">/month</option>
                                                                            <option value="lumpsum">lumpsum</option>
                                                                        </select>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {job.salaryProfile == "Performance based" ? (
                                                            <>
                                                                <div className="instruction">For output-driven performance-based internships, such as Business Development (Sales), etc. we insist on a minimum assured stipend (₹1000/month for a work from home internship and ₹2000/month for an in-office internship) and the rest could be incentive-based</div>
                                                                <div className="details">
                                                                    Minimum Assured
                                                                    <div className="d-flex align-items-center flex-wrap gap-4">
                                                                        <input className="input-text" name="currency" style={{ cursor: "pointer", maxWidth: "50px" }} value="₹" />
                                                                        <input type="number" min="0" className="input-text" placeholder="10,000" value={job.salary} onChange={handleJobChange} name="salary" required />
                                                                        <select style={{ cursor: "pointer" }} className="input-text" value={job.stipendAmountType} required onChange={handleJobChange} name="stipendAmountType">
                                                                            <option value="week">/week</option>
                                                                            <option value="month">/month</option>
                                                                            <option value="lumpsum">lumpsum</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="details">
                                                                    Incentive based
                                                                    <div className="d-flex align-items-center flex-wrap gap-4">
                                                                        <input className="input-text" name="currency" style={{ cursor: "pointer", maxWidth: "50px" }} value="₹" />
                                                                        <input type="number" min="0" className="input-text" placeholder="e.g. 750" value={job.incentive} onChange={handleJobChange} name="incentive" required />
                                                                        <span>per</span>
                                                                        <select style={{ cursor: "pointer" }} className="input-text" value={job.incentiveType} required onChange={handleJobChange} name="incentiveType">
                                                                            <option value="sale">sale</option>
                                                                            <option value="design">design</option>
                                                                            <option value="write up">write up</option>
                                                                            <option value="video">video</option>
                                                                            <option value="data entry">data entry</option>
                                                                            <option value="others">others</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div style={{ color: "#8A8A8A" }}>We allow unpaid internships only in the case of NGOs or niche profiles</div>
                                                        )}
                                                    </>
                                                )}

                                                <div className="details">
                                                    <div className="sub-title mb-3">
                                                        Perks <span style={{ fontSize: "13px", color: "#8A8A8A" }}>(Optional)</span>
                                                    </div>
                                                    <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3">
                                                        <div className="d-flex gap-3 flex-column" style={{ maxWidth: "100%", minWidth: "50%" }}>
                                                            <div className="d-flex align-items-center gap-3">
                                                                <input className="check-input" type="checkbox" value="Certificate" onChange={() => handlePerksChange("Certificate")} checked={job?.perks?.includes("Certificate")} />
                                                                <div className="check-labels" htmlFor="perks">
                                                                    Certificate
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center gap-3">
                                                                <input className="check-input" type="checkbox" value="Flexible Work hours" onChange={() => handlePerksChange("Flexible Work hours")} checked={job?.perks?.includes("Flexible Work hours")} />
                                                                <div className="check-labels" htmlFor="perks">
                                                                    Work hours flexibility
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center gap-3">
                                                                <input className="check-input" type="checkbox" value="Informal dress code" onChange={() => handlePerksChange("Informal dress code")} checked={job?.perks?.includes("Informal dress code")} />
                                                                <div className="check-labels" htmlFor="perks">
                                                                    Informal dress code
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex gap-3 flex-column" style={{ maxWidth: "100%", minWidth: "50%" }}>
                                                            <div className="d-flex align-items-center gap-3">
                                                                <input className="check-input" type="checkbox" value="Letter of recommendation" onChange={() => handlePerksChange("Letter of recommendation")} checked={job?.perks?.includes("Letter of recommendation")} />
                                                                <div className="check-labels" htmlFor="perks">
                                                                    Letter of recommendation
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center gap-3">
                                                                <input className="check-input" type="checkbox" value="5 days of week" onChange={() => handlePerksChange("5 days of week")} checked={job?.perks?.includes("5 days of week")} />
                                                                <div className="check-labels" htmlFor="perks">
                                                                    5 days of week
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center gap-3">
                                                                <input className="check-input" type="checkbox" value="Free Snacks & beverages" onChange={() => handlePerksChange("Free Snacks & beverages")} checked={job?.perks?.includes("Free Snacks & beverages")} />
                                                                <div className="check-labels" htmlFor="perks">
                                                                    Free Snacks & beverages
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sub-section">
                                            <div className="fields" style={{ backgroundColor: "#f8f8f8" }}>
                                                <div>Alternate mobile number for this listing</div>
                                                <span style={{ color: "#8a8a8a" }}>Our team will contact you at this number for any inquiries specifically related to this listing. Please note that the primary account number will not be altered.</span>
                                                <div className="d-flex gap-2 align-items-center">
                                                    <input type="text" value="+91" className="input-text w-25" />
                                                    <input type="text" value={job.hrContact} name="hrContact" onChange={handleJobChange} className="input-text w-75" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-100 d-flex align-items-center justify-content-center justify-content-md-end">
                                            {loading22 ? (
                                                <>
                                                    <button style={{ backgroundColor: "#B3B3B3", border: "1px solid #B3B3B3", zIndex: "100" }} className="submit-btn px-4 py-2 text-white">
                                                        Update {job.opportunityType}
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button type="submit" className="submit-btn px-4 py-2 text-white" style={{ zIndex: "100" }}>
                                                        Update {job.opportunityType}
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/*EDIT APPLICATION CODE END HERE*/}
                        </div>
                    </div>
                </div>
            </div>

            <Popup open={showModal} modal closeOnDocumentClick={false}>
                <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                    <div className="popup-containernew">
                        <div className="success-message mx-3" style={{ marginBottom: "10px" }}>
                            Are you sure you want to delete? This action cannot be undone.
                        </div>
                        <div style={{ display: "flex", alignItems: "center", alignSelf: "center", gap: "10px", marginBottom: "15px" }}>
                            <button className="employerbuttonbacktodash" onClick={handleCancelDelete}>
                                Cancel
                            </button>
                            <button className="employerbuttontobuyinformation" style={{ backgroundColor: "#ED5E68" }} onClick={handleConfirmDelete}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default AdminPostedJobsAndInternships;
