import React from 'react';
import '../css/LandingPageNew.scss';

const TrendingSkills = () => {
    const skills = {
        technical: [
            "Generative AI Specialists",
            "Data Entry Specialists",
            "Video Editors",
            "Data Analyst",
            "Shopify Developer",
            "Ruby on Rails Developer",
            "Android Developer",
            "Bookkeeper",
            "Content Writer",
            "Copywriter",
            "Data Scientist",
            "Front-End Developer",
            "Game Developer",
            "Graphic Designer",
            "iOS Developer",
            "Java Developer",
        ],
        creative: [
            "JavaScript Developer",
            "Logo Designer",
            "Mobile App Developer",
            "PHP Developer",
            "Python Developer",
            "Resume Writer",
            "SEO Expert",
            "Social Media Manager",
            "Software Developer",
            "Software Engineer",
            "Technical Writer",
            "UI Designer",
            "UX Designer",
            "Virtual Assistant",
            "Web Designer",
            "Wordpress Developer",
        ]
    };

    return (
        <div className="skills-container">
            <div className="skills-grid">

                <div className="skills-column">
                    <h1 className="skills-title">Top skills</h1>
                    <h2 className="trending-title">Trending skills</h2>
                </div>

                <div className="skills-column">
                    {skills.technical.map((skill, index) => (
                        <div key={index} className="skill-item">{skill}</div>
                    ))}
                </div>

                <div className="skills-column">
                    {skills.creative.map((skill, index) => (
                        <div key={index} className="skill-item">{skill}</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TrendingSkills;