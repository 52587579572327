import React, { useState } from 'react'

import Spinner from '../Layout/Spinner'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { eyeClosed,eyeOpen } from '../../assets';
function AdminSignup() {

  const navigate = useNavigate();
  const [user, setUser] = useState({ role: "Admin"  });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  const [hideShowPassword,sethideShowPassword] = useState(false)

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let isValid = true;
    if (!values.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!values.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
      isValid = false;
    }

    if (!values.password) {
      errors.password = "password is required";
      isValid = false;
    }

   

    setFormErrors(errors);
    return isValid;
  };



  const handleUserChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const handleSignup = async () => {
    console.log('Start of handleSignup');
  
    if (!validate(user)) {
      return;
    }
  
    try {
      setLoading(true);
      await axios.post('/api/admin/create', user);
        toast.success('Signup successful!');
      navigate('/AdminLogin');
    } catch (error) {
      console.error('Error during signup:', error);
      toast.error('Error during signup. Please try again.');
    } finally {
      setLoading(false);
    }
  
    console.log('End of handleSignup');
  };
  return (
    <>
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

            <div className="col-lg-5 col-md-5 col-sm-12 my-5 empform">
              <form className="form-box">
                <div className="form">
                <h2 className="title text-center">Admin-Sign Up</h2>
                  <TextField
                    type="text"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin = "normal"
                    name="name"
                    onChange={handleUserChange}
                    id="name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fa fa-user" style={{ fontSize: "20px" }}></i>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin = "normal"
                    name="email"
                    onChange={handleUserChange}
                    id="email"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fa fa-envelope" ></i>
                        </InputAdornment>
                      ),
                    }}
                  />


                  <TextField
                    type={hideShowPassword ? "text" : "password"}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin='normal'
                    name="password"
                    onChange={handleUserChange}
                    id="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {hideShowPassword ? (<img src={eyeOpen} className="icons" style={{cursor:"pointer"}} alt="password" onClick={()=> sethideShowPassword(!hideShowPassword)}/>) : (<img src={eyeClosed} className="icons" alt="password" style={{cursor:"pointer"}} onClick={()=> sethideShowPassword(!hideShowPassword)}/>) }
                        </InputAdornment>
                      ),
                    }}
                  />
        
                  <button className="btn btnsign" style={{ width: "100%", marginTop: "20px" }}  onClick={handleSignup} >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <span style={{ fontSize: "1.6rem" }}  
                      >Sign </span>
                    )}
                  </button>
                  <p className="text-center pt-4" style={{ fontSize: "13px" }}>
                    Already have an Account?{" "}
                    <Link to="/AdminLogin" className="font-weight-bold" style={{ fontSize: "13px" }}>
                      Log In
                    </Link>
                  </p>
                  <div className="row my-2">
                  </div>
                </div>
              </form>
            </div>
            </div>
            <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>
  )
}

export default AdminSignup