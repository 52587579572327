import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Spinner from '../Layout/Spinner'
import CoverLetterQuestion from './CoverLetterQuestion'
import './css/CreateJobs.scss'
import JobStipends from './JobStipends'
import PostJobForm from './PostJobForm'

function CreateJobs() {


    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0)
      },[]);

    const [stepFormPage, setStepFormPage] = useState(1);
    const [draftSavedText, setDraftSavedText] = useState(false);
    const [viewDraftModal, setViewDraftModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [job, setJob] = useState({
        opportunityType: "",
        startDate: "",
        jobType: "",
        internshipProfile: "",
        skillsRequired: [],
        cities: "",
        isPartTimeAllowed: false,
        noOfOpenings: 0,
        duration: 8,
        salaryProfile: "",
        perks: []
    });

    const pageTitle = [
        "Post Internships/Jobs",
        "Stipends and Perks",
        "Cover Letter Availability and Assesment Question"
    ]

    const showDraftSavedText = () => {
        setDraftSavedText(true);

        setTimeout(() => {
            setDraftSavedText(false);
        }, 3000);
    }

    const nextPage = () => {
        if (stepFormPage >= pageTitle.length) {
            return;
        }
        console.log(job);
        setStepFormPage(stepFormPage + 1);
    }

    const previousPage = () => {
        if (stepFormPage <= 1) {
            return;
        }

        setStepFormPage(stepFormPage - 1);
    }

    const setJobValueByName = (event) => {
        setJob({ ...job, [event.target.name]: event.target.value })
    }

    const saveDraft = () => {
        localStorage.setItem("jobDraft", JSON.stringify(job));

        showDraftSavedText();
    }

    const deleteDraft = () => {
        localStorage.removeItem("jobDraft");
        setViewDraftModal(false);
    }

    const submit = async (event) => {
        event.preventDefault();

        setLoading(true);

        try {
            const { data } = await axios.post("/api/employer/postJob", job);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="post-job-form">
                {localStorage.getItem("jobDraft") && viewDraftModal && <div className='modal-box job-draft-modal-box'>
                    <div className="modal">
                        <h3 style={{ margin: ".5rem" }}>I have found a saved Job Draft on this PC?</h3>
                        <div className="buttons" style={{ marginTop: "3rem" }}>
                            <button className="btn btn-primary" onClick={() => { setJob(JSON.parse(localStorage.getItem("jobDraft"))); setViewDraftModal(false) }} style={{ margin: ".5rem" }}>View Draft</button>
                            <button className="btn btn-danger" onClick={deleteDraft} style={{ margin: ".5rem" }}>Delete Draft</button>
                            <button className="btn btn-primary" onClick={() => { setViewDraftModal(false) }} style={{ margin: ".5rem" }}>Keep it saved and Add a new one</button>
                        </div>
                    </div>
                </div>}
                <form onSubmit={submit} className="job-form">
                    <h3 className="title"> {pageTitle[stepFormPage - 1]} </h3>
                    {stepFormPage === 1 && <PostJobForm job={job} setJob={setJob} setJobValueByName={setJobValueByName} />}
                    {stepFormPage === 2 && <JobStipends job={job} setJob={setJob} setJobValueByName={setJobValueByName} />}
                    {stepFormPage === 3 && <CoverLetterQuestion job={job} setJob={setJob} setJobValueByName={setJobValueByName} />}
                    {draftSavedText && <div style={{ textAlign: "center", fontSize: "1.4rem", marginTop: "1rem", color: "blueviolet" }}><strong>Draft Saved!</strong></div>}
                    <div className="move-page-buttons">
                        <button type='button' disabled={stepFormPage <= 1} onClick={previousPage} className='btn btn-primary'>&larr; Previous</button>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <button type='button' className='btn btn-primary' onClick={saveDraft}>Save Draft</button>
                            {stepFormPage === pageTitle.length &&
                                <button type='submit' className='btn btn-primary'>
                                    {loading ? <Spinner /> : "Finish"}
                                </button>
                            }
                            {stepFormPage !== pageTitle.length &&
                                <button type='button' disabled={stepFormPage >= pageTitle.length} onClick={nextPage} className='btn btn-primary'>Next &rarr;</button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CreateJobs