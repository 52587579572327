import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
// import Logo from '../../assets/images/logo.png'
import Spinner from '../Layout/Spinner';
import Logo from '../../assets/images/Workcroft.png'
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function ResetPassword() {


    const navigate = useNavigate();
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({ email: state.email});

    const handleUserChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
      };
   

      const changepassword = async () => {

        setLoading(true);
        if(user.newpassword==""){
            setLoading(false);
            toast.error("Please enter the new password")
        }
        else if(user.conpassword==""){
          setLoading(false);
          toast.error("Please enter the confirm password")
      }
        else if(user.newpassword != user.conpassword){
          setLoading(false);
          toast.error("Password does not matched!!")
        }
        else{
          try {
           const { data } = await axios.post("/api/user/passwordchange", user);
            setLoading(false);
            toast.success("Password Changed Successfully", {
              style: {
                padding: '18px',  
              }
            });  
            const timer = setTimeout(() => {
                navigate('/login'); 
              }, 2000);
            
        timer();
      
          } catch (error) {
        
            setLoading(false);
            toast.error(error.response.data, {
              style: {
                padding: '18px',  
              }
            });
          
        }}

      }
  
  

   {    /* const resetPassowrd = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const { state } = await axios.post(`/api/user/resetPassword/${token}`, {
                password, cpassword
            });
            setResponse(data.message);
        } catch (error) {
            setResponse(error.response.data.message);
        } finally {
            setLoading(false);
        }
    }
*/}


useEffect(() => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const myButton = document.querySelector('.changePassword');
      if (myButton) {
        myButton.click();
      } else {
        console.error('Button not found');
      }
    }
  };
  // Attach the event listener to the document
  document.addEventListener('keydown', handleKeyPress);
  // Clean up the event listener when the component is unmounted
  return () => {
    document.removeEventListener('keydown', handleKeyPress);
  };
}, []);
    return (
<>
        <div className="login-component">
            <div className="form-box">
                <div  className="form">
                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: "3rem" }}>
                        
                        <img src={Logo} alt="logo" style={{ width: "100px", height: "auto" }} />
                    </div>
                    <p className='text-center' style={{fontSize: "22px",  fontWeight: "bold"}}>Reset Your Password</p>
                    <p className='text-center'><b style={{color: "#2874A6"}}>{state.email}</b></p>
                    <div className="input-box mt-5">
                        <label htmlFor="email">Enter new Password</label>
                        <input type="password" name="newpassword"  id="newpassword"  onChange={handleUserChange} value={user.newpassword} />
                    </div>
                    <div className="input-box">
                        <label htmlFor="email">Confirm Password</label>
                        <input type="password" name="conpassword"  id="conpassword"  onChange={handleUserChange} value={user.conpassword}/>
                    </div>
                    { /* <div className="input-box">
                        <label htmlFor="password">Password</label>
                        <Link to='/forgot' className='forgot'>Forgot Password?</Link>
                        <input type="password" name="password" value={user.password} onChange={handleUserChange} id="password" />
                    </div> */}
                   <button onClick={changepassword} className="btn btn-primary changePassword" style={{ width: "100%", marginTop: "2rem" }}>
                        {loading ? <Spinner /> : <span>Change Password</span>}
                    </button>
                </div>
            </div>
        </div>
        <ToastContainer
      position="top-center"
      autoClose={10000}
      />
        </>
    )
}

export default ResetPassword