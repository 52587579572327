import React, { useState } from "react";
import "./css/CheckOpening.scss";
const internshipData = [
    { id: 1, title: "Full stack development", status: "Closed", action: "View Application" },
    { id: 2, title: "Marketing Intern", status: "Closed", action: "View Application" },
    { id: 3, title: "Design Intern", status: "Closed", action: "View Application" }
  ];
  const jobData = [
    { id: 1, title: "Front-end Developer", status: "Closed", action: "View Application" },
    { id: 2, title: "Product Manager", status: "Closed", action: "View Application" },
    { id: 3, title: "Data Scientist", status: "Closed", action: "View Application" }
  ];  

  const JobTable = ({ data }) => {
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
  
    function handleMenuClick(index) {
      setOpenMenuIndex(openMenuIndex === index ? null : index);
    }
  
    return (
      <div className="container-fluid py-5 text-center table-box table-responsive " style={{marginTop: "80px",marginBottom: "80px", borderRadius: "18px", borderStyle: "solid", borderWidth: "1px", maxWidth: "1200px"}} >
        <table className="maintable applications table table-hover" 
            border="0" cellSpacing="0" cellPadding="0" style={{marginTop: "-32px", marginBottom: "-30px"}}>
          <thead >
            <tr >
              <th style={{backgroundColor: "white"}}>Title</th>
              <th style={{backgroundColor: "white"}}>STATUS</th>
              <th style={{backgroundColor: "white"}}>ACTION</th>
              <th style={{backgroundColor: "white"}}>SHARE AND OTHER</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{row.title}</td>
                <td className="font-weight-bold">{row.status}</td>
                <td className="font-weight-bold">{row.action}</td>
                <td>
                  <div className="three-dots-menu" style={{ paddingLeft: "60px" }}>
                    <div className="menu-icon" onClick={() => handleMenuClick(index)}>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                    {openMenuIndex === index && (
                      <div className="menu">
                        <ul>
                          <li>View Job</li>
                          <li>Close Job</li>
                          <li>Extend deadline</li>
                          <li>Post similar job</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  
const CheckOpening = () => {
    const [showInternships, setShowInternships] = useState(true);

  const handleInternshipClick = () => {
    setShowInternships(true);
  };

  const handleJobClick = () => {
    setShowInternships(false);
  };

  return (
    <div className="container maincheck py-5">
        <div className="btngroup text-center">
        <button className="btn intern" onClick={handleInternshipClick}>Internships</button>
      <button className="btn job" onClick={handleJobClick}>Jobs</button>
      {showInternships ? <JobTable data={internshipData} /> : <JobTable data={jobData} />}

        </div>
    </div>
  );

}
const rows = [
    {
      profile: "Front-end Developer",
      status: "Closed",
      totalViews: "View Applications",
    },
    {
      profile: "Back-end Developer",
      status: "Closed",
      totalViews: "View Applications",
    },
    {
      profile: "Full-stack Developer",
      status: "Closed",
      totalViews: "View Applications",
    },
    {
      profile: "Back-end Developer",
      status: "Closed",
      totalViews: "View Applications",
    },
    {
      profile: "Full-stack Developer",
      status: "Closed",
      totalViews: "View Applications",
    },
    {
      profile: "Front-end Developer",
      status: "Closed",
      totalViews: "View Applications",
    },
    {
      profile: "Full-stack Developer",
      status: "Under review",
      totalViews: 1024,
    },
    {
      profile: "Front-end Developer",
      status: "Closed",
      totalViews: "View Applications",
    },
  ];

export default CheckOpening