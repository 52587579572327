import React, { useState } from 'react';
import "./css/chooseFreelancerOrEmployer.scss";
import { worker,userWithLaptop } from '../../assets';
import { Link } from 'react-router-dom';

const ChooseFreelancerOrEmployer = () => {
    const [selectedRole, setSelectedRole] = useState('');

    return (
        <div className="join-page">
            <h1>Join as employer or freelancer</h1>

            <div className="role-cards">
                <label  className={`role-card ${selectedRole === 'employer' ? 'selected' : ''}`}>
                    <div className="card-content">
                        <div className="card-details">
                            <div className="icon">
                                <img src={worker} alt="worker icon" />
                            </div>
                            <p className="role-title">I'm an employer, <br/> hiring for a project</p>
                        </div>
                        <div className="radio-wrapper">
                            <input 
                                type="radio"
                                name="role"
                                value="employer"
                                checked={selectedRole === 'employer'}
                                onChange={() => setSelectedRole('employer')}
                            />
                            <span className="custom-radio"></span>
                        </div>
                    </div>
                </label>

                <label className={`role-card ${selectedRole === 'freelancer' ? 'selected' : ''}`}>
                    <div className="card-content">
                        <div className="card-details">
                            <div className="icon">
                                <img src={userWithLaptop} alt="worker icon" />
                            </div>
                            <p className="role-title">I'm a freelancer, <br />looking for work</p>
                        </div>
                        <div className="radio-wrapper">
                            <input 
                                type="radio"
                                name="role"
                                value="freelancer"
                                checked={selectedRole === 'freelancer'}
                                onChange={() => setSelectedRole('freelancer')}
                            />
                            <span className="custom-radio"></span>
                        </div>
                    </div>
                </label>
            </div>

            <Link to={`${selectedRole === 'employer'  ? '/employeeSignup' : '/freelancerSignup'}`}>
            {selectedRole === "" ? (
                <button className={`${selectedRole === '' ?  "disable-button" : 'join-button'}`}>
                    Create Account
            </button>
            ) : (<button className={`${selectedRole === '' ?  "disable-button" : 'join-button'}`}>
                Join as  {selectedRole === 'employer' ? 'an Employer' : 'a Freelancer'}
            </button>)}
            
            
            </Link>

            <div className="login-section">
                <p>Already have an account? <a href="/login">Log In</a></p>
            </div>
        </div>
    );
}

export default ChooseFreelancerOrEmployer;