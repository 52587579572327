import React, { useEffect } from 'react'
import GetStarted from './components/GetStarted'
import PerformanceSection from './components/Performance'
import EnterprisePackage from './components/Enterprises'
import "./css/LandingPageNew.scss"
import Categories from './Categories'
import TalentDiscovery from './components/TalentDiscover'
import BusinessBenefits from './components/BusinessBenefits'
import TalentOpportunities from './components/Opportunity'
import TrendingSkills from './components/Skills'
import Footer from './components/Footer'
const LandingPageNew = () => {
  useEffect(() => {
    window.scroll(0,0);
  },[])
  return (
    <div className='mainWrapper'>
      <GetStarted />
      <PerformanceSection />
      <Categories />
      <EnterprisePackage />
      <TalentDiscovery />
      <BusinessBenefits />
      <TalentOpportunities />
      <TrendingSkills />

    </div>
  )
}

export default LandingPageNew