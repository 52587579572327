import React from 'react';
import "../css/LandingPageNew.scss"
import { Link } from 'react-router-dom';
const TalentDiscovery = () => {
  return (
    <div className="talent-discovery">
      <div className="content-wrapper">
        <span className="client-label">For Clients</span>

        <h1 className="main-title">
          Discover talent<br />
          your own way
        </h1>

        <p className="description">
          Collaborate with the largest network of independent professionals
          to achieve everything from fast turnarounds to significant transformations.
        </p>

        <div className="cards-container">
          <div className="card publish">
            <h2>Publish a job listing and hire a pro</h2>
            <div className="linkArrow">
              <a href="/signup">Talent Marketplace →</a>
            </div>
          </div>

          <div className="card solutions">
            <h2>Tailored Solutions for Your Needs</h2>
          </div>

          <div className="card quality">
            <h2>Quality Assurance and Support</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentDiscovery;