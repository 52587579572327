// TalentCategories.jsx
import React from 'react';
import './css/LandingPageNew.scss';
import { Link } from 'react-router-dom';

const Categories = () => {
  const categories = [
    { title: 'Development & IT', rating: 4.85, skills: 1853 },
    { title: 'AI Services', rating: 4.85, skills: 1853 },
    { title: 'Design & Creative', rating: 4.85, skills: 1853 },
    { title: 'Sales & Marketing', rating: 4.85, skills: 1853 },
    { title: 'Writing & Translation', rating: 4.85, skills: 1853 },
    { title: 'Admin & Customer Support', rating: 4.85, skills: 1853 },
    { title: 'Finance & Accounting', rating: 4.85, skills: 1853 },
    { title: 'Engineering & Architecture', rating: 4.85, skills: 1853 },
  ];

  return (
    <div className="talent-categories">
      <h1 className="title">Browse talent by category</h1>
      <p className="subtitle">
        Looking for work? <Link  to="/matchedHourlyProject/all" className="browse-link">Browse jobs</Link>
      </p>

      <div className="categories-grid">
        {categories.map((category, index) => (
          <div key={index} className="category-card">
            <h2 className="category-title">{category.title}</h2>
            <div className="category-stats">
              <div className="rating">
                <svg className="star-icon" viewBox="0 0 24 24">
                  <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                </svg>
                <span>{category.rating}/5</span>
              </div>
              <div className="skills">{category.skills} skills</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;